@import '../../../scss/settings';

.FileModalDetailView {
    background: var(--ui-modal-background-primary);

    overflow: hidden;

    .FilePreviewUnavailable {
        padding-bottom: 56.25%;
    }

    .editable-title {
        color: var(--ws-element-primary);
        font-family: $font-link-file-heading;
        font-size: $font-size-link-file-heading;
        text-decoration: none;
    }
}
