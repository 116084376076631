@import '../../../../../scss/settings';

.checklist-ul {
    margin: 0 0 $element-paragraph-spacing-default 0;
}

.ChecklistEditorBlock {
    position: relative;
    padding-left: 0;

    transition: color $checkbox-animation-duration linear;

    .text {
        .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
            margin-bottom: $element-paragraph-spacing-default;
        }
    }

    &.checked {
        color: var(--ws-task-completed);
    }

    &.editable {
        .Checkbox {
            cursor: pointer;
        }
    }

    .Checkbox {
        display: inline-block;
        position: absolute;
        top: 0.2rem;
        left: -2.7rem;
    }
}
