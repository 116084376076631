@import '../../../../scss/settings';

.IFrameVideoPlayer {
    position: relative;
    height: 0;
    background: $black;
    overflow: hidden;

    // Slightly enlarging the iFrame to prevent 1px black borders
    iframe {
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);

        // For some reason iPads need this in order to interact with the iFrame
        z-index: 10;
    }
}
