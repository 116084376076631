.HotTable.presentational-table {
    --table-cell-border-rendered-width: 1px;
    --negative-border-width: calc(0px - var(--table-cell-border-rendered-width));

    .MilanoteCellRendererComponent {
        .presentation-cell-content {
            color: var(--ws-element-primary);
            font-size: var(--font-size-ui-default);
            line-height: var(--line-height-element);
            white-space: pre-wrap;
            overflow-wrap: break-word;

            &.DraftEditor-alignCenter {
                text-align: center;
            }

            &.DraftEditor-alignRight {
                text-align: right;
            }

            &.DraftEditor-alignLeft {
                text-align: left;
            }
        }

        &.colored-background-light .presentation-cell-content {
            color: var(--ws-table-cell-text-dark);
        }

        &.colored-background-dark .presentation-cell-content {
            color: var(--ws-table-cell-text-light);
        }
    }
}
