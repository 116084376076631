@import '../../scss/settings';
@import '../../scss/mixins/fade-transition-group-mixin';

.PopupPanel {
    @include fade-transition-group-mixin;

    position: absolute;
    border-radius: $ui-border-radius-default;

    background: var(--ui-modal-background-primary);

    font-family: $font-body;
    font-size: $font-size-ui-default;

    box-shadow: var(--element-shadow-elevation-3);
    z-index: var(--z-index-modal);

    user-select: text;

    // Note: We cannot use overflow: hidden here because the arrow would get hidden

    &.arrow-top {
        @include arrow('top', 6px, 149px, var(--ui-modal-background-primary));
    }

    &.arrow-left {
        @include arrow('left', 6px, 149px, var(--ui-modal-background-primary));
    }

    &.arrow-bottom {
        @include arrow('bottom', 6px, 149px, var(--ui-modal-background-primary));
    }

    &.arrow-right {
        @include arrow('right', 6px, 149px, var(--ui-modal-background-primary));
    }

    .mobile-header {
        display: none;
    }

    &.padded {
        .popup-content {
            padding: 1rem 1rem 1.4rem;
        }
    }

    .popup-content {
        border-radius: $ui-border-radius-default;

        color: var(--ui-modal-foreground-primary);
        line-height: 1.31em;
        text-align: left;

        // Ensures the border radius of the popups are adhered to
        overflow: hidden;

        > p {
            padding: 1rem 1.4rem;
            color: var(--ui-modal-foreground-primary);
        }

        > .link-list {
            a {
                display: block;
                padding: 1rem 1.4rem;
                color: var(--ui-modal-foreground-secondary);
                text-decoration: none;

                &:hover {
                    background: var(--ui-modal-background-secondary);
                }
            }
        }
    }
}
