@import '../../../../scss/settings';

.DrawingEditorCanvasDrawMode {
    // stylelint-disable declaration-block-no-duplicate-properties
    cursor: url('./cursors/cursor-drawing-pen.png') 1 17, auto;
    cursor: image-set(url('./cursors/cursor-drawing-pen.png') 1x, url('./cursors/cursor-drawing-pen@2x.png') 2x) 1 17,
        auto;
    cursor: -webkit-image-set(url('./cursors/cursor-drawing-pen.png') 1x, url('./cursors/cursor-drawing-pen@2x.png') 2x)
            1 17,
        auto;
    // stylelint-enable declaration-block-no-duplicate-properties

    .dark-theme .AnnotationEditor &,
    .dark-theme .SketchModal:not(.colored-background-light) &,
    .colored-background-dark & {
        // stylelint-disable declaration-block-no-duplicate-properties
        cursor: url('./cursors/cursor-drawing-pen-dark.png') 1 17, auto;
        cursor: image-set(
                    url('./cursors/cursor-drawing-pen-dark.png') 1x,
                    url('./cursors/cursor-drawing-pen-dark@2x.png') 2x
                )
                1 17,
            auto;
        cursor: -webkit-image-set(
                    url('./cursors/cursor-drawing-pen-dark.png') 1x,
                    url('./cursors/cursor-drawing-pen-dark@2x.png') 2x
                )
                1 17,
            auto;
        // stylelint-enable declaration-block-no-duplicate-properties
    }
}

.AnnotationEditor {
    &.drawing {
        .DrawingEditorToolbar {
            transform: translate(0, 15px);
            opacity: 0;
            pointer-events: none;
        }
    }
}
