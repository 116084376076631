@import '../../../scss/settings';
@import '../../../scss/mixins/outer-border-mixin';

.ShortcutKey {
    @include overlapping-border-full(1px, var(--ui-shortcut-key-border-color));

    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    min-width: 2.8rem;
    height: 2.4rem;

    margin: 0 0.4rem;
    padding: 0 1rem;

    border-radius: 0.4rem;
    background: var(--ui-shortcut-key-background-color);

    color: var(--ui-shortcut-key-text-color);
    font-size: $font-size-ui-small;
    font-weight: normal;
    text-transform: capitalize;
    font-feature-settings: 'zero'; // Use the slashed zero font feature to improve discernibility https://rsms.me/inter/#features/zero

    &:before {
        border-radius: 0.4rem;
    }

    &.char {
        padding: 0;
    }

    &.shortcut-small {
        min-width: 1.8rem;
        height: 1.8rem;
        border-radius: 0.2rem;
        line-height: $font-size-ui-small;

        &:before {
            border-radius: 0.2rem;
        }

        .app-size-medium & {
            min-width: 16px;
            height: 16px;
        }

        .app-size-small & {
            min-width: 14px;
            height: 14px;
            padding-top: 1px;
        }
    }

    &.shift-up {
        padding-bottom: 2px;
    }

    & + .ShortcutKey {
        margin-left: 0;
    }
}
