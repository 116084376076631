@import '../../../scss/settings';

.FullScreenModal.overlay .Modal.file {
    background: none;
    box-shadow: var(--modal-box-shadow);
}

.FileModal {
    display: flex;
    width: 100%;
    height: 100%;

    &.native-view {
        display: flex;
        width: 80vw;
        height: 90vh;
    }
}
