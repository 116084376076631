@mixin list-placeholder-shadow() {
    background: var(--container-background);

    .light-theme & {
        filter: brightness(0.96);
    }

    .colored-background-light.colored-background-light & {
        filter: brightness(0.93);
    }

    .dark-theme &,
    .colored-background-dark.colored-background-dark & {
        filter: brightness(0.78);
    }
}
