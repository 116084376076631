.NumberInput {
    display: flex;
    position: relative;
    width: fit-content;

    .number-input {
        align-self: start;
        width: 6.5rem;
        padding: 0.6rem 0.5rem 0.5rem 0.9rem;
        border: 1px solid var(--ui-input-border);
        border-radius: 3px;
        outline: none;
        background: none;
        color: var(--ui-foreground-primary);
        font-family: var(--font-default), 'Helvetica', 'Arial', sans-serif;
        font-size: var(--font-size-ui-small);
        line-height: 1.75rem;
        -webkit-appearance: none;

        /* Remove default arrows Chrome, Safari, Edge */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Remove default arrows Firefox */
        &[type='number'] {
            -moz-appearance: textfield;
        }
    }
    .number-input-buttons {
        display: flex;
        position: absolute;
        top: 1px;
        right: 3px;
        flex-direction: column;
        justify-content: space-between;
        width: max-content;
        height: calc(100% - 2px);
        padding: 0.15rem 0;

        button {
            width: 1.2rem;
            height: 1.25rem;
            padding: 0;
            border: none;
            background: none;
            font-size: 1.2rem;
            cursor: pointer;

            svg {
                width: 1.2rem;
                height: 1.25rem;

                path:first-child {
                    fill: var(--ui-input-checkbox-background);
                }
            }
        }
    }
}
