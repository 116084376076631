// FIXME - Remove the "modern" prefix from the variable names when we remove the old UI
:root {
    --safe-area-top: var(--capacitor-safe-area-top, env(safe-area-inset-top, 0px));
    --safe-area-bottom: var(--capacitor-safe-area-bottom, env(safe-area-inset-bottom, 0px));
    --safe-area-left: var(--capacitor-safe-area-left, env(safe-area-inset-left, 0px));
    --safe-area-right: var(--capacitor-safe-area-right, env(safe-area-inset-right, 0px));

    --modern-mobile-header-height: 50px;
    --modern-mobile-toolbar-height: 78px;

    // Since this is used often, sum the toolbar and safe area heights
    --workspace-safe-area-bottom: calc(var(--modern-mobile-toolbar-height) + var(--safe-area-bottom));

    --keyboard-animation-reveal-delay: 0.2s;
    --keyboard-animation-reveal-duration: 0.25s;

    --keyboard-animation-reveal-control-point-1-x: 0.3;
    --keyboard-animation-reveal-control-point-1-y: 0;
    --keyboard-animation-reveal-control-point-2-x: 0;
    --keyboard-animation-reveal-control-point-2-y: 1;

    --keyboard-animation-hide-delay: 0s;
    --keyboard-animation-hide-duration: 0.25s;

    --keyboard-animation-hide-control-point-1-x: 0.1;
    --keyboard-animation-hide-control-point-1-y: 0.5;
    --keyboard-animation-hide-control-point-2-x: 0;
    --keyboard-animation-hide-control-point-2-y: 1;

    --keyboard-height: 0px;
    --current-keyboard-height: 0px;

    .browser-engine-ios-safari {
        --keyboard-animation-reveal-delay: 0.1s;
        --keyboard-animation-reveal-duration: 0.2s;
        --keyboard-animation-hide-delay: 0s;
        --keyboard-animation-hide-duration: 0.3s;
    }

    .keyboard-open {
        --current-keyboard-height: var(--keyboard-height);
    }
}
