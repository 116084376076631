@import '../../scss/settings';

.LinkButton {
    color: var(--ui-accent-primary);
    text-decoration: underline;

    &.primary {
        background: none;
        color: var(--ui-accent-primary);
    }

    &.secondary {
        color: var(--ui-foreground-tertiary);
    }

    &:disabled {
        background: none;
    }
}
