@import '../../../../scss/settings';

.PopupModeMenuSearchForm {
    position: relative;
    margin-top: auto;

    .PopupTextInput {
        padding: 0.8rem 3.2rem 0.8rem 1rem;
    }
}
