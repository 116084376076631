.DualColorIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;

    .diagonal-half-icon {
        width: 100%;
        height: 100%;
    }

    &.letter {
        position: absolute;
        top: 0;
        left: 0;

        .letter-icon {
            font-size: 70cqh;
            font-weight: 600;
        }
    }
}
