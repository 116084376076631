@import '../../../scss/settings';

.ToolSeparator {
    display: flex;
    justify-content: center;

    margin: 2px 0;
    padding: $tool-padding-regular;

    user-select: none;
    -webkit-touch-callout: none;

    .separator-line {
        width: 3.2rem;
        border-bottom: 1px solid var(--ws-section-divider-color);
    }

    .toolbar-size-small & {
        padding: $tool-padding-small;
    }
}
