@import '../../../scss/settings';

.CardCloneSection {
    padding: 0 $element-padding-default;

    .ElementTip {
        .Icon.clone {
            top: -0.1rem;

            path {
                fill: var(--ws-element-tertiary);
            }
        }
    }
}
