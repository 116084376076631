.CellTypePopup {
    width: 24rem;
    padding: 1rem 0;

    .CellTypeButton {
        display: flex;
        width: 100%;
        height: 4rem;
        padding: 0 1rem;

        span {
            margin: auto 0 auto 0.5rem;
            color: var(--ui-foreground-primary);
        }

        &.active {
            background: var(--ui-block-style-active-background);
        }

        &.disabled {
            cursor: default;
            opacity: 0.4;
        }

        .Icon {
            width: 3rem;
            height: 3rem;
            margin: auto 0;

            &.block-style-tick,
            &.chevron-right {
                width: 1.75rem;
                height: 1.75rem;
                margin: auto 1rem auto auto;
            }
        }
    }
}
.dark-theme {
    .CellTypePopup .CellTypeButton .Icon {
        &.block-style-tick,
        &.chevron-right {
            path {
                fill: var(--ui-foreground-primary);
            }
        }
    }
}
.CellTypeFormattingOptionsPopup {
    width: fit-content;
    padding: 2rem 2rem 1rem 2rem;
    font-size: var(--font-size-ui-small);
    z-index: 36000;

    .FormattingOptionsPopupContent {
        .celltype-format-section {
            display: flex;
            flex-direction: row;

            .celltype-format-title {
                width: 7.2rem;
                margin-right: 0.6rem;
                //line-height of input/select (1.75rem) + 1.1rem vertical padding + 2px border
                line-height: calc(2.85rem + 2px);
                text-align: left;
            }

            .celltype-format-inputs {
                display: flex;
                flex-direction: column;

                .celltype-format-input {
                    margin-bottom: 1rem;
                }

                .checkbox-input {
                    display: flex;
                    .Checkbox {
                        --ws-task-completed-checkbox: var(--ui-input-checkbox-background);
                        --ws-task-completed-checkbox-tick: var(--ui-input-checkbox-tick);
                        display: flex;
                        align-items: center;
                        width: 1.75rem;
                        height: 1.75rem;
                        margin: auto 0.6rem auto 0;

                        cursor: pointer;

                        .Icon .border {
                            stroke: var(--ui-input-border);
                        }
                    }
                    span {
                        margin: 0.05rem 0 -0.05rem 0;
                        line-height: 1.7rem;
                    }
                }

                input,
                select {
                    border: 1px solid var(--ui-input-border);
                    border-radius: 3px;
                    outline: none;
                    background-color: var(--ui-modal-background-primary);
                    color: var(--ui-foreground-primary);
                    font-family: var(--font-default), 'Helvetica', 'Arial', sans-serif;
                    font-size: var(--font-size-ui-small);
                    line-height: 1.75rem;
                    -webkit-appearance: none;
                }

                .dropdown-container {
                    position: relative;

                    .dropdown {
                        width: 17rem;
                        padding: 0.6rem 2.4rem 0.5rem 0.9rem;
                        cursor: pointer;
                    }
                    .arrow-icon-container {
                        display: flex;
                        position: absolute;
                        top: 0;
                        right: 0;
                        flex-direction: column;
                        justify-content: center;
                        height: 100%;
                        pointer-events: none;

                        path {
                            fill: var(--ui-foreground-primary);
                        }
                    }
                }
            }
        }
    }
}
