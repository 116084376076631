@import '../../../../scss/settings';

.SheetSearchBar {
    position: relative;
    width: 100%;
    padding: 0 2.4rem;

    .search-magnifying-glass {
        position: absolute;
        top: 50%;
        left: 3.2rem;
        transform: translateY(-50%);
        color: var(--ui-search-icon);
    }

    input {
        display: block;
        width: 100%;
        height: 4rem;
        padding: 0 0 0 3.9rem;

        border: 1px solid var(--ui-search-border-unfocused);
        border-radius: 0.3rem;
        outline: 0;

        background: var(--ui-modal-background-primary);

        color: var(--ui-modal-foreground-primary);
        font-family: var(--font-default);
        font-size: 16px;
        line-height: 4rem;

        &.active,
        &:focus {
            border: 1px solid var(--ui-foreground-secondary);
        }

        &::placeholder {
            color: var(--ui-search-placeholder);
        }
    }

    .SearchClearButton {
        right: 2.4rem;
        padding: 0 1.2rem;
    }

    .search-bar-fade {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3rem;
        background-image: linear-gradient(
            to bottom,
            var(--ui-modal-background-primary) 0%,
            // ensure it shows a little bit of the solid color
            var(--ui-modal-background-primary) 5%,
            rgba(255, 0, 0, 0) 100%
        );
        z-index: 1;
    }
}
