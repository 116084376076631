@import 'webkit-overflow-scroll-mixin';

@mixin mini-scrollbar-mixin(
    $track-color: var(--ui-scrollbar-track),
    $thumb-color: var(--ui-scrollbar-thumb),
    $size: 6px,
    $preventUserSelect: true,
    $alwaysUseSpace: false
) {
    @include webkit-overflow-scroll;

    overflow-x: hidden;
    overflow-y: auto;

    // NOTE: We need to test this on other touch devices
    touch-action: pan-x pan-y;

    @if ($preventUserSelect) {
        user-select: none;
        -webkit-touch-callout: none;
    }

    @if ($alwaysUseSpace) {
        // Ensure that there is no jumping around when the scrollbar appears/disappears
        scrollbar-gutter: stable;
    }

    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar:hover {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-track {
        background: $track-color;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: calc((#{$size} * 10) / 6);
        background: $thumb-color;
    }
}
