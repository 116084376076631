@import '../../scss/settings';

.ModalCloseButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem 2rem;

    z-index: var(--z-index-modal);

    path {
        fill: var(--ui-modal-foreground-secondary);
    }

    .app-size-medium & {
        padding-top: 1.8rem;
    }

    .app-size-small & {
        padding-top: 1.8rem;
    }
}
