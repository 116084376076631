@import '../../../../scss/settings';

.AssignmentSearchForm {
    position: relative;

    padding: 0.4rem;
    border-bottom: 1px solid var(--color-neutral-600);

    input {
        display: block;
        width: 100%;
        padding: 0.5rem 2.7rem 0.5rem 0.5rem;

        border: 0;
        border-radius: 0.3rem;
        outline: 0;
        background: none;

        font-family: $font-body;
        font-size: $font-size-element-secondary;

        &::placeholder {
            color: var(--color-neutral-600);
        }
    }

    .Button.SearchClearButton {
        padding: 0.7rem;

        .search-magnifying-glass {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
}
