.FormulaDropdown {
    position: absolute;
    max-width: 14.4rem;
    padding: 0;
    background-color: var(--ui-modal-background-primary);
    text-align: left;
    box-shadow: var(--element-shadow-elevation-3);

    .formula-list {
        overflow-y: scroll;
        max-height: 19rem;
        border-radius: 5px;
    }
    .formula-option.true,
    .formula-option:focus,
    li:focus {
        background-color: var(--ui-modal-background-primary-hover);
    }

    .formula-docs-link,
    .formula-option {
        button {
            width: 100%;
            padding: 0.6rem 1rem;
            color: var(--ws-element-primary);
            font-size: 1.2rem;
            line-height: 2rem;
            text-align: left;
        }
    }

    .formula-docs-link {
        button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--ws-table-secondary-text);

            svg.Icon.chevron-right {
                width: 1.6rem;
                height: 1.6rem;

                path {
                    fill: #8d929a;
                }
            }
        }
    }

    .formula-docs-link::before {
        display: block;
        width: 100%;
        height: 1px;
        background: var(--ws-default-line-color);
        content: '';
        opacity: 0.1;
    }
}
