@import '../../scss/settings';

.FloatingPanel.Tooltip.small {
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 4px;

    .primary-text {
        display: flex;
        align-items: baseline;
    }

    .ShortcutKey {
        min-width: 1px;
        height: auto;
        margin: -1px 0;
        padding-top: 0;
        font-size: $font-size-ui-default;

        &:last-of-type {
            margin-right: 0;
        }

        &.shift-up {
            padding-bottom: 0;
            transform: translateY(-1px);
        }
    }

    &.small-text .primary-text {
        font-size: $font-size-ui-small;
        font-weight: $font-weight-semibold;
        line-height: 1.6rem;
    }

    .app-size-medium & {
        padding: 4px 9px 3px 9px;

        .ShortcutKey {
            margin-bottom: 0;
        }
    }

    .app-size-small & {
        padding: 3px 8px 2px 8px;
    }
}
