@import '../../../scss/settings';
@import '../../elementBox';
@import '../../elementColoredBackgroundMixin';
@import '../../../list/listElementDraggingMixin';

.SketchContainer {
    &:focus {
        outline: none;
    }
}

.Sketch {
    --ws-drawing-line-color: var(--palette-grey-900);

    @include element-box;
    @include list-element-dragging;
    @include colored-background('.EmptySketch');

    padding: 0;

    // This ensures that that the SVG will be cropped to the container
    .DrawingSvg {
        position: absolute;

        svg,
        .svg-content {
            width: 100%;
            height: 100%;
        }
    }

    .Caption {
        background: var(--ws-element-background-primary-default);
        pointer-events: all;
    }

    .app-size-small & {
        .ElementResizeHandle {
            bottom: -1px;
        }
    }

    &.minimum-width {
        .EmptySketch > * {
            display: none;
        }
    }

    &.hide-reactions {
        .ElementReactions {
            display: none;
        }
    }
}

// Turn off the pointer events on the drag preview, otherwise it will interrupt the drag
.ElementDragPreview {
    .Sketch {
        .Caption {
            pointer-events: none;
        }
    }
}
