@import '../../../../scss/settings';

.ImageModalToolbar {
    .ModalToolbarBody {
        flex-direction: column;
        transition: 0.2s cubic-bezier(0.3, 1, 0.7, 1) background;
        overflow: hidden;
    }

    .toolbar-animation-container,
    .image-secondary-tools,
    .image-primary-tools {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
    }

    .toolbar-animation-container {
        transition: 0.2s cubic-bezier(0.3, 1, 0.7, 1) transform;

        &.primary {
            transform: translate3d(0, 0, 0);

            .image-secondary-tools {
                display: none;
            }
        }
        &.secondary {
            transform: translate3d(0, -100%, 0);
        }
    }
}
