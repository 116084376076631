@import '../../../scss/settings';

.TitleDraftJsEditor.TaskListTitleDraftJsEditor {
    .public-DraftEditorPlaceholder-hasFocus,
    .public-DraftEditorPlaceholder-root {
        color: var(--ws-task-title-placeholder);
    }

    .public-DraftEditorPlaceholder-root,
    .public-DraftEditor-content {
        padding: calc(#{$element-padding-default} - 0.5rem) $element-padding-default 0.6rem $element-padding-default;
    }

    .app-size-small & {
        .public-DraftEditorPlaceholder-root,
        .public-DraftEditor-content {
            padding: 14px $element-padding-default 6px $element-padding-default;
        }
    }
}
