@import '../../../../../scss/settings';

// Styles specific to the TableCellColorTool
.ColorTool:has(.table-cell-color-tool) {
    position: relative;

    .Tool.ToolbarTool {
        padding-top: 16px;
    }

    .app-size-small,
    .toolbar-size-small & {
        .Tool.ToolbarTool {
            padding-top: 10px;
        }
    }
}
