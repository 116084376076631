@import '../../scss/settings';

.QuickLineCreationTool {
    --line-tool-hit-size: 3rem;
    --line-tool-circle-size: 1.6rem;

    display: flex;
    position: absolute;

    top: 0;
    right: 0;

    align-items: center;
    justify-content: center;

    width: var(--line-tool-hit-size);
    height: var(--line-tool-hit-size);
    transform: translate(
        calc(var(--line-tool-hit-size) / 2 - var(--element-border-offset)),
        calc(var(--line-tool-hit-size) / -2 + var(--element-border-offset))
    );

    transition: transform ease-out 0.1s;

    cursor: default;
    z-index: var(--z-index-element-tag);

    .Icon.quick-line-circle {
        width: var(--line-tool-circle-size);
        height: var(--line-tool-circle-size);

        transform: scale(0.5);
        transition: transform ease-out 0.1s;

        border-radius: 50%;
        box-shadow: var(--line-handle-shadow-elevation-1);

        rect {
            stroke-width: 1;
            stroke: $black-opacity-30;
        }
    }

    .arrow-container {
        position: absolute;

        transform: translate3d(-1px, 0, 0);
        transition: opacity 30ms linear;
        opacity: 0;
        overflow: hidden;

        .Icon.quick-line-arrow {
            width: 1rem;
            height: 1.4rem;

            transform: translate3d(-8px, 8px, 0);
            transition: transform 100ms ease-out;
        }
    }

    &.dragging {
        opacity: 0;
    }

    &:hover {
        .Icon.quick-line-circle {
            transform: scale(1);

            rect {
                stroke-width: 0;
            }
        }

        .arrow-container {
            opacity: 1;

            .Icon.quick-line-arrow {
                transform: translate3d(0, 2px, 0);
            }
        }
    }

    .platform-ipad & {
        --line-tool-hit-size: 4rem;

        .Icon.quick-line-circle {
            transform: scale(1);
        }

        .arrow-container {
            opacity: 1;

            .Icon.quick-line-arrow {
                transform: translate3d(0, 2px, 0);
            }
        }
    }
}
