@import '../../../scss/settings';

// Important because this component is used as a comparison point to know how to relatively position
// the card Clipper tool
.CardClipper {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
}
