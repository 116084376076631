@import '../../scss/settings';
@import '../elementFloatingReactionsMixin';

.DrawingSvg {
    @include floating-reactions;

    position: relative;

    line-height: 0;

    svg {
        fill: var(--ws-drawing-line-color);

        path.stroke-only:not([stroke]) {
            stroke: var(--ws-drawing-line-color);
        }
    }

    .disable-drag & {
        // We need to add this to ensure that drag and drop is possible
        // Otherwise the drag will start but immediately get followed by a drop (I think the dragged element
        // gets the .disable-drag class added again immediately after the drag starts)
        pointer-events: none;

        path {
            pointer-events: all;
        }

        path:not(.stroke-only) {
            stroke-width: 0.8rem;
        }
    }

    // Stops the selection marque from flickering when hovering drawings
    .selection-marquee-active .disable-drag & {
        path {
            pointer-events: none;
        }
    }
}
