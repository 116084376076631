.List {
    position: relative;

    [data-scrollable] & {
        pointer-events: none;

        .ListElement .Element {
            pointer-events: auto;
        }
    }
}
