@import '../../scss/settings';

.OptimisticImage {
    position: relative;
}

.AttachmentImage {
    display: block;
    max-width: 100%;
    z-index: 1;

    img {
        display: block;
        width: 100%;
    }

    &.force-aspect-ratio {
        width: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            object-fit: cover;
        }
    }
}
