@import '../../scss/settings';
@import '../elementBox';

.MissingClonedElement {
    .Spinner {
        width: 2rem;
        height: 2rem;
    }
}

.ErrorClonedElement {
    @include element-box;

    width: 17rem;
    padding: 2rem;
    background: var(--error-background);
}
