@import '../../../../../../../../scss/settings';
@import '../../../../../../../../scss/mixins/outer-border-mixin';

.BoardImagePicker {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    // These properties are required to ensure the border floats over the top of the image
    // and thus doesn't cause issues with overlays and the border radius
    .board-image-wrapper {
        @include overlapping-border-full(2px, var(--ws-element-selection));

        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        width: 8rem;
        height: 8rem;

        font-size: 1rem;

        &:before {
            border-radius: $board-border-radius;
            z-index: 1;
        }

        .BoardImage {
            border-radius: $board-border-radius;
        }
    }

    .upload-title {
        display: block;
        margin: 1.8rem 0 1rem 0;

        font-family: $font-body;
        font-size: $font-size-ui-small;
    }
}
