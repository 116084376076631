@import '../../scss/settings';

.MobileWebFullScreenSignupSuccess {
    .PageContent {
        display: flex;
        flex-direction: column;
    }

    .green-tick {
        width: 4rem;
        height: 4rem;
        margin: 2rem auto 1rem auto;
    }

    .explanation {
        padding: 0 2rem 2rem 2rem;
    }

    .secondary {
        padding: 2rem;
        color: var(--ui-foreground-tertiary);

        .question {
            display: block;
            font-weight: $font-weight-semibold;
        }
    }
}
