@import '../../../../../scss/settings';
@import '../../../../../scss/mixins/outer-border-mixin';

.ToolbarPopup.LinePopup {
    width: auto;
    padding: 2rem;

    .content,
    .Button {
        line-height: 0;
    }

    .Button {
        position: relative;
        margin-left: 0.8rem;

        &:first-child {
            margin-left: 0;
        }

        &::before {
            border-radius: $ui-border-radius-xsmall;
        }

        &.active {
            @include overlapping-border-full(2px, var(--ui-tool-path));
        }

        rect {
            fill: var(--ui-toolbar-tool-background);
        }

        .Icon {
            path {
                stroke: var(--ui-tool-path);
            }

            &.toolbar-line-style-solid {
                path {
                    stroke: none;
                    fill: var(--ui-tool-path);
                }
            }
        }
    }
}
