@import '../../scss/settings';

.CommentActivity {
    .CommentLine {
        .details {
            margin-left: 1.6rem;
            padding-top: 2.1rem;
            padding-bottom: 2.1rem;
        }
    }

    &.has-comments {
        .CommentLine {
            .avatar {
                padding-top: 0.9rem;
                padding-bottom: 1.1rem;
            }

            .details {
                padding-top: 1.2rem;
                padding-bottom: 0.6rem;
            }
        }
    }
}
