/* stylelint-disable selector-class-pattern */
@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';

.Calendar {
    button {
        border: 0;
        border-radius: 3px;
        background: none;

        font-family: $font-body;
        font-size: 1.4rem;

        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
            background: var(--ui-modal-background-primary-hover);

            &:disabled {
                background: none;
                cursor: auto;
            }
        }
    }

    .react-calendar__navigation {
        align-items: center;
        margin-bottom: 2rem;
    }

    .react-calendar__navigation__arrow {
        width: 3.6rem;
        height: 3rem;

        padding-top: 0;
        padding-bottom: 0.3rem;

        font-size: 2.4rem;
        line-height: 0;

        .Icon path {
            fill: var(--ui-calendar-secondary);
        }

        &:hover,
        &:focus {
            outline: none;
            background: none;
        }

        &:disabled {
            .Icon path {
                fill: var(--color-neutral-500);
            }
        }

        &.react-calendar__navigation__next-button {
            .Icon {
                transform: scaleX(-1);
            }
        }
    }

    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
        display: none;
    }

    .react-calendar__navigation__label {
        height: 3rem;
        margin-right: 1rem;
        margin-left: 1rem;

        color: var(--ui-modal-foreground-primary);
        font-weight: $font-weight-semibold;
    }

    .react-calendar__month-view__weekdays {
        margin-bottom: 0.4rem;
    }

    .react-calendar__month-view__weekdays__weekday,
    .react-calendar__tile {
        padding: 0;

        border: 1px solid var(--ui-modal-background-primary);
        border-radius: 0.4rem;
        color: var(--ui-modal-foreground-primary);
        font-size: $font-size-ui-small;

        user-select: none;
        -webkit-touch-callout: none;

        &:before {
            border-radius: 3px;
        }
    }

    .react-calendar__month-view__weekdays__weekday {
        height: 2rem;
        margin-bottom: 0.4rem;
        font-weight: $font-weight-semibold;

        abbr {
            text-decoration: none;
        }
    }

    .react-calendar__year-view__months__month,
    .react-calendar__month-view__days__day {
        &:disabled {
            color: var(--color-neutral-600);
        }
    }

    // These sizes ensure that the date boxes sit on pixels.
    // This works in conjunction with the TaskPillPopup width
    .react-calendar__month-view__days__day {
        width: 36px;
        height: 36px;

        .app-size-medium & {
            width: 33px;
            height: 33px;
        }

        .app-size-small & {
            width: 29px;
            height: 29px;
        }
    }

    .react-calendar__tile--now {
        @include overlapping-border-full(2px, var(--ui-calendar-secondary));
        position: relative;
    }

    .react-calendar__tile--past {
        color: var(--ui-calendar-secondary);
    }

    .react-calendar__tile--active {
        @include overlapping-border-full(2px, var(--ui-modal-foreground-primary));
        position: relative;
        font-weight: bold;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        visibility: hidden;
    }

    .react-calendar__year-view__months__month {
        height: 4.8rem;
    }

    .react-calendar__year-view {
        margin-bottom: 3.3rem;
    }
}
