@import '../../scss/settings';
@import '../elementBox';
@import '../../list/listElementDraggingMixin';

.TaskList {
    @include element-box;
    @include list-element-dragging;

    --element-border-color-in-list: var(--ws-element-column-card-border-color);
    background: var(--ws-element-background-primary);

    &.in-list-column {
        // Ensure the cards sit on perfect grids while in columns
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .Task {
        padding: 0;
    }

    // Placing the padding on the List ensures that the TaskList's drop target
    // reaches the bottom of the TaskList
    > .ListPresentationContainer .EmptyListPlaceholder,
    > .ListContainer .EmptyListPlaceholder,
    .List.depth-0 {
        padding-bottom: calc(#{$element-padding-default} - 0.6rem);
    }

    .ListElement {
        padding-bottom: 0;
    }

    .is-dragging & {
        .TitleEditor {
            pointer-events: none;
        }
    }

    &.show-title > .ListContainer {
        margin-top: -4.9rem;
        padding-top: 4.9rem;
    }

    .ListDropPreview {
        .preview-line {
            top: -1px;
            z-index: 1;
        }
    }

    &.no-title {
        .List.depth-0 > .ListDropPreview:first-child {
            .preview-line {
                top: 1.8rem;
            }
        }
    }

    &:before,
    .QuickLineCreationTool {
        transition-delay: 0.1s;
    }
}
