@import '../../../scss/settings';

.CardClipperButton {
    position: relative;
    transition: margin-right 0.1s ease-in-out, transform 0.1s ease-out;

    cursor: pointer;

    .DraggableElementCreationTool {
        &.dragging {
            opacity: 0;
        }
    }

    .Icon {
        width: 3.2rem;
        height: 2.2rem;
        pointer-events: none;
        filter: drop-shadow(var(--clipper-tool-border));
    }

    .draggable-shadow {
        position: absolute;
        transition: opacity 0.1s ease-in-out;
        opacity: 0;
        z-index: -1;
        filter: drop-shadow(var(--clipper-tool-shadow));
    }

    &:hover {
        margin-right: -5px;
        padding-left: 5px;

        // Add some padding to the left when hovered to allow the mouse down / touch start events
        //  to trigger on this element
        .DraggableElementCreationTool {
            padding-left: 10px;
        }

        .draggable-shadow {
            opacity: 1;
        }
    }

    .ElementDragPreview {
        position: absolute;
        box-shadow: var(--element-shadow-elevation-2);
        pointer-events: none;

        a {
            color: var(--ui-accent-primary);
        }
    }

    // Avoids the button getting in the way of the drop target when the clipped text is being dragged
    .clip-dragging & {
        margin-right: -3rem;
    }

    .clipping & {
        .element-card,
        .draggable-shadow {
            opacity: 0;
        }
    }
}
