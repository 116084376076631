@import '../../../../scss/settings';

.ModalToolbarButton {
    display: flex;
    align-items: center;
    height: 100%;

    .button-contents {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 var(--element-padding-default) 0 calc(var(--element-padding-default) - 0.8rem);

        border-right: 1px solid var(--ui-modal-divider);

        color: var(--ui-modal-foreground-primary);
        font-size: var(--font-size-ui-small);
        font-weight: $font-weight-semibold;

        white-space: nowrap;

        .Icon {
            width: 3.2rem;
            height: 3.2rem;

            path {
                fill: var(--ui-modal-foreground-primary);
            }

            .app-size-medium & {
                width: 29px;
                height: 29px;
            }
        }
    }

    .text {
        margin-bottom: 1px;
        margin-left: 0.4rem;
    }

    &:last-child {
        .button-contents {
            border-right: none;
        }
    }
}
