@import '../../../../../scss/settings';

.RichTextTool.Tool.ToolbarTool {
    .Icon:not(.toolbar-highlight) {
        rect {
            fill: none;
        }

        path {
            fill: var(--ui-tool-path);
        }
    }

    .Icon.toolbar-highlight {
        rect {
            fill: none;
        }
    }

    &.active {
        .icon:before {
            border: 0;
        }

        .Icon:not(.toolbar-highlight) {
            rect {
                fill: var(--ui-tool-rich-text-active-background);
            }

            path {
                fill: var(--ui-tool-rich-text-active);
            }
        }

        .Icon.toolbar-highlight {
            rect {
                fill: var(--ui-tool-rich-text-active-background);
            }
        }
    }
}

// Ensure that the more popup has the correct space at the top
.MorePopupContents {
    .TooltipSource:first-child .RichTextTool {
        margin-top: 1px;
    }
}
