@import '../../../../scss/settings';

.ToolbarPopup {
    padding: 2.4rem;

    border-radius: $ui-border-radius-default;

    font-size: $font-size-ui-default;

    .popup-content {
        position: relative;
        overflow: hidden;
    }
}
