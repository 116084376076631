@import '../../scss/settings';
@import '../../list/listElementDraggingMixin';

.Task {
    .TaskStyler {
        .task-utility-button {
            display: none;
        }

        &:hover .task-utility-button.display-on-hover,
        .task-utility-button.display-always {
            display: block;

            .selection-marquee-active & {
                display: none;
            }
        }
    }

    .ListElement {
        padding-bottom: 0;
    }

    .EmptyListPlaceholder {
        height: 0;
        overflow: hidden;

        .default-placeholder {
            height: 0;
            margin: 0;
            border: 0;
            background: var(--ws-element-background-primary);

            &:before {
                display: none;
            }
        }
    }

    .SimpleActivityIndicator {
        top: 1rem;
    }

    &.completed {
        > .TaskStyler > .task-body .TaskDraftJsEditor,
        > .TaskStyler > .task-body .TaskTiptapEditor {
            color: var(--ws-task-completed);
        }
    }

    &.editing,
    &.in-list-document-mode {
        cursor: text;
        user-select: text;
    }

    .ListElement .dragging & {
        filter: grayscale(1) opacity(0.3);

        .Task {
            filter: none;
        }
    }

    &.attach-mode > .TaskStyler {
        background: var(--ws-element-background-secondary);
    }
}

.is-dragging-commit {
    .delete-button-container,
    .TaskDragHandle {
        pointer-events: none;
    }
}

// Hack to ensure the Task looks correct when it's dragged
.ElementDragPreview > .Element > .Task {
    padding-bottom: calc(#{$element-padding-default} - 0.5rem);
    background: var(--ws-element-background-primary);
}
