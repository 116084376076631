@import 'MilanoteTiptapScssFunctions';
@import '../../scss/mini-scrollbar-mixin';

$code-font-family: var(--font-element-code), menlo, 'Courier New', courier, monospace;

.tiptap {
    // Inline style
    code {
        padding: 0 0.6em 0.1em;
        border-radius: 0.5em;

        background: var(--ws-text-code-background-color);

        color: var(--ws-text-code-block-color);
        font-family: $code-font-family;
        font-size: var(--font-size-ui-small);
        overflow-wrap: break-word;
    }

    pre {
        margin-top: 1.5rem;
        margin-right: var(--margin-correct);
        margin-bottom: 1.5rem;

        border-radius: em(4px);
        background: var(--ws-text-code-background-color);

        color: var(--ws-text-code-block-color);
        font-family: $code-font-family;
        font-size: var(--font-size-ui-small);
        line-height: 2rem;
        tab-size: 4;

        &:first-child {
            margin-top: -0.5rem;
        }

        &:last-child {
            margin-bottom: 0.5rem;
        }

        code {
            @include mini-scrollbar-mixin(
                var(--neutral-opacity-white-1000-10),
                var(--neutral-opacity-white-1000-30),
                6px,
                false
            );

            display: block;
            padding: 0.5rem em(12px) 0.5rem;
            border-radius: 0;
            background: none;

            letter-spacing: var(--letter-spacing-body-text);
            white-space: unset;
            word-wrap: normal;
            overflow-x: auto;

            .code-content {
                text-wrap: nowrap;
            }
        }

        & + pre {
            margin-top: 1rem;
        }

        &:has(+ pre) {
            margin-bottom: 1rem;
        }

        // Placeholder
        &.is-editor-empty:first-child::before {
            position: relative;
            top: 0.5rem;
            left: 1rem;
            width: auto;

            color: var(--ws-text-code-block-color);
            opacity: 0.3;
        }
    }
}
