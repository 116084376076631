@import '../../../../scss/settings';

.ModalToolbar {
    display: flex;
    position: absolute;
    bottom: -8.3rem;
    align-items: center;
    justify-content: center;

    width: 100%;

    pointer-events: none;

    .ModalToolbarBody {
        display: flex;
        align-items: center;
        height: 5.2rem;

        border-radius: $ui-border-radius-default;
        background: var(--ui-modal-background-primary);

        color: var(--ui-modal-foreground-primary);
        box-shadow: var(--modal-toolbar-box-shadow);

        pointer-events: all;

        .app-size-medium & {
            height: 48px;
        }

        .app-size-small & {
            height: 43px;
        }
    }
}
