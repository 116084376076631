@import 'settings';
@import 'reset';
@import 'ui-variables';
@import 'font-variables';
@import 'colors-light-mode';
@import 'colors-dark-mode';
@import 'z-indexes';
@import 'common-keyframes';

// including this here instead of from the component
// because server-side imports don't recognise sass
// and try to interpret as JS
@import '../components/loaders/PageLoader';
@import '../workspace/Workspace';
@import '../workspace/header/AppHeader';
@import '../components/loaders/Spinner';

// inlcude this to hide the google recaptcha badge
@import './recaptcha-hide';

// Fake input for iPad
@import '../utils/ipad/fakeInput';

// Modern mobile variables
@import '../mobile/mobile-ui-variables';

* {
    box-sizing: border-box;
}

::-moz-selection {
    background: var(--ws-text-select-color);
}

::selection {
    background: var(--ws-text-select-color);
}

html {
    height: 100%;

    font-size: 10px;

    &.prevent-overscroll {
        overscroll-behavior: none;
    }

    &.app-size-medium {
        font-size: 9px;
    }

    &.app-size-small {
        font-size: 8px;
    }
}

body {
    // Fix canvas mode background colour when Ionic is added
    --ion-background-color: var(--ws-canvas-background);

    height: 100%;
    background: var(--ws-canvas-background);
    color-scheme: var(--ws-color-scheme);
    font-family: $font-body;
    font-size: $font-size-element-default;

    // Medium font properties - test on other devices
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    overscroll-behavior: none;

    // This is to prevent mobile browsers from adjusting font sizes
    text-size-adjust: 100%;

    // TODO-TOUCH - Test this property on other touch devices
    // NOTE: We might not need to prefix this style based on iPads, it might work well across
    //  all touch devices. I just don't have another device to test on at the moment.
    &.platform-ipad {
        touch-action: none;
        -webkit-touch-callout: none;
    }
}

input {
    background: var(--ui-background-primary);
    color: var(--ui-foreground-primary);
    font-family: $font-body;
    user-select: text;
}

.app {
    height: 100%;
}

.hidden {
    display: none;
}

.focusable:focus {
    outline: none;
}

strong {
    font-weight: 600;
}

a,
button {
    -webkit-tap-highlight-color: transparent;
}

@media print {
    body {
        background: $white;
    }

    @page {
        margin: 0;
    }
}
