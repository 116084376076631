@import '../../../scss/settings';

.SimpleTextEditor {
    .public-DraftEditorPlaceholder-root,
    .public-DraftEditorPlaceholder-hasFocus {
        color: var(--ws-element-placeholder);
    }

    .public-DraftEditorPlaceholder-root,
    .DraftEditor-editorContainer .public-DraftEditor-content {
        padding: 3px $element-padding-default calc(#{$element-padding-default} - 4px) $element-padding-default;

        .app-size-medium & {
            padding: 3px 21px 18px 21px;
        }

        .app-size-small & {
            padding: 2px 18px 16px 18px;
        }

        > div {
            font-size: $font-size-ui-small;
        }
    }

    .editing & {
        user-select: text;
    }

    .center-text .public-DraftStyleDefault-ltr,
    .center-text {
        text-align: center;
    }
}
