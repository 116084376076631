@import '../../../../../../../../scss/settings';

.IconButton {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 6rem;
    height: 6rem;
    border-radius: 1.4rem;

    &.active {
        border: 2px solid var(--ui-foreground-secondary);
    }

    .ElementIcon {
        --element-icon-color: var(--ui-foreground-secondary);
        --element-icon-size: 3.2rem;

        margin: auto;
    }
}
