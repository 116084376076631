@import '../../../../scss/settings';

.PopupModeMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 37%;

    > ul {
        line-height: 1.2;

        > li {
            text-align: left;
        }
    }
}
