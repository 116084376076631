.LineDropTarget {
    display: none;
}

.workspace-dragging-line {
    .LineDropTarget {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
