@import '../../../scss/settings';

.FormInput {
    width: 100%;
    padding: 0.9rem 1.5rem 1.1rem 1.5rem;

    border: 1px solid var(--ui-input-border);
    border-radius: 3px;

    background: var(--ui-input-background);

    color: var(--ui-foreground-primary);
    font-size: $font-size-ui-default;
    -webkit-appearance: none;

    &:focus {
        border-color: var(--ui-input-border-focused);
        outline: none;
    }

    &:disabled,
    &.disabled {
        border-color: var(--color-neutral-500);
        color: var(--ui-input-disabled);
    }

    &.error {
        border-color: var(--error-color);
    }

    &::-webkit-input-placeholder {
        color: var(--ui-input-placeholder);
    }

    &:-ms-input-placeholder {
        color: var(--ui-input-placeholder);
    }

    &::-moz-placeholder {
        color: var(--ui-input-placeholder);
    }
}
