@import '../../scss/settings';
@import '../../scss/mixins/pop-transition-group-mixin';

.ElementTag {
    @include pop-transition-group-mixin;

    display: flex;
    position: absolute;
    top: -1.4rem;
    right: -1.4rem;

    align-items: center;

    justify-content: flex-start;
    height: 3rem;

    border-radius: 1.5rem;

    background: var(--color-neutral-800);

    color: $white;
    font-size: $font-size-element-indicator;
    font-weight: bold;

    z-index: var(--z-index-element-tag);

    .app-size-mobile & {
        top: -0.6rem;
        right: -0.6rem;
    }
}
