@import '../../scss/settings';

.Button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
    font-family: $font-body;
    font-size: 1em;

    text-decoration: none;
    cursor: pointer;

    &.secondary {
        color: var(--ui-foreground-tertiary);

        &:not(.no-hover) {
            &:hover:not(:disabled),
            &:focus:not(:disabled) {
                color: var(--ui-foreground-primary);
            }
        }
    }

    &.primary {
        background: var(--ui-accent-primary);
    }

    &.feedback {
        &:active {
            background: $black-opacity-10;
        }
    }

    &.success,
    &:disabled.success {
        background: var(--success-color);
    }

    &.failure,
    &:disabled.failure {
        background: var(--error-color);
    }

    &:disabled {
        cursor: default;

        &:not(.maintain-enabled-styling) {
            background: var(--color-neutral-600);
            opacity: 0.3;
        }
    }
}
