@import '../../../scss/settings';

.Modal.CheckoutModal {
    // the width of this modal is defined by the child components.
    // this the default width of the child content so we can easy reference it
    --checkout-modal-default-width: 92rem;

    display: grid;

    padding: 4rem 4rem 4rem 4rem;

    // Need to allow overflow here for the team suggestions popup
    overflow: visible;

    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;

    .modal-content {
        display: flex;
        width: 100%;
        min-height: calc(57rem - 8rem);
    }

    .button-container {
        display: flex;
        flex-direction: column;
        align-items: end;
    }
}
