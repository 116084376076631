@import '../../scss/settings';

.TitleTiptapEditor {
    .tiptap {
        padding: calc(var(--element-padding-default) - 0.5rem)
            var(--element-padding-default)
            0.6rem
            var(--element-padding-default);

        color: var(--ws-element-primary);
        font-family: var(--font-family-element-heading), serif;
        font-size: var(--font-size-element-heading);
        font-weight: var(--font-weight-semi-bold);
        line-height: var(--line-height-heading);

        p {
            margin-bottom: 0;
        }

        .app-size-small & {
            padding: 14px $element-padding-default 6px $element-padding-default;
        }
    }
}
