/* stylelint-disable selector-class-pattern, declaration-no-important */
.HotTableLib.handsontable {
    // In order for the ref headers to be visible outside of the table
    overflow: visible !important;

    .manualColumnResizer,
    .ht_clone_top {
        top: calc(-34px - var(--table-title-height)) !important;
    }

    // This element contains the header clone table.
    .ht_clone_top {
        .wtHolder {
            height: 24px !important;
            overflow: visible;
        }

        table.htCore {
            col.rowHeader {
                width: 0 !important;
            }

            tr {
                border-radius: 8px;
                background: none;
                box-shadow: 0 1px 3px 0 rgba(50, 59, 74, 0.15);

                th {
                    border-top: none;
                    border-bottom: none;
                    border-color: var(--ws-table-cell-borders);

                    background-color: var(--ws-table-ref-header-background);
                    color: var(--ws-table-ref-header-text);
                    font-size: var(--font-size-ui-small);

                    .relative {
                        padding: 1.5px 4px 1.5px 4px;
                    }

                    &.ht__highlight {
                        border-color: var(--ws-table-ref-header-selected-border);
                        background-color: var(--ws-table-ref-header-selected-background);
                        color: var(--ws-table-ref-header-selected-text);
                    }
                }
            }

            th:nth-of-type(2) {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-left-color: rgba(255, 255, 255, 0) !important;
            }

            th:last-of-type {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-right-color: rgba(255, 255, 255, 0) !important;
            }

            th:has(+ .ht__highlight) {
                border-right-color: var(--ws-table-ref-header-selected-border);
            }
        }
    }

    .ht_clone_left {
        left: -34px !important;

        .wtHolder {
            overflow: visible;
        }

        table.htCore {
            border-radius: 8px;
            box-shadow: 0 1px 3px 0 rgba(50, 59, 74, 0.15);

            col.rowHeader {
                width: 24px !important;
            }

            thead {
                display: none;
            }

            tbody {
                tr {
                    background: none;
                    th {
                        padding-right: 0;
                        padding-left: 0;
                        border-right: none !important;
                        border-left: none !important;
                        border-color: var(--ws-table-cell-borders);
                        background-color: var(--ws-table-ref-header-background);
                        color: var(--ws-table-ref-header-text);
                        font-size: var(--font-size-ui-small);
                        vertical-align: middle;

                        &.ht__highlight {
                            border-color: var(--ws-table-ref-header-selected-border);
                            background-color: var(--ws-table-ref-header-selected-background);
                            color: var(--ws-table-ref-header-selected-text);
                        }
                    }
                }

                tr:first-of-type th {
                    border-top: 1px solid transparent;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                tr:last-of-type th {
                    border-bottom: 1px solid transparent;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }

                tr:has(+ tr th.ht__highlight) {
                    th {
                        border-bottom-color: var(--ws-table-ref-header-selected-border);
                    }
                }
            }
        }
    }

    .ht_clone_top_left_corner {
        display: none;
    }

    // Hide ref headers for readonly tables
    .Table.read-only & {
        .ht_clone_top,
        .ht_clone_left {
            display: none;
        }
    }

    .Table.selected-single & {
        .ht_clone_top,
        .ht_clone_left {
            opacity: 1;
        }
    }

    .Table.element-in-list &,
    .Table:not(.selected-single) & {
        .ht_clone_top,
        .ht_clone_left {
            display: none;
        }

        .manualColumnResizer {
            pointer-events: none;
        }
    }

    &.col-resizing {
        & table,
        & .wtHider,
        &.handsontable.ht__manualColumnMove.after-selection--columns thead th.ht__highlight,
        & .wtBorder {
            cursor: col-resize !important;
        }
    }

    .manualColumnResizerHandle {
        position: absolute;
        top: 0;
        right: -6px;
        width: 12px;
        height: 100%;
        margin-left: 0;
        cursor: col-resize;
        z-index: 500;
    }
    .ht_clone_top th:last-of-type .manualColumnResizerHandle {
        // Add a bit more space to the final column
        right: -12px;
        width: 20px;
    }

    .touch-device & .ht__active_highlight .manualColumnResizerHandle {
        // This is the visible resizer (3px), although the above gives a wider area for grabbing (12px)
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 4px;
            width: 4px;
            background-color: var(--ws-element-selection);
            content: '';
        }
    }

    // Hide the handsontable resize handle and guide
    .manualColumnResizer,
    .manualColumnResizerGuide {
        display: none;
        visibility: hidden;
    }

    // this is to fill the gap between the table and the ref headers without changing how they look
    // Currently just used for moving columns and rows but could be useful for other functions too

    // Column ref header
    &.on-moving--columns,
    &.col-resizing {
        .ht_clone_top {
            .wtSpreader {
                height: calc(4rem - 1px);
            }
        }
    }

    &.on-moving--rows,
    &.ht__selection--rows,
    &.col-resizing {
        .ht_clone_left {
            .wtSpreader {
                width: 35px;
            }
        }
    }

    // Correct cursor for the entire table while moving columns or rows
    &.on-moving--columns,
    &.on-moving--rows {
        table.htCore {
            cursor: grabbing;
        }
    }
}

// If showing title, extend the temporary col ref header height to cover it so that
// column moving functionality works correctly
.Table.show-title {
    .HotTableLib.handsontable.on-moving--columns {
        .ht_clone_top .wtSpreader {
            height: calc(4rem + var(--table-title-height) + 2px);
        }
    }
}

/* stylelint-enable selector-class-pattern, declaration-no-important */
