@import '../../../../scss/settings';

.ImageEditor {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    .editor {
        position: relative;
        width: 100%;

        &.loading {
            .editor-source-image {
                opacity: 0;
            }

            .DrawingSvg {
                position: absolute;
                top: 0;
                left: 0;
            }

            .DrawingSvg,
            .svg-content,
            .DrawingSvg svg {
                width: 100%;
                height: 100%;
            }
        }

        .loading-message {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;

            color: var(--ui-foreground-primary-contrast);

            overflow: hidden;

            .loading-image {
                flex-shrink: 0;
                transform-origin: center center;
            }

            .loading-overlay {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                background: $black-opacity-50;
                color: var(--palette-white);

                .Spinner {
                    width: 4rem;
                    height: 4rem;
                    margin-bottom: 1rem;

                    .spinner-line {
                        stroke: var(--palette-white);
                    }
                }
            }
        }
    }

    .cropper-container {
        --cropper-line-affordance-size: 20px;
        --cropper-point-affordance-size: 40px;

        .cropper-dashed {
            border-style: solid;
        }

        .cropper-view-box {
            outline-color: var(--ui-accent-primary);
        }

        .cropper-line {
            background-color: var(--ui-accent-primary);

            &::before {
                display: block;
                position: absolute;
                content: '';
            }

            &.line-e:before,
            &.line-w:before {
                left: calc(var(--cropper-line-affordance-size) * -0.5 + 2px);
                width: var(--cropper-line-affordance-size);
                height: 100%;
            }

            &.line-n:before,
            &.line-s:before {
                top: calc(var(--cropper-line-affordance-size) * -0.5 + 2px);
                width: 100%;
                height: var(--cropper-line-affordance-size);
            }
        }

        .cropper-face {
            opacity: 0;
        }

        .cropper-point {
            width: 8px;
            height: 8px;

            border-radius: 50%;

            background: var(--ui-accent-primary);

            box-shadow: 0 1px 2px $black-opacity-40;

            opacity: 1;

            &::before {
                display: block;
                position: absolute;

                top: calc(var(--cropper-point-affordance-size) * -0.5 + 4px);
                left: calc(var(--cropper-point-affordance-size) * -0.5 + 4px);

                width: var(--cropper-point-affordance-size);
                height: var(--cropper-point-affordance-size);

                content: '';

                opacity: 0;
            }

            &.point-n {
                top: -4px;
            }

            &.point-ne {
                top: -4px;
                right: -4px;
            }

            &.point-e {
                right: -4px;
            }

            &.point-se {
                right: -4px;
                bottom: -4px;
            }

            &.point-s {
                bottom: -4px;
                cursor: ns-resize;
            }

            &.point-sw {
                bottom: -4px;
                left: -4px;
            }

            &.point-w {
                left: -4px;
            }

            &.point-nw {
                top: -4px;
                left: -4px;
            }
        }
    }

    .drawing-svg-container {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        pointer-events: none;
    }
}

.warning-dialog {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    background: $black-opacity-30;

    .dialog-body {
        width: auto;
        min-width: 20rem;
        padding: 2rem;

        border-radius: 0.8rem;
        background: var(--ui-background-primary);

        box-shadow: var(--modal-box-shadow);

        .dialog-text {
            padding-bottom: 2rem;
            color: var(--ui-modal-foreground-primary);
            text-align: center;
            .warning-label {
                font-weight: $font-weight-semibold;
            }
        }

        .buttons {
            display: flex;
            justify-content: center;

            .Button {
                width: auto;
                font-size: var(--font-size-ui-small);

                &:first-child {
                    margin-right: 0.8rem;
                }
            }
        }
    }
}

.ImageEditorModalToolbar {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 100%;
    padding: 1.2rem;

    .Button {
        width: auto;
        height: 100%;
        margin-right: 0.8rem;
        padding: 0 1.6rem;

        font-size: var(--font-size-ui-small);

        &.save {
            margin-right: 0;
            background: var(--ui-accent-primary);
        }

        &.rotate {
            display: flex;
            align-items: center;

            margin-right: auto;
            padding-left: 0;

            border: 0;
            background: none;

            font-weight: $font-weight-semibold;
            white-space: nowrap;

            .Icon {
                width: 3.2rem;
                height: 3.2rem;
                margin-right: 0.4rem;

                path {
                    fill: var(--ui-foreground-primary);
                }
            }
        }
    }
}
