// FIXME These should probably be constants, but I'm making them variables to make it easier for
//  Marc to change them
$black: var(--palette-black);
$white: var(--palette-white);

// Dark grey shades
$grey-900: var(--palette-grey-900);
$grey-800: var(--palette-grey-800);
$grey-700: var(--palette-grey-700);
$grey-600: var(--palette-grey-600);
$grey-500: var(--palette-grey-500);
$grey-400: var(--palette-grey-400);
$grey-300: var(--palette-grey-300);

// Dark mode
$charcoal-900: var(--palette-charcoal-900);
$charcoal-850: var(--palette-charcoal-850);
$charcoal-800: var(--palette-charcoal-800);
$charcoal-750: var(--palette-charcoal-750);
$charcoal-700: var(--palette-charcoal-700);
$charcoal-600: var(--palette-charcoal-600);
$charcoal-200: var(--palette-charcoal-200);
$charcoal-100: var(--palette-charcoal-100);

// Canvas
$canvas-light-grey: var(--palette-canvas-light-grey);
$canvas-dark-grey: var(--palette-canvas-dark-grey);

// Spectrum
// Purple
// Brand purple
$purple-1000: var(--palette-purple-1000);
$purple-900: var(--palette-purple-900);
$purple-850: var(--palette-purple-850);
$purple-800: var(--palette-purple-800);
$purple-700: var(--palette-purple-700);
$purple-600: var(--palette-purple-600);
$purple-500: var(--palette-purple-500);
$purple-400: var(--palette-purple-400);
$purple-300: var(--palette-purple-300);
// Blue
// Cyan
$blue-900: var(--palette-blue-900);
$blue-400: var(--palette-blue-400);
// Green
$green-1000: var(--palette-green-1000);
$green-900: var(--palette-green-900);
$green-400: var(--palette-green-400);
// Yellow
$yellow-980: var(--palette-yellow-980);
$yellow-950: var(--palette-yellow-950);
$yellow-900: var(--palette-yellow-900);
$yellow-700: var(--palette-yellow-700);
$yellow-300: var(--palette-yellow-300);
// Orange
// Brand orange - Primary
$deep-orange-900: var(--palette-deep-orange-900);
$orange-1000: var(--palette-orange-1000);
$orange-900: var(--palette-orange-900);
$orange-400: var(--palette-orange-400);
// Red
$red-1000: var(--palette-red-1000);
$red-900: var(--palette-red-900);
$red-400: var(--palette-red-400);

// FIXME This is temporary due to the variable system - replace this with the real
//  $purple-900 when these are no longer variables (if that ends up being the case)
$temp-color-purple-900: #2f63f7;

// Transparencies
$white-opacity-85: rgba(255, 255, 255, 0.85);
$white-opacity-70: rgba(255, 255, 255, 0.7);
$white-opacity-60: rgba(255, 255, 255, 0.6);
$white-opacity-50: rgba(255, 255, 255, 0.5);
$white-opacity-40: rgba(255, 255, 255, 0.4);
$white-opacity-30: rgba(255, 255, 255, 0.3);
$white-opacity-20: rgba(255, 255, 255, 0.2);
$white-opacity-10: rgba(255, 255, 255, 0.1);
$white-opacity-0: rgba(255, 255, 255, 0);

$grey-opacity-40: rgba(51, 61, 78, 0.4);
$grey-opacity-10: rgba(51, 61, 78, 0.1);

$black-opacity-70: rgba(0, 0, 0, 0.7);
$black-opacity-60: rgba(0, 0, 0, 0.6);
$black-opacity-50: rgba(0, 0, 0, 0.5);
$black-opacity-40: rgba(0, 0, 0, 0.4);
$black-opacity-30: rgba(0, 0, 0, 0.3);
$black-opacity-20: rgba(0, 0, 0, 0.2);
$black-opacity-15: rgba(0, 0, 0, 0.15);
$black-opacity-10: rgba(0, 0, 0, 0.1);
$black-opacity-05: rgba(0, 0, 0, 0.05);
$black-opacity-0: rgba(0, 0, 0, 0);

$charcoal-opacity-80: rgba(51, 51, 51, 0.8);
$charcoal-opacity-40: rgba(51, 51, 51, 0.4);
$charcoal-opacity-15: rgba(51, 51, 51, 0.15);
$charcoal-opacity-10: rgba(51, 51, 51, 0.1);
$charcoal-opacity-05: rgba(51, 51, 51, 0.05);
$charcoal-opacity-0: rgba(51, 51, 51, 0);

// Social colors
$twitter-color: #1da1f2;
$google-dark-background-color: #4285f4;
$facebook-color: #3b5998;

// Elements
$element-shadow: rgba(51, 61, 78, 0.3);
$element-hover-shadow-color: rgba(110, 110, 113, 0.25);

// FIXME THESE NEED TO BE UPDATED
// Popups
$popup-border-color: rgba(110, 110, 113, 0.6);
$popup-shadow-color: rgba(110, 110, 113, 0.3);

// Tooltip
$tooltip-shadow-color: rgba(49, 48, 58, 0.5);

// FIXME Move these across to palette colours
// Forms
$form-button-secondary-border-color: rgba(48, 48, 58, 0.2);
$form-button-secondary-border-color-dark: rgba(48, 48, 58, 0.6);

$form-toggle-button-border-color-1: rgba(95, 93, 108, 0.09);
$form-toggle-button-border-color-2: rgba(48, 48, 58, 0.3);

// FIXME CHECK THIS!
// FIXME THESE NEED TO BE UPDATED
$image-modal-caption-divider-color: #ededed;
// Template picker
$template-picker-item-color: #ffffff;
$template-picker-item-secondary-color: #cccccc;
$template-picker-item-selected-background: #41404b;
$template-picker-item-hover-background: transparentize($template-picker-item-selected-background, 0.7);
$template-picker-shadow-color: rgba(49, 48, 58, 0.5);
$template-picker-button-flash-color: #ef825f;

// Debug
$debug-doc-mode-background: rgba(255, 255, 0, 0.5);
$debug-doc-mode-cluster-line-color: #3d9970;
$debug-doc-mode-column-line-color: #38cccc;
$debug-doc-mode-elements-line-color: #85144b;
