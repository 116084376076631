@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';

.ColumnBottomDropArea {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.6rem;

    padding: 0 var(--element-in-list-horizontal-padding);

    z-index: 1;

    .hovered-placeholder {
        width: 100%;
        height: 2px;
        margin-top: 1.1rem;

        background: var(--ws-element-can-drop);
    }

    .line-edge-connect-inside-child & {
        z-index: -1;
    }

    .ws-is-attach-mode & {
        pointer-events: none;
    }

    .column-collapsed & {
        top: 0;
        height: 100%;

        .hovered-placeholder {
            position: absolute;
            bottom: 0.5rem;
            width: calc(100% - 1.6rem);
            margin-top: 0;
        }
    }

    &.collapsed-hovered {
        @include outer-border-full(2px, var(--ws-element-selection));
    }
}
