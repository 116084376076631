@import '../scss/settings';
@import '../scss/mixins/outer-border-mixin';

@mixin element-metadata-title-mixin {
    line-height: 1.5;

    a {
        outline: 0;
        color: var(--ws-element-anchor);
        text-decoration: none;

        h3 {
            display: inline;
        }
    }

    .EditableTitle.editing {
        @include outer-border(0);
    }

    .editable-title {
        padding: 0 $element-padding-default $element-paragraph-spacing-default $element-padding-default;

        color: var(--ws-element-anchor);
        font-family: $font-element-heading;
        font-size: $font-size-element-heading;
        text-decoration: none;
        word-wrap: break-word;

        .app-size-medium & {
            padding: 0 21px 21px 21px;
        }

        .app-size-small & {
            padding: 0 18px 18px 18px;
        }

        &.editing {
            border: 0;

            background: transparent;
        }

        .editing & {
            color: var(--ws-element-primary);
            text-decoration: none;

            a {
                color: var(--ws-element-primary);
            }
        }
    }

    .editing .ListElement & {
        a,
        .editable-title {
            color: var(--ws-element-anchor);
        }
    }
}
