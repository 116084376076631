@import '../../../scss/settings';

.RichMediaContent {
    position: relative;

    // The below styles prevent Safari from accepting mouse events while a move operation is happening
    .mouse-blocker {
        display: none;
    }

    .is-dragging & {
        .mouse-blocker {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    // This was required for the element being dragged, as its hidden iframe was actually preventing mouse events
    .dragging & {
        iframe {
            display: none;
        }
    }
}
