@import '../../../../scss/settings';

.MentionSuggestions {
    display: flex;
    position: absolute;
    flex-direction: column;

    width: 32rem;
    padding: 1.5rem 0;

    transform: scale(0);

    border-radius: $ui-border-radius-default;
    background: var(--ws-element-background-primary);

    font-size: $font-size-ui-default;
    box-shadow: var(--element-shadow-elevation-3);

    cursor: default;
    z-index: calc(var(--z-index-floating-panels) + 1);

    .app-size-mobile & {
        max-height: 17.5rem;
        overflow: scroll;
    }
}

// TODO Improve the sharing of these styles
.AssignmentSuggestionsDisabled,
.MentionSuggestionsDisabled {
    margin-top: 0.7rem;

    .section-title {
        padding: 1rem $element-padding-default;

        border-top: 1px solid var(--ui-divider-on-primary);

        color: var(--ui-foreground-tertiary);
        font-size: $font-size-ui-small;
        text-align: left;

        cursor: default;
        user-select: none;
        -webkit-touch-callout: none;
    }
}
