@import '../../../scss/settings';

.DocumentModalDraftJs {
    .document-modal-body {
        .content {
            .RichTextEditor {
                h1,
                h2 {
                    margin: 1.7rem 0 1.2rem 0;
                    font-size: var(--font-size-ui-xlarge);
                    line-height: 2.9rem;

                    &:first-child {
                        margin-top: -6px;
                    }
                }

                h1 {
                    font-size: var(--font-size-ui-xxxlarge);
                }

                .public-DraftEditor-content {
                    padding: 0;
                }

                .public-DraftStyleDefault-block {
                    margin-bottom: 1rem;
                }

                .DraftEditor-editorContainer {
                    z-index: 0;
                }

                blockquote {
                    line-height: 2.4rem;
                }
            }
        }
    }
}
