@import '../../../scss/settings';

.ErrorRenderer.ModalErrorRenderer {
    width: 54rem;
    padding: 4rem;

    .error-message {
        h3 {
            font-size: $font-size-ui-modal-heading;
        }

        p {
            font-size: $font-size-ui-modal-subheading;
        }
    }

    details {
        font-size: $font-size-ui-large;

        .info {
            font-size: 1em;
        }
    }
}
