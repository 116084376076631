@import '../../scss/settings';

.MobilePage {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;

    overflow: hidden;

    z-index: var(--z-index-header);

    .MobileHeader {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .MobilePageBody {
        flex-grow: 1;
        overflow: auto;
    }
}
