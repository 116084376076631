@import '../../scss/settings';

.ActivityIndicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;

    z-index: var(--z-index-activity-indicator);

    .TooltipSource {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

.SimpleActivityIndicator {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;

    width: 7px;
    height: 7px;

    border-radius: 50%;
    background: var(--ui-accent-primary);

    z-index: var(--z-index-activity-indicator);

    .app-size-medium & {
        width: 6px;
        height: 6px;
    }

    .app-size-small & {
        width: 5px;
        height: 5px;
    }
}

.ActivityIndicatorTooltipContent {
    font-size: $font-size-ui-small;
    font-weight: normal;

    .FriendlyTimestamp {
        font-weight: normal;
    }
}
