@import '../scss/settings';

.SocialSignInButton {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 4rem;
    margin-bottom: 0.8rem;
    padding: 0;

    border: 1px solid var(--ui-button-floating-secondary-border);
    border-radius: 0.4rem;

    font-family: $font-body;
    font-size: $font-size-ui-default;
    font-weight: $font-weight-semibold;

    cursor: pointer;

    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        overflow: hidden;
    }

    svg {
        width: 3.3rem;
        height: 3.3rem;
    }

    .text {
        margin-top: -2px;
        color: $black;
        text-align: center;
    }

    .app-size-small & {
        svg {
            height: 27px;
        }

        .text {
            margin-top: 0;
        }
    }

    .app-size-medium & {
        svg {
            height: 30px;
        }

        .text {
            margin-top: 1px;
        }
    }
}
