@import '../../scss/settings';

.Comment {
    .avatar {
        padding-top: 0.8rem;
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        padding-top: 0.8rem;
        padding-bottom: 0.6rem;
    }

    // This is used to ensure the "mousedown-handler" is only the width of the text inside it.
    // Thus dragging on empty space will allow the comment thread to be dragged, but dragging on the text will
    // allow the text to be selected.
    .editor-container {
        display: flex;
    }

    .CommentEditor {
        margin-right: 1.4rem;
        margin-left: 0.6rem;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .details-header {
        display: flex;
        justify-content: space-between;
        margin-right: $element-padding-default;
        margin-left: 1.6rem;
    }

    .FriendlyTimestamp {
        display: inline-block;

        margin-left: 0.6rem;

        color: var(--color-neutral-700);
        font-size: $font-size-element-indicator;
    }

    .MentionEntity {
        padding: 0;
        background: none;
    }

    .ElementReactions {
        margin-top: 0.5rem;
        margin-left: 1.6rem;
        padding: 0;

        .ElementReactionPill {
            background: var(--ws-reaction-comment-pill-background);

            &.active {
                background: var(--ws-reaction-pill-active-background);
            }
        }
    }

    .CommentTools {
        display: none;
    }

    // Hide the tools on Android hybrid when "hovered" i.e. tapped.
    // This is because editing on the board list is generally disabled.
    .hybrid-android & {
        &:hover,
        &.keep-tools-visible {
            .CommentTools {
                display: none;
            }
        }
    }

    // Hide the tools on Android hybrid when the comment is selected.
    .hybrid-android .selected & {
        .CommentTools {
            display: none;
        }
    }

    &:hover,
    &.keep-tools-visible,
    .selected & {
        .CommentTools {
            display: block;
        }
    }

    &:first-of-type {
        .avatar {
            padding-top: 1.6rem;
        }

        .details {
            padding-top: 1.7rem;
            padding-bottom: 0.7rem;
        }
    }
}
