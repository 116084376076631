@import '../../../../scss/settings';

.AssignmentSuggestionEntry {
    .Icon {
        margin-left: 1rem;

        circle {
            fill: var(--ws-mention-color);
        }
    }
}
