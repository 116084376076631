@import '../../../../../scss/settings';

.AccountPlanSection {
    display: grid;

    grid-template-columns: auto auto;
    grid-template-rows: auto auto;

    grid-template-areas:
        'title action'
        'details details';

    .title {
        margin-bottom: 0.4rem;
        font-weight: $font-weight-semibold;
        grid-area: title;
    }

    .details {
        color: var(--ui-modal-foreground-secondary);
        font-size: $font-size-ui-small;
        line-height: 2rem;

        grid-area: details;
    }

    .section-action {
        margin-left: $ui-section-spacing-large;
        text-align: right;
        grid-area: action;
    }
}
