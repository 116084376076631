@import '../../scss/settings';

.TextActivityIndicator {
    position: absolute;
    top: -0.3rem;
    right: -0.2rem;

    width: auto;
    min-width: 2rem;
    height: 2rem;

    padding: 0 0.6rem;
    border-radius: 1.1rem;

    background: var(--ui-accent-primary);
    box-shadow: 0 1px 5px 0 $black-opacity-30;

    z-index: var(--z-index-activity-indicator);
    pointer-events: none;

    &.wide {
        padding: 0 0.7rem;
    }

    .text {
        color: var(--ui-accent-primary-contrast);
        font-size: $font-size-element-indicator;
        font-weight: $font-weight-semibold;
        // This will vertically center the text
        line-height: 2rem;
        text-align: center;
    }

    .app-size-small & {
        right: -2px;
        min-width: 16px;
        height: 16px;

        padding: 0 5px;
        border-radius: 10px;

        &.wide {
            padding: 0 6px;
        }

        .text {
            line-height: 16px;
        }
    }

    .InboxClosed &,
    .ListElement & {
        min-width: 17px;
        height: 17px;

        padding: 0 0.4rem;
        border-radius: 0.9rem;

        &.wide {
            padding: 0 0.5rem;
        }

        .text {
            font-size: $font-size-ui-xxsmall;
            line-height: 1.6rem;
        }
    }

    .app-size-medium .InboxClosed &,
    .app-size-medium .ListElement & {
        min-width: 15px;
        height: 15px;

        padding: 0 3px;
        border-radius: 8px;

        &.wide {
            padding: 0 4px;
        }

        .text {
            line-height: 15px;
        }
    }

    .app-size-small .InboxClosed &,
    .app-size-small .ListElement & {
        min-width: 14px;
        height: 14px;

        padding: 0 3px;
        border-radius: 8px;

        &.wide {
            padding: 0 4px;
        }

        .text {
            line-height: 14px;
        }
    }
}
