@import '../../../../scss/settings';

.task-reaction-pill-container {
    .TaskReactionPill {
        min-width: 3.1rem;
        height: 19px;

        margin-right: 0.4rem;
        margin-bottom: 0;
        padding: 2px 0.2rem 2px 0.3rem;

        border: 1px solid var(--ws-element-background-primary);

        line-height: 1.1rem;

        .EmojiImage {
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    .app-size-medium & {
        .TaskReactionPill {
            height: 17px;

            .EmojiImage {
                width: 11px;
                height: 11px;
            }
        }
    }

    .app-size-small & {
        .TaskReactionPill {
            height: 15px;
            line-height: 0;

            .EmojiImage {
                width: 10px;
                height: 10px;
            }
        }
    }
}
