@import '../../../../../scss/settings';

.icon-tool-container {
    position: relative;
}

.IconTool {
    cursor: default;

    .icon {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        width: 32px;
        height: 32px;
        border-radius: 4px;
        background: var(--ui-toolbar-tool-background);

        font-size: 3.8rem;
        cursor: pointer;

        overflow: hidden;

        .ElementIcon {
            --element-icon-color: var(--ui-tool-path);
            --element-icon-size: 2.4rem;
        }

        .IconToolImage {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .Icon.StringIcon {
            width: auto;
            height: auto;
        }
    }

    &.active .icon {
        background: var(--ui-toolbar-tool-popup-open-background);
    }

    .app-size-small & {
        .icon {
            width: 28px;
            height: 28px;

            font-size: 3.2rem;

            .Icon {
                width: 18px;
                height: 18px;
            }
        }
    }
}
