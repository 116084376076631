@import '../../scss/settings';

.ExportPopupExportButton {
    display: flex;
    align-items: center;

    width: 29rem;
    height: 3.2rem;
    padding-left: $ui-section-spacing-large;

    color: var(--ui-modal-foreground-primary);
    font-size: $font-size-ui-small;
    font-weight: 500;

    user-select: none;
    -webkit-touch-callout: none;

    &.error {
        .Icon {
            margin-right: 1.1rem;

            path {
                stroke: var(--error-color);
            }

            g g {
                path {
                    stroke: none;
                    fill: var(--error-color);
                }
            }
        }

        span {
            color: var(--error-color);
        }
    }

    span {
        margin-top: 0.1rem;
    }

    .FileIcon {
        width: 1.6rem;
        margin-top: 0.3rem;
        margin-right: 0.9rem;

        .app-size-small & {
            width: 2rem;
        }
    }

    .Spinner {
        width: 1.6rem;
        margin-right: 0.9rem;
        line-height: 0;

        .app-size-small & {
            width: 2rem;
        }
    }

    &.error,
    &.loading {
        .text {
            margin-top: 1px;
        }
    }

    &.Button {
        &:disabled {
            background: none;
            color: var(--ui-modal-foreground-disabled);
            opacity: 1;
        }

        &:hover:not(:disabled) {
            background: var(--ui-modal-background-primary-hover);
        }
    }
}
