@import '../../scss/settings';
@import '../../scss/mixins/fade-transition-group-mixin';

$link-input-padding: $element-padding-default $element-padding-default $element-padding-default 1.2rem;

.LinkInput {
    display: flex;
    position: relative;
    width: 100%;

    &:focus {
        outline: none;
    }

    .link-form {
        display: flex;
        flex: 1;
        overflow: hidden;

        .input-wrapper {
            flex: 1;
        }

        input {
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
            padding: $link-input-padding;

            border: 0;
            outline: 0;
            background: var(--ws-element-background-primary);

            font-family: $font-body;
            font-size: $font-size-element-default;
            line-height: $element-line-height;

            cursor: default;

            &::placeholder {
                color: var(--ws-element-placeholder);

                .BoardPreviewWorkspace.board-export & {
                    visibility: hidden;
                }
            }

            &:disabled {
                background: var(--ws-element-background-primary);
                pointer-events: none;
            }

            .selected & {
                cursor: text;
            }
        }

        .error-message {
            display: flex;
            align-items: center;
            width: 100%;
            padding: $link-input-padding;

            color: var(--ws-element-tertiary);
            font-family: $font-body;
            font-size: $font-size-element-default;
            line-height: $element-line-height;
        }
    }
}
