@import '../../../scss/settings';

.CardCloneInstancesPopupContentInfo {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1.2rem;

    text-align: center;

    &.error {
        background: var(--error-background);
        color: var(--error-color);
    }
}
