@import '../../../../scss/settings';

.PopupModeMenuButton {
    display: flex;
    align-items: center;

    margin-bottom: 0.6rem;
    padding: 0.8rem 0.7rem 0.8rem 1.5rem;

    color: var(--ui-foreground-primary);
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .Icon {
        margin-right: 0.8rem;
    }

    &.active {
        border-radius: 0.4rem;
        background: var(--ui-mode-active-background);
        color: var(--color-neutral-1000);

        .Icon {
            path {
                fill: var(--ui-foreground-primary);
            }
        }
    }

    &.disabled {
        color: var(--color-neutral-600);
        cursor: default;
    }
}
