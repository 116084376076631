@import '../../scss/settings';

.Button.SearchClearButton {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    height: 100%;
    padding: 0 0.8rem;

    cursor: default;
    z-index: 1;
    pointer-events: none;

    svg {
        width: 1.6rem;
        height: 1.6rem;
    }

    &:not(.clear) {
        path {
            fill: var(--ui-search-glass-fill);
        }
    }

    &.clear {
        cursor: pointer;
        pointer-events: all;

        circle {
            fill: var(--ui-modal-foreground-tertiary);
        }
    }

    .app-size-medium & {
        transform: scale(0.9);
    }

    .app-size-small & {
        transform: scale(0.8);
    }
}
