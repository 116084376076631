@import '../../scss/settings';

.CropToGridContainer {
    position: relative;
    width: 100%;
    overflow: hidden;

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
    }
}
