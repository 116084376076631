@use 'sass:math';
@import './fonts';

@function em($px, $default-size: 14px) {
    @return math.div($px, $default-size) * 1em;
}

@mixin text-crop($line-height: 2rem, $cap-ratio: $cap-ratio-inter, $bottom-adjustment: 0px, $top-adjustment: 0px) {
    &::before,
    &::after {
        display: block;
        width: 0;
        height: 0;
        content: '';
    }

    &::before {
        margin-bottom: calc(calc(calc(#{$cap-ratio} * 1em - #{$line-height}) / 2) + #{$top-adjustment});
    }

    &::after {
        margin-top: calc(calc(calc(#{$cap-ratio} * 1em - #{$line-height}) / 2) + #{$bottom-adjustment});
    }
}

// Used to create a fancy thin underline.
// See: https://medium.design/crafting-link-underlines-on-medium-7c03a9274f9#.6zvj56j6k
@mixin underline($color: #000000, $weight: 1px, $offset: 0) {
    background-image: linear-gradient(
        to top,
        transparent,
        transparent $offset,
        $color $offset,
        $color ($offset + $weight),
        transparent ($offset + $weight)
    );
}

@mixin arrow($location, $size, $position, $background-color, $border-width: 0px, $border-color: 'none') {
    $border-transparent-white: rgba(255, 255, 255, 0);
    $border-transparent-black: rgba(0, 0, 0, 0);

    &:after,
    &:before {
        position: absolute;
        width: 0;
        height: 0;
        border: solid transparent;
        content: '';
        pointer-events: none;
    }

    &:after {
        border-width: $size;
        border-color: $border-transparent-white;
    }

    &:before {
        border-width: $size + $border-width;
        border-color: $border-transparent-black;
    }

    @if ($location == 'top') {
        &:after,
        &:before {
            bottom: 100%;
            left: $position;
        }

        &:after {
            margin-left: -$size;
            border-bottom-color: $background-color;
        }

        &:before {
            margin-left: -($size + $border-width);
            border-bottom-color: $border-color;
        }
    }

    @if ($location == 'left') {
        &:after,
        &:before {
            top: $position;
            right: 100%;
        }

        &:after {
            margin-top: -$size;
            border-right-color: $background-color;
        }

        &:before {
            margin-top: -($size + $border-width);
            border-right-color: $border-color;
        }
    }

    @if ($location == 'right') {
        &:after,
        &:before {
            top: $position;
            left: 100%;
        }

        &:after {
            margin-top: -$size;
            border-left-color: $background-color;
        }

        &:before {
            margin-top: -($size + $border-width);
            border-left-color: $border-color;
        }
    }

    @if ($location == 'bottom') {
        &:after,
        &:before {
            top: 100%;
            left: $position;
        }

        &:after {
            margin-left: -$size;
            border-top-color: $background-color;
        }

        &:before {
            margin-left: -($size + $border-width);
            border-top-color: $border-color;
        }
    }
}
