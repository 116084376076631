@import '../../scss/settings';
@import '../../scss/mixins/webkit-overflow-scroll';

@mixin fade-grow-transition {
    &.fade-grow-appear,
    &.fade-grow-enter {
        opacity: 0;
    }

    &.fade-grow-appear.fade-grow-appear-active,
    &.fade-grow-enter.fade-grow-enter-active,
    &.fade-grow-leave {
        transition: opacity var(--modal-transition-bg-duration) linear;
        opacity: 1;
    }

    &.fade-grow-leave.fade-grow-leave-active {
        transition-delay: var(--modal-transition-delay-duration);
        opacity: 0;
    }
}

.FullScreenModal {
    @include webkit-overflow-scroll;
    @include fade-grow-transition;

    display: flex;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    flex-direction: column;
    align-items: center;

    background: var(--ui-modal-overlay-opaque);
    z-index: var(--z-index-error-modal);

    overflow-x: auto;

    &.overlay {
        background: $black-opacity-70;

        .Modal {
            box-shadow: none;
        }
    }

    &.align-top {
        .modal-positioner {
            margin-top: 0;
        }
    }

    .modal-positioner {
        margin: auto;
    }

    .modal-container {
        padding: 2rem 1.6rem;
    }

    .info {
        font-size: $font-size-ui-xsmall;
    }

    .app-mode-mobile-legacy &,
    &.fill-screen {
        padding: var(--safe-area-top, 0px) var(--safe-area-right, 0px) var(--safe-area-bottom, 0px)
            var(--safe-area-left, 0px);

        background: var(--ui-background-primary);

        &.overlay {
            background: var(--ui-background-primary);
        }

        .modal-positioner {
            width: 100%;
            margin: 0;
        }

        .modal-container {
            width: 100%;
            padding: 0;
        }
    }
}
