@import '../../scss/settings';
@import './linkStyleConstants';

.LinkHeader {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    padding: calc(#{$element-padding-default} + 1px) $element-padding-default $link-section-spacing
        $element-padding-default;

    .in-list-column & {
        padding-top: calc(#{$element-padding-default} + 2px);
    }

    .favicon-container {
        width: 1.6rem;
        min-width: 1.6rem;
        height: 1.6rem;
        min-height: 1.6rem;

        margin-right: $link-section-spacing;

        -webkit-touch-callout: none;

        .link-icon,
        .favicon {
            width: 100%;
            height: 100%;
        }
    }

    .app-size-medium & {
        padding: 22px 21px 11px 21px;

        .favicon-container {
            height: 14px;
            min-height: 14px;
        }
    }

    .app-size-small & {
        padding: 20px 18px 9px 18px;

        .favicon-container {
            height: 13px;
            min-height: 13px;
        }
    }
}
