@import '../../../scss/settings';

.RichMediaIFrameContent {
    position: relative;
    height: 0;
    background: var(--color-neutral-300);
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .app-size-medium & {
        iframe {
            width: 111.1111%;
            height: 111.1111%;
            transform: scale(0.9);
            transform-origin: top left;
        }
    }

    .app-size-small & {
        iframe {
            width: 125%;
            height: 125%;
            transform: scale(0.8);
            transform-origin: top left;
        }
    }
}
