@import '../../../../scss/settings';

.DrawingEditorColorTool {
    margin-right: 0.8rem;

    .ColorToolIcon {
        --ui-color-button-background: var(--ws-drawing-line-color);
    }

    // Make the hit area flow into the next button so the cursor doesn't flicker
    .DrawingEditorColorButton {
        position: relative;
        border-radius: $ui-border-radius-xsmall;

        &::before {
            position: absolute;
            top: 0;
            right: -0.4rem;
            bottom: 0;
            left: -0.4rem;
            content: '';
        }
    }

    .CustomColorInput {
        position: absolute;
        top: auto;
        left: -10000px;
        width: 1px;
        height: 1px;

        &:focus {
            outline: none;
        }

        &.is-open {
            top: 18px;
            left: 20px;
            width: 40px;
            height: 40px;
            opacity: 0;
        }
    }
}

.DrawingEditorColorPopup {
    width: 20rem;
    padding: 2.4rem;
    border-radius: $ui-border-radius-default;

    &.sketch {
        --ws-drawing-line-color: var(--palette-grey-900);
    }

    .CustomColorPickerContainer {
        .custom-color-picker {
            .app-size-large & {
                padding-bottom: 1px;
            }
        }
    }

    .ColorButton.no-color {
        --ws-no-color-button-border-color: transparent;
        --ui-color-button-background: var(--ws-drawing-line-color);
    }
}
