@mixin pop-transition-group-mixin {
    &.pop-appear,
    &.pop-enter {
        transform: scale(0);
        opacity: 0;
    }

    &.pop-appear.pop-appear-active,
    &.pop-enter.pop-enter-active,
    &.pop-leave {
        transform: scale(1);
        opacity: 1;
    }

    &.pop-leave.pop-leave-active {
        transform: scale(0);
        transition: opacity 0.2s ease-in, transform 0.3s ease-in;
        opacity: 0;
    }

    &.pop-appear.pop-appear-active,
    &.pop-enter.pop-enter-active {
        transition: opacity 0.3s ease-in, transform 0.4s cubic-bezier(0.665, 0.1, 0, 2.5);
        transition-delay: 0.1;
    }
}
