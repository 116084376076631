.SheetContainer.reaction-tool {
    .Sheet .Content {
        height: 100%;
        padding: 0 2.4rem;

        .non-draggable-area {
            height: 100%;

            .height-controller {
                width: 100%;
                height: 100%;

                .emoji-list {
                    margin: auto;
                    padding-bottom: max(var(--safe-area-bottom), 2.4rem);

                    .keyboard-open & {
                        padding-bottom: 2.4rem;
                        transition: padding-bottom 0.2s;
                    }

                    .emoji-list-row {
                        column-gap: 4px;

                        .ReactionPopupEmojiButton::before {
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }

                        &.title {
                            display: flex;
                            align-items: flex-end;
                            padding-bottom: 1.5rem;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
        .no-results-text {
            padding-top: 2rem;

            &.none-found {
                display: flex;
                justify-content: start;
                line-height: 2.4rem;

                .EmojiImage {
                    margin-left: 4px;
                }
            }
        }
    }
}
