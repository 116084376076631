@import '../../../scss/settings';

.TaskDraftJsEditor {
    color: var(--ws-element-primary);
    font-size: $font-size-element-default;
    line-height: 1.5;
    text-decoration: none;

    .public-DraftEditorPlaceholder-hasFocus {
        color: var(--ws-element-tertiary);
    }

    .public-DraftEditorPlaceholder-root {
        color: var(--ws-element-placeholder);
    }
}
