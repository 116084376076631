@import '../../../scss/settings';

@keyframes mini-pulse {
    70% {
        box-shadow: 0 0 0 7px transparent;
    }

    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

.CommentThreadCollapsedRemoteActivityIndicator {
    position: absolute;
    top: 1px;
    right: 0px;
    width: 8px;
    height: 8px;

    border: 1px solid var(--ws-default-canvas-background);
    border-radius: 50%;

    background: var(--ui-accent-primary-2);

    .pulse {
        width: 6px;
        height: 6px;

        border-radius: 100%;

        background: var(--ui-accent-primary-2);
        box-shadow: 0 0 0 0 var(--ui-accent-primary-2);
        opacity: 0.4;
        animation: mini-pulse 1.5s infinite;
    }
}
