@import '../../scss/settings';

.PopupPanel.ExportPopup {
    width: 29rem;

    .export-section {
        padding: $ui-section-spacing-large 0 1.3rem 0;

        &:last-child {
            padding-bottom: 2.1rem;
        }
    }

    h3 {
        margin-top: -0.3rem;
        margin-bottom: 0.8rem;
        padding-left: $ui-section-spacing-large;

        color: var(--ui-modal-foreground-primary);
        font-size: var(--font-size-ui-default);
        font-weight: 600;

        user-select: none;
        -webkit-touch-callout: none;
    }

    .divider {
        border-bottom: 1px solid var(--ui-modal-divider);
    }

    .SettingsRow {
        margin-top: 0;
        margin-bottom: 0.8rem;
        padding-left: 2.4rem;

        &.toggle-row {
            grid-template-columns: 12rem 4rem;
            grid-template-rows: auto;
            grid-template-areas: 'label input';

            .label,
            label {
                display: flex;
                align-items: center;
                color: var(--ui-export-popup-toggle-text);
                font-size: var(--font-size-ui-small);
                font-weight: normal;
            }

            .ToggleInput {
                margin-left: -7px;
                transform: scale(0.8);
            }
        }
    }
}
