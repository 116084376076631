@import '../../../../../../scss/settings';

.ToolbarPopup.AlignmentPopup {
    width: 15.4rem;

    .app-size-medium & {
        width: 150px;
    }

    .app-size-small & {
        width: 145px;
    }

    .Icon {
        rect {
            fill: var(--ui-toolbar-tool-background);
        }

        path {
            fill: var(--ui-tool-path);
        }
    }
}
