@import '../../../scss/settings';

.RichMediaPreviewImage {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    background: $black;

    overflow: hidden;

    .OptimisticImage {
        position: static;

        .BackgroundImage {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .Spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .spinner-line {
                stroke: $white;
                stroke-width: 3px;
            }
        }
    }
}
