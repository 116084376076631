@import '../../../scss/settings';

.BoardShortcutIcon {
    position: absolute;
    bottom: -2px;
    left: -2px;
    // Force the shortcut icon to sit above the selected border
    z-index: 1;

    .Icon {
        width: 1.6em;
        height: 1.6em;
    }

    .in-list-column & {
        top: 5.6em;
        bottom: auto;

        .app-size-small & {
            bottom: auto;
        }

        .app-size-medium & {
            bottom: auto;
        }
    }
}
