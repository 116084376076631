@import '../../../scss/settings';

.ImageModalNavigation {
    display: flex;
    position: fixed;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100%;

    pointer-events: none;

    .nav {
        position: relative;

        width: 100px;
        height: 100px;

        transition: left 0.3s ease-in-out, right 0.3s ease-in-out;

        background-repeat: no-repeat;
        background-position: center;

        font-size: 0;
        text-indent: -9999px;

        pointer-events: all;

        &.left {
            left: -10rem;
            background-image: url('./image-nav-left.svg');
        }

        &.right {
            right: -10rem;
            background-image: url('./image-nav-right.svg');
        }
    }

    &.show {
        .nav {
            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }
        }
    }
}
