// A textTarget can be provided so the text styles are only applied to part of the element
@mixin colored-background($textTargetList: '') {
    $light-selector-list: '';
    $dark-selector-list: '';

    @each $current-text-target in $textTargetList {
        $light-selector-list: $light-selector-list + '&.colored-background-light ' + $current-text-target + ', ';
        $dark-selector-list: $dark-selector-list + '&.colored-background-dark ' + $current-text-target + ', ';
    }

    &.colored-background {
        --element-shadow-elevation-1-contrast: var(--element-shadow-elevation-1);

        > .ElementReactions {
            .ElementReactionPill {
                box-shadow: var(--element-shadow-elevation-1);
            }

            .ElementReactionAddButton {
                background: var(--ws-reaction-pill-background);
                box-shadow: var(--element-shadow-elevation-1);
            }
        }

        #{$light-selector-list} {
            --ws-element-primary: var(--palette-grey-900);
            --ws-element-secondary: #{$black-opacity-50};
            --ws-element-tertiary: #{$black-opacity-40};
            --ws-element-colored-background-secondary: var(--palette-white);
            --ws-card-word-count: var(--ws-element-tertiary);
            --ws-task-completed: var(--ws-element-tertiary);
            --ws-task-completed-checkbox: #{$black-opacity-30};
            --ws-element-placeholder: #{$black-opacity-20};
            --ws-text-color-grey: #{$black-opacity-40};
        }

        #{$dark-selector-list} {
            --ws-element-primary: var(--palette-white);
            --ws-element-secondary: #{$white-opacity-60};
            --ws-element-tertiary: #{$white-opacity-50};
            --ws-element-colored-background-secondary: var(--palette-grey-900);
            --ws-card-word-count: var(--ws-element-tertiary);
            --ws-task-completed: var(--ws-element-tertiary);
            --ws-task-completed-checkbox: #{$white-opacity-40};
            --ws-element-placeholder: #{$white-opacity-30};
            --ws-text-color-grey: #{$white-opacity-50};
        }
    }
}
