@import 'fonts';
@import 'colors';
@import 'ui-constants';
@import 'utils';
@import 'animation';

// Inbox
$inbox-width: 410px;

// Toolbar
$toolbar-width: 72px;
$toolbar-width-medium: 63px;
$toolbar-width-small: $toolbar-width-medium;

// Modals
$standard-modal-width: 64rem;
// FIXME Weird height...
$standard-modal-height: 70.7rem;

// Media Sizes
$size-large: 1600px;
$size-mobile: 600px;
$breakpoint-collapse-icons: 1100px;

// Default element widths
$default-element-width-large: 340px;
$default-element-width-medium: $default-element-width-large * 0.9;
$default-element-width-small: $default-element-width-large * 0.8;

// Scale
$scale-animation-timing: 0.3s;
$scale-animation-easing: cubic-bezier(0.645, 0.045, 0.355, 1);
