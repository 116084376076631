@import '../../../scss/settings';

$media-player-progress-color: var(--ui-accent-primary);

.FileMediaPlayerScrubberBar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .bar-container {
        position: relative;
        width: 100%;
        height: 0.4rem;

        border-radius: 2px;

        background: var(--ui-scrubber-background);

        &.vertical {
            width: 0.4rem;
            height: 100%;
        }

        &.dragging {
            cursor: pointer;
        }
    }

    .scrubber-bar-fill {
        position: absolute;
        width: 100%;
        height: 100%;

        border-radius: 2px;

        background: $media-player-progress-color;
    }

    .bar-scrubber {
        position: absolute;
        width: 12px;
        height: 12px;

        margin-top: -4px;
        margin-left: -6px;

        border: 2px solid $white;
        border-radius: 50%;

        background: $media-player-progress-color;

        box-shadow: var(--ui-floating-panel-shadow-1);

        cursor: pointer;

        // This increases the hit area for the bar-scrubber
        &::before {
            position: absolute;
            top: -1rem;
            right: -1rem;
            bottom: -1rem;
            left: -1rem;
            content: '';
        }

        &.vertical {
            margin-top: -6px;
            margin-left: -4px;
        }

        .app-size-medium & {
            width: 11px;
            height: 11px;
            margin-top: -4px;
            margin-left: -5px;

            &.vertical {
                margin-top: -5px;
                margin-left: -4px;
            }
        }

        .app-size-small & {
            width: 10px;
            height: 10px;
            margin-top: -4px;
            margin-left: -4px;

            &.vertical {
                margin-top: -4px;
                margin-left: -4px;
            }
        }
    }
}
