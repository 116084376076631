@import '../../elementBox';
@import '../../../list/listElementDraggingMixin';

.DocumentDetailView {
    @include element-box;
    @include list-element-dragging;

    .document-preview {
        --ws-text-code-background-color: var(--ws-text-code-preview-background-color);

        --line-height-element: 1.4rem;
        --line-height-heading: 1.4rem;
        --line-height-heading-one: 1.6rem;
        --element-paragraph-spacing-default: 0.5rem;
        --element-paragraph-spacing-xlarge: 0.5rem;
        --element-paragraph-spacing-large: 0.3rem;

        height: 39rem;

        background: var(--ws-element-background-secondary);
        overflow: hidden;

        .public-DraftStyleDefault-block {
            margin-bottom: 0.5rem;
            font-size: 0.6em;

            span {
                // This is used for inline code
                --font-size-ui-small: 0.75em;
            }
        }

        .public-DraftStyleDefault-listLTR {
            padding-left: 1.5rem;

            &:before {
                width: 1.5rem;
                font-size: 0.6em;
            }
        }

        .public-DraftEditor-content {
            padding: var(--element-padding-default);
        }

        .small-text {
            line-height: 1rem;
        }

        blockquote,
        .public-DraftStyleDefault-pre {
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .Caption {
        margin-top: -3px;

        .app-size-small & {
            margin-top: -2px;
        }
    }

    .EmptyDocumentPreview {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100%;
        color: var(--ui-foreground-tertiary);
        font-size: $font-size-element-default;

        .Icon {
            width: 4.8rem;
            height: 4.8rem;
            margin-bottom: $ui-section-spacing-xxsmall;
        }
    }
}
