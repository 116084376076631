.ColorSwatchContainer {
    padding: var(--color-swatch-container-padding);

    .background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--ws-element-column-empty-placeholder);
    }
}
