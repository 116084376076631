@import '../../../scss/settings';

.FileMediaPlayerProgressBar {
    .bar-scrubber {
        transform: scale(0, 0);
        transition: transform 0.15s ease-out;
    }

    &:hover,
    .bar-container.dragging {
        .bar-scrubber {
            transform: scale(1, 1);
            transition: transform 0.15s ease-in;
        }
    }
}
