@import '../../../../scss/settings';
@import '../../../../scss/mixins/mini-scrollbar-mixin';

.AssignmentSuggestionResults {
    @include mini-scrollbar-mixin;

    position: relative;
    max-height: 24rem;
    padding: 0.7rem 0;
}
