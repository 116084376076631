@import '../../scss/settings';
@import '../../scss/mixins/fade-move-transition-group-mixin';

.PopupPanel.tooltip {
    @include fade-move-transition-group-mixin;

    width: auto;

    background: $black;
    color: $white;

    &.arrow-top {
        @include arrow('top', 6px, 60px, $black, 0, transparent);
    }

    &.arrow-left {
        @include arrow('left', 6px, 14px, $black, 0, transparent);
    }

    &.arrow-bottom {
        @include arrow('bottom', 6px, 60px, $black, 0, transparent);
    }

    &.arrow-right {
        @include arrow('right', 6px, 14px, $black, 0, transparent);
    }

    .popup-content {
        padding: 4px 12px 6px 12px;
        font-size: 14px;
        white-space: nowrap;
    }
}
