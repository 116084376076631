.tiptap {
    // Some block styles will specify their own properties (e.g. lists)
    //  but these will cover the general cases
    .is-editor-empty:first-child::before {
        width: 100%;
        height: 0;
        float: left;

        color: var(--ws-element-placeholder);
        content: attr(data-placeholder);
        pointer-events: none;
    }
}
