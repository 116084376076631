@import '../../../../../../../../scss/settings';

.BoardImageUploadIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 7.2rem;
    height: 7.2rem;

    border-radius: $board-border-radius;
    background: var(--color-neutral-500);

    font-size: 1rem;

    .Icon {
        width: 4rem;
        height: 3.8rem;
    }

    .hovered & {
        border: 2px solid var(--ws-element-can-drop);
    }
}
