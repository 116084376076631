@import '../scss/mixins/outer-border-mixin';

@mixin remove-all-borders {
    --element-border-color: transparent !important; // stylelint-disable-line
    --element-box-shadow: none !important; // stylelint-disable-line
    --element-auxiliary-border: none !important; // stylelint-disable-line
}

@mixin element-box {
    --element-box-background: var(--ws-element-background-primary);

    --element-border-offset: 0px;
    --element-border-width: 2px;
    --element-border-color: transparent;

    --element-border-color-in-list: var(--ws-element-border-soft);
    --element-border-radius: 0;

    --element-box-shadow: var(--element-shadow-elevation-1);

    --element-auxiliary-border: none;
    --element-auxiliary-z-index: 0;
    --element-auxiliary-offset: 0px;

    position: relative;
    border-radius: var(--element-border-radius);
    background: var(--element-box-background);

    /**
    * Rendering cases
    * */

    // Ensures that two white elements that overlap will have a separation border
    // E.g. A card covering another card will have a border, while images shouldn't use this
    &.contrast-shadow {
        --element-box-shadow: var(--element-shadow-elevation-1-contrast);
    }

    // Export
    .hide-background & {
        --element-auxiliary-border: 1px solid var(--element-border-color-in-list);
        --element-auxiliary-z-index: calc(var(--z-index-element-border) - 2);
        --element-auxiliary-offset: -1px;
        --element-box-shadow: none;
    }

    &.in-list-column,
    &.in-list-document-mode {
        --element-auxiliary-border: 1px solid var(--element-border-color-in-list);
        --element-auxiliary-z-index: calc(var(--z-index-element-border) - 2);
        --element-auxiliary-offset: -1px;
        --element-box-shadow: none;

        // This is used to make the size of the cards larger by 2px so that
        // the gap between cards can be 8px and still sit on grid points
        border: 1px solid var(--container-background);
    }

    &.in-list-quick-notes,
    &.in-list-unsorted-notes,
    &.in-list-trash {
        --element-box-shadow: var(--element-shadow-elevation-1);
    }

    &.transparent {
        --element-box-background: transparent;
        --element-auxiliary-border: none;
        --element-box-shadow: none;

        &:hover:not(.remote-selected) {
            --element-auxiliary-border: 1px solid var(--ws-element-border-soft);
            --element-auxiliary-z-index: calc(var(--z-index-element-border) - 2);
        }
    }

    // Attachment mode

    &.line-edge-can-drop,
    &.can-drop {
        --element-border-color: var(--ws-element-can-drop);
    }

    &.attach-mode,
    &.line-edge-connect-inside {
        --element-border-color: var(--ws-element-selection-locked);
        --element-box-shadow: none;

        > .attach-mode-drop-padding {
            position: absolute;
            top: -4rem;
            right: -4rem;
            bottom: -4rem;
            left: -4rem;

            z-index: -1;
        }

        &.in-list-column {
            > .attach-mode-drop-padding {
                z-index: 0;
            }
        }
    }

    // Selection
    &.remote-selected {
        --element-border-color: var(--remote-user-color);
        --element-border-width: 2px;
        --element-box-shadow: none;

        --element-auxiliary-border: none;
        --element-auxiliary-z-index: 0;
    }

    &.selected:not(.marquee-remove-selected),
    &.marquee-selected:not(.marquee-remove-selected) {
        --element-border-color: var(--ws-element-selection);
        --element-border-width: 2px;

        --element-box-shadow: none;

        body:not(.app-mode-mobile) &.focus-editing {
            --element-border-offset: 2px;

            // Hide the auxiliary border when the element is being edited, so that it doesn't overlap with the selection border
            --element-auxiliary-border: none;
            --element-auxiliary-z-index: 0;
        }

        &.locked {
            --element-border-color: var(--ws-element-selection-locked);
        }
    }

    // Focus editing:
    // Insets the border to indicate that the element is being edited

    .dragging & {
        @include remove-all-borders;
    }

    // Focus mode:
    // Show a "shadow" where the element used to be when it's being focused
    &.being-focused {
        @include remove-all-borders;

        background: var(--ws-canvas-background);
        filter: brightness(0.97);

        &.in-list-column {
            background: var(--ws-element-column-dragging-placeholder);
            filter: none;
        }

        > div,
        > input {
            visibility: hidden;
        }
    }

    // clear out any styles that might be applied to the element when it's focused
    .FocusModeElement & {
        @include remove-all-borders;
    }

    // Platform specific

    // in hybrid-android, selection border state is not rendered
    .hybrid-android & {
        &.selected {
            --element-border-width: 0;
        }

        &.selected.focus-editing {
            --element-auxiliary-border: 1px solid var(--element-border-color-in-list);
        }
    }

    /**
    * Actual border / box shadow styling below
    * */

    // before is used for the element border
    &:before {
        --element-box-border-pos: calc(var(--element-border-offset) - var(--element-border-width));

        position: absolute;

        top: var(--element-box-border-pos);
        right: var(--element-box-border-pos);
        bottom: var(--element-box-border-pos);
        left: var(--element-box-border-pos);

        transition: top 0.1s ease-out, right 0.1s ease-out, bottom 0.1s ease-out, left 0.1s ease-out;

        border: var(--element-border-width) solid var(--element-border-color);
        border-radius: var(--element-border-radius);

        content: '';
        z-index: var(--z-index-element-border);
        pointer-events: none;
    }

    // after is used for the element shadow
    &:after {
        position: absolute;
        top: var(--element-auxiliary-offset);
        right: var(--element-auxiliary-offset);
        bottom: var(--element-auxiliary-offset);
        left: var(--element-auxiliary-offset);

        border: var(--element-auxiliary-border);
        border-radius: var(--element-border-radius);
        box-shadow: var(--element-box-shadow);

        content: '';
        z-index: var(--element-auxiliary-z-index);
        pointer-events: none;
    }
}
