@import '../../../../scss/settings';

a.DraftLinkAnchor {
    text-decoration: none;

    // Only apply underline in inner spans, so that the underline will follow the color of the text
    & > span {
        text-decoration: underline;
    }

    &.highlight {
        background: var(--ws-text-select-color);
    }
}
