@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';

.ElementReactionAddButton {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 2rem;

    padding: 0.3rem 0.4rem 3px 0.4rem;

    border-radius: 3px;

    .PopupTrigger,
    .sheet-trigger {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 1.4rem;
        height: 100%;
    }

    .add-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;

        &.active {
            .Icon path {
                fill: var(--ws-reaction-add-icon-foreground-active);
            }
        }
    }

    .Main.board-preview & {
        display: none;
    }

    .app-size-small .app-electron & {
        .add-icon {
            position: relative;
            top: -2px;
        }
    }

    .app-size-medium .app-electron & {
        .add-icon {
            position: relative;
            top: -1px;
        }
    }
}
