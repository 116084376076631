@import '../../../../../../scss/settings';

.FloatingPanel.icon-popup {
    width: 57rem;
    min-height: 29rem;

    .popup-content {
        display: flex;
        overflow: visible;

        .icon-popup-content {
            display: flex;
            position: relative;
            flex: 1;
            flex-grow: 1;
            width: 100%;

            .PopupModeMenuButton span::first-letter {
                text-transform: capitalize;
            }

            .PopupModeContent {
                height: 24.8rem;
            }
        }
    }
}
