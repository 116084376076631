.SheetContainer.color-tool .ColorPopupContent {
    padding: 2.4rem;

    .ColorPopupModeSwitcher {
        margin: 0 0 3.2rem 0;

        .color-mode-button {
            font-size: var(--font-size-ui-default);

            svg {
                display: none;
            }
        }
    }

    .divider {
        display: none;
    }

    .colors {
        --gap: 0.8rem;

        max-width: 450px;
        margin: 0 auto 3.2rem auto;
        grid-template-columns: repeat(7, minmax(10px, 1fr));
        row-gap: var(--gap);
        column-gap: var(--gap);

        @media (min-width: 370px) {
            --gap: 1.4rem;
        }

        li {
            width: unset;

            .ColorButton {
                --button-border-radius: 0.4rem;
                --selection-border-radius: 0.8rem;

                width: 100%;

                &.transparent {
                    background-size: 33%;
                }
            }
        }
    }

    .CustomColorPickerContainer {
        padding: 0;

        .custom-color-picker {
            justify-content: center;
            height: 4rem;
            padding: 0;
            color: var(--ui-color-picker-mode-tab-inactive-color);
            font-size: var(--font-size-ui-default);

            .color-wheel {
                margin-right: 1.6rem;
            }
        }
    }
}

.MobileColorTool .CustomColorInput {
    position: absolute;
    bottom: 30px;
    margin-top: -30px;
    opacity: 0;
}
