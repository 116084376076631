@import '../../../../scss/settings';

.DrawingEditorCanvasSvg {
    width: 100%;
    height: 100%;
    touch-action: none;

    fill: var(--ws-drawing-line-color);

    .DrawingPath {
        &.stroke-only:not([stroke]) {
            stroke: var(--ws-drawing-line-color);
        }
    }
}
