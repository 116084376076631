@import '../../scss/settings';
@import '../../scss/mixins/fade-transition-group-mixin';

$line-color: $black;
$container-rotate-timing: 2s;
$spinner-fill-rotate-timing: 1.5s;

@keyframes container-rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

.Spinner {
    @include fade-transition-group-mixin;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: translate3d(0, 0, 0);
    transition-duration: 200ms;
    will-change: transform;

    .spinner-wrapper {
        position: relative;
        width: 100%;
        max-width: 40px;
        height: 100%;
        max-height: 40px;
    }

    .spinner-container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .circular {
        animation: container-rotate #{$container-rotate-timing} linear infinite;
    }

    .path {
        stroke: url("#spinner-gradient");
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash #{$spinner-fill-rotate-timing} ease-in-out infinite;
        stroke-linecap: round;
    }

    #spinner-gradient {
        stop {
            stop-color: var(--ui-loading-spinner-color);
        }
    }
}
