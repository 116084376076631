.tiptap {
    // NOTE: We need to use !important in the following cases because the color plugin
    //  will add inline styles to the text, which would otherwise override these styles.
    //  We could prevent the extension from doing this, by extending it and implementing
    //  the logic ourselves, but the inline styles are useful when pasting to other apps
    span[style*='color: blue'] {
        color: var(--ws-text-color-blue) !important;
    }

    span[style*='color: green'] {
        color: var(--ws-text-color-green) !important;
    }

    span[style*='color: orange'] {
        color: var(--ws-text-color-orange) !important;
    }

    span[style*='color: red'] {
        color: var(--ws-text-color-red) !important;
    }

    span[style*='color: grey'] {
        color: var(--ws-text-color-grey) !important;
    }

    // Slight hack - because the colour plugin will add a background colour to the text inside an anchor,
    //  the underline will keep the colour of the surrounding anchor. So here we override the underline.
    a[href] span {
        text-decoration: underline;
    }
}
