.ToolbarPopup.MoreToolPopup {
    width: auto;
    padding: 0;
    background: var(--ws-canvas-background);
}

.MoreToolPopupContents {
    --popup-columns: 1;

    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(var(--popup-columns), 1fr);

    margin: 1.2rem 1.6rem 1.2rem 1.6rem;

    row-gap: 0.8rem;
    column-gap: 1.6rem;

    .TooltipSource {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 32px;

        .toolbar-size-small & {
            min-height: 28px;
        }
    }
}
