@import '../../../scss/settings';

.DocumentModalDraftJsSidebar {
    display: flex;
    position: relative;

    flex-shrink: 0;
    justify-content: center;
    width: 10.4rem;

    margin-right: -0.1rem;
    padding-top: 3.6rem;

    .RichTextToolList {
        width: 5rem;
        overflow: visible;
        z-index: 1;
    }

    .BlockStyleRichTextTool {
        margin-bottom: -12px;
    }

    .Tool {
        .tool-name {
            font-size: 1rem;
        }
    }

    &.hide-sidebar-content {
        width: 3.5rem;
    }

    .Tool.ToolbarTool.TextStyleRichTextTool {
        padding-top: 0;

        .icon {
            padding: 0.7rem;
            border: 1px solid var(--ui-document-modal-text-tool-border-color);
            background: none;
        }

        &.open {
            .icon {
                background: var(--ui-document-modal-text-tool-border-color);
            }
        }
    }

    .app-size-small & {
        .BlockStyleRichTextTool {
            margin-bottom: -18px;
        }

        .ToolbarPopupTool.Text-colorTool {
            .icon {
                width: 28px;
                height: 24px;
            }
        }
    }
}

@media (max-width: $size-mobile) {
    .DocumentModalDraftJsSidebar {
        display: none;
    }
}
