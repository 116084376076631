@import '../../../scss/settings';

.VisibilityButton {
    display: flex;
    width: 20%;

    padding: 0 1.5rem 0.2rem 1.5rem;

    border: 1px solid var(--color-neutral-600);
    border-left: 0;
    border-radius: 0 3px 3px 0;

    outline: none;
    background: var(--ui-background-primary);

    color: var(--ui-foreground-secondary);
    font-size: $font-size-ui-default;
    text-decoration: underline;
    -webkit-appearance: none;

    &.has-input-focus {
        border-color: var(--ui-foreground-secondary);
    }

    &.error {
        border-color: var(--error-color);
    }
}
