@import '../../../scss/settings';

.FullScreenModal.ElementModal.image {
    --ws-drawing-line-color: var(--palette-grey-900);

    // Prevent dragging of image modal background.
    // By default, all modal overlays are draggable in the  in Electron App,
    // however, this interferes with the cropping tool, so disable for the image modal
    -webkit-app-region: no-drag;

    .modal-positioner {
        pointer-events: none;
    }

    .modal-container {
        margin-bottom: 8.3rem;
        padding: 0;

        pointer-events: all;
    }

    .FloatingPanel {
        pointer-events: all;
    }

    .light-theme & {
        .ModalToolbar {
            --ui-modal-divider: #{$white-opacity-10};

            --ui-background-primary: var(--palette-charcoal-930);
            --ui-foreground-primary: var(--palette-white);
            --ui-foreground-secondary: var(--palette-grey-500);

            --ui-modal-background-primary: var(--palette-charcoal-930);
            --ui-modal-foreground-primary: var(--palette-white);
            --ui-divider-on-secondary: var(--palette-charcoal-750);

            --element-shadow-elevation-3: var(--element-shadow-elevation-3-dark-mode);

            --ws-element-selection: var(--palette-white);
            --ws-no-color-button-border-color: var(--palette-charcoal-750);
            --ws-high-contrast-button-border-color: var(--palette-charcoal-750);

            // Disabled buttons
            --color-neutral-600: var(--palette-charcoal-800);
        }

        .ImageModal.crop .ModalToolbar,
        .ImageModal.draw .ModalToolbar {
            --ui-background-primary: var(--palette-white);
            --ui-modal-background-primary: var(--palette-white);
            --ui-modal-divider: #{$black-opacity-10};
        }

        .DrawingEditorColorPopup,
        .DrawingEditorStrokeSizePopup,
        .ImageModalToolbarSecondaryTools {
            --ui-background-primary: var(--palette-white);
            --ui-foreground-primary: var(--palette-grey-900);
            --ui-foreground-secondary: var(--palette-grey-800);

            --ui-modal-background-primary: var(--palette-white);
            --ui-modal-foreground-primary: var(--palette-grey-900);
            --ui-modal-divider: #{$black-opacity-10};
            --ui-divider-on-secondary: var(--palette-grey-600);
            --ui-toolbar-tool-background: rgba(155, 152, 158, 0.2);
            --ui-toolbar-tool-default-background: rgba(155, 152, 158, 0.2);

            --element-shadow-elevation-3: var(--element-shadow-elevation-3-dark-mode);

            --ws-element-selection: var(--palette-black);
            --ws-no-color-button-border-color: var(--palette-grey-600);
            --ws-high-contrast-button-border-color: var(--palette-grey-600);

            // Disabled buttons
            --color-neutral-600: var(--palette-grey-600);
        }
    }
}

.FullScreenModal.overlay .Modal.ElementModal.image {
    background: none;
    box-shadow: var(--modal-box-shadow);

    overflow: visible;
}

.ElementModal.image {
    min-width: 0;

    .auto-width & {
        min-width: 0;
    }
}

.ImageModal {
    display: flex;
    flex-direction: column;

    max-width: calc(100vw - 2.2rem);

    border-radius: 0.5rem;

    background: none;
    font-size: $font-size-element-default;

    .image-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;
    }

    .image-container {
        display: flex;
        position: relative;

        flex-direction: column;

        width: 100%;
        height: 100%;

        .image-wrapper {
            width: 100%;
            height: 100%;
            background: var(--ws-element-background-primary);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            > div {
                width: 100%;
            }

            .DrawingSvg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
            }
        }

        .OptimisticImage {
            position: relative;
            width: 100%;
            height: 100%;

            .BackgroundImage {
                width: 100%;
                height: 100%;

                // We need to override the background image padding-bottom to prevent mis-sized
                // images from having a tiny white border on the right or bottom of the image
                // stylelint-disable-next-line declaration-no-important
                padding-bottom: unset !important;
            }

            .image-node {
                width: 100%;
                height: 100%;

                object-fit: cover;

                user-select: none;
                -webkit-touch-callout: none;
            }
        }

        .AttachmentImage {
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .Caption {
            padding: 1.6rem 2.4rem 0;
            background: var(--ws-element-background-primary);

            .CaptionTiptapEditor,
            .SimpleTextEditor {
                color: var(--ui-foreground-secondary);

                .tiptap,
                .public-DraftEditor-content {
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            .app-size-medium & {
                padding-top: 14px;
            }

            .app-size-small & {
                padding-top: 13px;
            }
        }
    }

    .uploading {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .ModalToolbar {
        .DownloadOpenLinkButton {
            height: 100%;
            border-right: 1px solid var(--ui-modal-divider);
            text-decoration: none;

            &:last-child {
                border-right: none;
            }
        }

        .ModalToolbarBody {
            flex-shrink: 0;
            width: auto;
        }
    }

    .app-size-mobile & {
        .content {
            max-width: 100%;
        }
    }
}
