@import '../../../scss/settings';

.ErrorRenderer {
    background: var(--error-background);
    color: var(--palette-grey-900);

    .LinkButton {
        color: var(--error-color);
    }

    .error-message {
        margin-bottom: 1.6rem;

        h3 {
            color: var(--error-color);
            font-weight: $font-weight-semibold;
        }

        p {
            margin-top: 1rem;
        }
    }

    details {
        font-size: $font-size-ui-small;

        summary {
            outline: none;
        }

        .clipboard {
            margin: 0.6rem 0 1.2rem 0;
        }

        .info {
            margin-top: 0.5rem;
            margin-left: 1.6rem;
        }

        .support-message {
            margin-bottom: 1rem;
        }

        button {
            color: var(--palette-grey-900);
        }

        textarea {
            width: 100%;
            height: 5rem;
            padding: 1rem;
            outline: 0;

            background: $white-opacity-50;
            color: var(--palette-grey-900);

            resize: none;
        }
    }
}
