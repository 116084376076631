@import '../../../scss/settings';
@import '../../elementBox';
@import '../../../list/listElementDraggingMixin';

.FileContainer {
    &:focus {
        outline: none;
    }
}

.FileDetailView {
    @include element-box;
    @include list-element-dragging;

    .Caption {
        margin-top: -3px;

        .app-size-small & {
            margin-top: -2px;
        }
    }

    .focusable:focus {
        outline: none;
    }
}
