@import '../../scss/settings';

.ElementContentsTag {
    min-height: 1.4rem;
    color: var(--ws-element-tertiary);
    font-size: $font-size-element-secondary;
    line-height: 1em;
    white-space: nowrap;
    pointer-events: none;

    .text {
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }

    .in-list-column & {
        min-height: 1.2rem;

        .app-size-medium & {
            min-height: 11px;
        }

        .app-size-small & {
            min-height: 10px;
        }
    }

    .app-size-medium & {
        min-height: 12px;
    }

    .app-size-small & {
        min-height: 11px;
    }
}
