@import '../../../../scss/settings';

.CheckoutModalContent {
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;

    .checkout-modal-header {
        .ModalBackButton {
            position: relative;
            margin-top: -1.8rem;
            margin-bottom: 1.4rem;
            margin-left: -1rem;
            padding: 1rem;
        }

        h1 {
            font-family: $font-modal-heading;
            font-weight: 600;
            letter-spacing: -0.25px;
            line-height: 1.17;
            text-align: left;
        }
    }

    .checkout-modal-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 10rem;
        margin-top: 2.6rem;


        .button-container {
            margin-top: 3.2rem;

            .StyledButton {
                width: 22.4rem;
                height: 4rem;
                padding-top: 1.2rem;
                padding-bottom: 1.1rem;
            }

            .error-message {
                max-width: 50rem;
                max-height: 2rem;
                margin-top: 1.4rem;
            }
        }
    }

    .secondary-description {
        color: var(--ui-modal-foreground-tertiary);
        font-size: var(--font-size-ui-small);

        & > * {
            color: var(--ui-modal-foreground-tertiary);
        }
    }
}
