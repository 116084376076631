.CellFormulaErrorRenderer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: var(--line-height-element);

    .TooltipSource.cell-formula-error-tooltip-source {
        display: flex;

        svg.Icon.error-alert-round {
            width: 1.6rem;
            height: 1.6rem;
            overflow: visible;

            .Table:not(.selected-single) & {
                z-index: calc(var(--z-index-table-cover) + 1);
            }
        }
    }
}

.FloatingPanel.Tooltip.cell-formula-error-tooltip-source {
    padding: 1rem 1.2rem 0.9rem 1.2rem !important; /* stylelint-disable-line declaration-no-important */

    .tooltip-cell-formula-error-content {
        display: flex;
        align-items: center;
        font-size: 1.2rem;

        svg.Icon.error-alert-round {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 0.8rem;
        }
    }
}
