@import '../../../../../../scss/settings';

.TextStyleEntry {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 4rem;

    padding: 0 1.6rem 0 2rem;

    text-align: left;
    cursor: pointer;
    user-select: none;
    -webkit-touch-callout: none;

    &.active {
        background: var(--ui-block-style-active-background);

        cursor: default;
        pointer-events: none;
    }

    &:last-of-type {
        border-bottom: 0;
    }

    .Icon.block-style-tick path {
        fill: var(--ui-foreground-primary);
    }

    .ShortcutKeyGroup {
        --ui-shortcut-key-border-color: var(--ui-tooltip-shortcut-key-background-color);
        --ui-shortcut-key-background-color: var(--ui-tooltip-shortcut-key-background-color);
        --ui-shortcut-key-text-color: var(--palette-grey-600);

        align-items: baseline;
        justify-content: flex-end;

        height: 1.6rem;
        margin-top: 1px;
        margin-left: 0.8rem;
        font-weight: normal;

        .ShortcutKey {
            min-width: 0.8rem;
            height: auto;
            margin: 0;
            padding-top: 0;
        }
    }

    .header-one {
        margin-bottom: 2px;
        color: var(--ws-element-primary);
        font-family: $font-element-heading;
        font-size: var(--font-size-element-heading-one);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: $letter-spacing-heading;
    }

    .header-two {
        margin-bottom: 2px;
        color: var(--ws-element-primary);
        font-family: $font-element-heading;
        font-size: $font-size-element-heading;
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: $letter-spacing-heading;
    }

    .small-text {
        font-size: $font-size-ui-small;
    }

    .code-block {
        padding: em(8px) em(12px) em(8px);
        border-radius: em(4px);
        background: var(--ws-text-code-background-color);

        color: var(--ws-text-code-block-color);
        font-family: $font-monospace;
        font-size: $font-size-ui-small;
    }

    .blockquote {
        padding-top: 1px;
        padding-bottom: 3px;
        padding-left: calc(#{$element-padding-default} - 1rem);
        border-left: 2px solid var(--ws-element-secondary);

        font-family: $font-element-quote;
        font-style: italic;
        line-height: $element-line-height;
    }

    .app-size-large & {
        .unstyled {
            margin-bottom: 2px;
        }
    }

    .app-size-small & {
        .ShortcutKeyGroup {
            margin-top: 0;
        }
    }
}
