@import '../../../../scss/settings';

.MentionSuggestionEntry {
    display: flex;
    position: relative;
    align-items: center;

    min-height: 4.8rem;
    padding: 0 $element-padding-default;
    cursor: pointer;

    &.focused {
        background-color: var(--ws-mention-background);
    }

    .UserAvatar {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .user-details {
        display: flex;
        flex-direction: column;

        flex-grow: 1;
        flex-shrink: 1;
        align-items: flex-start;

        padding-left: 1rem;
        overflow: hidden;

        .email,
        .name {
            max-width: 100%;
            padding-bottom: 0.4rem;

            font-size: $font-size-ui-small;
            line-height: 1em;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .name {
            padding-top: 0.4em;

            color: var(--ws-mention-color);
            font-weight: $font-weight-semibold;

            .highlight {
                font-weight: bold;
            }
        }

        .email {
            color: var(--ui-foreground-tertiary);

            .highlight {
                font-weight: $font-weight-semibold;
            }
        }
    }

    &.disabled {
        cursor: default;

        .user-details {
            .email {
                color: var(--ws-element-tertiary);
            }

            .name {
                color: var(--ws-element-secondary);

                .highlight {
                    color: var(--ws-element-primary);
                }
            }
        }

        &:hover,
        &.focused {
            background-color: var(--ui-background-primary);
        }
    }

    .invite-button {
        width: auto;
        margin-left: 0.6rem;
        padding: 0.4rem 0.8rem;
        font-size: $font-size-ui-small;
    }

    .Spinner {
        width: 2rem;
        height: 2rem;
        margin: 0 1.6rem 0 2rem;
    }

    .error {
        display: block;
        flex-shrink: 0;
        width: 4.8rem;
        margin-left: 0.6rem;

        color: var(--error-color);
        font-size: $font-size-ui-small;
        text-align: center;
    }
}
