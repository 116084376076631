@import '../../../scss/settings';

.HiddenInput {
    // If the height is 0 the events won't fire
    height: 10px;
    margin-top: -10px;
    // Prevents focus glow
    outline: none;
    // Prevents the caret from flashing in Firefox
    color: $white;
    opacity: 0;
    overflow: hidden;
    // Stops the cursor from appearing as "text"
    pointer-events: none;
    // Ensures Safari receives the input events
    -webkit-user-select: text;
    user-select: text;
}
