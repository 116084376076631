#onetrust-banner-sdk {
    display: none;

    body.show-cookie-banner & {
        display: block;
    }
}

/*
 * Dark banner overlay, will prevent page interaction
 */
.onetrust-banner-dark-filter {
    animation: fade-in 0.15s linear;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // Match the registration modal overlay
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    z-index: 2147483640;
}

#onetrust-consent-sdk {
    $break-mobile: 767px;

    .board-preview & {
        display: none;
    }

    /*
    * Cookie banner
    */
    #onetrust-banner-sdk {
        width: 90%;
        max-width: 105rem;
        margin-right: auto;
        margin-bottom: 30px;

        margin-left: auto;
        transition: none !important; // stylelint-disable-line declaration-no-important

        border-radius: 8px;
        box-shadow: 0 10px 20px 3px rgba(50, 59, 74, 0.2);

        opacity: 1;
        animation: fade-in 0.15s linear, slide-up-banner 0.15s ease-out;

        * {
            font-size: 1.4rem;
        }

        &:focus {
            outline: none;
        }

        button {
            padding: 1rem 2rem;
            font-size: 1.4rem;
            font-weight: normal;
            white-space: nowrap;
        }

        button:hover,
        button:focus {
            opacity: 1 !important; // stylelint-disable-line declaration-no-important
        }

        > div {
            display: flex;
            align-items: flex-start;

            // Cookie Policy container
            .ot-sdk-container {
                position: relative;
                flex-shrink: 100;
                width: auto;
                padding: 0;

                .ot-sdk-row {
                    display: flex;
                    align-items: center;
                    padding: 3.2rem;

                    @media (max-width: $break-mobile) {
                        flex-direction: column;
                        padding: 2.4rem;
                    }

                    // Policy text
                    #onetrust-group-container {
                        width: auto;

                        #onetrust-policy {
                            margin: 0;
                        }

                        #onetrust-policy-text {
                            margin: 0;
                            padding-right: 1rem;
                            font-size: 1.4rem;

                            a,
                            a:hover,
                            a:visited {
                                outline: none;
                                text-decoration: underline;
                            }
                        }
                    }

                    // Consent buttons
                    #onetrust-button-group-parent {
                        display: flex;
                        position: static;
                        flex-shrink: 0;
                        width: auto;
                        padding: 0;
                        transform: initial;

                        @media (max-width: $break-mobile) {
                            flex-direction: column-reverse;
                            width: 100%;
                            margin-bottom: -0.8rem;
                            padding-top: 2.4rem;

                            button {
                                margin-right: 0;
                                margin-bottom: 7px;
                            }

                            button:first-child {
                                margin-bottom: 20px;
                            }
                        }

                        #onetrust-button-group {
                            display: contents;

                            .cookie-setting-link {
                                font-weight: normal;
                                white-space: nowrap;
                            }

                            button {
                                width: auto;
                                min-width: auto;
                                margin-top: 2px;
                                margin-bottom: 2px;
                                border-radius: 3px;
                                text-align: center;
                            }

                            button:hover,
                            button:focus {
                                opacity: 1;
                            }

                            button:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            // Close button
            #onetrust-close-btn-container {
                position: absolute;
                top: 0;
                right: 0;
                width: auto;
                margin: 0;
                transform: initial;

                button {
                    top: 0;
                    right: 0;
                    padding: 20px;
                }

                button:hover,
                button:focus {
                    outline: none;
                    opacity: 1;
                }
            }
        }

        // Mobile only
        @media (max-width: $break-mobile) {
            width: 95%;
            margin-bottom: 10px;
        }
    }

    /*
   * Cookie preference centre
   */
    #onetrust-pc-sdk.ot-fade-in,
    .onetrust-pc-dark-filter.ot-fade-in {
        animation: none !important; // stylelint-disable-line declaration-no-important
        transition: none !important; // stylelint-disable-line declaration-no-important
    }
    #onetrust-pc-sdk.otPcCenter {
        top: 15%;
        height: 70vh !important; // stylelint-disable-line declaration-no-important
        border-radius: 8px;

        @media (max-width: $break-mobile) {
            top: 0;
            height: 100vh !important; // stylelint-disable-line declaration-no-important
            border-radius: 0;
        }

        & :focus,
        & :hover {
            outline: none;
        }

        h2,
        h3 {
            font-size: 1.8rem;
        }

        button {
            font-size: 1.4rem;
            font-weight: normal;
        }

        button.save-preference-btn-handler,
        button.ot-pc-refuse-all-handler,
        button#accept-recommended-btn-handler {
            padding: 1rem 2rem;
        }

        .ot-pc-header {
            height: 0;
            padding: 0;
            border-bottom: none;

            .ot-pc-logo {
                display: none;
            }
        }

        #ot-pc-content {
            top: 23px;
            bottom: 91px;
            width: auto;
            margin: 0;
            padding: 0 32px;

            @media (max-width: $break-mobile) {
                bottom: 172px;
            }

            button:hover,
            button:focus {
                opacity: 1;
            }

            #ot-pc-title {
                font-size: 2.1rem;
                font-weight: 600;
            }

            #ot-pc-desc {
                font-size: 1.4rem;
                font-weight: normal;
                line-height: 2.2rem;
            }

            .ot-cat-grp {
                padding-bottom: 50px;

                #ot-category-title {
                    font-size: 1.5rem;
                    font-weight: 600;
                }

                .ot-cat-item.ot-accordion-layout {
                    border: none;

                    &:first-of-type {
                        border-top: none;
                    }

                    .ot-acc-hdr {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 7.5px 0;

                        .ot-cat-header {
                            flex-grow: 1;
                            min-height: auto;
                            margin-left: 25px;
                            font-size: 1.4rem;
                            font-weight: normal;
                        }

                        .ot-always-active {
                            right: 0;
                            color: #4eb85a;
                            font-weight: normal;
                        }

                        // Arrow
                        .ot-arw-cntr {
                            display: none;
                        }

                        // Toggle
                        .ot-tgl {
                            position: static;
                            top: auto;
                            right: auto;
                            transform: initial;
                        }

                        .ot-tgl input + .ot-switch .ot-switch-nob {
                            border: 1px solid #e8e9eb;
                            background-color: #e8e9eb;

                            &::before {
                                background-color: #ffffff;
                            }
                        }

                        .ot-tgl input:checked + .ot-switch .ot-switch-nob {
                            border: 1px solid #4eb85a;
                            background-color: #4eb85a;

                            &::before {
                                background-color: #ffffff;
                            }
                        }

                        .ot-tgl input:focus + .ot-switch {
                            outline: none;
                        }
                    }

                    .ot-acc-hdr .ot-plus-minus {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        margin-left: -6px;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        background-image: url('./images/arrow-small-right.svg');
                        background-size: contain;

                        span {
                            display: none;
                        }
                    }

                    [aria-expanded='true'] + .ot-acc-hdr .ot-plus-minus {
                        -webkit-transform: translateY(-50%) rotate(90deg);
                        transform: translateY(-50%) rotate(90deg);
                    }

                    .ot-acc-txt {
                        width: auto;
                        margin: 11.5px 0;
                        padding: 0 0 0 25px;
                        background-color: #ffffff;

                        .ot-category-desc {
                            width: auto;
                            margin-top: 0;
                            padding: 0;
                            color: #323b4a;
                            font-size: 1.4rem;
                            line-height: 2.2rem;
                        }

                        .ot-hlst-cntr {
                            padding: 0;

                            button {
                                font-size: 1.4rem;
                                text-decoration: underline;
                            }
                        }

                        .ot-subgrp-cntr {
                            width: auto;
                            padding-right: 0;
                            padding-left: 0;
                            border: none;

                            .ot-subgrp {
                                margin: 0;
                            }

                            h5 {
                                font-size: 1.4rem;
                            }

                            .ot-subgrp-desc {
                                font-size: 1.4rem;
                                line-height: 2.2rem;
                            }
                        }
                    }

                    .ot-cat-grp {
                        .ot-always-active {
                            color: #4eb85a;
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .ot-pc-footer {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            margin-bottom: 12px;
            border-top: none;
            background: #ffffff;
            z-index: 100;

            .ot-btn-container {
                margin: 0 20px;
                border-top: solid 1px #e8e9eb;

                button {
                    width: auto;
                    margin: 20px 0;
                    border-radius: 3px;
                    letter-spacing: normal;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                button:hover,
                button:focus {
                    opacity: 1;
                }

                @media (max-width: $break-mobile) {
                    width: calc(100% - 40px);
                    padding: 20px 0 0 0;

                    button {
                        width: 100%;
                        margin: 10px 0 0 0;
                    }
                }
            }
        }

        .ot-pc-footer-logo {
            display: flex;
            position: absolute;
            bottom: 0;
            height: 100%;
            background: transparent;
            text-align: left;
            z-index: -1;

            a {
                width: 120px;

                margin: auto 0 auto 20px;
            }

            @media (max-width: $break-mobile) {
                display: block;
                position: static;
                height: auto;
                margin-top: 20px;
                text-align: center;
            }
        }
    }

    /*
   * Cookie List
   */
    #ot-pc-lst.ot-hosts-ui {
        top: 23px;
        bottom: 91px;
        overflow: visible;

        @media (max-width: $break-mobile) {
            bottom: 172px;
        }

        #ot-pc-hdr {
            width: auto;
            margin: 0 20px;

            .ot-lst-title {
                font-size: 1.8rem;
            }

            .ot-search-cntr {
                width: 90%;

                svg {
                    right: 0;
                }
            }

            .ot-fltr-cntr {
                right: 0;
            }

            .ot-lst-subhdr {
                margin: 20px 0;

                button#filter-btn-handler {
                    border: none;
                    background-color: #323b4a;
                }

                button#filter-btn-handler:hover,
                button#filter-btn-handler:focus {
                    opacity: 1;
                }

                #ot-anchor {
                    top: 102px;
                    right: 24px;
                }

                #ot-fltr-cnt {
                    right: 15px;
                    left: auto;
                    width: auto;
                    height: auto;
                    margin-top: 15px;
                    padding: 20px;

                    @media only screen and (max-width: 600px) {
                        top: 99px;
                    }

                    #clear-filters-handler {
                        margin-top: 0;
                        margin-bottom: 25px;
                    }

                    .ot-fltr-scrlcnt {
                        overflow-x: initial;
                        overflow-y: initial;

                        .ot-fltr-opt {
                            width: auto;
                            margin-left: 0;

                            .ot-chkbox input[type='checkbox']:focus {
                                outline: none;
                            }
                        }

                        .ot-fltr-btns {
                            margin-left: 0;

                            @media only screen and (max-width: 600px) {
                                padding-top: 20px;
                            }

                            button {
                                margin-bottom: 0;
                            }

                            button:hover,
                            button:focus {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            #vendor-search-handler {
                margin-left: 0;
            }

            #ot-back-arw {
                margin: 0;
            }
        }

        #ot-lst-cnt {
            position: absolute;
            top: 120px;
            right: 0;
            bottom: 0;
            left: 0;
            width: auto;

            min-width: auto;
            height: auto;
            max-height: initial;
            margin: 0;
            padding: 0 20px;

            #ot-host-lst {
                .ot-host-item {
                    padding: 15px 0;

                    .ot-acc-txt {
                        width: auto;

                        .ot-host-opt li > div div {
                            font-size: 1.2rem;
                        }
                    }

                    button:focus {
                        border: none;
                    }

                    // Cookie name
                    .ot-acc-hdr {
                        display: flex;
                        position: relative;

                        .ot-host-hdr {
                            margin-left: 30px;

                            & > a {
                                margin: 0.5rem 0;
                                font-size: 1.2rem;
                            }

                            .ot-host-expand {
                                margin: 0.3rem 0;
                                font-size: 1.2rem;
                            }
                        }
                    }

                    .ot-acc-hdr .ot-plus-minus {
                        position: absolute;
                        top: 30%;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        background-image: url('./images/arrow-small-right.svg');
                        background-size: contain;

                        span {
                            display: none;
                        }
                    }

                    [aria-expanded='true'] + .ot-acc-hdr .ot-plus-minus {
                        -webkit-transform: translateY(-50%) rotate(90deg);
                        transform: translateY(-50%) rotate(90deg);
                    }

                    .ot-c-description {
                        div:last-child {
                            width: 65%;
                        }
                    }
                }
            }
        }
    }
}

@keyframes slide-up-banner {
    0% {
        transform: translateY(40px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
