@import '../../scss/settings';

.MobileHeader {
    padding-right: var(--safe-area-right, 0px);
    padding-left: var(--safe-area-left, 0px);

    background: var(--ui-background-primary);
    box-shadow: var(--shadow-app-header);
    z-index: var(--z-index-header);

    .mobile-header-safe-area-padding {
        height: var(--safe-area-top, 0px);
    }

    .mobile-header-content {
        height: var(--modern-mobile-header-height);

        color: var(--ui-foreground-primary);
        font-family: $font-element-heading;
        font-size: var(--font-size-ui-xxlarge);
    }
}
