.fake-element-create-input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;

    margin: 0;
    padding: 0;
    border: 0;

    outline: none;
    background: none;
    opacity: 0.1;

    pointer-events: none;
    touch-action: none;
    caret-color: var(--ui-background-primary);
}
