@import '../../scss/settings';

.LinkStateDisplay {
    display: flex;
    align-items: center;

    width: 1.6rem;
    min-width: 1.6rem;
    height: 1.6rem;
    min-height: 1.6rem;

    .Spinner {
        width: 1.6rem;
        height: 1.6rem;
    }
}
