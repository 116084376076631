@mixin floating-reactions() {
    .ElementReactions {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 0;
        padding-bottom: calc(var(--element-padding-default) - 0.2rem);

        .ElementReactionPill {
            box-shadow: var(--element-shadow-elevation-1);
        }

        .ElementReactionAddButton {
            background: var(--ws-reaction-pill-background);
            box-shadow: var(--element-shadow-elevation-1);
        }
    }
}
