@import '../scss/settings';
@import './dnd/ElementDragPreview';

.Element {
    position: relative;

    &.dragging {
        visibility: hidden;
        pointer-events: none;

        .ListElement & {
            visibility: visible;
        }

        .ListElement .AttachedCommentsContainer & {
            visibility: hidden;
        }
    }

    &.copy {
        opacity: 0.4;
        // Prevent copy-dragged elements from being dropped onto their original element, otherwise
        // dropping a duplicate task-list onto itself will kill the task list.
        pointer-events: none;
    }
}

.CanvasElement.drag-end,
.CanvasElement > .Element.dragging {
    // NOTE: This is important. It makes the CanvasElement 0 in size while dragging but does not
    // cause a measurements update.  By making it 0 in size, hovered elements can be dropped onto
    // things underneath themselves.
    position: absolute;

    // NOTE: This is the same as the drag preview
    // Need to use "after" here instead of before, because the :before is used for the border
    // Note ">" ensure that this only applies to the direct child of the draggables
    > .drag-handle {
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            transition: opacity 0.1s linear;

            box-shadow: var(--element-shadow-elevation-3);

            content: '';
            opacity: 0;

            z-index: -1;
            pointer-events: none;
        }
    }

    .drop-shadow {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        transition: opacity 0.1s linear;

        box-shadow: var(--element-shadow-elevation-3);

        content: '';
        opacity: 1;

        z-index: -1;
        pointer-events: none;

        .drop-shadow {
            display: none;
        }
    }
}
