@import '../../../scss/settings';

.BoardImage {
    position: relative;
    width: 80px;
    height: 80px;
    overflow: hidden;

    .OptimisticImage {
        .Spinner {
            border-radius: $board-border-radius;
        }
    }

    .ProgressiveImage {
        position: static;
    }

    .app-size-small & {
        width: 64px;
        height: 64px;
    }
}
