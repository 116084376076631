@import '../../../scss/settings';

.RichMediaDragPreview {
    position: relative;

    font-size: $font-size-ui-default;

    .RichMediaOverlay {
        background: var(--color-neutral-300);
    }
}
