@import '../../../scss/settings';

.DocumentModal {
    .DocumentModalTiptapEditor {
        height: inherit;
    }

    .tiptap {
        min-height: 100%;
        color: var(--ws-element-primary);

        h1,
        h2 {
            margin: 1.7rem 0 1.2rem 0;
            line-height: 2.9rem;

            &:first-child {
                margin-top: -6px;
            }
        }

        h1 {
            font-size: var(--font-size-ui-xxxlarge);
        }

        h2 {
            font-size: var(--font-size-ui-xlarge);
        }

        p {
            margin-bottom: 1.2rem;
        }

        blockquote {
            line-height: 2.4rem;
        }
    }
}
