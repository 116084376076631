@import '../../../../../../scss/settings';
@import '../../../../../../scss/mixins/outer-border-mixin';

.TextColorButton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 20px;

    border-radius: 0.2rem;

    font-weight: 600;

    cursor: pointer;

    &.active {
        @include outer-border-full(2px, var(--ws-element-selection), 0.5rem);

        &:before {
            top: -4px;
            right: -4px;
            bottom: -4px;
            left: -4px;
        }
    }

    &.text-blue,
    &.text-green,
    &.text-purple,
    &.text-red,
    &.text-orange,
    &.text-brown,
    &.text-grey,
    &.text-standard {
        background: none;
        color: var(--ui-foreground-primary);
    }

    &.text-blue {
        color: var(--ws-text-color-blue);
    }

    &.text-green {
        color: var(--ws-text-color-green);
    }

    &.text-purple {
        color: var(--ws-text-color-purple);
    }

    &.text-red {
        color: var(--ws-text-color-red);
    }

    &.text-orange {
        color: var(--ws-text-color-orange);
    }

    &.text-brown {
        fill: var(--ws-text-color-brown);
    }

    &.text-grey {
        color: var(--ws-text-color-grey);
    }

    &.bg-darkgrey,
    &.bg-dark-grey {
        background: var(--ws-text-background-color-dark-grey);
        color: var(--ws-text-background-color-dark-grey-text);
    }

    &.bg-blue,
    &.bg-green,
    &.bg-purple,
    &.bg-pink,
    &.bg-orange,
    &.bg-yellow,
    &.bg-grey {
        color: var(--ws-text-highlight-color);
    }

    &.bg-blue {
        background: var(--ws-text-background-color-blue);
    }

    &.bg-green {
        background: var(--ws-text-background-color-green);
    }

    &.bg-purple {
        background: var(--ws-text-background-color-purple);
    }

    &.bg-pink {
        background: var(--ws-text-background-color-pink);
    }

    &.bg-orange {
        background: var(--ws-text-background-color-orange);
    }

    &.bg-yellow {
        background: var(--ws-text-background-color-yellow);
    }

    &.bg-grey {
        background: var(--ws-text-background-color-grey);
    }

    .app-size-medium & {
        width: 24px;
        height: 20px;

        .Icon {
            width: 20px;
            height: 16px;
        }
    }

    .app-size-small & {
        width: 21px;
        height: 18px;

        .Icon {
            width: 17px;
            height: 14px;
        }
    }
}
