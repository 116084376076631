@import '../../../scss/settings';

.Editor {
    outline: none;

    a {
        --ws-text-highlight-color: var(--ui-accent-primary);
        color: var(--ws-element-anchor);
        text-decoration: underline;
        cursor: pointer;
    }

    .DraftEditor-editorContainer {
        border-left: 0;

        .public-DraftEditor-content {
            > div {
                user-select: none;
                -webkit-touch-callout: none;

                // Allow text selection when the page is not editable
                .text-selectable &,
                .not-editable & {
                    user-select: text;
                }
            }
        }
    }

    .editing & {
        a {
            --ws-text-highlight-color: var(--ws-element-primary);

            color: var(--ws-element-primary);
            text-decoration: underline;
            cursor: text;

            &.clickable {
                color: var(--ws-element-anchor);
                cursor: pointer;
            }
        }

        .DraftEditor-editorContainer {
            .public-DraftEditor-content {
                > div {
                    user-select: text;
                }
            }
        }

        .public-DraftStyleDefault-pre {
            a {
                color: var(--ws-text-code-block-color);
            }
        }
    }

    .SearchHighlightSpan {
        background: var(--search-color);
        color: var(--search-color-contrast);

        span {
            background: var(--search-color);
            color: var(--search-color-contrast);
        }
    }

    .editing .ListElement & {
        a {
            color: var(--ws-element-anchor);

            &:hover {
                cursor: pointer;
            }
        }
    }

    .BoardPreviewWorkspace.board-export & {
        .public-DraftEditorPlaceholder-hasFocus,
        .public-DraftEditorPlaceholder-root {
            visibility: hidden;
        }
    }
}
