@import '../../../../../../scss/settings';
@import '../../../../../../scss/mixins/outer-border-mixin';

.ColorButton {
    // This ensures that the white colour button stands out from the background
    --ws-no-color-button-border-color: #{$grey-600};
    --ui-color-button-background: var(--ws-element-background-primary);
    --button-border-radius: 0.2rem;
    --selection-border-radius: 0.5rem;

    position: relative;
    width: 24px;
    aspect-ratio: 1.2;
    container-type: size;
    border-radius: var(--button-border-radius);
    background-color: var(--ui-color-button-background);

    .app-size-medium & {
        width: 22px;
    }

    .app-size-small & {
        width: 19px;
    }

    &.low-contrast-color,
    &.no-color {
        @include overlapping-border-full(1px, var(--ws-no-color-button-border-color), var(--button-border-radius));
    }

    &.high-contrast-color {
        @include overlapping-border-full(1px, var(--ws-high-contrast-button-border-color), var(--button-border-radius));
    }

    &.remove-color {
        background-image: linear-gradient(
            141deg,
            #{$white-opacity-0} 46%,
            var(--ui-color-picker-no-color-stroke-color) 47%,
            var(--ui-color-picker-no-color-stroke-color) 53%,
            #{$white-opacity-0} 54%
        );
    }

    &.transparent {
        @include overlapping-border-full(1px, var(--ws-no-color-button-border-color), var(--button-border-radius));

        background-image: url('../transparency-pattern-light.gif');
        image-rendering: pixelated;

        &:before {
            border-radius: var(--button-border-radius);
        }
    }

    &.mode-color-bar {
        @include overlapping-border-full(1px, var(--ws-no-color-button-border-color), var(--button-border-radius));

        background-color: transparent;

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 27cqh;

            border-top-left-radius: var(--button-border-radius);
            border-top-right-radius: var(--button-border-radius);

            background-color: var(--ui-color-button-background);

            content: '';

            z-index: 1;
        }

        &.no-color:after {
            display: none;
        }

        &.low-contrast-color {
            &:after {
                border-top: 1px solid var(--ws-no-color-button-border-color);
                border-right: 1px solid var(--ws-no-color-button-border-color);
                border-left: 1px solid var(--ws-no-color-button-border-color);
            }
        }
    }

    &.active .selection-border {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        @include outer-border-full(2px, var(--ws-element-selection), var(--selection-border-radius));

        &:before {
            top: -4px;
            right: -4px;
            bottom: -4px;
            left: -4px;
        }
    }

    .dark-theme & {
        --ws-no-color-button-border-color: var(--palette-charcoal-750);

        &.transparent {
            background-image: url('../transparency-pattern-dark.gif');
        }
    }
}
