@import '../../../scss/settings';

.RichMediaOverlay {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background: $black-opacity-70;
    color: $white;
}
