@import '../../scss/settings';

.delete-button-container {
    position: absolute;
    top: 0;
    right: 0.6rem;

    overflow: hidden;
    z-index: 1;

    .drag-enabled & {
        right: 2.6rem;
    }

    .platform-ipad & {
        display: none;
    }
}

.TaskDeleteButton {
    padding: 0.8rem 0.5rem 0.8rem 1rem;

    cursor: pointer;

    .background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        transition: opacity 0.16s linear;

        background: linear-gradient(
            to right,
            var(--ws-element-background-primary-opacity-40),
            var(--ws-element-background-primary) 30%
        );
        opacity: 1;
        z-index: -1;
    }

    .Icon {
        transform: translate3d(0, 0, 0);
        transition: transform 0.16s ease-out, opacity 0.1s linear;
        opacity: 1;
    }

    &.slide-enter,
    &.slide-appear,
    &.slide-leave.slide-leave-active {
        .Icon {
            transform: translate3d(3.1rem, 0, 0);
            opacity: 0;
        }

        .background {
            opacity: 0;
        }
    }

    &.slide-enter.slide-enter-active,
    &.slide-appear.slide-appear-active,
    &.slide-leave {
        .Icon {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }

        .background {
            opacity: 1;
        }
    }

    &.slide-enter.slide-enter-active,
    &.slide-appear.slide-appear-active {
        .Icon {
            transition-delay: 0.5s;
        }

        .background {
            transition-delay: 0.5s;
        }
    }

    &.slide-leave {
        .Icon {
            transition: transform 0.16s ease-in, opacity 0.1s linear;
        }
    }
}

.TaskStyler {
    &.large-top {
        .delete-button-container {
            top: 1.7rem;
        }
    }

    .app-size-medium & {
        .TaskDeleteButton {
            padding: 7px 4px 7px 8px;
        }

        &.large-top {
            .delete-button-container {
                top: 16px;
            }
        }
    }

    .app-size-small & {
        .TaskDeleteButton {
            padding: 6px 4px 6px 8px;
        }

        .delete-button-container {
            top: 1px;
        }

        &.large-top {
            .delete-button-container {
                top: 14px;
            }
        }
    }
}
