.SimpleDateTimePicker {
    .calendar-section {
        margin-bottom: 1.5rem;
    }
    .time-section {
        display: flex;
        flex-direction: row;
    }
}
.TableDateTimePicker {
    width: 29rem;
    padding: 2rem;
}
