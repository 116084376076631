@import '../../scss/settings';

.Caption {
    &.hidden {
        display: none;
    }

    .focusable:focus {
        outline: none;
    }

    .SimpleTextEditor {
        color: var(--ws-element-primary);
        line-height: 2rem;
        word-wrap: break-word;
    }

    &.pad-top {
        .SimpleTextEditor {
            .public-DraftEditorPlaceholder-root,
            .public-DraftEditor-content {
                padding-top: $element-padding-small;
                padding-bottom: $element-padding-small;
            }
        }
    }
}
