.tiptap {
    blockquote {
        position: relative;

        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 1px;
        padding: 0.5rem 0 0.5rem calc(var(--element-padding-default) - 1rem);
        border-left: 2px solid var(--ws-element-secondary);

        font-family: var(--font-element-quote), serif;
        font-style: italic;
        line-height: 2rem;

        quotes: '\201C''\201D''\2018''\2019';

        > p {
            // Ensure that text-align properties aren't accidentally rendered
            text-align: unset !important;

            &:not(:has(.ProseMirror-trailingBreak:only-child)) {
                &:first-child:before {
                    line-height: 1em;
                    content: open-quote;
                }

                &:last-child:after {
                    line-height: 1em;
                    content: close-quote;
                }
            }

            &:not(:first-child):last-child:after {
                line-height: 1em;
                content: close-quote;
            }

            &:has(br + br.ProseMirror-trailingBreak),
            &:has(.ProseMirror-trailingBreak:only-child) {
                &:after {
                    position: absolute;
                    bottom: 8px;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            .app-size-medium &:has(br + br.ProseMirror-trailingBreak) {
                &:after {
                    bottom: 7px;
                }
            }

            .app-size-small &:has(br + br.ProseMirror-trailingBreak) {
                &:after {
                    bottom: 6px;
                }
            }
        }

        & + blockquote {
            margin-top: 1rem;
        }

        &:last-child {
            margin-bottom: 0.5rem;
        }

        &:first-child {
            margin-top: -0.5rem;
        }
    }
}
