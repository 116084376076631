@import '../../../../../scss/settings';
@import '../../../../../scss/mixins/outer-border-mixin';

.TaskDueDatePill.TaskPill {
    .Icon.reminder-bell-mini {
        position: relative;
        top: 2px;
        width: 1.2rem;
        height: 1.2rem;
        margin-top: -1px;
        margin-right: 0.4rem;
    }

    &.completed {
        background: var(--ws-due-date-completed-background);
        color: var(--ws-due-date-completed-color);

        &.active {
            color: var(--ws-due-date-completed-color);

            &:before {
                border-color: var(--ws-due-date-completed-color);
            }
        }

        .Icon {
            path {
                stroke: var(--ws-due-date-completed-color);
            }
        }
    }

    &.overdue {
        background: var(--ws-due-date-overdue-background);
        color: var(--ws-due-date-overdue-color);

        &.active {
            color: var(--ws-due-date-overdue-color);

            &:before {
                border-color: var(--ws-due-date-overdue-color);
            }
        }

        .Icon {
            path {
                stroke: var(--ws-due-date-overdue-color);
            }
        }
    }

    &.due-today {
        background: var(--ws-due-date-today-background);
        color: var(--ws-due-date-today-color);

        &.active {
            color: var(--ws-due-date-today-color);

            &:before {
                border-color: var(--ws-due-date-today-color);
            }
        }

        .Icon {
            path {
                stroke: var(--ws-due-date-today-color);
            }
        }
    }

    &.due-future {
        background: var(--ws-due-date-future-background);
        color: var(--ws-due-date-future-color);

        &.active {
            color: var(--ws-due-date-future-color);

            &:before {
                border-color: var(--ws-due-date-future-color);
            }
        }

        .Icon {
            path {
                stroke: var(--ws-due-date-future-color);
            }
        }
    }

    .app-size-medium & {
        .Icon.reminder-bell-mini {
            top: 1px;
            width: 10px;
            height: 10px;
        }
    }

    .app-size-small & {
        .Icon.reminder-bell-mini {
            top: 1px;
            width: 9px;
            height: 9px;
        }
    }
}
