@import '../../scss/settings';

.MiniElementUserTag {
    top: -1.2rem;
    right: -1.2rem;

    width: 2.4rem;
    height: 2.4rem;

    &.pulse {
        // TODO Try to move this over to the variable system
        box-shadow: 0 0 0 0 transparentize($temp-color-purple-900, 0.4);
        animation: pulse 1.5s infinite;
    }
}
