@import '../../../scss/settings';

.CollapsableTools {
    width: 100%;

    .tools {
        display: flex;
        // NOTE: Flex wrap is not currently possible due to:
        // https://bugs.chromium.org/p/chromium/issues/detail?id=507397
        // See: https://codepen.io/glenn-allen/pen/vRgmQj
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .measurement-container {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: column;

        width: 100%;
        height: 100%;

        z-index: -1;
        pointer-events: none;

        .available-space {
            flex-grow: 1;
        }

        .static-space {
            flex-shrink: 0;
        }
    }
}
