/* stylelint-disable selector-class-pattern */

.SheetContainer.due-date-tool {
    .Sheet .Content {
        padding: 2.4rem 2.4rem calc(var(--safe-area-bottom) + 2.4rem) 2.4rem;

        // The calendar doesn't change height, so we can set the spinner to a
        // fixed height to make sure the sheet gets set to the correct height
        .Spinner {
            height: 419px;
        }

        .Calendar {
            .react-calendar__navigation {
                margin-bottom: 3rem;

                .react-calendar__navigation__label {
                    font-size: var(--font-size-ui-xlarge);
                }

                .react-calendar__navigation__arrow {
                    padding-bottom: 0;

                    svg {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }

            .react-calendar__tile {
                font-size: var(--font-size-ui-large);
                overflow: visible !important; // necessary for the selection border to display correctly
            }

            .react-calendar__month-view__weekdays__weekday {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .time-section {
            .DueDateTimePicker {
                height: 4.5rem;

                .StyledButton {
                    height: 100%;
                }

                .inputs {
                    align-items: center;
                }

                .time-text,
                .TimeInput select {
                    padding: 0.1rem 0.3rem 0 0.3rem;
                    font-size: var(--font-size-ui-large);
                    font-weight: normal;
                    line-height: 2.5rem;
                }
            }
        }

        .reminder-section {
            .DueDateReminderSelect {
                height: 4.5rem;

                select {
                    height: 100%;
                    font-size: var(--font-size-ui-large);
                    font-weight: normal;
                }

                .arrow-icon-container {
                    top: calc(50% - 1.75rem);

                    svg {
                        width: 3.5rem;
                        height: 3.5rem;
                    }
                }
            }
        }
    }
}
