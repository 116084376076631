@import '../../../scss/settings';

.CardClipperTool {
    position: absolute;
    // This shifts the clipper up half its height so the translation can center it on the selection mid point
    top: -1.2rem;
    right: -1.8rem;

    transform: translate3d(0, 0, 0);

    transition: opacity 0.1s linear, transform 0.1s ease-out;

    opacity: 1;
    z-index: var(--z-index-element-clipper);

    .Inbox & {
        right: -1.5rem;
    }

    // Animation
    &.fade-leave.fade-leave-active,
    &.fade-enter,
    &.fade-appear {
        transform: translate3d(-0.5rem, 0, 0);
        opacity: 0;
    }

    &.fade-leave,
    &.fade-enter.fade-enter-active,
    &.fade-appear.fade-appear-active {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    &.fade-leave {
        transition: opacity 0.1s linear, transform 0.1s ease-out;
    }
}
