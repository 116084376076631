@import '../../scss/settings';
@import '../elementBox';
@import '../../list/listElementDraggingMixin';
@import '../../scss/mixins/outer-border-mixin';

// Matches the colours in the colour picker and the board icon
.Breadcrumb,
.BoardIcon,
.ColorToolIcon.BOARD,
.ColorButton.BOARD {
    --ws-element-background-primary: var(--ws-default-board-icon-background);
    --color-element-grey: var(--color-board-grey);
}

.Board {
    .EditableTitle {
        margin-bottom: 3px;
        padding: 3px 3px 2px;

        color: var(--ui-canvas-foreground-primary);
        font-family: $font-board-heading;
        font-size: $font-size-board-heading;

        .editable-title.editing,
        &.editing {
            background: var(--ui-canvas-background-primary);
        }
    }

    .template-tag {
        padding: 0.3rem 0.4rem 0.4rem 0.4rem;
        border-radius: 0.4rem;

        background: var(--ws-board-template-tag);

        color: var(--ui-canvas-foreground-tertiary);
        font-size: $font-size-ui-xxsmall;
        font-weight: $font-weight-semibold;
        line-height: 1em;
        text-transform: uppercase;
    }

    &.element-in-list {
        .EditableTitle {
            margin-top: -0.5rem;
            margin-left: 0.2rem;

            text-align: left;
        }

        // board that is currently being dragged
        .Element.dragging & {
            .TextActivityIndicator,
            .BoardShortcutIcon,
            .BoardStatus {
                display: none;
            }

            .board-drop-target {
                height: 0;
            }
        }
    }

    &.in-list-column,
    &.in-list-document-mode {
        .EditableTitle {
            color: var(--ws-element-primary);

            .editable-title.editing,
            &.editing {
                background: var(--ui-background-primary);
            }
        }
    }

    // add navigation ">" caret for mobile view boards
    &.in-list-document-mode {
        &:after {
            display: block;
            position: absolute;
            top: 50%;
            right: 12px;

            width: 8px;
            height: 13px;

            margin-top: -6px;

            background: url('../../scss/images/chevron-right-large.svg');
            background-repeat: no-repeat;
            background-position: center;
            content: '';
        }
    }

    .OptimisticImage {
        .image-node {
            pointer-events: none;
        }
    }

    .Main.board-preview & {
        .ElementContentsTag {
            display: none;
        }
    }

    .app-size-medium & {
        .EditableTitle {
            margin-top: 1px;
            padding-top: 2px;
        }
    }

    // FIXME-FAVOURITES-SIDEBAR - Remove this when no longer needed
    // Keeping all override styles in one place for easy removal
    &.BoardFolder {
        .BoardIcon {
            .board-icon-content {
                background: none !important;

                .BoardImage {
                    width: 100% !important;
                    height: 100% !important;
                    border-radius: 4px 6px 6px 6px;

                    .image-node {
                        border-radius: 4px 6px 6px 6px;
                    }
                }
            }

            .QuickLineCreationTool {
                top: -0.1rem;
                right: 0.3rem;
            }

            .BoardStatus {
                right: 9px;
            }

            .BoardShortcutIcon {
                left: 9px;
            }
        }

        &.can-drop {
            .BoardIcon {
                .board-icon-content {
                    transform: scale(1, 1);
                    filter: brightness(0.8);
                    background: none !important;
                    box-shadow: none !important;

                    .BoardImage {
                        transform: scale(0.82, 0.82);
                    }

                    .ElementIcon {
                        opacity: 0.5;
                    }
                }
            }
        }

        .Icon.SVGIcon,
        .Icon.DefaultIcon {
            width: 2.8rem;
            height: 2.8rem;
        }

        &.selected:not(.marquee-remove-selected),
        &.marquee-selected:not(.marquee-remove-selected),
        &.line-edge-can-drop &,
        &.remote-selected & {
            .BoardIcon {
                .board-icon-content {
                    &:before {
                        border: 0;
                        border-radius: 0;
                    }
                }
            }
        }

        &.editing.selected & {
            .BoardIcon {
                .board-icon-content {
                    &:before {
                        border: 0;
                        border-radius: 0;
                    }
                }
            }
        }

        &.remote-selected & {
            .BoardIcon {
                .board-icon-content {
                    &:before {
                        border: 0;
                        border-radius: 0;
                    }
                }
            }
        }

        &.locked.selected,
        &.locked.marquee-selected {
            .BoardIcon {
                .board-icon-content {
                    &:before {
                        border: 0;
                        border-radius: 0;
                    }
                }
            }
        }

        .IconView {
            .details-container {
                margin-top: 0.7rem;
            }
        }

        &.in-list-column,
        &.element-in-list {
            .QuickLineCreationTool {
                top: 1.2rem;
                right: 2.3rem;
            }

            .BoardStatus {
                right: 17px;
            }

            .BoardShortcutIcon {
                left: 17px;
            }
        }
    }
}
