@import '../../../scss/settings';

.FloatingPanel.FileMediaSliderControl {
    width: 3.2rem;
    height: 10rem;

    padding: 0;

    transform: translate(1px, 32px);

    border: 0;
    border-radius: 0;

    background: transparent;

    box-shadow: none;

    &.dragging {
        cursor: pointer;
    }

    > .content {
        display: flex;
        position: relative;

        top: -30px;

        width: 100%;
        height: 100%;

        border-radius: 1.6rem;

        background: var(--ui-background-primary);
        box-shadow: var(--element-shadow-elevation-3);

        > .scrubber-bar-container {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;

            padding: 0.5rem 0;
        }
    }

    .FileMediaPlayerScrubberBar {
        .scrubber-bar-fill {
            background-color: var(--ui-scrubber-fill);
        }

        .bar-scrubber {
            border: 2px solid $white;
            background-color: var(--ui-scrubber-fill);
            box-shadow: var(--ui-floating-panel-shadow-1);
        }
    }

    .app-size-medium & {
        transform: translate(3px, 32px);
        font-size: 15px;
    }

    .app-size-small & {
        transform: translate(5px, 32px);
        font-size: 14px;
    }
}
