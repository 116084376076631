@import '../../scss/settings';

$single-task-height: 3.2rem;
$drag-handle-height: 1.6rem;
$margin-top: calc((#{$single-task-height} - #{$drag-handle-height}) / 2);

.TaskDragHandle {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 2.5em;

    cursor: move;
    z-index: 2;

    user-select: none;

    .Icon {
        margin: $margin-top 0 0 0.4rem;
    }

    .app-size-medium & .Icon {
        margin-top: 9px;
        margin-left: 7px;
    }

    .app-size-small & .Icon {
        margin-top: 9px;
        margin-left: 6px;
    }

    .platform-ipad & {
        width: 4rem;
        height: 2.5em;

        .Icon {
            margin-top: 9px;
            margin-left: 7px;
        }
    }

    .selection-marquee-active & {
        opacity: 0;
        pointer-events: none;
    }
}

.TaskStyler.large-top > .TaskDragHandle {
    margin-top: 1.7rem;

    .app-size-medium & .Icon {
        margin-top: 10px;
    }

    .app-size-small & .Icon {
        margin-top: 8px;
    }
}
