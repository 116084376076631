@import '../../scss/settings';

$editor-padding: 0.4rem 0.4rem 0.6rem 0.4rem;

$checkbox-size: 1.6rem;

.TaskStyler {
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;

    padding-left: calc(#{$element-padding-default} / 2);

    background: var(--ws-element-background-primary);

    .TaskDepthIndent {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .Checkbox {
        flex-grow: 0;
        flex-shrink: 0;

        // Prevents flash when loading
        width: calc(#{$checkbox-size} + 0.6rem + #{$element-padding-default} / 2);
        height: calc(#{$checkbox-size} + 1.4rem);

        padding: 0.6rem 0.6rem 0.6rem calc(#{$element-padding-default} / 2);
    }

    .task-body {
        padding-right: $element-padding-default;
    }

    .task-body,
    .TaskDraftJsEditor {
        flex-grow: 1;
        flex-shrink: 1;

        transition: color $checkbox-animation-duration linear;

        letter-spacing: $letter-spacing-body-text;
        line-height: $element-line-height;

        overflow: hidden;

        .tiptap,
        .public-DraftEditorPlaceholder-root,
        .public-DraftEditor-content {
            padding: $editor-padding;

            .app-size-medium & {
                padding: 4px 22px 5px 4px;
            }

            .app-size-small & {
                padding: 3px 19px 5px 3px;
            }
        }

        .tiptap p {
            margin: 0;
        }
    }

    .fake-editor {
        flex-grow: 1;
        flex-shrink: 1;
        padding: $editor-padding;
        font-size: $font-size-element-default;
    }

    .editable & {
        .Checkbox {
            cursor: pointer;
        }
    }

    &.large-top {
        .task-body,
        .TaskDraftJsEditor {
            .tiptap,
            .public-DraftEditorPlaceholder-root,
            .public-DraftEditor-content {
                padding-top: var(--element-padding-default);
            }

        }

        .fake-editor {
            padding-top: var(--element-padding-default);
        }

        .Checkbox {
            margin-top: 2rem;
        }
    }

    .platform-ipad & {
        padding-left: 0;

        .Checkbox {
            width: calc(#{$checkbox-size} + 0.6rem + #{$element-padding-default});
            padding-left: $element-padding-default;
        }
    }

    .app-size-medium & {
        .Checkbox {
            padding-top: 6px;
        }

        &.large-top {
            .task-body,
            .TaskDraftJsEditor {
                .tiptap,
                .public-DraftEditorPlaceholder-root,
                .public-DraftEditor-content {
                    padding-top: 22px;
                }
            }
        }
    }

    .app-size-small &.large-top {
        .task-body,
        .TaskDraftJsEditor {
            .tiptap,
            .public-DraftEditorPlaceholder-root,
            .public-DraftEditor-content {
                padding-top: 19px;
            }
        }
    }
}
