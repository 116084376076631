@import '../../scss/settings';

.ColumnCollapseButton {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;

    align-items: center;
    justify-content: center;

    width: 1.6rem;
    height: 1.6rem;

    margin-top: 0.8rem;
    margin-right: 0.8rem;

    transition: opacity 0.1s linear;

    border-radius: 2px;

    cursor: pointer;

    opacity: 0;
    z-index: 1;

    .selected &,
    .Element:hover & {
        opacity: 1;
        pointer-events: auto;

        &.light {
            opacity: 0.5;
        }
    }

    .Icon {
        path {
            fill-opacity: 1;
            fill: var(--ws-element-tertiary);
        }
    }

    @media (hover: none) {
        opacity: 1;
        pointer-events: auto;
    }

    &:before {
        display: block;
        position: absolute;

        top: -0.8rem;
        left: -0.8rem;

        width: calc(1.6rem + 0.8rem * 2);
        height: calc(1.6rem + 0.8rem * 2);

        content: '';
        z-index: 0;
    }

    &:hover {
        background: var(--ws-element-column-collapse-background);
    }

    .column-collapsed & {
        z-index: 3;
    }

    .Element .marquee-remove-selected &,
    .selection-marquee-active .Element:hover & {
        opacity: 0;
        pointer-events: none;
    }
}
