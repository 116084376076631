@import '../../scss/settings';

$auth-modal-spacing-large: 4rem;
$auth-modal-spacing-medium: 2.2rem;
$auth-modal-spacing-default: 1.7rem;
$auth-modal-spacing-small: 1rem;

.FullScreenModal.AuthModal,
.AuthSheetContainer.AuthModal {
    .Modal.AuthModal {
        width: 47rem;
    }

    .AuthSheetContents.AuthModal {
        // mobile-specific layout tweaks
        max-width: 47rem;
        margin: auto;
    }

    .Modal.AuthModal,
    .AuthSheetContents.AuthModal {
        padding: $auth-modal-spacing-large;
        border: 1.6rem;

        .Icon.milanote-logo-medium {
            width: 5rem;
            height: 5rem;
        }

        .Form {
            .form-row {
                margin-bottom: $auth-modal-spacing-default;
            }

            .form-row:last-of-type {
                margin-bottom: $auth-modal-spacing-medium;
            }

            .field-info {
                margin-top: -0.2rem;
                margin-bottom: $auth-modal-spacing-small - 0.3rem;
            }
        }

        .section {
            padding-bottom: 0;
            border-bottom: 0;
        }

        .auth-checkbox {
            margin-bottom: 1.2rem;
        }

        .auth-checkbox:last-of-type {
            margin-bottom: 2.4rem;
        }
    }

    .Form .field-row .field {
        margin-right: $auth-modal-spacing-small;

        &:last-of-type {
            margin-right: 0;
        }
    }

    .forgotten-password {
        margin-bottom: $auth-modal-spacing-small - 0.3rem;

        a {
            color: var(--ui-link-secondary-solid);
            font-size: 1.2rem;
        }
    }

    .register-instead {
        margin-top: $auth-modal-spacing-medium + 0.3rem;
        text-align: center;

        a {
            font-weight: bold;
            text-decoration: none;
        }
    }

    .app-size-small & {
        .StyledButton.primary {
            padding-top: 8px;
        }
    }

    .app-size-medium & {
        .StyledButton.primary {
            padding-top: 9px;
        }
    }

    .app-size-mobile & {
        .Modal.AuthModal {
            width: auto;
            padding: $ui-section-spacing-default;
        }
    }

    .disclaimer {
        margin-top: 2.2rem;
        margin-right: 10%;
        margin-left: 10%;

        color: var(--ui-foreground-tertiary);
        font-family: $font-body;
        font-size: $font-size-ui-small;
        text-align: center;

        a {
            color: var(--ui-foreground-tertiary);
        }
    }
}
