@import '../../scss/settings';

.ModalLoader {
    display: flex;
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    align-items: center;
    justify-content: center;

    background: $black-opacity-70;

    z-index: var(--z-index-modal);

    .Spinner {
        width: 4rem;
        height: 4rem;

        .path {
            stroke: var(--color-neutral-0);
        }
    }
}
