.CellCheckboxRenderer {
    display: flex;
    padding: 0 1px 0 1px;
    z-index: 1;

    .Checkbox {
        --ws-task-completed-checkbox-tick: var(--ui-input-checkbox-tick);
        --ws-task-completed-checkbox: var(--ui-input-checkbox-background);
        display: flex;

        rect {
            fill: var(--ws-task-completed-checkbox);
        }

        &:not(.checked) {
            rect {
                fill: var(--ws-element-background-primary);
            }
        }
    }

    .Table:not(.selected-single, .read-only) & {
        z-index: calc(var(--z-index-table-cover) + 1);
    }
}
