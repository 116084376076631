@import '../../scss/settings';

$feature-suggestion-height: 4rem;

.ElementTip {
    height: $feature-suggestion-height;
    margin-top: -0.2rem;
    padding-top: 0.2rem;

    color: var(--ws-element-secondary);
    font-size: $font-size-element-messaging;
    overflow: hidden;

    .relative-container {
        position: relative;
    }

    .absolute-container {
        display: flex;
        position: absolute;
        justify-content: flex-start;
        width: 100%;

        padding: 0 0 calc(#{$element-padding-default} - 0.1rem) 0;

        z-index: 1;

        .full-width & {
            padding-right: $element-padding-default;
            padding-left: $element-padding-default;
        }
    }

    .icon-container {
        width: 1.6em;
        margin-right: 0.8rem;
    }

    .Icon {
        position: absolute;
        top: -0.2rem;
        left: 0;
        flex-shrink: 0;
        width: 1.6rem;
        height: 1.6rem;
    }

    .Button {
        color: var(--ws-element-secondary);
        text-decoration: underline;

        &:last-of-type {
            margin-right: 0;
        }
    }

    .text,
    .question,
    .Button {
        flex-shrink: 0;
        margin-right: 1rem;
        margin-bottom: 0.3rem;
    }

    // ANIMATION
    &.added.slide-enter,
    &.added.slide-appear {
        transition: opacity 0.2s ease-out, height 0.2s ease-out;

        .absolute-container {
            transition: transform 0.2s ease-out;
        }
    }

    &.slide-leave {
        transition: opacity 0.1s ease-out, height 0.2s ease-out;

        .absolute-container {
            transition: transform 0.3s ease-out;
        }
    }

    &.added.slide-enter,
    &.added.slide-appear,
    &.slide-leave.slide-leave-active {
        height: 0;
        opacity: 0;

        .absolute-container {
            transform: translate3d(0, -$feature-suggestion-height, 0);
        }
    }

    &.added.slide-enter.slide-enter-active,
    &.added.slide-appear.slide-appear-active,
    &.slide-leave {
        height: $feature-suggestion-height;
        opacity: 1;

        .absolute-container {
            transform: translate3d(0, 0, 0);
        }
    }
}
