@import '../../../scss/settings';
@import '../../elementFloatingReactionsMixin';

.EmptySketch {
    @include floating-reactions;

    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(21rem - 2px);

    padding: 0.5rem;
    overflow: hidden;

    .icon-container {
        width: 4.8rem;
        height: 4.8rem;
        margin-top: -1rem;
        margin-bottom: 0.8rem;
    }

    .Icon.drawing-empty-placeholder {
        width: 100%;
        height: 100%;

        path {
            fill: var(--ws-element-tertiary);
        }
    }

    .text {
        width: 100%;

        color: var(--ws-element-tertiary);
        font-size: $font-size-ui-small;
        line-height: normal; // ensures that descenders are not cut off by the overflow property
        text-align: center;

        text-overflow: ellipsis;
        white-space: pre-wrap;
        overflow: hidden;
    }
}
