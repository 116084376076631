@import '../../scss/settings';

.BoardActivityIndicator {
    top: -21px;
    right: -16px;

    .indicator {
        width: auto;
        min-width: 22px;
        height: 22px;

        padding: 0 6px;
        border-radius: 11px;

        box-shadow: 0 1px 5px 0 $black-opacity-30;

        > div {
            transform: translateY(-1px);

            color: $white;
            font-size: 14px;
            font-weight: bold;
            // This will vertically center the text
            line-height: 22px;
        }
    }

    .app-size-small & {
        .indicator {
            min-width: 20px;
            height: 20px;

            padding: 0 5px;
            border-radius: 10px;

            > div {
                transform: translateY(0);

                font-size: 12px;
                line-height: 20px;
            }
        }
    }

    .ListElement & {
        .indicator {
            min-width: 18px;
            height: 18px;

            padding: 0 4px;
            border-radius: 9px;

            > div {
                transform: translateY(0);

                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    .app-size-small .ListElement & {
        .indicator {
            min-width: 16px;
            height: 16px;

            padding: 0 3px;
            border-radius: 8px;

            > div {
                font-size: 10px;
                line-height: 16px;
            }
        }
    }
}
