@import '../../scss/settings';

$transition-time: 0.1s;
$transition-delay: calc(#{$transition-time} * (3 / 4));

.CommentThreadDragPreviewTypeSwitch {
    position: absolute;
    top: 0;
    left: 0;

    transition: transform $transition-time ease-in, opacity $transition-time ease-in;

    &.collapsed .pin-button .Icon,
    &.collapsed .pin-button .comments-count-wrapper {
        transition: transform $transition-time ease-in, opacity $transition-time ease-in;
    }

    .collapsed-thread & {
        &.collapsed {
            transition-delay: $transition-delay;
            opacity: 1;
        }

        &.expanded {
            transform: scale(0.5);
            opacity: 0;
        }
    }

    .expanded-thread & {
        &.collapsed {
            opacity: 0;
        }

        &.expanded {
            transition-delay: $transition-delay;
            opacity: 1;
        }
    }
}
