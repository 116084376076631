@import '../../../../scss/settings';

.ModalToolbarDownloadButton {
    flex-shrink: 0;
    width: 13.5rem;
    overflow: hidden;

    // Override specificity
    &.ModalToolbarButton.Button {
        padding-top: 0;
    }

    // We want the anchor to stretch to the full dimensions
    .button-contents {
        width: 100%;
        padding: 0;
    }

    .DownloadButton {
        width: 100%;
        height: 100%;

        .downloading,
        .download-button {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            color: var(--ui-foreground-primary);
            text-decoration: none;
        }

        .download-button {
            justify-content: center;
            padding: 0 var(--element-padding-default) 0 calc(var(--element-padding-default) - 0.8rem);
        }

        .downloading {
            position: relative;
            flex-direction: column-reverse;
            justify-content: center;
            padding: 0 calc(var(--element-padding-default) - 1rem) 0 calc(var(--element-padding-default) - 1rem);
            font-size: var(--font-size-ui-xxsmall);

            .ProgressBar {
                width: 100%;
            }

            .progress-message {
                width: 100%;
                margin: -2px 0 4px 0;
                text-align: center;
            }
        }
    }
}
