@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';

.ImageUploader {
    position: relative;
    width: 100%;
    overflow: hidden;

    &.hovered {
        @include outer-border-full(2px, var(--ws-element-can-drop));
        overflow: visible;
    }

    .FileUploader {
        display: flex;

        position: absolute;
        top: 0;
        left: 0;

        align-items: center;
        justify-content: center;

        width: 100%;
        max-width: 100%;
        height: 100%;
        padding: 0;

        background: var(--color-neutral-400);

        .click-area {
            padding: 2.7rem;
        }

        .image-icon-container {
            margin-bottom: 0;
        }
    }
}
