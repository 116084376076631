.MilanoteCellRendererComponent {
    td:has(& .CellFormulaErrorRenderer) {
        vertical-align: middle;
    }

    // Alternative cell render components for when the cell is not editable
    // For cell type components that are buttons/ui like checkbox, rating etc.
    &.button-cell-renderer {
        display: flex;
        align-items: center;
        height: 2rem;
        cursor: pointer;

        &.align-center {
            justify-content: center;
        }

        &.align-right {
            justify-content: flex-end;
        }

        &.align-left {
            justify-content: flex-start;
        }
    }
}

/**
 *  Coloured borders
 *
 *    NOTE: see colorBorders function in tableCellFormattingUtils.js
 */
.handsontable {
    .MilanoteCellRendererComponent.colored-background {
        border-color: transparent;
        &::after {
            position: absolute;
            top: var(--negative-border-width);
            right: var(--negative-border-width);
            bottom: var(--negative-border-width);
            left: var(--negative-border-width);
            border: 1px solid transparent;
            border-right: none;
            border-bottom: none;
            // Necessary to colour the border
            border-color: inherit;
            content: '';
        }

        &.colored-border-top-only {
            &::after {
                border-left: none;
            }
        }

        &.colored-border-left-only {
            &::after {
                border-top: none;
            }
        }
        &.colored-no-border-top-right-corner {
            &::after {
                right: 0;
            }
        }
        &.colored-border-bottom-right-corner::before {
            position: absolute;
            right: -1px;
            bottom: -1px;
            width: 1px;
            border-top: 1px solid transparent;
            // Necessary to colour the border
            border-color: inherit;
            content: '';
            z-index: 1;
        }
    }
    tr:not(:first-of-type) td {
        .MilanoteCellRendererComponent.colored-border-left-only::after {
            top: 0;
        }
    }

    tr td:not(:first-of-type) {
        .MilanoteCellRendererComponent.colored-border-top-only::after {
            left: 0;
        }
    }
}
.Table:not(.show-title) {
    .handsontable tbody tr:first-of-type td {
        .MilanoteCellRendererComponent.colored-background::after {
            border-top: none;
        }
    }
}
