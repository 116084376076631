@import '../../../../scss/settings';

.TaskPill.TaskAssignmentPill {
    &.assigned {
        background: var(--ws-assignment-background);
        color: var(--ws-assignment-color);

        &.active {
            color: var(--ws-assignment-color);

            &:before {
                border-color: var(--ws-assignment-color);
            }
        }
    }


    @media (-webkit-min-device-pixel-ratio: 2) {
        .pill-contents {
            margin-top: 1px;
        }

        .app-size-small &,
        .app-size-medium & {
            .pill-contents {
                margin-top: 0.5px;
            }
        }
    }
}


