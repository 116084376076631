@import '../../scss/settings';
@import '../../scss/mixins/fade-transition-group-mixin';

@keyframes loader {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, -5280px, 0);
    }
}

.LogoSpinner {
    @include fade-transition-group-mixin;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 54px;
    height: 54px;

    .logo-container {
        width: 24px;
        height: 24px;

        overflow: hidden;

        z-index: 1;

        .animation-frame {
            transform: translate3d(0, 0, 0);
            animation: loader 3.666666667s steps(220) infinite;
        }
    }
}
