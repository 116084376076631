@import '../../scss/settings';

.ElementIcon {
    --element-icon-color: var(--palette-charcoal-950);
    --element-icon-size: 4.2rem;
    --element-icon-size-multiplier: 1;
    --element-icon-opacity: 1;
    --element-icon-blend-mode: normal;

    --element-icon-rendered-size: calc(var(--element-icon-size) * var(--element-icon-size-multiplier));

    opacity: var(--element-icon-opacity);
    mix-blend-mode: var(--element-icon-blend-mode);

    &.mn-svg {
        --element-icon-size-multiplier: 0.8571428571;
    }

    svg {
        width: 100%;
        height: 100%;
    }

    .Icon {
        margin: auto;
        transition: opacity 0.2s linear;

        &.faded {
            opacity: 0;
        }
    }

    .Icon.SVGIcon,
    .Icon.DefaultIcon {
        width: var(--element-icon-rendered-size);
        height: var(--element-icon-rendered-size);
        line-height: 0;

        svg path {
            fill: var(--element-icon-color);
        }
    }

    .Icon.StringIcon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        color: var(--element-icon-color);
        font-family: $font-icon;
        text-align: center;

        .string-icon-1 {
            // Full size icon is 80px
            font-size: var(--element-icon-rendered-size);
            line-height: 1em;
        }

        .string-icon-2,
        .string-icon-3,
        .string-icon-4 {
            // Full size icon is 80px
            font-size: calc(var(--element-icon-rendered-size) / 2);
            font-weight: bold;
            line-height: 1em;
        }

        .string-icon-4 {
            display: flex;
            flex-wrap: wrap;
            width: 2em;

            span {
                display: inline-block;
                width: 1em;
            }
        }
    }

    .Spinner {
        width: var(--element-icon-size);
        height: var(--element-icon-size);
        line-height: 0;

        svg {
            g {
                stroke: var(--element-icon-color);
                stroke-width: 2px;
            }
        }
    }
}
