@import '../../scss/settings';
@import '../elementBox';
@import '../elementFloatingReactionsMixin';
@import '../../list/listElementDraggingMixin';

.Image {
    @include element-box;
    @include list-element-dragging;

    &.locked {
        &:before {
            box-shadow: none;
        }
    }

    .focusable:focus {
        outline: none;
    }

    &.small-width {
        .OptimisticImage {
            .ElementReactions {
                padding-right: 1.2rem;
                padding-bottom: 0.8rem;
                padding-left: 1.2rem;
            }
        }
    }

    .OptimisticImage {
        @include floating-reactions;

        position: relative;

        .Spinner {
            min-height: 150px;
        }

        .image-node {
            pointer-events: none;
        }
    }

    .ElementReactions {
        max-height: 100%;
        overflow: hidden;
    }

    &.minimum-width .ElementReactions {
        display: none;
    }

    // Export - hide element-box border on images without caption
    .hide-background &:not(.caption-visible) {
        &:before {
            border: 0;
        }
    }

    @media print {
        page-break-inside: avoid;
    }
}
