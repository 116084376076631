@mixin fade-move-transition-group-mixin($x-start: -10px, $x-end: 10px, $y-start: 0, $y-end: 0, $duration: 0.3s) {
    &.fade-move-appear,
    &.fade-move-enter {
        transform: translate($x-start, $y-start);
        opacity: 0;
    }

    &.fade-move-appear.fade-move-appear-active,
    &.fade-move-enter.fade-move-enter-active,
    &.fade-move-leave {
        transform: translate(0, 0);
        opacity: 1;
    }

    &.fade-move-appear.fade-move-appear-active,
    &.fade-move-enter.fade-move-enter-active {
        transition: opacity $duration ease-out, transform $duration ease-out;
    }

    &.fade-move-leave.fade-move-leave-active {
        transform: translate($x-end, $y-end);
        transition: opacity $duration ease-in, transform $duration ease-in;
        opacity: 0;
    }
}
