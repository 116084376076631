@import '../../scss/settings';

.version-blocker {
    .app-mode-mobile-legacy &,
    .app-mode-mobile & {
        .modal-positioner,
        .modal-container,
        .Modal,
        .modal-content {
            height: 100%;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-top: 1.6rem;
    }

    .maintenance-image {
        width: 60%;
        margin-top: auto;
    }

    .title {
        margin-top: 1.2rem;
    }

    .subtitle {
        margin-top: 0.6rem;
        font-size: $font-size-ui-small;
        font-weight: $font-weight-semibold;
        text-align: center;
    }

    .StyledButton {
        width: 18rem;
        margin-top: 2.4rem;
        margin-bottom: 4.8rem;
        font-size: $font-size-ui-xxsmall;
    }

    .logo {
        width: 6.4rem;
        margin-top: auto;
    }
}
