@import '../../../scss/settings';

.LinkImage {
    position: relative;
    padding: 0;

    // Prevent browser drag preview in Safari
    .image-node {
        pointer-events: none;
    }
}
