@import '../../../scss/settings';

.ToolbarMeasure {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;

    width: 100%;
    height: 100%;

    z-index: -1;
    pointer-events: none;

    .available-space {
        flex-grow: 1;
    }
}
