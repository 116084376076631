@import '../scss/settings';

.ListDropPreview {
    position: relative;

    .preview-line {
        position: absolute;
        top: -0.5rem;
        left: 0;
        width: 100%;
        border-top: 2px solid var(--ws-element-can-drop);

        .app-size-medium & {
            top: -4px;
            transform: translateY(-0.5px);
        }
    }
}
