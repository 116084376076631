@import '../../scss/settings';

.InitialPageLoader {
    width: 100%;
    height: 100%;

    background: var(--ws-canvas-background);

    z-index: calc(var(--z-index-onboarding-steps) + 1);

    .fake-pwa-header {
        position: fixed;
        top: 0;
        width: 100%;
        height: env(titlebar-area-height);
        background-color: var(--palette-charcoal-850);
        // Allow the app to be dragged in the top header area
        -webkit-app-region: drag;
        // stylelint-disable property-no-unknown
        app-region: drag;
    }
}
