@import '../../../scss/settings';

.DocumentModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: var(--modal-header-height);
    border-bottom: 1px solid var(--ws-element-border-soft);
    text-align: center;
    z-index: 10;

    .header-left,
    .header-right {
        flex-shrink: 1;
        width: 100px;
    }

    .header-center {
        // Make sure to overflow text downwards to when editing long title
        display: flex;
        flex-grow: 1;
        align-items: flex-start;
        justify-content: center;

        max-width: 450px;
        height: 3rem;

        .EditableTitle {
            width: 100%;

            font-family: $font-body;
            font-size: var(--font-size-element-heading);
            letter-spacing: var(--letter-spacing-heading);

            .editable-title {
                width: 100%;
                line-height: 3rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &.editing {
                    text-overflow: clip;
                    white-space: pre-wrap;
                }
            }
        }
    }

    .header-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        height: 3rem;
        margin-right: 20px;

        .ExportUtil {
            position: relative;

            .PopupToolButton .tool-text {
                font-size: var(--font-size-ui-small);
            }

            .PopupTrigger {
                margin: 1px 0 0 0;
            }
        }
    }

    .PopupPanel {
        &.ExportPopup {
            top: 2.6rem;
            right: -1.1rem;
            width: 20rem;

            .ExportPopupExportButton {
                width: 15.2rem;
            }
        }

        &.arrow-top:before,
        &.arrow-top:after {
            right: 1.2rem;
            left: auto;
        }
    }

    .app-size-mobile & {
        .header-right .ExportUtil {
            margin-right: 2rem;
        }
    }
}
