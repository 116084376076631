@import '../../../../../scss/settings';

$transition-time: 0.1s;
$transition-out-time: 0.1s;
$transition-delay: 0.05s;

$animation-time: 0.15s;
$tick-animation-delay: calc(#{$animation-time} / 2);
$tick-animation-time: calc(#{$animation-time} - #{$tick-animation-delay});

.Checkbox {
    position: relative;
    width: 16px;
    height: 16px;

    user-select: none;
    -webkit-touch-callout: none;

    .Icon {
        width: 16px;
        height: 16px;

        .fill {
            transition: opacity $transition-out-time linear $transition-delay;
            opacity: 0;
            fill: var(--ws-task-completed-checkbox);
        }

        .border {
            transition: opacity $transition-out-time linear $transition-delay;
            opacity: 1;
            stroke: var(--ws-element-primary);
        }

        .tick {
            transition: stroke-dashoffset $transition-out-time linear 0s;
            stroke: var(--ws-task-completed-checkbox-tick);
            stroke-dasharray: 12;
            stroke-dashoffset: 12;
        }
    }

    &.checked {
        .Icon {
            .fill {
                opacity: 1;
            }

            .border {
                transition-delay: 0s;
                opacity: 0;
            }

            .tick {
                transition: stroke-dashoffset $tick-animation-time linear $tick-animation-delay;
                stroke-dashoffset: 0;
            }
        }
    }

    &.animate.checked {
        .Icon {
            animation: $animation-time linear bounce-up;

            .fill {
                animation: $animation-time linear fill-flash;
            }
        }
    }

    &.error {
        .Icon {
            .border {
                stroke: var(--error-color);
                stroke-width: 2;
            }
        }
    }
}

.app-size-medium {
    .Checkbox .Icon,
    .Checkbox {
        width: 14px;
        height: 14px;
    }
}

.app-size-small {
    .Checkbox .Icon,
    .Checkbox {
        width: 13px;
        height: 13px;
    }
}

@keyframes fill-flash {
    0% {
        opacity: 1;
        fill: $green-900;
    }
    50% {
        fill: $green-900;
    }
    100% {
        fill: var(--ws-task-completed-checkbox);
    }
}

@keyframes bounce-up {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}
