@import '../../../../scss/settings';

.SubToolList {
    display: flex;
    flex-direction: column;
    align-items: center;

    .app-size-small & {
        width: 100%;
    }
}
