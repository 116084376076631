@import '../../scss/settings';
@import '../elementBox';
@import '../../list/listElementDraggingMixin';

.LinkContainer {
    &:focus {
        outline: none;
    }
}

.Link {
    @include element-box;
    @include list-element-dragging;

    input {
        user-select: none;
        -webkit-touch-callout: none;
    }

    &.editing {
        cursor: text;
        user-select: text;

        input {
            user-select: text;
        }
    }
}
