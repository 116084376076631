@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';

.EdgeDragHandle.DragHandle {
    @include outer-border-full(7px, var(--ws-line-edge-drag-handle-hover-ring));

    &.snapped {
        .handle {
            circle {
                opacity: 1;
                stroke: var(--ws-line-edge-drag-handle-snapped-border);
                stroke-width: 1.5;
                fill: var(--ws-line-color);
            }
        }
    }

    &.long-hover {
        @include outer-border-full(7px, var(--ws-line-edge-drag-handle-hover-ring));

        border-radius: 50%;
        border-color: var(--ws-line-color);

        &:before {
            transform: scale(1);
            transition-delay: 0s;
        }
    }

    &:before {
        transform: scale(0);
        transition: transform 0.1s ease-out;
        transition-delay: 0.2s;

        border-radius: 50%;
        z-index: -1;
    }

    &.guide-snapped {
        z-index: -1;

        .handle {
            circle {
                stroke-width: 0;
                fill: none;
            }
        }
    }
}
