@import '../../../scss/settings';

.PasswordInput {
    display: flex;

    &.has-show-button {
        .input.password-input {
            padding-right: 6.5rem;
        }

        .password-input {
            flex-grow: 1;
            border-right: 0;
            border-radius: 3px 0 0 3px;
        }
    }

    .password-input {
        flex-grow: 1;
        border-radius: 3px;
    }
}
