@import '../../scss/settings';

.ModalBackButton {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;

    align-items: center;

    padding: 2rem 2rem;

    color: var(--ui-foreground-secondary);
    font-size: $font-size-ui-small;

    z-index: var(--z-index-modal);

    .Icon {
        position: relative;
        top: 1px;
        margin-right: 1rem;

        .app-size-small & {
            top: 2px;
        }
    }
}
