// Import the styles for the various tiptap nodes and marks
@import 'MilanoteTiptapSmallParagraphStyles';
@import 'MilanoteTiptapHeadingStyles';
@import 'MilanoteTiptapBlockquoteStyles';
@import 'MilanoteTiptapListStyles';
@import 'MilanoteTiptapCodeStyles';
@import 'MilanoteTiptapLinkStyles';
@import 'MilanoteTiptapGeneralPlaceholderStyles';
@import 'MilanoteTiptapColorStyles';
@import 'MilanoteTiptapHighlightStyles';
@import 'MilanoteTiptapChecklistStyles';

.tiptap {
    line-height: var(--line-height-element);
    user-select: none;

    // need to select .ProseMirror for specificity here
    &.ProseMirror {
        font-variant-ligatures: normal;
        font-feature-settings: 'liga' on;
    }

    &:focus {
        outline: none;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: var(--font-weight-bold);
    }

    // Defining this style here because it exists within most other block types
    p {
        margin-bottom: 1rem;
        letter-spacing: var(--letter-spacing-body-text);
    }

    // for selected tiptap cards, highlight any preserved
    // selections as though the editor was still in focus
    .selected-single & .virtual-selection:not(.clipped-text) {
        background: var(--ws-text-select-color);
    }

    .clipped-text {
        filter: opacity(0.3);
    }

    .SearchHighlightSpan {
        background: var(--search-color);
        color: var(--search-color-contrast);

        span {
            background: var(--search-color);
            color: var(--search-color-contrast);
        }
    }

    &[contenteditable='true'] {
        user-select: text;
    }
}
