@import '../../../scss/settings';

.ProgressiveImage {
    position: relative;
    width: 100%;
    overflow: hidden;

    .image-node {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);

        &.transparent {
            opacity: 0;
        }

        &.fit-vertical {
            width: auto;
            height: 100%;
        }
    }
}
