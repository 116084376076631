.tiptap {
    mark {
        --highlight-color: var(--ws-text-background-color-green);
        --text-color: var(--ws-text-highlight-color);

        // NOTE: We need to use !important in the following cases because the highlight plugin
        //  will add inline styles to the text, which would otherwise override these styles.
        //  We could prevent the extension from doing this, by extending it and implementing
        //  the logic ourselves, but the inline styles are useful when pasting to other apps
        background-color: var(--highlight-color) !important;
        color: var(--text-color) !important;

        &[data-color='darkgrey'] {
            --highlight-color: var(--ws-text-background-color-dark-grey);
            --text-color: var(--ws-text-background-color-dark-grey-text);
        }

        &[data-color='blue'] {
            --highlight-color: var(--ws-text-background-color-blue);
            --text-color: var(--ws-text-highlight-color);
        }

        &[data-color='orange'] {
            --highlight-color: var(--ws-text-background-color-orange);
            --text-color: var(--ws-text-highlight-color);
        }

        &[data-color='pink'] {
            --highlight-color: var(--ws-text-background-color-pink);
            --text-color: var(--ws-text-highlight-color);
        }

        &[data-color='grey'] {
            --highlight-color: var(--ws-text-background-color-grey);
            --text-color: var(--ws-text-highlight-color);
        }
    }
}
