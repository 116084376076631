@import '../../scss/settings';

.LinkEditor {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    padding: 0 0 0 $element-padding-default;
}
