@import '../../../scss/settings';

$expand-button-font-size: 14px;

.ResizableCardEditorContainer {
    // NOTE: This is required for the card resizing to work correctly.  Otherwise the user won't be able to
    //  drag select text, starting from the empty space below the text
    display: flex;
    position: relative;
    min-height: 5.8rem;

    .card-editor-container-contents {
        display: flex;
        position: relative;
        width: 100%;

        // This is required to hide overflowing contents when resizing smaller than the text
        overflow: hidden;
    }

    // This is required to show the mentions popup when the card is being edited
    .Card.editing:not(.resizing) & {
        .card-editor-container-contents {
            overflow: visible;

            .CardEditor {
                overflow: hidden;

                &.editing {
                    overflow: visible;
                }
            }
        }
    }

    // NOTE: These styles are related to the card resizing comment above
    .RichTextEditor {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 100%;

        .DraftEditor-root {
            height: 100%;
        }
    }
}
