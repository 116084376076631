@import '../../../../../../scss/settings';

.HyperLinkRichTextToolContainer {
    position: relative;
    will-change: transform;
}

@keyframes blink-input-opacity-to-prevent-scrolling-when-focus {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.FloatingPanel.hyperlink-popup {
    display: flex;
    align-items: center;

    width: 29rem;
    height: 5rem;
    padding-right: 0.6rem;

    .content {
        flex-grow: 1;
    }

    .hyperlink-input {
        width: 100%;
        margin: 0;
        padding: 0.2rem 3.5rem 0 0;

        border: 0;
        outline: 0;
        background: var(--ui-modal-background-primary);

        font-family: $font-body;
        font-size: $font-size-ui-default;

        &::-webkit-input-placeholder {
            color: var(--ui-placeholder-text);
        }

        &:-ms-input-placeholder {
            color: var(--ui-placeholder-text);
        }

        &::-moz-placeholder {
            color: var(--ui-placeholder-text);
        }
    }

    .app-mode-mobile & {
        // Set as important here as the size and positioning of the floating panel
        // is relative to the screen in mobile
        left: 12px !important;
        width: calc(100% - 24px);

        // This is a hack to prevent the page from scrolling when the input is focused.
        // This is a built-in functionality on mobile browsers.
        animation: blink-input-opacity-to-prevent-scrolling-when-focus 0.01s;
    }
}
