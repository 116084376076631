@import '../../../../scss/settings';

// These sizes ensure that the date boxes sit on pixels.
// This works in conjunction with the Calendar .react-calendar__month-view__days__day width
.TaskPillPopup {
    // 36*7 + 40px padding
    width: 292px;

    .app-size-medium & {
        // 33*7 + 36px padding
        width: 267px;
    }

    .app-size-small & {
        // 29*7 + 32px padding
        width: 235px;
    }
}
