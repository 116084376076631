@import '../../../../../scss/settings';

.DrawingEditorCanvasSelectMode {
    --ws-drawing-select-highlight-border-color: var(--ui-accent-primary-2);

    .DrawingPath {
        &.path-hit-area {
            fill: transparent;
            stroke: transparent;

            &:not(.stroke-only) {
                stroke-width: calc(1.2rem / var(--drawing-canvas-scale, 1));
            }

            &.stroke-only {
                stroke: transparent;
                pointer-events: stroke;
            }
        }

        &.outline {
            opacity: 0;

            &:not(.stroke-only) {
                stroke-width: calc(3px / var(--drawing-canvas-scale));
            }
        }

        &.selected.outline {
            opacity: 1;
            stroke: var(--ws-drawing-select-highlight-border-color);
        }

        &.dragging {
            visibility: hidden;
        }

        &.drag-preview {
            pointer-events: none;
        }
    }
}
