@import '../../../scss/settings';

.RichMediaPreviewOverlay {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    // For some reason iPads need this in order to interact with the overlay
    z-index: var(--z-index-element-rich-media-preview-overlay);

    .circle-hit-area {
        padding: 20px;
        cursor: pointer;
    }

    .circle {
        display: flex;
        position: relative;

        align-items: center;
        justify-content: center;

        width: 56px;
        height: 56px;

        border-radius: 50%;
        background: var(--ws-video-player-background);
    }

    .play-arrow {
        margin-left: 4px;
    }
}
