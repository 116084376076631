@import '../scss/settings';

.ListElement {
    display: block;
    padding-bottom: var(--element-in-list-vertical-padding);

    .Element:has(.CommentThread.hide-comments) {
        margin-bottom: -7px;
    }
}
