@import '../../scss/settings';

.PageContent {
    position: relative;
}

.StyledPageContent {
    position: relative;

    color: var(--ui-foreground-primary);
    font-size: $font-size-ui-default;
    line-height: 1.125em;

    .logo,
    .center-content {
        text-align: center;
    }

    .logo {
        .actionable {
            display: inline-block;
            cursor: pointer;
        }
    }

    .secondary {
        color: var(--ui-foreground-secondary);

        > * {
            margin-bottom: 1rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    h1,
    h2 {
        font-family: $font-ui-heading;
        font-weight: bold;
        line-height: 1.375em;
        text-align: center;
    }

    h1 {
        font-size: $font-size-ui-page-heading;
    }

    h2 {
        margin: 2rem 0 2.7rem 0;
        font-size: $font-size-ui-modal-heading;

        &.with-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            .Icon {
                margin-top: 0.3rem;
                margin-right: 0.8rem;
            }
        }
    }

    h3 {
        margin: 2rem 0;
        font-size: $font-size-ui-modal-subheading;
    }

    a {
        color: var(--ui-accent-primary);
    }

    p {
        line-height: 1.5em;
    }

    .option-list {
        margin: 2rem 0;
    }

    .explanation {
        text-align: center;
    }

    .heading {
        margin-top: 1rem;
        margin-bottom: 3rem;
        text-align: center;
    }

    .section {
        margin-top: 2.3rem;
        padding-bottom: 3.5rem;

        border-bottom: 1px solid var(--ui-divider-on-primary);

        &:last-of-type {
            padding-bottom: 1.5rem;
            border-bottom: 0;
        }
    }

    .StyledButton {
        .Spinner {
            height: 1.7rem;

            .circular {
                position: relative;
                top: -0.3rem;
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }

    @media (max-width: $size-mobile) {
        width: 100%;
    }

    .dark-theme & {
        h1,
        h2,
        h3 {
            color: $white;
        }
    }
}
