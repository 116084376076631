@import '../../../../../scss/settings';

.FloatingPanel.TaskDueDatePopup {
    min-height: 35rem;
    padding: 2rem;

    .Spinner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
