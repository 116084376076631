@import '../../scss/settings';

.StyledButton {
    display: block;
    width: 100%;
    padding: 0.9rem 2rem 1.1rem 2rem;

    transition: background 0.2s linear, opacity 0.2s linear;

    border-radius: 0.4rem;

    color: var(--ui-foreground-primary-contrast);
    font-family: $font-body;
    font-size: 1em;
    font-weight: $font-weight-semibold;

    text-align: center;
    text-decoration: none;

    &.thin {
        padding: 0.7rem 2rem 0.8rem 2rem;
    }

    &.danger {
        background: var(--error-color-strong);
        color: var(--palette-white);
        &:disabled {
            background-color: var(--ui-button-danger-disabled-background-color);
        }
    }

    &.action {
        background: var(--color-neutral-900);
        color: var(--color-neutral-0);
    }

    &.primary {
        background: var(--ui-accent-primary);
        color: var(--ui-accent-primary-contrast);
    }

    &.secondary {
        border: solid 1px $form-button-secondary-border-color;
        background: var(--ui-background-primary);
        color: var(--ui-foreground-secondary);
        font-weight: normal;
    }

    &.secondary-solid {
        background: var(--ui-button-secondary-solid);
        color: $white;
    }

    &.payment {
        background: var(--success-color);
    }

    &.success,
    &:disabled.success {
        background: var(--success-color);
    }

    &.failure,
    &:disabled.failure {
        background: var(--error-color);
    }

    &:disabled,
    &.disabled {
        background: var(--color-neutral-700);
        cursor: default;
    }

    &.row {
        margin: 1rem 0;
    }

    &.inline {
        width: auto;
        margin-left: 2rem;
    }

    &.compact {
        padding: 0.6rem 1.6rem 0.7rem 1.6rem;
    }

    &.ghost {
        flex-shrink: 0;
        width: auto;

        border: 1px solid var(--palette-gunmetal-grey-700);
        color: var(--palette-gunmetal-grey-600);
        font-size: $font-size-ui-small;
        font-weight: $font-weight-semibold;
    }
}
