.AccountPlanContainer {
    h3 {
        margin-top: 3rem;
        margin-bottom: 1.5rem;
    }

    .account-subscription-warning {
        color: var(--ui-foreground-secondary);
        font-size: var(--font-size-ui-small);
    }

    .account-subscription-warning a {
        color: var(--ui-accent-primary);
        font-size: var(--font-size-ui-small);
    }
}
