@import '../../../scss/settings';


.CaptionTiptapEditor {
    // Ensure the editor fills up the entire space, so that text selection works well around
    //  the padding of the editor.
    .tiptap {
        padding: 3px var(--element-padding-default) var(--element-padding-small) var(--element-padding-default);

        color: var(--ws-element-primary);
        font-size: var(--font-size-ui-small);
        line-height: 2rem;

        p {
            letter-spacing: normal;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .app-size-medium & {
            padding: 3px 21px 18px 21px;
        }

        .app-size-small & {
            padding: 2px 18px 16px 18px;
        }

        .pad-top & {
            padding-top: var(--element-padding-small);
        }
    }
}
