@import '../../scss/settings';

.handsontable.htContextMenu {
    --table-width: calc(24rem - 2px);

    padding: 3px 0;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    background: var(--ui-background-secondary);
    box-shadow: var(--context-menu-shadow);

    .wtHolder,
    .wtHider {
        width: var(--table-width) !important; /* stylelint-disable-line declaration-no-important */
        height: inherit !important; /* stylelint-disable-line declaration-no-important */
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ht_master table.htCore {
        width: var(--table-width) !important; /* stylelint-disable-line declaration-no-important */
        border: none;

        col {
            width: 100% !important; /* stylelint-disable-line declaration-no-important */
        }

        tr td {
            padding: 0 1.5rem;
            background: var(--ui-background-secondary);
            color: var(--ws-element-primary);
            font-size: var(--font-size-ui-default);

            &:not(.htDisabled):hover,
            &:not(.htDisabled).current {
                background: var(--ui-context-menu-item-hover-background);
            }

            .htItemWrapper {
                display: flex;
                justify-content: space-between;
                margin: 0;
                font-family: $font-body;
            }

            &.htSubmenu .htItemWrapper::after {
                right: 1rem;
                color: var(--ws-element-primary);
                font-size: 1.2rem;
            }

            &.htDisabled {
                color: var(--ui-modal-foreground-disabled);
            }

            // shortcut symbols
            .shortcut-key-group {
                display: flex;
                align-items: center;
                // remove the width of the padding, then add 7px space
                margin-right: calc(-1.5rem + 7px);

                .shortcut-key {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    min-width: calc(2rem - 2px);
                    height: calc(2rem - 2px);
                    margin-top: 1px;
                    margin-left: 0.4rem;
                    border-radius: 0.2rem;
                    background: var(--ui-shortcut-key-background-color);
                    color: var(--ui-shortcut-key-text-color);
                    font-size: var(--font-size-ui-small);
                    line-height: var(--font-size-ui-small);
                    text-transform: capitalize;
                }

                .shortcut-long {
                    min-width: unset;
                    padding: 0 1rem;
                }
            }
        }

        tr:has(td.htSeparator) {
            display: flex;
            padding: 3px 0;
            background: var(--ui-background-secondary);

            td.htSeparator {
                width: 100%;
                border-color: var(--ui-context-menu-divider);

                &:hover,
                &.current {
                    background: transparent;
                }
            }
        }
    }

    // Alignment menu
    /* stylelint-disable-next-line selector-class-pattern */
    &.htContextMenuSub_Change_Alignment___ {
        --table-width: calc(22rem - 2px);
        margin-left: -1px;
    }
}
