@import '../../scss/settings';

.AppHeader {
    position: relative;
    background: var(--ui-background-primary);

    font-size: $font-size-static-header;
    text-align: center;

    box-shadow: var(--shadow-app-header);

    z-index: var(--z-index-header);
    will-change: transform;
    user-select: none;
    -webkit-touch-callout: none;

    // Reduces the amount of white space at the top of the installed PWA
    @media all and (display-mode: standalone) {
        .header-section {
            margin-top: 0;
        }
    }

    .platform-ipad & {
        padding-top: var(--safe-area-top, 0);
    }
}
