@import '../../scss/settings';

.CommentForm {
    position: relative;
    margin-top: -0.5rem;

    border-bottom: 0;
    background: var(--ws-comment-background);

    &.replying {
        margin-top: -1rem;

        .details {
            overflow: hidden;
        }

        .CommentEditor {
            padding-right: 5rem;
            background: var(--ws-comment-editor-background);
        }
    }

    &.showing-activity {
        .reply-button {
            margin-top: 0;
        }
    }

    &.hide {
        margin-top: -0.9rem;

        .reply-button {
            padding: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    // Hide the reply button on Android hybrid.
    // This is because we are disabling editing on the board list.
    .hybrid-android & {
        .reply-button {
            padding: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    .details {
        margin: 1.6rem 2.4rem 1.6rem 0.8rem;
    }

    .reply-button {
        display: inline-block;

        padding: 0.6rem 2rem calc(#{$element-padding-default} - 0.8rem) 6.4rem;

        color: var(--ws-mention-color);
        font-size: $font-size-element-secondary;

        &.disabled {
            color: var(--ui-input-disabled);
        }
    }

    .extra-padding {
        padding-bottom: calc(#{$element-padding-default} - 0.4rem);
    }
}

.CommentLine:first-of-type {
    &.CommentForm {
        margin-top: 0;
        border-top: 0;
    }
}
