@import '../../../../scss/settings';

.TaskAddReactionButton.ElementReactionAddButton {
    height: 19px;
    padding: 1px 3px 0;

    .app-size-medium & {
        height: 17px;
    }

    .app-size-small & {
        height: 15px;
    }
}
