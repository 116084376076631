@import '../../../scss/settings';

.FileUploaderErrorContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .details {
        margin-bottom: 1rem;
        color: var(--color-neutral-800);
        line-height: 2.1rem;
    }
}
