@import 'settings';

.app-electron {
    --app-header-tools-section-height: 40px;

    .AppHeader .WorkspaceToolsHeader {
        -webkit-app-region: drag;

        // -webkit-app-region: drag; eats click events, so we need to add an explict
        // app region to the buttons to make them clickable.
        .header-left .Breadcrumb {
            -webkit-app-region: no-drag;
        }

        .header-right .Button {
            -webkit-app-region: no-drag;
        }

        .header-left {
            padding-left: 70px;

            .electron-fullscreen & {
                padding-left: 0;
            }

            .Breadcrumb:first-child {
                padding-left: 0px;

                .BreadcrumbContents {
                    padding-left: 5px;
                }
            }
        }
    }

    .FullScreenModal {
        // create an invisible draggable area that covers the header area,
        // allowing the user to still drag the window around from the same area
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: calc(var(--app-header-tools-section-height) - 3px);

            content: '';

            -webkit-app-region: drag;
        }
    }

    &.app-electron-screenshot {
        .TemplateStatusUtil {
            display: none;
        }

        .BreadcrumbList .Breadcrumb {
            &.template-public-parent {
                .BreadcrumbContents {
                    .breadcrumb-title {
                        color: var(--ui-foreground-tertiary);
                    }
                }
            }

            &.template-public-child {
                display: none;
            }
        }
    }
}
