.DraftJsConversionIndicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0px;
    height: 0px;
    border-width: 1.2rem 0 0 1.2rem;
    border-style: solid;

    border-color: transparent transparent transparent powderblue;

    cursor: pointer;
    z-index: 100;

    &.Unpostpone {
        border-color: transparent transparent transparent pink;
    }

    .HotTable & {
        display: none;
    }
}
