@import '../../scss/settings';

.ImageReplacementOverlay {
    display: flex;
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: $black-opacity-60;
    z-index: 1;

    pointer-events: none;

    .overlay-icon {
        margin-bottom: 0.5rem;

        .Icon {
            width: 4.9rem;
            height: 4.9rem;
        }
    }

    .overlay-text {
        color: var(--palette-gunmetal-grey-100);
        font-size: var(--font-size-ui-small);
    }
}
