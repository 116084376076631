@import '../../scss/settings';
@import '../elementBox';
@import '../elementColoredBackgroundMixin';
@import '../../list/listElementDraggingMixin';

.Column {
    @include element-box;
    @include colored-background([
        '.title-container',
         '.ContainerElementChildCount',
          '.ColumnCollapseButton',
          '.Card.transparent',
          '.Element .IconView .EditableTitle',
          '.Element .IconView .secondary-details-container',
    ]);
    @include list-element-dragging;

    --container-background: var(--ws-element-background-primary);
    --element-in-list-vertical-padding: calc(1rem - 2px);

    padding: calc(#{$element-padding-default} - 0.7rem) 0 0;

    background: var(--ws-element-background-primary);

    &.colored-background-light {
        --ws-element-column-collapse-background: var(--neutral-opacity-darkmodegrey-1000-05);
    }

    &.colored-background-dark {
        --ws-element-column-collapse-background: var(--neutral-opacity-white-1000-15);
    }

    // Remove the border for coloured background elements that are inside a coloured background column
    &.colored-background {
        .colored-background {
            &::after {
                --element-auxiliary-border: none;
            }
        }
    }

    > .title-container {
        display: flex;
        position: relative;
        justify-content: center;

        padding-top: 0;
        padding-bottom: 4px;

        border: 0;
        font-size: $font-size-column-heading;
        letter-spacing: $letter-spacing-column-heading;
        text-align: center;

        .app-size-medium & {
            padding-bottom: 3px;
        }

        .app-size-small & {
            padding-bottom: 3px;
        }

        .editable-title {
            padding: 0 $element-padding-default;

            color: var(--ws-element-primary);
            font-family: $font-column-heading;
            line-height: var(--line-height-heading);

            &.editing {
                background: var(--ws-element-background-primary);
            }
        }

        .is-dragging & {
            pointer-events: none;

            .editable-title {
                pointer-events: none;
            }
        }
    }

    &.column-collapsed {
        .title-container {
            z-index: 2;
        }
    }

    > .ElementContentsTag {
        text-align: center;

        .show {
            display: inline-block;
            padding-bottom: calc(#{$element-padding-default} - 0.4rem);
        }
        .app-size-medium & {
            .show {
                display: inline-block;
                padding-bottom: 15px;
            }
        }

        .SimpleActivityIndicator {
            display: inline-block;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;

            transform: translate(0.5rem, 0.3rem);
        }
    }

    .ElementResizeHandle {
        padding: 2px 0 0 2px;
    }

    .Main.board-preview & {
        > .ElementContentsTag {
            padding-bottom: 0;
            visibility: hidden;
        }
    }

    .app-size-large.sans-serif-headings & {
        > .title-container {
            .editable-title {
                transform: translateY(1px);
            }
        }
    }

    .app-size-medium & {
        padding: 17px 0 0;
    }

    .List {
        // In columns, the padding between elements should be smaller as there are no inset padding
        --ws-element-background-primary: var(--ws-element-background-primary-default);
    }

}

// For some reason we need this style on the ListContainer in order for the EditableTitle to still receive
// click events.
.Column > .List.depth-0,
.Column > .ListContainer,
.Column > .ListPresentationContainer {
    margin: -8rem 0 0 0;
    padding: 8rem var(--element-in-list-horizontal-padding) 0 var(--element-in-list-horizontal-padding);

    z-index: var(--z-index-element);
}
