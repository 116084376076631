@import '../../../../scss/settings';

.ImageModalDrawingModeContent {
    .DrawingEditorCanvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .DrawingEditorCanvasSvg {
            width: 100%;
            height: 100%;
        }
    }
}

.drawing-editor-toolbar-container {
    .DrawingEditorToolbar {
        position: relative;
        top: 0;
        left: 0;
        flex-shrink: 0;
        width: 100%;

        background: none;
        box-shadow: none;

        .app-size-medium & {
            padding: 10px;
        }

        .app-size-small & {
            padding: 9px;
        }
    }
}
