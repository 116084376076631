@import '../../../scss/settings';

.BreadcrumbIcon {
    position: relative;

    flex-shrink: 0;
    width: 16px;
    height: 16px;

    margin-right: 10px;
    margin-bottom: -1px;

    border-radius: 4px;
    background: var(--ws-default-board-icon-background);

    .Icon {
        margin-top: -1px;
        transition: transform 0.3s ease-in-out;
    }

    .Spinner {
        position: absolute;
        top: -4px;
        left: -3px;
        width: 24px;
        height: 24px;

        .path {
            stroke: var(--color-neutral-700);
        }

        &.scale-appear,
        &.scale-enter {
            transform: scale(1.4);
            transition: opacity 0.3s ease-in, transform 0.3s ease-in-out;
            opacity: 0;
        }

        &.scale-leave {
            transition: opacity 0.3s ease-out, transform 0.3s ease-in-out;
        }

        &.scale-appear.scale-appear-active,
        &.scale-enter.scale-enter-active,
        &.scale-leave {
            transform: scale(1);
            opacity: 1;
        }

        &.scale-leave.scale-leave-active {
            transform: scale(1.2);
            opacity: 0;
        }
    }

    &.loading {
        .Icon {
            transform: scale(0.8);
        }
    }

    &.low-contrast {
        border: 1px solid var(--ui-breadcrumbs-low-contrast-icon-border-color);
    }
}
