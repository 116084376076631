@import '../../../scss/settings';
@import '../../elementMetadataTitleMixin';

.FileDetailViewContent {
    @include element-metadata-title-mixin;

    .editable-title {
        color: var(--ws-element-primary);
        font-family: $font-link-file-heading;
        font-size: $font-size-link-file-heading;
        text-decoration: none;
    }

    .open-link {
        color: var(--ws-element-secondary);
    }

    .FileSecondaryDetails a {
        color: var(--ws-element-secondary);
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }

    .FileSecondaryDetails {
        a,
        span {
            white-space: nowrap;
        }
    }

    .DownloadButton {
        .downloading {
            display: flex;
        }
    }
}
