@import 'colors';
@import 'color-palette';

:root {
    // FIXME These should probably be constants, but I'm making them variables to make it easier for
    //  Marc to change them
    --palette-black: #000000;
    --palette-white: #ffffff;

    // Opacities
    --palette-black-opacity-76: rgba(0, 0, 0, 0.76);
    --palette-white-opacity-95: rgba(255, 255, 255, 0.95);

    // Dark grey shades
    --palette-grey-900: #323b4a;
    --palette-grey-800: #666d7a;
    --palette-grey-750: #767c86;
    --palette-grey-700: #8d929a;
    --palette-grey-650: #a3a7ae;
    --palette-grey-625: #bbbec3;
    --palette-grey-600: #cbced2;
    --palette-grey-575: #dcdee1;
    --palette-grey-550: #e0e2e4;
    --palette-grey-500: #e8e9eb;
    --palette-grey-400: #f4f5f5;
    --palette-grey-300: #f9f9f9;

    // Dark mode
    --palette-charcoal-950: #1a1a1a;
    --palette-charcoal-930: #2a2a2a;
    --palette-charcoal-920: #292929;
    --palette-charcoal-910: #2c2c2c;
    --palette-charcoal-900: #333333;
    --palette-charcoal-850: #3d3d3d;
    --palette-charcoal-800: #4d4d4d;
    --palette-charcoal-790: #505050;
    --palette-charcoal-780: #5d5d5d;
    --palette-charcoal-775: #666666;
    --palette-charcoal-750: #808080;
    --palette-charcoal-700: #999999;
    --palette-charcoal-650: #b3b3b3;
    --palette-charcoal-600: #cccccc;
    --palette-charcoal-200: #e6e6e6;
    --palette-charcoal-100: #f2f2f2;

    --palette-gunmetal-grey-900: #3d4554;
    --palette-gunmetal-grey-800: #48505e;
    --palette-gunmetal-grey-700: #5f6672;
    --palette-gunmetal-grey-600: #999ea6;
    --palette-gunmetal-grey-500: #d1d3d6;
    --palette-gunmetal-grey-300: #e6e6e6;
    --palette-gunmetal-grey-100: #f3f3f4;

    // Canvas
    --palette-canvas-light-grey: #ebedee;
    --palette-canvas-dark-grey: #232323;
    --palette-canvas-section-divider-colour: rgba(0, 10, 20, 0.08);

    // Spectrum
    // Purple
    // Brand purple
    --palette-purple-1000: #31416d;
    --palette-purple-900: #2f63f7;
    --palette-purple-850: #82a1fa;
    --palette-purple-800: #97b1fb;
    --palette-purple-700: #abc0fb;
    --palette-purple-600: #c1d0fc;
    --palette-purple-500: #d5dffd;
    --palette-purple-400: #eaeffe;
    --palette-purple-300: #f4f7fe;
    // Blue
    // Cyan
    --palette-blue-900: #16a0e5;
    --palette-blue-400: #e8f5fc;
    // Green
    --palette-green-1000: #3a5a3e;
    --palette-green-900: #4eb85a;
    --palette-green-400: #edf8ee;
    --palette-fluro-green-980: #4a5e17;
    --palette-fluro-green-900: #bbff00;
    --palette-fluro-green-700: #dcff7f;
    // Yellow
    --palette-yellow-980: #666126;
    --palette-yellow-950: #e0d878;
    --palette-yellow-900: #ffed00;
    --palette-yellow-700: #fff67f;
    --palette-yellow-300: #fffbca;
    // Orange
    // Brand orange - Primary
    --palette-deep-orange-900: #f4511c;
    --palette-orange-1000: #704d2d;
    --palette-orange-900: #ff8d22;
    --palette-orange-400: #fff3e9;
    // Red
    --palette-red-1000: #6b4141;
    --palette-red-900: #ed6363;
    --palette-red-400: #fdefef;

    --color-element-grey: #bbbec3;
    --color-element-brown: #d58558;
    --color-element-red: #ff5757;
    --color-element-orange: #ff8d48;
    --color-element-yellow: #ffd14d;
    --color-element-green: #7cd651;
    --color-element-pink: #ff6ed4;
    --color-element-purple: #ad6fff;
    --color-element-cyan: #62dbc8;
    --color-element-blue: #4ebafd;
    --color-element-steel-blue: #6383b8;
    --color-element-deep-blue: #5882f8;

    --color-element-light-blue: #90d5ff;

    --color-board-grey: #5f6672;

    // Canvas background colours
    --color-canvas-grey: var(--palette-canvas-light-grey);
    --color-canvas-warm-grey: #e5e3e8;
    --color-canvas-yellow: #f0eedf;
    --color-canvas-green: #d9ebe6;
    --color-canvas-blue: #d8dff4;

    --color-neutral-1000: #{$black};
    --color-neutral-900: #{$grey-900};
    --color-neutral-800: #{$grey-800};
    --color-neutral-700: #{$grey-700};
    --color-neutral-600: #{$grey-600};
    --color-neutral-500: #{$grey-500};
    --color-neutral-400: #{$grey-400};
    --color-neutral-300: #{$grey-300};
    --color-neutral-0: #{$white};

    // Interface
    --ui-background-primary: #{$white};
    --ui-background-primary-hovered: #{$grey-400};
    --ui-background-secondary: #{$grey-300};
    --ui-background-tertiary: var(--palette-gunmetal-grey-100);

    --ui-background-primary-contrast: #{$grey-900};

    --ui-foreground-primary-contrast: #{$white};
    --ui-foreground-secondary-contrast: #{$grey-600};
    --ui-foreground-tertiary-contrast: #{$grey-700};

    // TODO UI2 - problem here is the colours aren't necessarily foreground.. Sometimes they're used as the background!
    --ui-foreground-primary: #{$grey-900};
    --ui-foreground-secondary: #{$grey-800};
    --ui-foreground-tertiary: #{$grey-700};
    --ui-foreground-quaternary: var(--palette-grey-650);

    // Buttons and Links
    --ui-button-secondary-solid: #{$grey-900};
    --ui-button-floating-secondary-border: var(--palette-gunmetal-grey-500);
    --ui-button-floating-secondary-text: var(--color-neutral-800);
    --ui-button-danger-disabled-background-color: var(--palette-grey-600);
    --ui-link-secondary-solid: var(--palette-gunmetal-grey-800);

    --ui-modal-overlay-opaque: #5a5961;
    --ui-modal-foreground-primary: var(--ui-foreground-primary);
    --ui-modal-foreground-secondary: var(--ui-foreground-secondary);
    --ui-modal-foreground-tertiary: var(--ui-foreground-tertiary);
    --ui-modal-foreground-quaternary: var(--ui-foreground-quaternary);
    --ui-modal-foreground-disabled: var(--palette-grey-600);
    --ui-modal-background-primary: var(--ui-background-primary);
    --ui-modal-background-primary-hover: var(--palette-gunmetal-grey-100);
    --ui-modal-background-tab-active: var(--palette-gunmetal-grey-100);
    --ui-modal-background-secondary: var(--ui-background-secondary);
    --ui-modal-divider: var(--ui-divider-on-primary);
    --ui-modal-contrast-color: var(--ui-foreground-primary-contrast);
    --ui-modal-contrast-background: var(--ui-background-primary-contrast);

    // Interface overflow gradients
    --ui-modal-background-primary-overflow-color-2: #{$white-opacity-70};
    --ui-modal-background-primary-overflow-color-3: #{$white-opacity-0};

    // Inputs
    --ui-input-border: var(--palette-gunmetal-grey-500);
    --ui-input-border-focused: var(--palette-gunmetal-grey-600);
    --ui-input-background: none;
    --ui-input-disabled: var(--palette-charcoal-700);
    --ui-input-background-disabled: var(--palette-gunmetal-grey-100);
    --ui-input-placeholder: var(--palette-grey-625);

    --ui-input-checkbox-background: var(--palette-grey-600);
    --ui-input-checkbox-tick: var(--palette-charcoal-900);

    --ui-text-color-match-canvas: var(--color-neutral-0);
    --ui-text-color-opposite-to-canvas: var(--color-neutral-1000);

    --ui-calendar-secondary: var(--palette-grey-700);
    --ui-calendar-tertiary: var(--palette-gunmetal-grey-500);

    // Loading spinner
    --ui-loading-spinner-color: var(--color-neutral-1000);

    --ui-loading-placeholder-color-1: #e9e9e9;
    --ui-loading-placeholder-color-2: #f8f8f8;

    // These colours are the same as the ui-foreground colours, however they change
    //  depending on whether the canvas background is light or dark
    --ui-canvas-foreground-primary: var(--ui-foreground-primary);
    --ui-canvas-foreground-secondary: rgba(2, 12, 31, 0.6);
    --ui-canvas-foreground-tertiary: rgba(45, 55, 72, 0.5);

    --ui-canvas-background-primary: #{$white};

    --ui-divider-on-primary: var(--color-neutral-500);
    --ui-divider-on-secondary: var(--color-neutral-600);

    --ui-context-menu-divider: var(--color-neutral-500);

    --ui-toggle-inactive: var(--palette-grey-600);
    --ui-toggle-thumb: #{$white};
    --ui-toggle-active: var(--palette-green-900);

    --ui-pill-background-default: var(--palette-grey-750);
    --ui-pill-foreground-default: #{$white};

    --ui-placeholder-text: #{$grey-600};
    --ui-placeholder-image: #{$grey-400};

    --ui-accent-primary: #{$deep-orange-900};
    --ui-accent-primary-contrast: #{$white};
    --ui-accent-primary-2: #{$purple-900};
    --ui-accent-primary-2-contrast: #{$white};

    --ui-search-popup-background: var(--ui-modal-background-secondary);
    --ui-search-border-unfocused: var(--palette-gunmetal-grey-500);
    --ui-search-placeholder: #{$grey-700};
    --ui-search-glass-fill: var(--palette-grey-625);
    --ui-search-input-background: var(--palette-white);

    --ui-detached-header-background: #{$grey-900};
    --ui-header-icon: #8d929a;
    --ui-mobile-header-back-button: var(--palette-gunmetal-grey-800);

    // Breadcrumbs
    --ui-breadcrumbs-low-contrast-icon-border-color: var(--palette-gunmetal-grey-500);

    // Text
    --ui-clipper-extension-title-text-color: var(--palette-charcoal-900);
    --ui-clipper-extension-body-text-color: var(--palette-grey-700);

    // Toolbar
    --ui-element-tool-draggable-drop-shadow-color: rgba(51, 61, 78, 0.3);
    --ui-element-tool-draggable-drop-shadow-hover-color: rgba(51, 61, 78, 0.2);
    --ui-element-tool-line-color: var(--palette-gunmetal-grey-800);
    --ui-toolbar-tool-disabled-foreground: rgba(188, 190, 194, 0.5);

    // Account popup
    --ui-account-popup-foreground-secondary: var(--palette-grey-700);
    --ui-account-popup-background-secondary: var(--palette-gunmetal-grey-100);
    --ui-element-count-progress-background: var(--palette-gunmetal-grey-500);
    --ui-element-count-progress-foreground: var(--palette-gunmetal-grey-700);
    --ui-element-count-progress-foreground-full: var(--palette-gunmetal-grey-700);

    // Export popup
    --ui-export-popup-toggle-text: var(--palette-grey-700);

    // FIXME Made up colour, needs Marc
    --ui-trash-tab-active: #dadddf;
    --ui-mode-active-background: var(--palette-gunmetal-grey-100);

    --ui-board-status-background: #{$grey-500};
    --ui-board-status: #{$grey-700};
    --ui-board-status-comment: #{$purple-900};

    --ui-glow-color: #{$yellow-300};

    --ui-scrollbar-track: #{$black-opacity-10};
    --ui-scrollbar-thumb: #{$black-opacity-40};

    --ui-tool-path: var(--palette-gunmetal-grey-900);
    --ui-toolbar-tool-default-background: rgba(155, 152, 158, 0.2);
    --ui-toolbar-tool-background: var(--ui-toolbar-tool-default-background);
    --ui-toolbar-tool-popup-open-background: #{$white};
    --ui-toolbar-tool-popup-option-selected-background: rgba(155, 152, 158, 0.5);
    --ui-board-tool-color: #5882f8;

    --ui-tool-back: rgba(2, 12, 31, 0.6);
    --ui-tool-rich-text-active-background: #{$grey-900};
    --ui-tool-rich-text-active: #{$white};

    --ui-scrubber-background: #d1d3d6;
    --ui-scrubber-fill: #767c86;

    --ui-progress-bar-background: var(--color-neutral-500);
    --ui-progress-bar-fill-1: var(--palette-purple-900);
    --ui-progress-bar-fill-2: var(--palette-purple-900);

    // AI Ideas popup
    --ui-ai-conversation-background: var(--ws-default-canvas-background);

    --ui-ai-history-tab-selected-background: var(--palette-gunmetal-grey-100);
    --ul-ai-history-tab-new-border: var(--palette-grey-500);

    --ui-ai-conversation-divider: var(--ws-section-divider-color);

    --ui-ai-button-border-color: var(--palette-gunmetal-grey-500);
    --ui-ai-prompt-background: var(--palette-purple-500);
    --ui-ai-prompt-color: var(--palette-purple-900);
    --ui-ai-bot-history-background: var(--palette-grey-575);
    --ui-ai-bot-history-color: var(--palette-grey-900);
    --ui-ai-prompt-result-container-background: rgba(27, 37, 54, 0.1);
    --ui-ai-button-hover-background: rgba(27, 37, 54, 0.05);

    --ui-ai-assistant-toolbar-loading-animation-background: rgba(59, 67, 69, 1);

    --ui-ai-assistant-toolbar-feedback-text: var(--palette-charcoal-100);
    --ui-ai-assistant-toolbar-feedback-message-text: var(--palette-grey-650);
    --ui-ai-assistant-toolbar-feedback-message-disclaimer-text: var(--palette-grey-700);

    // Board search popup
    --ui-board-search-section-background: var(--palette-canvas-light-grey);
    --ui-board-search-section-foreground-color: var(--neutral-lightmodegrey-500);
    --ui-board-search-form-secondary-color: var(--palette-grey-700);
    --ui-board-search-element-counts-color: var(--palette-gunmetal-grey-700);
    --ui-board-search-breadcrumb: var(--ui-foreground-quaternary);
    --ui-board-search-focus-color: var(--neutral-lightmodegrey-050);
    --ui-board-search-result-divider-color: var(--neutral-lightmodegrey-050);

    // Template picker
    --ui-template-picker-background: var(--palette-grey-900);

    --ui-template-picker-item-foreground-primary: var(--palette-white);
    --ui-template-picker-item-foreground-secondary: var(--palette-grey-600);

    --ui-template-picker-keep-content-checkbox: var(--palette-grey-400);
    --ui-template-picker-keep-content-checkbox-disabled: var(--palette-grey-750);

    --ui-template-picker-item-pill-foreground: var(--palette-grey-900);
    --ui-template-picker-item-pill-background: var(--palette-grey-700);

    --ui-template-picker-item-background-selected: var(--palette-gunmetal-grey-800);
    --ui-template-picker-item-background-hover: var(--palette-gunmetal-grey-900);
    --ui-template-picker-button-flash: var(--palette-orange-900);

    --ui-template-picker-header-primary: var(--palette-white);
    --ui-template-picker-header-secondary: var(--palette-grey-600);

    --ui-template-picker-search-foreground: var(--palette-grey-400);
    --ui-template-picker-search-foreground-secondary: var(--palette-grey-750);
    --ui-template-picker-search-border-focused: var(--palette-gunmetal-grey-500);

    --ui-template-picker-scrollbar-foreground: var(--palette-black);
    --ui-template-picker-scrollbar-background: var(--palette-grey-700);

    // Drawing editor
    --ui-drawing-editor-overlay-background: #{$white-opacity-30};

    // User pills
    --ui-user-editor-pill-border: var(--palette-gunmetal-grey-500);
    --ui-user-editor-pill-color: var(--palette-grey-800);
    --ui-board-editor-list-status-color: var(--palette-grey-650);
    --ui-board-editor-list-email-color: var(--palette-grey-700);

    // Avatars
    --ui-avatar-foreground: var(--palette-charcoal-900);
    --ui-avatar-background: var(--palette-gunmetal-grey-500);
    --ui-avatar-foreground-contrast: var(--ui-foreground-primary-contrast);
    --ui-avatar-background-contrast: var(--palette-gunmetal-grey-700);
    --ui-avatar-guest-foreground: var(--palette-grey-700);
    --ui-avatar-count-background: var(--palette-grey-500);
    --ui-avatar-count-color: var(--palette-grey-900);

    // Avatar background colors (8 colors)
    --ui-avatar-background-color-0: #94dcd5;
    --ui-avatar-background-color-1: #92c1f5;
    --ui-avatar-background-color-2: #f3bd7f;
    --ui-avatar-background-color-3: #e68c8c;
    --ui-avatar-background-color-4: #ebdc91;
    --ui-avatar-background-color-5: #ba9af3;
    --ui-avatar-background-color-6: #8ce696;
    --ui-avatar-background-color-7: #e68cba;

    // Emojis & Reactions
    --ui-emoji-picker-item-background-hover: var(--palette-charcoal-100);

    // Color picker
    --ui-color-picker-mode-tab-inactive-color: var(--palette-charcoal-650);
    --ui-color-picker-no-color-stroke-color: #e62768;

    // Tooltips
    --ui-tooltip-color: var(--palette-white);
    --ui-tooltip-secondary-color: #{$grey-600};
    --ui-tooltip-background-color: #{$grey-900};
    --ui-tooltip-shortcut-key-background-color: none;
    --ui-tooltip-shortcut-key-text-color: var(--palette-grey-700);
    --ui-context-menu-item-hover-background: var(--palette-gunmetal-grey-300);
    --ui-tooltip-element-tip-color: var(--palette-charcoal-650);
    --ui-tooltip-element-tip-background-color: var(--palette-white);

    // Document modal
    --ui-document-modal-text-tool-border-color: var(--palette-gunmetal-grey-500);

    // Share modal
    --ui-share-modal-publish-column-enabled-input-background: var(--ui-modal-background-primary);

    // Element List Popups (trash and quick notes)
    --ui-element-list-popup-background: var(--ws-canvas-background);

    // Quick notes
    --ui-quick-notes-popup-background-color: var(--ui-element-list-popup-background);
    --ui-quick-notes-popup-divider-color: var(--ws-section-divider-color);
    --ui-quick-notes-popup-app-store-button-background-color: var(--palette-grey-700);

    // Trash
    --ui-trash-popup-background: var(--ui-element-list-popup-background);
    --ui-trash-popup-divider-color: var(--ws-section-divider-color);

    // Settings modal
    --ui-settings-modal-button-link-color: var(--palette-deep-orange-900);
    --ui-settings-modal-button-link-disabled-color: #636363; // Desaturated color of --ui-settings-modal-button-link-color
    --ui-settings-modal-toolbar-button-background-color: var(--ws-canvas-background);
    --ui-settings-modal-toolbar-button-text-color: var(--ui-canvas-utils-foreground-primary);

    // Checkout modal
    --ui-checkout-modal-current-plan-button-background-color: var(--palette-gunmetal-grey-100);
    --ui-checkout-modal-current-plan-button-text-color: var(--palette-charcoal-900);
    --ui-checkout-modal-disabled-team-plan-background-color: var(--palette-white);
    --ui-checkout-modal-savings-badge-background-color: var(--alert-alertgreen-1000);
    --ui-checkout-modal-savings-badge-text-color: var(--alert-alertgreen-050);

    // Shortcut keys
    --ui-shortcut-key-border-color: none;
    --ui-shortcut-key-background-color: var(--ui-toolbar-tool-default-background);
    --ui-shortcut-key-text-color: var(--palette-black);

    --ui-modal-shortcut-key-border-color: var(--palette-gunmetal-grey-500);
    --ui-modal-shortcut-key-background-color: none;
    --ui-modal-shortcut-key-text-color: var(--palette-grey-800);

    // Forms
    --ui-form-checkbox: var(--palette-charcoal-900);
    --ui-form-checkbox-tick: var(--ui-foreground-primary-contrast);
    --ui-slider-rail-background: #dfe0e1;

    // CUSTOM - From Ollie
    --ui-block-style-active-background: #f1f3f4;

    // Presentation Mode
    --ui-presentation-mode-button-secondary-text: var(--palette-grey-650);

    // Status colours
    --error-color: #{$red-900};
    --error-color-strong: #e60b0b;
    --error-background: #{$red-400};
    --success-color: #{$green-900};

    // Workspace
    --ws-default-canvas-background: #{$canvas-light-grey};
    --ws-canvas-background: #{$canvas-light-grey};

    --ws-section-divider-color: var(--palette-canvas-section-divider-colour);

    --ws-selection-marquee-background: #{$black-opacity-10};

    --ws-missing-image-background-color: #{$grey-400};

    // Elements
    --ws-element-light-text-on-color: var(--palette-white-opacity-95);
    --ws-element-dark-text-on-color: var(--palette-black-opacity-76);

    --ws-element-primary: #{$grey-900};
    --ws-element-secondary: #{$grey-800};
    --ws-element-tertiary: #{$grey-700};
    --ws-element-placeholder: #{$grey-600};
    --ws-comment-placeholder: #{$grey-700};
    --ws-task-title-placeholder: #{$grey-600};

    --ws-element-background-primary: #{$white};
    --ws-element-background-primary-default: #{$white};
    --ws-element-background-secondary: #{$grey-400};

    --ws-element-column-empty-placeholder: var(--palette-grey-400);
    --ws-element-column-dragging-placeholder: var(--palette-grey-400);
    --ws-element-inbox-dragging-placeholder: var(--palette-grey-500);

    --ws-element-column-collapse-background: var(--neutral-opacity-darkmodegrey-1000-05);
    --ws-element-column-card-border-color: var(--ws-element-border-soft);

    --ws-element-border-soft: #{$black-opacity-10};
    --ws-element-divider: #{$grey-500};

    --ws-element-selection: #{$black};
    --ws-element-edit-selection: #{$black};

    --ws-element-selection-locked: #{$grey-700};
    --ws-element-can-drop: #{$black};
    --ws-element-anchor: #{$deep-orange-900};

    --ws-element-colored-background-secondary: #{$white};

    --ws-card-word-count: var(--ws-element-tertiary);

    --ws-task-completed: var(--ws-element-tertiary);
    --ws-task-completed-checkbox: var(--palette-gunmetal-grey-500);
    --ws-task-completed-checkbox-tick: var(--ui-foreground-primary-contrast);

    --ws-icon-view-background-inbox: rgba(253, 253, 252, 0.5);
    --ws-default-board-icon-background: #{$grey-600};
    --ws-board-template-tag: rgba(75, 82, 98, 0.2);

    --ws-default-line-color: var(--palette-grey-900);
    --ws-line-color: var(--palette-grey-900);
    --ws-line-color-grey: var(--palette-grey-600);
    --ws-line-control-point-background: var(--color-neutral-0);
    --ws-line-edge-drag-handle-background: var(--ui-background-primary);
    --ws-line-edge-drag-handle-snapped-border: var(--ui-background-primary);
    --ws-line-edge-drag-handle-hover-ring: #{$charcoal-opacity-15};

    --ws-comment-editor-background: var(--ws-element-background-primary);
    --ws-comment-background: #{$purple-300};
    --ws-comment-border: #{$purple-500};
    --ws-comment-scroll-thumb: rgba(27, 37, 54, 0.15);

    --ws-comment-overflow-color-2: rgba(244, 247, 254, 0.7);
    --ws-comment-overflow-color-3: rgba(244, 247, 254, 0);

    --ws-comment-hot-spot-background: #{$purple-400};
    --ws-comment-hot-spot-text-color: #{$purple-900};

    --ws-mention-background: #{$purple-400};
    --ws-mention-color: #{$purple-900};

    --ws-pill-populated-background: #{$grey-400};
    --ws-pill-populated-color: #{$grey-800};
    --ws-assignment-background: var(--ws-mention-background);
    --ws-assignment-color: var(--ws-mention-color);
    --ws-due-date-completed-background: var(--ws-pill-populated-background);
    --ws-due-date-completed-color: var(--ws-pill-populated-color);
    --ws-due-date-overdue-background: #{$red-400};
    --ws-due-date-overdue-color: #{$red-900};
    --ws-due-date-today-background: #{$orange-400};
    --ws-due-date-today-color: #{$orange-900};
    --ws-due-date-future-background: #{$green-400};
    --ws-due-date-future-color: #{$green-900};

    // Table
    --ws-table-ref-header-background: var(--palette-white);
    --ws-table-ref-header-border: #eaebec;
    --ws-table-ref-header-text: var(--palette-grey-650);
    --ws-table-ref-header-selected-background: var(--palette-grey-650);
    --ws-table-ref-header-selected-text: var(--palette-gunmetal-grey-100);
    --ws-table-ref-header-selected-border: #979ca3;

    --ws-table-header-row-background: var(--palette-gunmetal-grey-500);
    --ws-table-cell-borders: rgba(50, 59, 74, 0.1);
    --ws-table-cell-selection: var(--ws-element-selection);
    --ws-table-backlight: rgba(27, 37, 54, 0.15);

    --ws-table-cell-text-light: var(--palette-white);
    --ws-table-cell-text-dark: var(--palette-grey-900);
    --ws-table-secondary-text: var(--palette-grey-700);

    // Media player
    --ws-video-player-background: rgba(27, 37, 54, 0.75);
    --ws-media-player-button-color: var(--ws-element-secondary);
    --ws-media-player-video-button-color: var(--palette-white);
    --ws-media-player-audio-controls-background: var(--palette-gunmetal-grey-100);
    --ws-media-player-audio-button-path: var(--palette-white);

    // Document
    --ws-document-icon-background-color: var(--ws-element-background-primary);
    --ws-document-icon-fold-color: var(--ws-element-background-primary);
    --ws-document-icon-selection-border-color: var(--ws-element-selection);

    // Element Text
    --ws-text-code-background-color: var(--palette-grey-500);
    --ws-text-code-preview-background-color: var(--palette-grey-500);
    --ws-text-code-color: var(--palette-black);
    --ws-text-code-block-color: var(--palette-black);

    --ws-text-select-color: #b2d7fe;
    --ws-text-color-blue: #0e84bf;
    --ws-text-color-green: #299635;
    --ws-text-color-orange: #fb8516;
    --ws-text-color-red: #ef5353;
    --ws-text-color-grey: #999999;
    --ws-text-background-color-dark-grey: var(--palette-black);
    --ws-text-background-color-dark-grey-text: var(--palette-white);
    --ws-text-background-color-blue: #8ae0ff;
    --ws-text-background-color-green: #c9f201;
    --ws-text-background-color-orange: #ffce26;
    --ws-text-background-color-pink: #ffa4de;
    --ws-text-background-color-grey: #dfe0e2;

    // Deprecated highlights
    --ws-text-highlight-background-color: var(--ws-text-background-color-green);
    --ws-text-highlight-color: var(--palette-grey-900);

    --ws-text-pseudo-select-color: var(--palette-charcoal-100);

    // Reactions
    --ws-reaction-add-icon-foreground-active: var(--palette-charcoal-900);

    --ws-reaction-pill-background: var(--palette-grey-400);
    --ws-reaction-pill-color: var(--palette-grey-800);
    --ws-reaction-pill-active-background: var(--ws-mention-background);
    --ws-reaction-pill-active-color: var(--ws-mention-color);
    --ws-reaction-comment-pill-background: var(--palette-grey-500);

    // Drawings
    --ws-drawing-line-color: var(--ws-element-primary);

    // General
    --search-color: var(--palette-yellow-900);
    --search-color-contrast: var(--palette-charcoal-900);

    --progress-bar-background: var(--palette-gunmetal-grey-500);
    --progress-bar-color: var(--palette-purple-900);
    --progress-bar-barbershop-color: #{$white-opacity-40};

    --remote-user-color: var(--palette-purple-900);
    --remote-user-contrast: var(--palette-white);

    // Transparencies
    --ws-element-background-primary-opacity-40: #{$white-opacity-40};
    --ws-element-background-primary-opacity-0: #{$white-opacity-0};

    // Marketing
    --marketing-grey-900: #31303a;
    --marketing-grey-800: #6e6e71;
    --marketing-grey-300: #dddddd;
    --marketing-grey-200: #cacaca;
    --marketing-grey-100: #a5a5a5;
}

// When there's a light background colour
.custom-bg-light.custom-bg-light {
    --ui-canvas-foreground-primary: var(--palette-grey-900);
    --ui-canvas-foreground-secondary: rgba(2, 12, 31, 0.6);
    --ui-canvas-foreground-tertiary: rgba(45, 55, 72, 0.5);

    --ui-canvas-background-primary: var(--palette-white);

    --ui-tool-back: rgba(2, 12, 31, 0.6);
    --ui-tool-path: rgba(2, 12, 31, 0.7);
    --ui-toolbar-tool-background: rgba(255, 255, 255, 0.6);
    --ui-toolbar-tool-popup-open-background: var(--palette-white);
    --ui-tool-rich-text-active-background: var(--palette-grey-900);
    --ui-tool-rich-text-active: var(--palette-white);

    --ws-icon-view-background-inbox: rgba(253, 253, 252, 0.5);
    --ws-board-template-tag: rgba(75, 82, 98, 0.2);

    --ws-section-divider-color: rgba(0, 10, 20, 0.08);
    --palette-canvas-section-divider-colour: rgba(0, 10, 20, 0.08);

    .CanvasViewport {
        --ws-element-selection: var(--palette-black);
    }
}

.light-theme.light-theme {
    // Ensure that selections inside columns are always this colour
    .Column .List .ListElement {
        --ui-canvas-foreground-primary: var(--ui-foreground-primary);
        --ui-canvas-foreground-secondary: rgba(2, 12, 31, 0.6);
        --ui-canvas-foreground-tertiary: rgba(45, 55, 72, 0.5);

        --ui-canvas-background-primary: var(--palette-white);
    }

    .ToolbarPopup {
        --ui-toolbar-tool-background: rgba(155, 152, 158, 0.2);
        --ui-tool-path: var(--palette-gunmetal-grey-900);
    }
}
