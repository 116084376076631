@import '../../scss/settings';

.MobileToolbarContainer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

.MobileToolbar {
    --tool-icon-size: 4rem;
    --tool-icon-border-radius: 0.5rem;

    --toolbar-transition-duration: 0s;
    --toolbar-color: var(--ui-background-primary);

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    // FIXME-MOBILE - We might need a way to change the padding when on the web rather than within an app
    padding-top: var(--mobile-toolbar-padding-top);
    padding-right: var(--safe-area-right, 0px);
    padding-bottom: var(--safe-area-bottom, 0px);
    padding-left: var(--safe-area-left, 0px);

    transform: translate3d(0, 0, 0) scale(1);

    background: var(--toolbar-color);

    box-shadow: var(--modal-box-shadow);

    z-index: var(--z-index-mobile-toolbar);

    -webkit-tap-highlight-color: transparent;
    user-select: none;

    touch-action: none;

    .toolbar-window {
        position: relative;
        height: var(--modern-mobile-toolbar-height);

        transition: height var(--toolbar-transition-duration) ease;

        overflow: hidden;
    }

    .tool-list-container {
        position: relative;
        top: 0;
        left: 0;

        height: 100%;
        transform: translate3d(0, 0, 0);
    }

    .tool-list {
        display: flex;
        position: absolute;

        top: 0;
        left: 0;

        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: var(--modern-mobile-toolbar-height);
    }

    .mobile-toolbar-content {
        height: calc(var(--modern-mobile-toolbar-height) - var(--mobile-toolbar-padding-top));
    }

    /**
     * Animation to animate toolbar when revealing or hiding the keyboard
     */

    &:not(.reveal-keyboard) {
        bottom: 0;
        transition: none;
    }

    &.reveal-keyboard {
        bottom: calc(var(--keyboard-height) - var(--safe-area-bottom));
        transition: bottom var(--keyboard-animation-reveal-duration)
            cubic-bezier(
                            var(--keyboard-animation-reveal-control-point-1-x),
                            var(--keyboard-animation-reveal-control-point-1-y),
                            var(--keyboard-animation-reveal-control-point-2-x),
                            var(--keyboard-animation-reveal-control-point-2-y)
            )
            var(--keyboard-animation-reveal-delay);
    }

    &.prepare-reveal-keyboard {
        bottom: calc(var(--keyboard-height) - var(--safe-area-bottom) - var(--modern-mobile-toolbar-height) - 50px);
        transition: none;
    }
}
