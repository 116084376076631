@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';

.EditableTitle {
    position: relative;

    color: var(--ui-foreground-primary);
    font-family: $font-element-heading;
    font-weight: $font-weight-semibold;
    letter-spacing: var(--letter-spacing-board-heading);
    line-height: $element-line-height;

    -webkit-touch-callout: none;

    .editable-title {
        outline: none;
        word-break: break-word;
        -webkit-touch-callout: none;

        &.editing {
            background: var(--ui-background-primary);
            user-select: text;

            // prevents the bold/italic/underline options in the iPad text popup
            -webkit-user-modify: read-write-plaintext-only;
            -webkit-touch-callout: none;
        }

        .highlight {
            background: var(--search-color);
            color: var(--search-color-contrast);
        }

        // Allow text selection when the page is not editable
        .not-editable & {
            -webkit-touch-callout: none;
            user-select: text;
        }
    }
}
