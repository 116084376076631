@import 'colors';

.dark-theme {
    // TODO Map other neutral colours
    --color-neutral-1000: #{$white};
    --color-neutral-900: #{$grey-300};
    --color-neutral-800: #{$grey-800};
    --color-neutral-700: #{$grey-700};
    --color-neutral-600: #{$grey-800};
    --color-neutral-500: #{$charcoal-750};
    --color-neutral-400: #{$charcoal-800};
    --color-neutral-300: #{$grey-800};
    --color-neutral-0: #{$black};

    // FIXME Get these colours from Marc!
    --color-canvas-grey: var(--palette-canvas-light-grey);
    --color-canvas-warm-grey: #26232a;
    --color-canvas-yellow: #27241f;
    --color-canvas-green: #1c2624;
    --color-canvas-blue: #1c2329;

    // Interface
    --ui-background-primary: #{$charcoal-900};
    --ui-background-primary-hovered: #{$charcoal-800};
    --ui-background-secondary: #{$charcoal-800};
    --ui-background-tertiary: var(--palette-charcoal-850);

    --ui-background-primary-contrast: #{$charcoal-100};

    --ui-foreground-primary-contrast: #{$grey-900};
    --ui-foreground-secondary-contrast: #{$grey-600};
    --ui-foreground-tertiary-contrast: #{$grey-700};

    --ui-foreground-primary: #{$charcoal-100};
    --ui-foreground-secondary: #{$grey-500};
    --ui-foreground-tertiary: #{$grey-700};
    --ui-foreground-quaternary: var(--palette-charcoal-950);

    // Buttons and Links
    --ui-button-secondary-solid: var(--palette-charcoal-930);
    --ui-button-floating-secondary-border: var(--palette-charcoal-750);
    --ui-button-floating-secondary-text: var(--palette-charcoal-100);
    --ui-button-danger-disabled-background-color: var(--palette-charcoal-800);
    --ui-link-secondary-solid: var(--palette-grey-600);

    --ui-modal-foreground-primary: var(--ui-foreground-primary);
    --ui-modal-foreground-secondary: var(--palette-charcoal-650);
    --ui-modal-foreground-tertiary: var(--palette-charcoal-700);
    --ui-modal-foreground-quaternary: var(--palette-charcoal-700);
    --ui-modal-foreground-disabled: var(--palette-charcoal-700);
    --ui-modal-background-primary: var(--palette-charcoal-850);
    --ui-modal-background-primary-hover: var(--palette-charcoal-900);
    --ui-modal-background-tab-active: var(--palette-charcoal-775);
    --ui-modal-background-secondary: var(--palette-charcoal-900);
    --ui-modal-divider: var(--palette-charcoal-790);
    --ui-modal-contrast-color: var(--ui-foreground-primary);
    --ui-modal-contrast-background: #{$charcoal-800};

    // Interface overflow gradients
    --ui-modal-background-primary-overflow-color-2: rgba(77, 77, 77, 0.7);
    --ui-modal-background-primary-overflow-color-3: rgba(77, 77, 77, 0);

    --ui-input-border: var(--palette-charcoal-775);
    --ui-input-disabled: var(--palette-charcoal-775);
    --ui-input-background-disabled: var(--palette-charcoal-900);
    --ui-input-placeholder: var(--palette-charcoal-700);

    --ui-input-checkbox-background: var(--palette-charcoal-775);
    --ui-input-checkbox-tick: var(--palette-white);

    --ui-text-color-match-canvas: var(--color-neutral-0);
    --ui-text-color-opposite-to-canvas: var(--color-neutral-1000);

    --ui-calendar-secondary: var(--palette-charcoal-700);
    --ui-calendar-tertiary: var(--palette-charcoal-750);

    --ui-detached-header-background: #{$purple-1000};

    --ui-trash-tab-active: var(--palette-charcoal-850);
    --ui-mode-active-background: var(--palette-charcoal-900);

    --ui-canvas-foreground-primary: var(--ui-foreground-primary);
    --ui-canvas-foreground-secondary: rgba(255, 255, 255, 0.5);
    --ui-canvas-foreground-tertiary: rgba(255, 255, 255, 0.4);

    --ui-canvas-background-primary: #{$charcoal-900};

    --ui-divider-on-primary: #{$charcoal-800};
    --ui-divider-on-secondary: #{$charcoal-750};

    --ui-context-menu-divider: #{$charcoal-750};

    --ui-toggle-inactive: var(--palette-charcoal-900);
    --ui-toggle-thumb: #{$white};
    --ui-toggle-active: var(--palette-green-900);

    --ui-placeholder-text: #{$grey-600};

    --ui-accent-primary: #{$deep-orange-900};
    --ui-accent-primary-contrast: #{$white};
    --ui-accent-primary-2: #{$purple-900};
    --ui-accent-primary-2-contrast: #{$white};

    --ui-search-popup-background: var(--ui-modal-background-primary);
    --ui-search-border-unfocused: var(--palette-charcoal-775);
    --ui-search-placeholder: var(--palette-charcoal-650);
    --ui-search-glass-fill: #{$charcoal-750};
    --ui-search-input-background: var(--palette-charcoal-850);
    --ui-header-icon: var(--palette-charcoal-750);
    --ui-mobile-header-back-button: var(--palette-charcoal-600);

    // Loading spinner
    --ui-loading-spinner-color: var(--color-neutral-1000);

    --ui-loading-placeholder-color-1: #262626;
    --ui-loading-placeholder-color-2: #333333;

    // Breadcrumbs
    --ui-breadcrumbs-low-contrast-icon-border-color: var(--palette-charcoal-775);

    // Toolbar
    --ui-element-tool-draggable-drop-shadow-color: rgba(0, 0, 0, 0.25);
    --ui-element-tool-draggable-drop-shadow-hover-color: rgba(0, 0, 0, 0.2);
    --ui-element-tool-line-color: var(--palette-grey-700);

    // Account popup
    --ui-account-popup-foreground-secondary: var(--palette-charcoal-700);
    --ui-account-popup-background-secondary: var(--palette-charcoal-900);
    --ui-element-count-progress-background: var(--palette-charcoal-930);
    --ui-element-count-progress-foreground: var(--palette-charcoal-750);
    --ui-element-count-progress-foreground-full: var(--palette-gunmetal-grey-700);

    // Utils colours
    --ui-board-status-comment: var(--palette-purple-800);

    --ui-glow-color: #{$yellow-980};

    --ui-scrollbar-track: #{$white-opacity-10};
    --ui-scrollbar-thumb: #{$white-opacity-40};

    --ui-toolbar-tool-default-background: rgba(255, 255, 255, 0.1);
    --ui-toolbar-tool-background: var(--ui-toolbar-tool-default-background);
    --ui-toolbar-tool-popup-open-background: rgba(245, 245, 245, 0.2);
    --ui-toolbar-tool-popup-option-selected-background: rgba(255, 255, 255, 0.3);
    --ui-toolbar-tool-disabled-foreground: rgba(255, 255, 255, 0.1);
    --ui-board-tool-color: #{$purple-900};

    --ui-tool-back: rgba(190, 190, 190, 0.6);
    --ui-tool-path: #{$charcoal-100};
    --ui-tool-rich-text-active-background: #{$charcoal-100};
    --ui-tool-rich-text-active: #{$grey-900};

    --ui-scrubber-background: var(--palette-charcoal-775);
    --ui-scrubber-fill: var(--palette-charcoal-600);

    --ui-progress-bar-background: var(--palette-charcoal-850);
    --ui-progress-bar-fill-1: var(--palette-purple-850);
    --ui-progress-bar-fill-2: var(--palette-purple-850);

    // AI Ideas popup
    --ui-ai-conversation-background: var(--palette-charcoal-920);
    --ui-ai-conversation-divider: rgba(255, 255, 255, 0.1);

    --ui-ai-history-tab-selected-background: rgba(0, 0, 0, 0.2);
    --ul-ai-history-tab-new-border: var(--palette-charcoal-800);

    --ui-ai-button-border-color: var(--palette-charcoal-775);
    --ui-ai-button-hover-background: var(--palette-charcoal-800);
    --ui-ai-prompt-result-container-background: rgba(0, 0, 0, 0.2);

    --ui-ai-prompt-background: var(--palette-grey-900);
    --ui-ai-prompt-color: var(--palette-purple-700);
    --ui-ai-bot-history-background: var(--palette-charcoal-850);
    --ui-ai-bot-history-color: var(--palette-charcoal-100);

    --ui-ai-assistant-toolbar-loading-animation-background: var(--ui-toolbar-tool-default-background);

    --ui-ai-assistant-toolbar-feedback-text: var(--palette-grey-900);
    --ui-ai-assistant-toolbar-feedback-message-text: var(--palette-grey-750);
    --ui-ai-assistant-toolbar-feedback-message-disclaimer-text: var(--palette-charcoal-700);

    // Board search popup
    --ui-board-search-section-background: var(--palette-canvas-dark-grey);
    --ui-board-search-section-foreground-color: var(--neutral-darkmodegrey-400);
    --ui-board-search-form-secondary-color: var(--palette-charcoal-700);
    --ui-board-search-element-counts-color: var(--palette-charcoal-700);
    --ui-board-search-focus-color: var(--neutral-darkmodegrey-800);

    // Template picker
    --ui-template-picker-background: var(--palette-charcoal-800);

    --ui-template-picker-item-foreground-primary: var(--palette-charcoal-100);
    --ui-template-picker-item-foreground-secondary: var(--palette-charcoal-600);

    --ui-template-picker-keep-content-checkbox: var(--palette-grey-400);
    --ui-template-picker-keep-content-checkbox-disabled: var(--palette-grey-750);

    --ui-template-picker-item-pill-foreground: var(--palette-charcoal-800);
    --ui-template-picker-item-pill-background: var(--palette-charcoal-700);

    --ui-template-picker-item-background-selected: var(--palette-charcoal-775);
    --ui-template-picker-item-background-hover: var(--palette-charcoal-780);

    --ui-template-picker-header-primary: var(--palette-white);
    --ui-template-picker-header-secondary: var(--palette-charcoal-700);

    --ui-template-picker-search-foreground: var(--palette-charcoal-100);
    --ui-template-picker-search-foreground-secondary: var(--palette-charcoal-750);
    --ui-template-picker-search-border-focused: var(--palette-charcoal-100);

    --ui-template-picker-scrollbar-foreground: var(--palette-charcoal-900);
    --ui-template-picker-scrollbar-background: var(--palette-charcoal-750);

    // Drawing editor
    --ui-drawing-editor-overlay-background: #{$black-opacity-30};

    // User pills
    --ui-user-read-only-pill: var(--palette-grey-600);
    --ui-user-editor-pill-border: var(--palette-charcoal-800);
    --ui-user-editor-pill-color: var(--palette-charcoal-750);
    --ui-board-editor-list-status-color: var(--palette-charcoal-750);
    --ui-board-editor-list-email-color: var(--palette-charcoal-700);

    // Avatars
    --ui-avatar-foreground: var(--palette-charcoal-900);
    --ui-avatar-background: var(--palette-charcoal-650);
    --ui-avatar-foreground-contrast: var(--palette-charcoal-950);
    --ui-avatar-background-contrast: var(--palette-charcoal-650);
    --ui-avatar-guest-foreground: var(--palette-charcoal-850);
    --ui-avatar-count-background: var(--palette-charcoal-800);
    --ui-avatar-count-color: var(--palette-charcoal-100);

    // Export popup
    --ui-export-popup-toggle-text: var(--palette-charcoal-100);

    // Tooltips
    --ui-tooltip-color: var(--palette-charcoal-100);
    --ui-tooltip-secondary-color: var(--palette-charcoal-650);
    --ui-tooltip-background-color: var(--palette-charcoal-850);
    --ui-context-menu-item-hover-background: var(--palette-charcoal-900);
    --ui-tooltip-shortcut-key-background-color: none;
    --ui-tooltip-shortcut-key-text-color: var(--palette-charcoal-700);

    --ui-tooltip-element-tip-color: var(--palette-charcoal-700);
    --ui-tooltip-element-tip-background-color: var(--palette-black);

    // Shortcut
    --ui-shortcut-key-border-color: var(--palette-charcoal-750);
    --ui-shortcut-key-background-color: var(--palette-charcoal-750);
    --ui-shortcut-key-text-color: var(--palette-white);

    --ui-modal-shortcut-key-border-color: var(--palette-charcoal-775);
    --ui-modal-shortcut-key-background-color: none;
    --ui-modal-shortcut-key-text-color: var(--palette-charcoal-700);

    // Emojis & Reactions
    --ui-emoji-picker-item-background-hover: var(--palette-charcoal-850);

    // Color picker
    --ui-color-picker-mode-tab-inactive-color: var(--palette-charcoal-700);

    // Document modal
    --ui-document-modal-text-tool-border-color: #{$charcoal-750};

    // Share modal
    --ui-share-modal-publish-column-enabled-input-background: var(--palette-charcoal-850);

    // Element List Popups (trash and quick notes)
    --ui-element-list-popup-background: var(--palette-charcoal-920);

    // Quick notes
    --ui-quick-notes-popup-background-color: var(--ui-element-list-popup-background);
    --ui-quick-notes-popup-divider-color: var(--ws-section-divider-color);
    --ui-quick-notes-popup-app-store-button-background-color: var(--palette-charcoal-750);

    // Trash
    --ui-trash-popup-background: var(--ui-element-list-popup-background);
    --ui-trash-popup-divider-color: var(--ws-section-divider-color);

    // Settings modal
    --ui-settings-modal-button-link-color: var(--palette-charcoal-100);
    --ui-settings-modal-toolbar-button-background-color: var(--palette-charcoal-900);
    --ui-settings-modal-toolbar-button-text-color: var(--palette-charcoal-100);

    // Forms
    --ui-form-checkbox: var(--palette-white);
    --ui-form-checkbox-tick: var(--palette-charcoal-900);
    --ui-slider-rail-background: #1c1c1c;

    // Checkout modal
    --ui-checkout-modal-current-plan-button-background-color: var(--palette-charcoal-800);
    --ui-checkout-modal-current-plan-button-text-color: var(--palette-charcoal-100);
    --ui-checkout-modal-disabled-team-plan-background-color: var(--palette-charcoal-900);
    --ui-checkout-modal-savings-badge-background-color: var(--alert-alertgreen-1000);
    --ui-checkout-modal-savings-badge-text-color: var(--alert-alertgreen-050);

    // CUSTOM - From Ollie
    --ui-block-style-active-background: var(--ui-modal-background-tab-active);

    // Presentation Mode
    --ui-presentation-mode-button-secondary-text: var(--palette-charcoal-750);

    // Text
    --ui-clipper-extension-title-text-color: var(--palette-charcoal-100);
    --ui-clipper-extension-body-text-color: var(--palette-charcoal-700);

    // Status colours
    --error-color: #{$red-900};
    --error-color-strong: #d6223e;
    --error-background: #{$red-400};
    --success-color: #{$green-900};

    // Workspace
    --palette-canvas-section-divider-colour: rgba(255, 255, 255, 0.1);

    --ws-default-canvas-background: #{$canvas-dark-grey};
    --ws-canvas-background: #{$canvas-dark-grey};
    --ws-section-divider-color: var(--palette-canvas-section-divider-colour);

    --ws-selection-marquee-background: #{$white-opacity-10};

    --ws-missing-image-background-color: #{$charcoal-850};

    // Elements
    --ws-element-primary: #{$charcoal-100};
    --ws-element-secondary: #{$charcoal-700};
    --ws-element-tertiary: #{$charcoal-600};
    --ws-element-placeholder: #{$charcoal-750};
    --ws-comment-placeholder: #{$grey-700};
    --ws-task-title-placeholder: #{$charcoal-750};

    --ws-element-column-empty-placeholder: var(--palette-charcoal-930);
    --ws-element-column-dragging-placeholder: var(--palette-charcoal-930);
    --ws-element-inbox-dragging-placeholder: var(--palette-charcoal-930);

    --ws-element-column-collapse-background: var(--neutral-opacity-white-1000-15);
    --ws-element-column-collapse-foreground: var(--neutral-opacity-white-1000-15);
    --ws-element-column-card-border-color: var(--palette-charcoal-800);

    --ws-element-background-primary: #{$charcoal-900};
    --ws-element-background-primary-default: #{$charcoal-900};
    --ws-element-background-secondary: var(--palette-charcoal-910);

    --ws-element-border-soft: rgba(255, 255, 255, 0.07);
    --ws-element-divider: #{$grey-800};

    --ws-element-selection: #{$charcoal-200};
    --ws-element-edit-selection: #{$charcoal-200};

    --ws-element-selection-locked: #{$charcoal-750};
    --ws-element-can-drop: #{$charcoal-200};
    --ws-element-anchor: #{$deep-orange-900};

    --ws-card-word-count: #{$charcoal-700};

    --ws-task-completed: #{$charcoal-750};
    --ws-task-completed-checkbox: #{$charcoal-750};
    --ws-task-completed-checkbox-tick: var(--ui-foreground-primary-contrast);

    --ws-icon-view-background-inbox: rgba(87, 87, 87, 0.5);
    --ws-default-board-icon-background: #{$grey-600};
    --ws-board-template-tag: rgba(115, 115, 115, 0.3);

    --ws-default-line-color: var(--palette-charcoal-600);
    --ws-line-color: var(--palette-charcoal-600);
    --ws-line-color-grey: var(--palette-charcoal-750);
    --ws-line-control-point-background: #{$white};
    --ws-line-edge-drag-handle-background: #{$white};
    --ws-line-edge-drag-handle-snapped-border: #{$white};
    --ws-line-edge-drag-handle-hover-ring: #{$white-opacity-20};

    --ws-comment-editor-background: var(--palette-gunmetal-grey-800);
    --ws-comment-background: #{$grey-900};
    --ws-comment-border: var(--ws-element-border-soft);
    --ws-comment-scroll-thumb: rgba(255, 255, 255, 0.2);

    --ws-comment-overflow-color-2: rgba(50, 59, 74, 0.7);
    --ws-comment-overflow-color-3: rgba(50, 59, 74, 0);

    --ws-comment-hot-spot-background: #{$purple-900};
    --ws-comment-hot-spot-text-color: #{$white};

    --ws-mention-background: #{$grey-900};
    --ws-mention-color: #{$purple-850};

    --ws-pill-populated-background: #{$charcoal-800};
    --ws-pill-populated-color: #{$charcoal-200};
    --ws-assignment-background: #{$purple-1000};
    --ws-assignment-color: #{$purple-600};
    --ws-due-date-completed-background: var(--ws-pill-populated-background);
    --ws-due-date-completed-color: var(--ws-pill-populated-color);
    --ws-due-date-overdue-background: #{$red-1000};
    --ws-due-date-overdue-color: #{$red-400};
    --ws-due-date-today-background: #{$orange-1000};
    --ws-due-date-today-color: #{$orange-400};
    --ws-due-date-future-background: #{$green-1000};
    --ws-due-date-future-color: #{$green-400};

    // Table
    --ws-table-ref-header-background: var(--palette-charcoal-800);
    --ws-table-ref-header-border: #5f5f5f;
    --ws-table-ref-header-text: var(--palette-charcoal-700);
    --ws-table-ref-header-selected-background: var(--palette-charcoal-700);
    --ws-table-ref-header-selected-text: var(--palette-charcoal-800);
    --ws-table-ref-header-selected-border: #a3a3a3;

    --ws-table-header-row-background: var(--palette-charcoal-800);
    --ws-table-cell-borders: rgba(255, 255, 255, 0.1);
    --ws-table-cell-selection: var(--ws-element-selection);
    --ws-table-backlight: rgba(255, 255, 255, 0.15);
    --ws-table-secondary-text: var(--palette-grey-700);

    // Document
    --ws-document-icon-background-color: var(--ws-element-background-primary);
    --ws-document-icon-fold-color: var(--palette-charcoal-850);
    --ws-document-icon-selection-border-color: var(--ws-element-selection);

    // Element Text
    --ws-text-code-background-color: var(--palette-charcoal-850);
    --ws-text-code-preview-background-color: var(--palette-charcoal-930);
    --ws-text-code-color: var(--ws-element-primary);
    --ws-text-code-block-color: var(--palette-charcoal-100);
    --ws-text-select-color: #6689ad;

    --ws-text-highlight-color: #{$grey-900};

    --ws-text-pseudo-select-color: var(--palette-charcoal-850);

    // Reactions
    --ws-reaction-add-icon-foreground-active: var(--palette-charcoal-100);
    --ws-reaction-pill-background: var(--palette-charcoal-800);
    --ws-reaction-pill-color: var(--palette-charcoal-200);
    --ws-reaction-pill-active-background: var(--palette-gunmetal-grey-800);
    --ws-reaction-pill-active-color: var(--palette-purple-850);
    --ws-reaction-comment-pill-background: var(--palette-charcoal-800);

    // Drawings
    --ws-drawing-line-color: var(--palette-white);

    // General
    --search-color: #80792b;
    --search-color-contrast: var(--palette-charcoal-100);

    --progress-bar-background: var(--palette-charcoal-950);
    --progress-bar-color: var(--palette-purple-900);
    --progress-bar-barbershop-color: #{$white-opacity-40};

    --remote-user-color: var(--palette-purple-900);
    --remote-user-contrast: var(--palette-white);

    // Transparencies
    --ws-element-background-primary-opacity-40: #{$charcoal-opacity-40};
    --ws-element-background-primary-opacity-0: #{$charcoal-opacity-0};

    // Media player
    --ws-video-player-background: rgba(49, 49, 49, 0.8);
    --ws-media-player-button-color: var(--ws-element-secondary);
    --ws-media-player-audio-controls-background: var(--palette-charcoal-850);
    --ws-media-player-audio-button-path: var(--palette-charcoal-850);
}

// When there's a dark background colour
.custom-bg-dark.custom-bg-dark {
    --ui-canvas-foreground-primary: #{$charcoal-100};
    --ui-canvas-foreground-secondary: rgba(255, 255, 255, 0.5);
    --ui-canvas-foreground-tertiary: rgba(255, 255, 255, 0.4);

    --ui-canvas-background-primary: #{$charcoal-900};

    --ui-tool-back: rgba(190, 190, 190, 0.6);
    --ui-tool-path: rgba(255, 255, 255, 0.94);
    --ui-toolbar-tool-background: rgba(255, 255, 255, 0.1);
    --ui-toolbar-tool-popup-open-background: rgba(245, 245, 245, 0.2);
    --ui-tool-rich-text-active-background: #{$charcoal-100};
    --ui-tool-rich-text-active: #{$grey-900};

    --ws-board-template-tag: rgba(255, 255, 255, 0.2);

    --ws-section-divider-color: rgba(255, 255, 255, 0.08);
    --palette-canvas-section-divider-colour: rgba(255, 255, 255, 0.07);

    .CanvasViewport {
        --ws-element-selection: #{$charcoal-200};
    }
}

.dark-theme.dark-theme {
    // Ensure that selections inside columns are always this colour
    .Column .List .ListElement {
        --ui-canvas-foreground-primary: var(--ui-foreground-primary);
        --ui-canvas-foreground-secondary: rgba(255, 255, 255, 0.5);
        --ui-canvas-foreground-tertiary: rgba(255, 255, 255, 0.4);

        --ui-canvas-background-primary: #{$charcoal-900};
    }

    .ToolbarPopup {
        --ui-toolbar-tool-background: rgba(255, 255, 255, 0.1);
        --ui-tool-path: rgba(255, 255, 255, 0.94);
    }
}
