// This file is generated by scripts/createColorTokens/createColorTokens.ts
:root {
    --alert-alertblue-050: rgba(232, 245, 252, 1);
    --alert-alertblue-200: rgba(205, 233, 247, 1);
    --alert-alertblue-900: rgba(22, 160, 229, 1);
    --alert-alertblue-1000: rgba(14, 132, 191, 1);
    --alert-alertblue-1500: rgba(10, 79, 114, 1);
    --alert-alertgreen-050: rgba(237, 248, 238, 1);
    --alert-alertgreen-200: rgba(217, 238, 219, 1);
    --alert-alertgreen-900: rgba(78, 184, 90, 1);
    --alert-alertgreen-1000: rgba(66, 157, 76, 1);
    --alert-alertgreen-1500: rgba(58, 90, 62, 1);
    --alert-alertorange-050: rgba(255, 243, 233, 1);
    --alert-alertorange-200: rgba(255, 228, 206, 1);
    --alert-alertorange-900: rgba(255, 141, 34, 1);
    --alert-alertorange-1000: rgba(228, 117, 14, 1);
    --alert-alertorange-1500: rgba(112, 77, 45, 1);
    --alert-alertred-050: rgba(253, 239, 239, 1);
    --alert-alertred-200: rgba(249, 222, 222, 1);
    --alert-alertred-900: rgba(240, 40, 72, 1);
    --alert-alertred-1000: rgba(214, 34, 62, 1);
    --alert-alertred-1500: rgba(106, 65, 65, 1);
    --avatar-avatarbert: rgba(235, 220, 145, 1);
    --avatar-avatarelmo: rgba(230, 140, 140, 1);
    --avatar-avatarernie: rgba(243, 189, 127, 1);
    --avatar-avatargonzo: rgba(146, 193, 245, 1);
    --avatar-avatargrover: rgba(146, 193, 245, 1);
    --avatar-avatarkermit: rgba(140, 230, 150, 1);
    --avatar-avatarpiggy: rgba(230, 140, 186, 1);
    --avatar-avatarrosita: rgba(148, 220, 213, 1);
    --avatar-avatarvoncount: rgba(186, 154, 243, 1);
    --canvas-canvasdarkforest: rgba(28, 38, 36, 1);
    --canvas-canvasdarkgalaxy: rgba(38, 35, 42, 1);
    --canvas-canvasdarkmode: rgba(38, 38, 38, 1);
    --canvas-canvasdarksand: rgba(39, 36, 31, 1);
    --canvas-canvasdarksky: rgba(28, 35, 41, 1);
    --canvas-canvaslightforest: rgba(217, 235, 230, 1);
    --canvas-canvaslightgalaxy: rgba(229, 227, 232, 1);
    --canvas-canvaslightmode: rgba(235, 237, 238, 1);
    --canvas-canvaslightsand: rgba(240, 238, 223, 1);
    --canvas-canvaslightsky: rgba(220, 234, 246, 1);
    --element-elementblue-900: rgba(108, 199, 255, 1);
    --element-elementblue-1000: rgba(71, 185, 255, 1);
    --element-elementbrown-900: rgba(237, 147, 110, 1);
    --element-elementbrown-1000: rgba(231, 131, 88, 1);
    --element-elementgreen-900: rgba(146, 227, 107, 1);
    --element-elementgreen-1000: rgba(124, 214, 81, 1);
    --element-elementlightgrey-900: rgba(220, 220, 220, 1);
    --element-elementlightgrey-1000: rgba(209, 211, 214, 1);
    --element-elementmediumgrey-900: rgba(118, 124, 134, 1);
    --element-elementmediumgrey-1000: rgba(95, 102, 114, 1);
    --element-elementorange-900: rgba(255, 169, 84, 1);
    --element-elementorange-1000: rgba(255, 147, 41, 1);
    --element-elementpink-900: rgba(255, 138, 221, 1);
    --element-elementpink-1000: rgba(255, 109, 212, 1);
    --element-elementpurple-900: rgba(191, 142, 255, 1);
    --element-elementpurple-1000: rgba(175, 114, 255, 1);
    --element-elementred-900: rgba(255, 113, 113, 1);
    --element-elementred-1000: rgba(255, 77, 77, 1);
    --element-elementroyalblue-900: rgba(121, 155, 249, 1);
    --element-elementroyalblue-1000: rgba(88, 130, 248, 1);
    --element-elementteal-900: rgba(123, 230, 213, 1);
    --element-elementteal-1000: rgba(98, 219, 200, 1);
    --element-elementyellow-900: rgba(255, 217, 106, 1);
    --element-elementyellow-1000: rgba(255, 201, 45, 1);
    --highlight-lightblue-050: rgba(235, 249, 255, 1);
    --highlight-lightblue-200: rgba(205, 241, 255, 1);
    --highlight-lightblue-900: rgba(138, 224, 255, 1);
    --highlight-lightblue-1500: rgba(30, 125, 159, 1);
    --highlight-lightlime-050: rgba(248, 255, 229, 1);
    --highlight-lightlime-200: rgba(218, 248, 141, 1);
    --highlight-lightlime-900: rgba(201, 242, 1, 1);
    --highlight-lightlime-1500: rgba(85, 116, 14, 1);
    --highlight-lightorange-050: rgba(255, 248, 224, 1);
    --highlight-lightorange-200: rgba(255, 239, 185, 1);
    --highlight-lightorange-1000: rgba(255, 206, 38, 1);
    --highlight-lightorange-1500: rgba(152, 77, 0, 1);
    --highlight-lightpink-050: rgba(255, 247, 252, 1);
    --highlight-lightpink-200: rgba(255, 222, 243, 1);
    --highlight-lightpink-900: rgba(255, 164, 222, 1);
    --highlight-lightpink-1500: rgba(155, 62, 116, 1);
    --highlight-lightyellow-050: rgba(255, 252, 230, 1);
    --highlight-lightyellow-200: rgba(255, 250, 192, 1);
    --highlight-lightyellow-900: rgba(255, 237, 0, 1);
    --highlight-lightyellow-1500: rgba(128, 121, 43, 1);
    --neutral-darkmodegrey-050: rgba(242, 242, 242, 1);
    --neutral-darkmodegrey-100: rgba(230, 230, 230, 1);
    --neutral-darkmodegrey-200: rgba(204, 204, 204, 1);
    --neutral-darkmodegrey-300: rgba(179, 179, 179, 1);
    --neutral-darkmodegrey-400: rgba(153, 153, 153, 1);
    --neutral-darkmodegrey-500: rgba(128, 128, 128, 1);
    --neutral-darkmodegrey-600: rgba(102, 102, 102, 1);
    --neutral-darkmodegrey-700: rgba(77, 77, 77, 1);
    --neutral-darkmodegrey-750: rgba(61, 61, 61, 1);
    --neutral-darkmodegrey-800: rgba(51, 51, 51, 1);
    --neutral-darkmodegrey-900: rgba(26, 26, 26, 1);
    --neutral-darkmodegrey-1000: rgba(0, 0, 0, 1);
    --neutral-lightmodegrey-050: rgba(243, 243, 244, 1);
    --neutral-lightmodegrey-100: rgba(232, 233, 235, 1);
    --neutral-lightmodegrey-200: rgba(209, 211, 214, 1);
    --neutral-lightmodegrey-300: rgba(187, 190, 195, 1);
    --neutral-lightmodegrey-400: rgba(163, 167, 174, 1);
    --neutral-lightmodegrey-500: rgba(141, 146, 154, 1);
    --neutral-lightmodegrey-600: rgba(118, 124, 134, 1);
    --neutral-lightmodegrey-700: rgba(95, 102, 114, 1);
    --neutral-lightmodegrey-800: rgba(72, 80, 94, 1);
    --neutral-lightmodegrey-900: rgba(50, 59, 74, 1);
    --neutral-lightmodegrey-1000: rgba(27, 37, 54, 1);
    --neutral-white-1000: rgba(255, 255, 255, 1);
    --neutral-opacity-darkmodegrey-1000-00: rgba(0, 0, 0, 0);
    --neutral-opacity-darkmodegrey-1000-05: rgba(0, 0, 0, 0.05);
    --neutral-opacity-darkmodegrey-1000-10: rgba(0, 0, 0, 0.1);
    --neutral-opacity-darkmodegrey-1000-15: rgba(0, 0, 0, 0.15);
    --neutral-opacity-darkmodegrey-1000-20: rgba(0, 0, 0, 0.2);
    --neutral-opacity-darkmodegrey-1000-30: rgba(0, 0, 0, 0.3);
    --neutral-opacity-darkmodegrey-1000-60: rgba(0, 0, 0, 0.6);
    --neutral-opacity-darkmodegrey-1000-80: rgba(0, 0, 0, 0.8);
    --neutral-opacity-lightmodegrey-1000-00: rgba(27, 37, 54, 0);
    --neutral-opacity-lightmodegrey-1000-05: rgba(27, 37, 54, 0.05);
    --neutral-opacity-lightmodegrey-1000-10: rgba(27, 37, 54, 0.1);
    --neutral-opacity-lightmodegrey-1000-15: rgba(27, 37, 54, 0.15);
    --neutral-opacity-lightmodegrey-1000-20: rgba(27, 37, 54, 0.2);
    --neutral-opacity-lightmodegrey-1000-30: rgba(27, 37, 54, 0.3);
    --neutral-opacity-lightmodegrey-1000-60: rgba(27, 37, 54, 0.6);
    --neutral-opacity-lightmodegrey-1000-80: rgba(27, 37, 54, 0.8);
    --neutral-opacity-white-1000-00: rgba(255, 255, 255, 0);
    --neutral-opacity-white-1000-05: rgba(255, 255, 255, 0.05);
    --neutral-opacity-white-1000-10: rgba(255, 255, 255, 0.1);
    --neutral-opacity-white-1000-15: rgba(255, 255, 255, 0.15);
    --neutral-opacity-white-1000-20: rgba(255, 255, 255, 0.2);
    --neutral-opacity-white-1000-30: rgba(255, 255, 255, 0.3);
    --neutral-opacity-white-1000-60: rgba(255, 255, 255, 0.6);
    --neutral-opacity-white-1000-80: rgba(255, 255, 255, 0.8);
    --primary-deeporange-050: rgba(254, 246, 243, 1);
    --primary-deeporange-100: rgba(253, 237, 232, 1);
    --primary-deeporange-200: rgba(252, 220, 209, 1);
    --primary-deeporange-300: rgba(251, 203, 187, 1);
    --primary-deeporange-400: rgba(250, 185, 164, 1);
    --primary-deeporange-500: rgba(249, 168, 141, 1);
    --primary-deeporange-600: rgba(248, 150, 118, 1);
    --primary-deeporange-700: rgba(247, 133, 96, 1);
    --primary-deeporange-800: rgba(246, 115, 73, 1);
    --primary-deeporange-900: rgba(245, 98, 51, 1);
    --primary-deeporange-1000: rgba(244, 81, 28, 1);
    --primary-royalblue-050: rgba(244, 247, 254, 1);
    --primary-royalblue-100: rgba(234, 239, 254, 1);
    --primary-royalblue-200: rgba(213, 223, 253, 1);
    --primary-royalblue-300: rgba(193, 208, 252, 1);
    --primary-royalblue-400: rgba(171, 192, 251, 1);
    --primary-royalblue-500: rgba(151, 177, 251, 1);
    --primary-royalblue-600: rgba(130, 161, 250, 1);
    --primary-royalblue-700: rgba(109, 146, 249, 1);
    --primary-royalblue-800: rgba(88, 130, 248, 1);
    --primary-royalblue-900: rgba(68, 114, 247, 1);
    --primary-royalblue-1000: rgba(47, 99, 247, 1);
}
