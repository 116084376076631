@import '../scss/settings';
@import '../scss/mixins/outer-border-mixin';
@import '../scss/mixins/list-placeholder-shadow-mixin';

@mixin list-element-dragging {
    .ListElement .dragging & {
        @include list-placeholder-shadow();

        box-shadow: none;

        > div {
            visibility: hidden;
        }

        &.in-list-quick-notes,
        &.in-list-unsorted-notes,
        &.in-list-trash {
            background: var(--ws-canvas-background);

            .dark-theme &,
            .custom-bg-dark & {
                filter: brightness(110%);
            }

            .light-theme &,
            .custom-bg-light & {
                filter: brightness(96%);
            }
        }
    }
}
