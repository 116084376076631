@import '../../scss/mixins/outer-border-mixin';

.ElementPaperIcon {
    display: block;
    position: relative;
    height: 100%;
    margin-top: -1px;

    > svg {
        position: relative;

        overflow: hidden;

        filter: var(--element-drop-shadow-elevation-1);

        .selection-stroke {
            opacity: 0;
        }
    }

    .icon {
        position: absolute;

        bottom: 0;
        left: 0;

        width: 100%;
        height: auto;

        z-index: 1;

        img {
            pointer-events: none;
        }
    }

    .drag-preview-shadow {
        top: 1.5rem;
    }

    &.background-light {
        .icon {
            filter: grayscale(100%) brightness(0) invert(100%) brightness(23%);
        }
    }

    &.background-dark {
        .icon {
            filter: grayscale(100%) brightness(0) invert(100%);
        }
    }

    .Clone.marquee-selected:not(.marquee-remove-selected) &,
    .selected:not(.marquee-remove-selected):not(.Column.selected) &,
    .marquee-selected:not(.marquee-remove-selected):not(.Column.marquee-selected) &,
    .line-edge-can-drop:not(.Column) & {
        .selection-stroke {
            opacity: 1;
        }
    }

    .remote-selected:not(.Column) & {
        --ws-document-icon-selection-border-color: var(--remote-user-color);

        .selection-stroke {
            opacity: 1;
        }
    }

    .editing.selected & {
        --ws-document-icon-selection-border-color: var(--ws-element-edit-selection);
    }
}
