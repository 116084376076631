@import '../../scss/settings';

.LinkURL {
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;

    color: var(--ws-element-tertiary);
    font-size: $font-size-element-secondary;

    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;

    -webkit-touch-callout: none;
}
