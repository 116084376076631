@import '../../../../../../scss/settings';

.ToolbarPopup.DistributePopup {
    width: auto;

    .Icon {
        rect {
            fill: var(--ui-toolbar-tool-background);
        }

        path {
            fill: var(--ui-tool-path);
        }
    }
}
