@import '../../scss/settings';

.Document {
    display: flex;
    flex-direction: column;

    .EditableTitle {
        color: var(--ws-element-primary);
        font-family: $font-board-heading;
        font-size: $font-size-board-heading;
    }

    &.element-in-list {
        // Document that is currently being dragged
        .Element.dragging & {
            .DocumentStatus {
                display: none;
            }
        }
    }
}
