@import '../../../scss/settings';

.Button.CommentPostButton {
    position: absolute;
    right: 0.8rem;
    bottom: 0.7rem;

    color: var(--ws-mention-color);
    user-select: none;

    &:disabled {
        background: none;
    }

    .CommentForm & {
        font-weight: $font-weight-semibold;
    }
}
