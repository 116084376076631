.FileIcon {
    svg {
        width: 100%;
        height: auto;
    }

    &.disabled {
        opacity: 0.3;
    }
}
