.tiptap {
    ul[data-type="taskList"] {
        margin-left: 0;
        padding: 0;

        list-style: none;

        li {
            display: flex;
            align-items: flex-start;

            color: var(--ws-element-primary);

            > .task-item-content {
                flex-grow: 1;
                flex-shrink: 1;
            }

            &[data-checked="true"] {
                color: var(--ws-task-completed);
            }
        }

        .Checkbox {
            margin-top: 2px;
            cursor: pointer;
            user-select: none;

            .Icon .tick {
                stroke: var(--ws-element-background-primary);
            }
        }

        // Placeholder
        &.is-editor-empty:first-child::before {
            left: 2.7rem;
        }
    }
}
