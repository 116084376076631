@import '../../scss/settings';

.PopupToolButton {
    position: relative;
    margin-bottom: 2px;
    margin-left: 20px;
    color: var(--ui-foreground-primary);
    line-height: 1em;

    .icon {
        margin-right: 16px;
    }

    .button-content {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .tool-text {
        white-space: nowrap;
        user-select: none;
        -webkit-touch-callout: none;
    }

    .arrow {
        position: relative;

        path {
            fill: var(--ui-foreground-primary);
        }
    }

    .arrow,
    .Icon.menu-down-arrow {
        width: 1.6rem;
        height: 1.6rem;
    }

    .app-size-mobile & {
        .tool-text {
            padding-left: 0;
        }
    }
}
