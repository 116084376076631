.snap-point {
    position: absolute;
    left: 0;
    width: 30px;
    z-index: 2000;

    .marker {
        height: 3px;
        margin-top: 2px;
        border-radius: 2px;
        background: #ffea00;

        &.initial-snap-point {
            background: #00ff66;
        }
    }
}
