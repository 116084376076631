@import '../../scss/settings';
@import '../elementBox';
@import '../../list/listElementDraggingMixin';
@import '../elementFloatingReactionsMixin';

.ColorSwatch {
    @include element-box;

    .ListElement .dragging & {
        filter: opacity(0.1) brightness(0)
    }

    &.locked {
        &:before {
            box-shadow: none;
        }
    }

    .focusable:focus {
        outline: none;
    }

    .ColorSwatchRenderer {
        @include floating-reactions;

        position: relative;
        overflow: hidden;

        .ElementSimpleContentEditable {
            position: absolute;
            top: 0;
            left: 0;
            margin: $element-padding-small var(--element-padding-default);

            outline: none;
            font-size: 1.4rem;
            font-weight: 600;

            &.editing {
                opacity: 1;
            }
        }
    }

    &.minimum-width.small-width .ElementReactions {
        display: none;
    }

    &.small-width .ElementReactions {
        padding-right: 1.2rem;
        padding-bottom: 0.8rem;
        padding-left: 1.2rem;
    }

    // Export - hide element-box border on images without caption
    .hide-background &:not(.caption-visible) {
        &:before {
            border: 0;
        }
    }

    @media print {
        page-break-inside: avoid;
    }
}

.ColorSwatchContainer {

    .CustomColorInput {
        position: absolute;
        top: 20%;
        left: 80%;
        width: 1px;
        height: 1px;
    }
}
