@import '../settings';

@mixin outer-border($size: 1px, $color: $black-opacity-15) {
    &:before {
        top: -$size;
        right: -$size;
        bottom: -$size;
        left: -$size;

        border: $size solid $color;
    }
}

@mixin outer-border-full($size: 1px, $color: $black-opacity-1, $border-radius: false) {
    @include outer-border($size, $color);

    &:before {
        position: absolute;

        // only output border radius if passed
        @if $border-radius {
            border-radius: $border-radius;
        }

        content: '';
        z-index: 0;
        pointer-events: none;
    }
}

@mixin overlapping-border-full($size: 1px, $color: $black-opacity-15, $border-radius: false) {
    &:before {
        position: absolute;

        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        border: $size solid $color;

        // only output border radius if passed
        @if $border-radius {
            border-radius: $border-radius;
        }

        content: '';
        z-index: 1;
        pointer-events: none;
    }
}

@mixin centred-border-full($size: 1px, $color: $black-opacity-15, $border-radius: false) {
    &:before {
        position: absolute;

        top: calc(-#{$size} / 2);
        right: calc(-#{$size} / 2);
        bottom: calc(-#{$size} / 2);
        left: calc(-#{$size} / 2);

        border: $size solid $color;

        // only output border radius if passed
        @if $border-radius {
            border-radius: $border-radius;
        }

        content: '';
        z-index: 1;
        pointer-events: none;
    }
}
