@import '../../scss/settings';

.AdminDashboardForm {
    padding: 10px;

    .input-wrapper {
        display: block;
        margin-bottom: 10px;

        span {
            margin-bottom: 3px;
            color: var(--ui-foreground-secondary);
        }
    }

    .submit-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        margin-top: 30px;

        .submit-button {
            margin-right: 15px;
            border: 0;

            background: var(--ui-background-primary-contrast);

            color: var(--ui-foreground-primary-contrast);
            font-weight: 600;

            cursor: pointer;

            &:disabled {
                opacity: 0.4;
            }
        }

        .Spinner {
            .spinner-wrapper {
                width: 16px;
            }
        }

        .success {
            color: var(--success-color);
            font-weight: $font-weight-semibold;
        }

        .error {
            color: var(--error-color);
        }
    }

    input,
    select {
        display: block;
        padding: 5px 10px;
        border: 1px solid var(--ui-divider-on-secondary);
        border-radius: 3px;

        outline: 0;

        font-family: $font-body;
        font-size: 16px;
    }

    &.warning {
        margin: 10px;
        border: 1px solid var(--error-color);
        background: var(--error-background);
        color: var(--error-color);
    }
}
