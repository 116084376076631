@import '../../scss/settings';

.ElementDragPreview {
    position: relative;
    transform-origin: center center;
    will-change: transform;

    user-select: none;
    -webkit-touch-callout: none;

    .drag-preview-initial-shadow,
    .drag-preview-shadow {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: -1;
        pointer-events: none;
    }

    .drag-preview-initial-shadow {
        box-shadow: var(--element-shadow-elevation-1);
        opacity: 1;
    }

    .drag-preview-shadow {
        box-shadow: var(--element-shadow-elevation-3);
        opacity: 0;
    }

    // Need double "drag-handle" here to raise the specificity of the selector
    .drag-handle.drag-handle:before {
        box-shadow: none;
    }

    .List .ListElement .Element .drag-preview-shadow {
        box-shadow: none;
    }
}

.ElementDragPreviewPositioner {
    perspective: 2000px;
}
