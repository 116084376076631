@import '../../../scss/settings';
@import '../../../scss/mixins/outer-border-mixin';

@keyframes board-hover-blink {
    0% {
        filter: brightness(0.9);
    }
    25% {
        filter: brightness(0.8);
    }
    50% {
        filter: brightness(0.9);
    }
    75% {
        filter: brightness(0.8);
    }
}

.BoardIcon {
    position: relative;
    font-size: 1rem;

    &.medium {
        .ElementIcon {
            --element-icon-size: 3.5rem;
        }
    }

    &.small {
        .ElementIcon {
            --element-icon-size: 2.4rem;
        }
    }

    .board-icon-content {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        transition: transform 0.2s $cubic-ease-bounceback, filter 0.2s linear, box-shadow 0.2s linear,
            background-color 0.2s linear;

        border-radius: $board-border-radius;
        background: var(--ws-default-board-icon-background);
        
        overflow: hidden;

        &.no-transition {
            transition: none;
        }

        .ElementIcon {
            transition: transform 0.2s $cubic-ease-bounceback, opacity 0.3s ease-out;
            cursor: default;

            &.dark {
                --element-icon-color: var(--palette-black);
                --element-icon-blend-mode: normal;
                --element-icon-opacity: 0.76;
            }

            &.light {
                --element-icon-color: var(--palette-white);
                --element-icon-blend-mode: normal;
                --element-icon-opacity: 0.95;
            }

            &.dual-color {
                --element-icon-color: var(--palette-black);
                --element-icon-blend-mode: normal;
                --element-icon-opacity: 1;
            }
        }

        .BoardImage {
            transition: transform 0.2s $cubic-ease-bounceback, opacity 0.3s ease-out;
            border-radius: $board-border-radius;

            .image-node {
                border-radius: $board-border-radius;
            }
        }

        .Board.selected:not(.marquee-remove-selected) &,
        .Board.marquee-selected:not(.marquee-remove-selected) &,
        .Board.line-edge-can-drop &,
        .Board.remote-selected & {
            @include overlapping-border-full(2px, var(--ws-element-selection), $board-border-radius);
        }

        .Board.editing.selected & {
            @include overlapping-border-full(2px, var(--ws-element-edit-selection), $board-border-radius);
        }

        .Board.remote-selected & {
            @include overlapping-border-full(2px, var(--remote-user-color), $board-border-radius);
        }

        .Board.locked.selected &,
        .Board.locked.marquee-selected & {
            @include overlapping-border-full(2px, var(--ws-element-selection-locked), $board-border-radius);
        }

        .can-drop & {
            transform: scale(1.1, 1.1);
            box-shadow: var(--element-shadow-elevation-2);
            filter: brightness(0.8);

            .BoardImage {
                transform: scale(0.82, 0.82);
            }

            .ElementIcon {
                transform: scale(0.82, 0.82);
                opacity: 0.5;
            }
        }
    }

    &.pre-nav {
        .board-icon-content {
            transition: none;
            animation: board-hover-blink 0.3s linear forwards;
        }
    }

    .QuickLineCreationTool {
        // half the total width in a negative direction, - 1rem to adjust the position of the circle
        --line-tool-position-offset: calc(-0.5 * (var(--line-tool-hit-size) - 1rem));
        top: var(--line-tool-position-offset);
        right: var(--line-tool-position-offset);
    }

    .in-list-column & {
        .QuickLineCreationTool {
            top: 0.4rem;
            right: 0.4rem;
        }
    }
}

.ElementDragPreview {
    .BoardIcon {
        .drag-preview-shadow {
            border-radius: $board-border-radius;
        }
    }
}
