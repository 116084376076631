@import '../../scss/settings';

.ElementSimpleContentEditable {
    -webkit-touch-callout: none;

    &.multiline {
        white-space: pre-wrap;
        -webkit-touch-callout: none;
    }

    &.editing {
        user-select: text;

        // prevents the bold/italic/underline options in the iPad text popup
        -webkit-user-modify: read-write-plaintext-only;

        -webkit-touch-callout: none;
    }
}
