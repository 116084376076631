@import '../../../../scss/settings';
@import '../../../../scss/mixins/fade-and-slide-transition-group-mixin';

.DrawingEditorToolbar {
    @include fade-and-slide-transition-group-mixin(15px, 0.15s);

    --drawing-toolbar-width: 46rem;

    display: flex;
    position: fixed;

    bottom: 4rem;
    left: calc(50% - var(--drawing-toolbar-width) / 2);

    align-items: center;
    justify-content: space-between;

    width: var(--drawing-toolbar-width);

    padding: 1.2rem;

    // TODO-DRAWING - Experiment - This is to ensure the .drawing class in
    //  DrawingEditorCanvasDrawMode can transition in and out the toolbar.
    //  We might want to clean up this if we go ahead with it.
    transform: translate(0, 0);
    transition: opacity 0.15s linear, transform 0.15s ease-out;

    border-radius: 0.8rem;
    background: var(--ui-modal-background-primary);

    line-height: 0;
    box-shadow: var(--element-shadow-elevation-3);

    opacity: 1;

    z-index: calc(var(--z-index-drawing-editor) + 1);

    user-select: none;
    -webkit-touch-callout: none;

    &.ipad {
        --drawing-toolbar-width: 54rem;
    }

    .tool-mode-controls {
        display: flex;
        align-content: center;
        justify-content: space-between;

        margin-right: 1.6rem;
        padding-right: 0.8rem;

        border-right: 1px solid var(--ui-modal-divider);

        > .TooltipSource {
            margin-right: 0.8rem;
        }
    }

    .draw-controls {
        display: flex;
        align-content: center;
        justify-content: space-between;

        .ColorToolIcon.no-color {
            .color-icon {
                background-color: var(--ws-drawing-line-color);
            }
        }
    }

    .exit-controls {
        display: flex;
        justify-content: space-between;

        height: 2.8rem;

        margin-left: auto;

        .StyledButton {
            padding-top: 0.4rem;
            padding-bottom: 0.5rem;

            font-size: var(--font-size-ui-small);
            user-select: none;
            -webkit-touch-callout: none;

            &.save {
                margin-left: 0.8rem;
            }
        }
    }

    .app-size-medium & {
        padding: 11px;

        .exit-controls {
            height: 28px;
        }
    }

    .app-size-small & {
        padding: 10px;

        .exit-controls {
            height: 25px;
        }
    }
}
