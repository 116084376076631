.SheetContainer.text-style-rich-text-tool {
    .TextStylePopupContent {
        padding: 1.2rem 0 1rem 0;

        .TextStyleEntry {
            padding: 0 2.4rem 0 2.4rem;
        }

        .text-color-section {
            padding: 1.2rem 2rem 1.2rem 2rem;
            border: none;

            .text-color-section-title {
                margin-bottom: 1.8rem;
            }

            .text-color-row {
                justify-content: start;

                .TextColorButton {
                    width: 3.6rem;
                    height: 3rem;
                    margin-right: 1.4rem;
                    font-size: 2rem;
                }
            }
        }
    }
}
