@import '../../scss/settings';

.AttachedCommentsContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    overflow: visible;

    z-index: calc(var(--z-index-element-hot-spot-container) + 1);
    pointer-events: none;
}

.HotSpot {
    position: absolute;
    width: 2rem;
    height: 1.6rem;

    .Element {
        pointer-events: all;

        &.dragging {
            pointer-events: none;
        }
    }

    .is-dragging-commit & {
        .Element {
            pointer-events: none;
        }
    }
}
