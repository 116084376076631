@mixin fade-and-slide-transition-group-mixin($distance: 30px, $time: 0.2s, $entrance-easing: ease-out, $leave-easing: ease-in) {
    &.fade-and-slide-appear,
    &.fade-and-slide-enter {
        transform: translate(0, $distance);
        opacity: 0;
    }

    &.fade-and-slide-appear.fade-and-slide-appear-active,
    &.fade-and-slide-enter.fade-and-slide-enter-active,
    &.fade-and-slide-leave {
        transform: translate(0, 0);
        opacity: 1;
    }

    &.fade-and-slide-appear.fade-and-slide-appear-active,
    &.fade-and-slide-enter.fade-and-slide-enter-active {
        transition: opacity $time linear, transform $time $entrance-easing;
    }

    &.fade-and-slide-leave.fade-and-slide-leave-active {
        transform: translate(0, $distance);
        transition: opacity $time linear, transform $time $leave-easing;
        opacity: 0;
    }
}
