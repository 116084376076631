@import '../../scss/settings';
@import '../../scss/mixins/list-placeholder-shadow-mixin';

.IconView {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 0.9rem 1rem 1rem 1rem;
    text-align: center;

    // NOTE: z-index on this component would interfere with the quick line drag and drop.
    //  If adding back, ensure that the quick line tool still works

    .background {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .icon-container {
        --ws-missing-image-background-color: var(--ws-default-board-icon-background);

        display: flex;

        flex-direction: column;
        flex-shrink: 0;
        align-items: center;

        width: 7.2rem;
        height: 7.2rem;

        .in-list-document-mode & {
            width: 5rem;
            height: 5rem;
        }

        .in-list-column &,
        .in-list-trash &,
        .in-list-quick-notes &,
        .in-list-unsorted-notes & {
            width: 6rem;
            height: 6rem;
        }
    }

    .details-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 18rem;
        margin-top: 0.6rem;

        .EditableTitle {
            margin-bottom: 3px;
            padding: 3px 3px 2px;

            .editable-title.editing,
            &.editing {
                background: var(--ui-canvas-background-primary);
            }
        }

        .secondary-details-container {
            display: flex;
            color: var(--ws-element-secondary);
            font-size: $font-size-element-secondary;
            z-index: 1;
        }
    }

    .secondary-details-container * {
        color: var(--ui-canvas-foreground-tertiary);
        font-size: $font-size-element-secondary;
    }

    .QuickLineCreationTool {
        top: -0.7rem;
        right: -0.6rem;
    }

    .element-in-list & {
        position: relative;
        flex-direction: row;
        align-items: stretch;

        padding: 1.6rem $element-padding-default;

        .app-size-medium & {
            padding: 14px $element-padding-default 15px;
        }

        .app-size-small & {
            padding: 13px $element-padding-default 13px;
        }

        // IconView that is currently being dragged should be greyed out
        .Element.dragging & {
            > div:not(.background) {
                visibility: inherit;
                filter: opacity(0.1) brightness(0);
            }

            > .background {
                visibility: inherit;
            }
        }

        .QuickLineCreationTool {
            top: 0.7rem;
            right: 0.7rem;
        }

        .details-container {
            align-items: flex-start;
            justify-content: center;
            width: auto;
            margin-top: 0;
            margin-left: 1rem;

            padding-top: 0.8rem;
            padding-bottom: 0.7rem;

            .app-size-medium & {
                padding-top: 6px;
                padding-bottom: 6px;
            }

            .app-size-small & {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            .EditableTitle {
                margin-top: -0.5rem;
                margin-left: 0.2rem;

                text-align: left;

                .app-size-medium & {
                    margin-top: -4px;
                    padding-top: 3px;
                }

                .app-size-small & {
                    margin-top: -4px;
                }
            }

            .secondary-details-container {
                // To match the ContainerElementChildCount (so Documents render the same)
                min-height: 1.2rem;
                margin-bottom: 0;
                margin-left: 0.5rem;

                .app-size-medium & {
                    min-height: 11px;
                }

                .app-size-small & {
                    min-height: 10px;
                }
            }
        }
    }

    .in-list-quick-notes &,
    .in-list-trash &,
    .in-list-unsorted-notes & {
        .background {
            display: block;
            background: var(--ws-icon-view-background-inbox);
        }
    }

    .in-list-column &,
    .in-list-document-mode & {
        .background {
            @include list-placeholder-shadow();

            display: block;
        }

        .secondary-details-container * {
            color: var(--ws-element-tertiary);
        }
    }
}

.ElementDragPreview {
    .IconView {
        .drag-preview-shadow {
            display: none;

            .platform-ipad & {
                display: block;
            }
        }
    }
}
