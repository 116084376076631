@import '../../../../../../../../scss/settings';

.IconList {
    display: flex;
    flex-wrap: wrap;
    min-height: 100%;

    .iconlist-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100%;

        .Spinner {
            width: 40px;
            height: 40px;
        }
    }

    .no-results {
        padding: 0 20px;
        color: var(--ui-foreground-secondary);
        text-align: center;

        a {
            color: var(--ui-foreground-tertiary);
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;

        width: 100%;
        min-height: 100%;
        padding: 5px 0;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25%;
            height: 70px;
        }
    }
}
