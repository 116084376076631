:root {
    // Fonts
    // Inbox header
    --font-size-tool-large: 1.6rem;
    --font-size-static-header: 12px;
    --font-size-static-header-secondary: 10px;
    --font-size-tool: 1.2rem;
    --font-size-tool-secondary: 1rem;
    --font-size-toolbar: 10px;
    --font-size-toolbar-small: 9px;

    // UI font sizes
    --font-size-ui-xxxlarge: 2.4rem;
    --font-size-ui-xxlarge: 2.1rem;
    --font-size-ui-xlarge: 1.8rem;
    --font-size-ui-large: 1.6rem;
    --font-size-ui-modal-title: 1.5rem;
    --font-size-ui-default: 1.4rem;
    --font-size-ui-small: 1.2rem;
    --font-size-ui-xsmall: 1.1rem;
    --font-size-ui-xxsmall: 1rem;

    --font-size-ui-mobile-default: 1.6rem;

    // Weights
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;

    // Letter spacing
    --letter-spacing-default: 0;

    // Brand
    --font-brand: 'Tiempos-semibold';

    // Page title
    --font-page-title: 'Tiempos-semibold';
    --font-size-page-heading: 2.2rem;
    --letter-spacing-page-title: -0.02rem;

    // Columns
    --font-column-heading: 'Tiempos-semibold';
    --font-size-column-heading: 1.7rem;
    --letter-spacing-column-heading: -0.02rem;

    // Link & Files
    --font-link-file-heading: 'Inter';
    --font-size-link-file-heading: 1.5rem;
    --letter-spacing-link-file-heading: -0.02rem;

    // Cards - Heading
    --font-element-heading: 'Tiempos-semibold';
    --font-size-element-heading-one: 2rem;
    --font-size-element-heading: 1.5rem;
    --letter-spacing-heading: -0.02rem;
    --line-height-heading-one: 2.6rem;
    --line-height-heading: 2.5rem;
    // Cards - default
    --font-default: 'Inter';
    --letter-spacing-body-text: -0.01rem;
    --font-size-element-default: 1.4rem;
    --font-size-element-indicator: 1.2rem;
    --font-size-element-secondary: 1.2rem;
    --font-size-element-messaging: 1.2rem;
    --font-element-quote: 'Inter';
    --font-element-code: 'RobotoMono';
    --line-height-element: 2rem;

    // Comment
    --font-size-comment: 1.2rem;

    // Boards
    --font-board-heading: 'Inter';
    --font-size-board-heading: 1.5rem;
    --letter-spacing-board-heading: -0.02rem;

    // UI
    --font-ui-heading: 'Tiempos-semibold';
    --font-toolbar: 'Inter';
    --font-modal-heading: 'Inter';

    // Fallback styles
    --font-basic: 'Georgia', 'Times New Roman', times, serif;
    --font-family-element-heading: var(--font-element-heading), var(--font-basic);
    --font-family-element-quote: var(--font-element-quote), var(--font-basic);
}

.sans-serif-headings {
    --font-page-title: 'Inter';
    --font-size-page-heading: 2.3rem;

    --font-column-heading: 'Inter';
    --font-size-column-heading: 1.8rem;
    --letter-spacing-column-heading: 0;

    --font-element-heading: 'Inter';
    --font-size-element-heading: 1.6rem;
    --letter-spacing-heading: -0.01rem;

    --font-ui-heading: 'Inter';

    --font-family-element-heading: var(--font-element-heading), var(--font-basic);
}
