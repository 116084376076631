@import '../../../../scss/settings';

.DrawingEditorToolbarExtendedPanel {
    display: flex;

    position: absolute;
    top: 0;

    width: auto;

    padding: 1.2rem;

    border-radius: 0.8rem;
    background: var(--ui-modal-background-primary);
    box-shadow: var(--element-shadow-elevation-3);

    > .Button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 32px;
        height: 28px;
        padding: 6px;

        border-radius: 0.4rem;

        &.disabled {
            opacity: 0.3;
        }

        &:nth-child(n + 2) {
            margin-left: 0.6rem;
        }
    }

    .app-size-small & {
        > .Button {
            width: 28px;
            height: 25px;
        }
    }
}
