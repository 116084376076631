@import '../../scss/settings';

.TimeInput {
    display: flex;
    align-items: center;

    select {
        margin: 0;
        padding: 0.7rem 0.3rem 0.7rem 0.3rem;

        border: 0;
        border-radius: 3px;
        outline: 0;
        background: none;

        color: var(--ui-foreground-primary);
        font-family: $font-body;
        font-size: 1em;
        font-weight: $font-weight-semibold;
        line-height: $font-size-ui-default;

        cursor: pointer;

        -moz-appearance: none;
        -webkit-appearance: none;

        &:hover {
            background: var(--color-neutral-400);
        }
    }

    .hours {
        margin-right: 0.2rem;
    }

    .minutes {
        margin-left: 0.2rem;
    }
}
