@import '../../../scss/settings';

$pin-height: 2.5rem;
$pin-width: 2.4rem;

.CommentThreadCollapsedDragHandle {
    --line-handle-size-halved: calc(var(--line-handle-size) / -2);

    position: absolute;
    top: $pin-height;
    left: calc(#{$pin-width} / 2);
    width: var(--line-handle-size);
    height: var(--line-handle-size);
    transform: translate(var(--line-handle-size-halved), var(--line-handle-size-halved));

    border-radius: 50%;

    line-height: 0;
    z-index: -1;

    .handle {
        display: none;
        stroke-width: 0.5;

        overflow: visible;
        z-index: 1;

        circle {
            stroke: $black-opacity-30;
            fill: var(--ws-line-edge-drag-handle-background);
        }
    }

    .attach-mode-shadow {
        position: absolute;
        top: -0.7rem;
        left: -0.7rem;
        width: 2.2rem;
        height: 2.2rem;

        transform: scale(0);
        transition: transform 0.1s ease-out;
        transition-delay: 0.1s;

        border-radius: 50%;
        background: var(--ws-line-edge-drag-handle-hover-ring);
        z-index: -1;
    }

    &.attach-mode {
        .attach-mode-shadow {
            transform: scale(1);
        }

        .handle {
            display: block;
        }
    }

    .app-size-medium & {
        top: 20px;
        left: 10px;

        .attach-mode-shadow {
            top: -6px;
            left: -6px;
            width: 20px;
            height: 20px;
        }
    }

    .app-size-small & {
        top: 20px;
        left: 10px;
        transform: translate(-4.5px, -4px);

        .attach-mode-shadow {
            top: -5px;
            left: -5px;
            width: 18px;
            height: 18px;
        }
    }
}
