@import '../../../../../scss/settings';

.DueDateTimePicker {
    width: 100%;
    height: 3.7rem;
    font-size: $font-size-ui-small;

    user-select: none;
    -webkit-touch-callout: none;

    .Icon.clock {
        width: 2.4rem;
        height: 2.4rem;
    }

    &.unset {
        .StyledButton.enable-time {
            display: flex;
            align-items: center;
            width: 100%;
            height: 3.7rem;
            padding: 0 0 0 0.8rem;

            border: 1px solid var(--ui-calendar-tertiary);
            background: none;

            color: var(--ui-calendar-secondary);

            text-decoration: underline;

            .Icon.clock {
                margin-right: 0.8rem;
            }
        }
    }

    &.set {
        display: flex;
        align-items: center;

        border: 1px solid var(--ui-calendar-tertiary);
        border-radius: 3px;
        box-sizing: border-box;

        .Icon.clock {
            margin: 0 0.4rem 0 0.8rem;
        }

        .inputs {
            display: flex;
            flex-grow: 1;
            align-items: baseline;
            justify-content: space-between;
        }

        .TimeInput {
            margin-right: 1rem;
        }

        .AnchorButton {
            margin-right: 1.6rem;
            text-decoration: underline;
        }
    }
}
