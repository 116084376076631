@import '../../scss/settings';
@import '../../scss/mixins/mini-scrollbar-mixin';

/* stylelint-disable selector-class-pattern, declaration-no-important */

@mixin square-corner-cover {
    position: absolute;
    height: 1px;
    border-right: 1px solid var(--ws-table-cell-borders);
    border-color: inherit;
    background-color: inherit;
    content: '';
}

.HotTable {
    // Prevents the contents of cell to shift if the rows above contains inline code
    --font-size-ui-small: 1.1rem;

    position: relative;

    &.is-selecting-cell .MilanoteCellRendererComponent {
        pointer-events: none;
    }

    // ---------- iPad Selection handles ---------- //

    body:not(.touch-device) & {
        .topLeftSelectionHandle,
        .bottomRightSelectionHandle,
        .topLeftSelectionHandle-HitArea,
        .bottomRightSelectionHandle-HitArea {
            display: none !important;
        }
    }

    .topLeftSelectionHandle {
        margin-top: -2px;
        margin-left: -2px;
        border: 1px solid var(--ws-table-cell-selection) !important;
        background: var(--ws-element-background-primary) !important;
        z-index: 1000;
    }

    .topLeftSelectionHandle-HitArea {
        margin-top: 5px;
        margin-left: 5px;
        z-index: 1001;
    }

    &.is-selected-last-col {
        .bottomRightSelectionHandle {
            margin-left: 13px;
        }

        .bottomRightSelectionHandle-HitArea {
            margin-left: 27px;
        }
    }

    .bottomRightSelectionHandle {
        margin-top: 2px;
        margin-left: 2px;
        border: 1px solid var(--ws-table-cell-selection) !important;
        background: var(--ws-element-background-primary) !important;
        z-index: 1000;
    }

    .bottomRightSelectionHandle-HitArea {
        margin-top: -3px;
        margin-left: -3px;
        z-index: 1001;
    }

    &.is-selected-last-row {
        .bottomRightSelectionHandle {
            margin-top: 14px;
        }

        .bottomRightSelectionHandle-HitArea {
            margin-top: 25px;
        }
    }

    // When the last row or column is selected, we will hide the bottom right selection handle, but the hit area
    // will still exist so that the user can still update the selection

    .Table.element-in-list & {
        &.is-selected-last-row,
        &.is-selected-last-col {
            .bottomRightSelectionHandle {
                display: none !important;
            }
        }

        &.is-selected-last-col .bottomRightSelectionHandle-HitArea {
            width: 15px !important;
        }

        &.is-selected-last-row .bottomRightSelectionHandle-HitArea {
            height: 15px !important;
        }
    }
}

.Table.remove-element-selection-border {
    --element-box-shadow: var(--element-shadow-elevation-1) !important;

    &::before {
        --element-border-width: 0;
    }
}

.HotTableLib {
    // this is the width of the actual rendered borders, which may not be 1px depending on the browser and zoom
    --negative-border-width: calc(0px - var(--table-cell-border-rendered-width));

    .touch-device .Table:not(.element-in-list) & {
        .ht_master {
            overflow: visible;

            .wtHolder {
                overflow: visible;
            }
        }
    }

    // Apply to all wtHolder elements
    .wtHolder {
        @include mini-scrollbar-mixin(var(--ui-scrollbar-track), var(--ui-scrollbar-thumb), 6px, false);

        // mini-scrollbar-mixin is set to scroll vertically, but in the case of table,
        // we want set it to scroll horizontally if needed.
        overflow-x: auto;
        overflow-y: hidden;
    }

    // This element contains the master table. In the master table, never show the header
    .ht_master {
        overflow: hidden;

        .wtHolder {
            width: auto !important;

            // Prevent showing white background when scrolling on touch devices
            overscroll-behavior: none;

            // For tables just on the canvas (not in a list) we should never see any scrollbar
            // This is necessary for things like resizing columns because handsontable rounds
            // up the width of the table to the nearest pixel, which can cause a small overflow
            .Table:not(.element-in-list) & {
                overflow: hidden;
            }
        }
        .wtHider {
            width: auto !important;
            // We never want to allow vertical scrolling within the table
            height: unset !important;
        }
        table.htCore {
            col.rowHeader {
                width: 0 !important;
            }
            thead {
                display: none;
            }
            tbody {
                td {
                    height: auto;
                    padding: 1rem;
                }
            }
        }
    }

    th,
    tr th {
        background: none;
    }

    input.ColumnInput {
        border: none;
        background: none;

        &:focus {
            outline: none;
        }
    }

    .handsontable tr {
        background-color: var(--ws-element-background-primary);
    }

    .handsontable th,
    .handsontable td {
        position: relative;
        border-color: var(--ws-table-cell-borders);
        background-color: var(--ws-element-background-primary);
        overflow: visible;
    }

    // Set the height of rows/cells without setting the ref headers
    // For more information about cell sizing, see https://app.milanote.com/1R8bq715wzSp8X/table-anatomy
    .handsontable td {
        padding: calc(0.5rem - 0.5px) calc(0.8rem - 2px) calc(0.5rem - 0.5px) calc(0.8rem - 2px) !important; // remove half of the border from top and bottom
    }

    // Border color customisations

    .handsontable tr:first-of-type td {
        border-top: 1px solid var(--ws-table-cell-borders);
    }

    .handsontable td:first-of-type,
    .handsontable th:first-child {
        border-left: 1px solid rgba(255, 255, 255, 0);
    }
    .handsontable td:last-of-type,
    .handsontable th:last-child {
        border-right: 1px solid rgba(255, 255, 255, 0);
    }

    .Table:not(.show-title) & {
        .handsontable tbody tr:first-of-type td {
            padding-top: calc(0.5rem - 2px) !important; // remove ~half of the border from top and bottom row
            padding-bottom: calc(0.5rem - 1px) !important;
            border-top: 1px solid rgba(255, 255, 255, 0);
        }
    }

    .Table:not(.show-caption) & {
        .handsontable tbody tr:last-of-type td {
            padding-bottom: calc(0.5rem - 1.5px) !important; // remove ~half of the border from top and bottom row
            border-bottom: 1px solid rgba(255, 255, 255, 0);
        }
    }

    // For cell reference borders, the position of right and bottom borders need to be adjusted
    .htBorders > div > .wtBorder:not(.current):not(.area):not(.fill) {
        &:nth-of-type(3) {
            margin-top: -1px;
        }

        &:nth-of-type(4) {
            margin-left: -1px;
        }
    }

    // Cell selection border
    .htBorders > div > .wtBorder.current,
    .htBorders > div > .wtBorder.area,
    .htBorders > div > .wtBorder.fill {
        background-color: var(--ws-table-cell-selection) !important;

        &.corner {
            // extra 1px added to margin since we are removing the border and using an outline instead
            // this is to avoid the slight gap outside the border that shows the
            // background color of the autofill handle
            margin-top: calc(var(--autofill-handle-position-offset-y) + 1px);
            margin-left: calc(var(--autofill-handle-position-offset-x) + 1px);
            border: none !important;
            outline: 1px solid var(--ws-element-background-primary) !important;

            // Adjustments for the last column to account for handsontable px rounding issue which causes selection
            // border to overflow when table in column
            // Only necessary if the last row is NOT selected since for last row the autofill handle
            // will be moved to the top left corner
            .Table.in-list-column .is-selected-last-col:not(.is-selected-last-row) & {
                margin-left: 0.5px;
            }
        }
    }

    // Adjustments for the last column to account for handsontable px rounding issue which causes selection border
    // to overflow when table in column
    .Table.in-list-column .is-selected-last-col & .htBorders > div > .wtBorder {
        &.current:nth-of-type(4) {
            margin-left: -0.5px;
        }

        &.area:nth-of-type(4) {
            margin-left: -1.5px;
        }
    }

    // Remove selection background
    .handsontable td.area {
        &:before {
            display: none;
        }
    }

    // Increase the area borders to 2px
    .htBorders > div > .wtBorder.fill,
    .htBorders > div > .wtBorder.area {
        // Top
        &:nth-of-type(1) {
            height: 2px !important;
        }

        // Left
        &:nth-of-type(2) {
            width: 2px !important;
        }

        // Bottom
        &:nth-of-type(3) {
            height: 2px !important;

            margin-top: -1px !important;
        }

        // Right
        &:nth-of-type(4) {
            width: 2px !important;
            margin-left: -1px;
        }
    }

    .handsontable a {
        color: var(--ws-element-anchor);
    }

    // background color/shadow when moving columns and rows

    .ht__manualRowMove--backlight {
        left: 0 !important;

        width: 100% !important;

        background: var(--ws-table-backlight) !important;
    }

    .ht__manualColumnMove--backlight {
        top: 0 !important;

        height: 100% !important;

        background: var(--ws-table-backlight) !important;
    }

    .ht__manualColumnMove--guideline {
        width: 2px !important;

        background: var(--ws-element-selection) !important;
    }

    .ht__manualRowMove--guideline {
        height: 2px !important;
        margin-top: -1px;
        background: var(--ws-element-selection) !important;

        &.guideline-top {
            margin-top: 0;
        }
    }

    // ---------- cover the ugly corners ----------
    // This is a bit of a hack to fix the corners while avoiding removing borders
    // and messing up the handsontable calculations/functionality
    .handsontable tbody {
        .square-corner-cover {
            @include square-corner-cover;
        }
        tr:not(:last-of-type) {
            // left side (single column)
            td:only-of-type:not(.area.highlight)::before {
                @include square-corner-cover;
                bottom: -1px;
                left: var(--negative-border-width);
                border-right: none;
                border-left: 1px solid var(--ws-table-cell-borders);
            }

            // right side
            td:last-of-type::after {
                @include square-corner-cover;
                right: var(--negative-border-width);
                bottom: -1px;
                height: 1px;
                border-right: none;
                border-left: 1px solid var(--ws-table-cell-borders);
            }
        }
        // left side (more than one column)
        tr:not(:first-of-type, :last-of-type) td:first-of-type:not(:only-of-type)::after {
            @include square-corner-cover;
            bottom: -1px;
            left: var(--negative-border-width);
        }
        // top row
        tr:first-of-type {
            // left side (because the first cell already uses after)
            td:first-of-type:not(:only-of-type):not(.area.highlight)::before {
                @include square-corner-cover;
                bottom: -1px;
                left: var(--negative-border-width);
            }
            // top of first row
            td:not(:last-of-type)::after {
                @include square-corner-cover;
                top: -1px;
                right: var(--negative-border-width);
            }
        }
        // bottom row
        tr:last-of-type td:not(:last-of-type)::after {
            @include square-corner-cover;
            right: var(--negative-border-width);
            bottom: -1px;
        }
    }

    // Hide handles while resizing columns
    &.col-resizing {
        .topLeftSelectionHandle,
        .bottomRightSelectionHandle,
        .topLeftSelectionHandle-HitArea,
        .bottomRightSelectionHandle-HitArea,
        .htBorders > div > .wtBorder.corner {
            display: none !important;
        }
    }
}

.MilanoteCellEditorComponent {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;

    .MilanoteCellEditorComponent-relative {
        position: relative;

        .handsontable-input-positioner {
            position: absolute;
            z-index: 100;

            .handsontable-input-holder {
                display: none;
                padding: calc(0.5rem + 0.5px) calc(0.8rem - 1px) calc(0.5rem + 0.5px) calc(0.8rem - 1px);
                background-color: var(--ws-element-background-primary);
                box-shadow: inset 0 0 0 2px var(--ws-table-cell-selection);
                pointer-events: all;
                opacity: 1;
                table-layout: fixed;

                // Adjustments for first row
                .Table:not(.show-title) & {
                    &.first-row {
                        padding-top: calc(0.5rem - 1px);
                        padding-bottom: 0.5rem;
                    }
                }

                // Adjustments for final row
                .Table:not(.show-caption) & {
                    &.last-row {
                        padding-bottom: calc(0.5rem - 0.5px);
                    }
                }

                &.htTop {
                    align-items: flex-start;
                }
                &.htMiddle {
                    align-items: center;
                }
                &.htBottom {
                    align-items: flex-end;
                }

                .handsontable-input {
                    width: 100%;
                    padding: 0;
                    background: none;
                    box-shadow: none;
                }
            }
        }
    }
}

.MilanoteCellRendererComponent,
.MilanoteCellEditorComponent {
    min-height: var(--line-height-element);
    color: var(--ws-element-primary);
    font-feature-settings: 'tnum';

    font-size: var(--font-size-ui-default);
    line-height: var(--line-height-element);
    overflow-wrap: break-word;

    .DraftEditor-editorContainer {
        border: 0;
        z-index: unset;

        .public-DraftEditor-content {
            padding: 0 !important;

            .public-DraftStyleDefault-block {
                margin-bottom: 0;
            }

            .DraftLinkAnchor {
                position: relative;
                z-index: calc(var(--z-index-table-cover) + 1);

                // Only time we don't want the link to be on top is when the cell is selected
                .Table.selected-single:not(.read-only) & {
                    z-index: 1;
                }
            }
        }
    }

    // We only want the pointer for links in the renderer, not the editor
    .handsontable-renderer .DraftLinkAnchor {
        cursor: pointer;
    }

    &.colored-background-light {
        color: var(--ws-table-cell-text-dark);
    }

    &.colored-background-dark {
        color: var(--ws-table-cell-text-light);
    }
}

.Table:not(.show-caption) {
    .handsontable tbody tr:last-of-type td {
        border-bottom-color: transparent !important;
    }
}

.handsontable.htContextMenu {
    z-index: var(--z-index-modal);
}

.table-auto-resize {
    display: inline-block;
    position: absolute;
    padding-right: 1rem; // This stops text from jumping down to the next line, although there is still a space at the end of the text
    transform: translate3d(0, 0, 0);
    font-family: var(--font-default), 'Helvetica', 'Arial', sans-serif;
    font-size: var(--font-size-element-default);
    white-space: pre;
    visibility: hidden;
}

.handsontable .manualRowResizer {
    display: none;
}
/* stylelint-enable selector-class-pattern, declaration-no-important */
