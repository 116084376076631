@import '../../scss/settings';

.CommentLine {
    display: flex;
    position: relative;

    background: var(--ws-comment-background);

    color: var(--ws-element-primary);
    font-size: $font-size-element-secondary;
    z-index: 1;

    .avatar {
        flex-grow: 0;
        flex-shrink: 0;

        // Padding due to the weird margin collapse CSS behaviour when there's no border / element above
        padding: 1.7rem 0 0 $element-padding-default;
    }

    .details {
        flex-grow: 1;

        // Forces long contents (such as URLs) to remain inside the element
        overflow: hidden;
    }

    .user-name {
        color: var(--ws-element-primary);
        font-weight: $font-weight-semibold;
    }

    .mousedown-handler {
        width: 100%;
    }
}
