@import '../../scss/settings';

.FullScreenErrorModal {
    .issues,
    p {
        text-align: center;
    }

    .details {
        text-align: left;
    }

    .app-size-mobile & {
        .ModalActionsList {
            margin-top: 3rem;
        }
    }
}
