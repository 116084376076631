@import '../../scss/settings';

.ImageDrawing {
    --ws-drawing-line-color: var(--palette-grey-900);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    pointer-events: none;

    .DrawingSvg,
    .svg-content,
    .DrawingSvg svg {
        width: 100%;
        height: 100%;
    }
}
