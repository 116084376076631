
// Override default Tiptap styles to shrink down to a preview size
.DocumentDetailViewTiptapEditor {
    font-size: 0.6em;

    .tiptap {
        padding: var(--element-padding-default);

        color: var(--ws-element-primary);

        code {
            --font-size-ui-small: 0.75em;
        }

        // Matching the styles of the Draft version, for now
        h1 {
            margin-bottom: 0.9rem;
            font-size: 1.2rem;
            line-height: 2rem;
        }

        h2 {
            font-size: 0.9rem;
            line-height: 1rem;

            &:first-child,
            &:last-child:first-child {
                margin-top: 1px;
            }
        }

        h1 + h2 {
            margin-top: 0;
        }

        p {
            margin-bottom: 0.5rem;
        }

        .small-text {
            line-height: 1rem;
        }

        blockquote {
            padding-top: 0;
            padding-bottom: 0;
        }

        pre {
            margin-top: 0.9rem;
            margin-bottom: 0.9rem;

            code {
                --font-size-ui-small: 0.6em;

                padding-top: 0;
                padding-bottom: 0;
            }
        }

        ul,
        ol {
            margin-left: 1rem;

            li p {
                margin-left: 0.7rem;
            }
        }

        ol {
            li::before {
                left: -1rem;
            }
        }

        // Prevent links from being clickable and using the accent colour
        a {
            pointer-events: none;
            color: var(--ws-element-primary);
        }
    }
}
