@import '../../scss/mixins/outer-border-mixin';

.ElementFolderIcon {
    --ws-board-icon-background-color: var(--ws-default-board-icon-background);
    position: relative;
    width: 80px;
    height: 72px;
    margin-top: -1px;

    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: var(--element-drop-shadow-elevation-1);

        .selection-stroke {
            stroke-width: 0;
        }
    }

 
    .icon {
        display: flex;
        position: relative;
        top: 55%;
        left: 50%;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    .Icon.StringIcon {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .drag-preview-shadow {
        top: 1.5rem;
    }

    &.background-light {
        .icon {
            filter: grayscale(100%) brightness(0) invert(100%) brightness(23%);
        }
    }

    &.background-dark {
        .icon {
            filter: grayscale(100%) brightness(0) invert(100%);
        }
    }

    .Clone.marquee-selected:not(.marquee-remove-selected) &,
    .selected:not(.marquee-remove-selected):not(.Column.selected) &,
    .marquee-selected:not(.marquee-remove-selected):not(.Column.marquee-selected) &,
    .line-edge-can-drop:not(.Column) & {
        .selection-stroke {
            stroke-width: 1;
        }
    }

    .remote-selected:not(.Column) & {
        --ws-document-icon-selection-border-color: var(--remote-user-color);

        .selection-stroke {
            stroke-width: 1;
        }
    }

    .editing.selected & {
        --ws-document-icon-selection-border-color: var(--ws-element-edit-selection);
    }

    .in-list-column &,
    .element-in-list & {
        width: 60px;
        height: 60px;
    }
}
