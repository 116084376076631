@import '../../scss/settings';

.TitleDraftJsEditor {
    color: var(--ws-element-primary);
    font-family: $font-element-heading;
    font-size: $font-size-element-heading;
    font-weight: $font-weight-semibold;
    line-height: $element-line-height-heading;

    .public-DraftEditorPlaceholder-hasFocus,
    .public-DraftEditorPlaceholder-root {
        color: var(--ws-task-title-placeholder);
    }

    .public-DraftEditorPlaceholder-root,
    .public-DraftEditor-content {
        padding: calc(#{$element-padding-default} - 0.5rem) $element-padding-default calc(#{$element-padding-default} - 0.5rem) $element-padding-default;
    }

    .app-size-small & {
        .public-DraftEditorPlaceholder-root,
        .public-DraftEditor-content {
            padding: 14px $element-padding-default 6px $element-padding-default;
        }
    }
}
