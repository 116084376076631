@import '../../../scss/settings';
@import 'assignment/TaskPill';

.TaskPillsPanel {
    display: flex;
    flex-wrap: wrap;

    margin: -0.2rem 0 0.9rem 0;
    padding-right: 2.8rem - $task-pill-margin-right;
    padding-left: 0.4rem;

    line-height: 0;

    .task-pill-container {
        overflow: hidden;

        > .Button {
            max-width: 100%;
        }

        .small & {
            margin: 0 0 0.6rem 0;
        }
    }

    .TaskAddReactionButton.ElementReactionAddButton,
    .task-reaction-pill-container,
    .task-pill-container {
        margin: 0.4rem 0 0 0;
    }

    .app-size-medium & {
        .TaskAddReactionButton.ElementReactionAddButton,
        .task-reaction-pill-container,
        .task-pill-container {
            margin-top: 4px;
        }
    }

    .app-size-small & {
        margin: -3px 0 8px 0;
        padding-left: 3px;

        .TaskAddReactionButton.ElementReactionAddButton,
        .task-reaction-pill-container,
        .task-pill-container {
            margin-top: 4px;
        }
    }
}
