.will-indent,
.will-dedent {
    position: relative;
}

.will-indent::after,
.will-dedent::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    height: 2px;

    content: '';
}

.will-indent::after {
    background: limegreen;;
}

.will-dedent::before {
    bottom: -2px;
    background: red;
}

