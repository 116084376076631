@import '../../../../../scss/settings';

.FloatingPanel.ArrangementPopup {
    padding-top: calc(2.4rem - 5px);
    padding-right: calc(2.4rem - 5px);

    .popup-content {
        display: flex;
        flex-direction: column;

        .tools {
            line-height: 0;
        }

        .Button {
            margin: 5px 5px 0 0;
            line-height: 0;

            &:disabled {
                background: none;
                cursor: initial;
                opacity: 0.5;
            }
        }
    }
}
