.CheckoutErrorModal {
    width: var(--checkout-modal-default-width);

    .error-message {
        margin-top: 2rem;
        font-weight: 600;
    }

    .support-message {
        margin-top: 2rem;
    }

    .checkout-modal-header h1 {
        color: var(--error-color);
    }
}
