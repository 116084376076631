@import '../../../../../../scss/settings';


.MobileIconModeList {
    --icon-min-gap: 1rem;
    --icon-button-size: 7.2rem;

    .title {
        display: flex;
        align-items: flex-end;
        padding: 1.5rem 0;
        font-size: 16px;
        font-weight: 600;

        span::first-letter {
            text-transform: capitalize;
        }
    }

    .IconListEmpty {
        color: var(--ui-foreground-secondary);
    }

    .IconList ul {
        padding: 0;

        li {
            justify-content: start;
        }
    }

    .IconListResults {
        display: grid;
        grid-template-columns: repeat(auto-fill, var(--icon-button-size));
        justify-content: space-between;
        gap: var(--icon-min-gap);

        .IconButton {
            width: var(--icon-button-size);
            height: var(--icon-button-size);

            .ElementIcon {
                --element-icon-size: calc(var(--icon-button-size) * 0.53333);

                svg {
                    overflow: visible;
                }
            }
        }
    }
}

// Ensure the results section of the recommended category is always the same height
/* stylelint-disable-next-line selector-id-pattern */
#icon-mode-category-___default___ {
    .results {
        height: 154px;
    }
}
