@import '../../../../../../scss/settings';


.ToolbarTool.MobileIconTool {
    .icon-container {
        display: flex;
        position: relative;

        align-items: center;
        justify-content: center;

        width: var(--tool-icon-size);
        height: var(--tool-icon-size);

        border-radius: var(--tool-icon-border-radius);
        background: var(--ui-toolbar-tool-background);

        overflow: hidden;

        .ElementIcon {
            --element-icon-size: 2.6rem;
            --element-icon-color: var(--ui-tool-path);

            .Icon {
                width: 26px;
                height: 26px;

                path {
                    fill: var(--element-icon-color)
                }
            }
        }

        .IconToolImage {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
