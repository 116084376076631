@import '../../../scss/settings';
@import '../../elementBox';
@import '../../elementColoredBackgroundMixin';
@import '../../../list/listElementDraggingMixin';

.Annotation {
    @include element-box;
    @include list-element-dragging;

    padding: 0;

    .app-size-small & {
        .ElementResizeHandle {
            bottom: -1px;
        }
    }

    &.transparent:hover:not(.selected):not(.remote-selected),
    .WorkspaceDragLayer &.transparent:not(.selected):not(.remote-selected) {
        &:before {
            border: none;
        }

        .ElementResizeHandle {
            display: none;
        }
    }

    &.colored-background-dark {
        --ws-drawing-line-color: #{$white};
    }

    &.colored-background-light {
        --ws-drawing-line-color: var(--palette-grey-900);
    }
}
