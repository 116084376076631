@import '../../../../../../../scss/settings';

.ToolbarPopup.color-popup {
    width: 20rem;
    border-radius: $ui-border-radius-default;

    .popup-content {
        overflow: visible;
    }
}

.DesktopColorPopup {
    .custom-color-picker-input {
        position: absolute;
        top: auto;
        left: -10000px;
        width: 1px;
        height: 1px;

        outline: none;
        overflow: hidden;

        &:focus {
            outline: none;
        }
    }

    &.is-open .custom-color-picker-input {
        top: 18px;
        left: 20px;
        width: 40px;
        height: 40px;

        opacity: 0;
    }
}
