@import '../../../scss/settings';

.PopupTextInput {
    display: block;
    width: 100%;

    border: 1px solid var(--ui-search-border-unfocused);
    border-radius: 0.3rem;
    outline: 0;

    background: var(--ui-modal-background-primary);

    color: var(--ui-modal-foreground-primary);
    font-family: var(--font-default);
    font-size: var(--font-size-ui-small);

    &.active,
    &:focus {
        border: 1px solid var(--ui-foreground-secondary);
    }

    &::placeholder {
        color: var(--ui-search-placeholder);
    }
}
