@import '../../../../../../../../scss/settings';

.IconTabContentImagePicker {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .instructions {
        color: var(--color-neutral-700);
        font-size: $font-size-ui-small;
    }
}
