@import '../../../scss/settings';

.ToolbarPopup.MorePopup {
    width: auto;
    padding: 0;
    background: var(--ws-canvas-background);
}

.MorePopupContents {
    display: flex;
    flex-direction: row;
    padding: 4px 0 17px 17px;

    .toolGroup {
        padding-right: 16px;
    }

    .Tool {
        line-height: 1em;
    }

    .RichTextTool {
        margin-bottom: 4px;
        margin-left: 4px;
        padding-right: 5px;
    }

    //.toolbar-size-medium & {
    //    padding: 2px 0 17px 15px;
    //
    //    .toolGroup {
    //        padding-right: 16px;
    //    }
    //}
    //
    //.toolbar-size-small & {
    //    padding: 3px 0 15px 12px;
    //
    //    .toolGroup {
    //        padding-right: 12px;
    //    }
    //}
}
