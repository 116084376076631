@import '../../../../scss/settings';
@import '../../../../scss/mixins/outer-border-mixin';

.DrawingEditorStrokeSizePopup {
    width: auto;
    padding: 2rem;
    border-radius: $ui-border-radius-default;

    .stroke-sizes {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 0.8rem;
    }
}

.DrawingEditorStrokeSizeTool {
    > .DrawingEditorStrokeSizeButton {
        margin-right: 0.8rem;
    }
}

.DrawingEditorStrokeSizeButton {
    display: flex;
    position: relative;

    align-items: center;
    justify-content: center;

    width: 32px;
    height: 28px;
    padding: 6px;

    border-radius: 0.4rem;

    background: var(--ui-toolbar-tool-background);

    .Icon path {
        fill: var(--ui-modal-foreground-primary);
    }

    &.square {
        width: 32px;
        height: 32px;
    }

    &.active {
        @include outer-border-full(2px, var(--ws-element-selection), 0.8rem);

        &:before {
            top: -4px;
            right: -4px;
            bottom: -4px;
            left: -4px;
        }
    }

    // Make the hit area flow into the next button so the cursor doesn't flicker
    &::after {
        position: absolute;
        top: 0;
        right: -0.4rem;
        bottom: 0;
        left: -0.4rem;
        content: '';
    }

    .app-size-small & {
        width: 28px;
        height: 25px;

        padding: 5px;

        &.square {
            width: 28px;
            height: 28px;
        }
    }
}
