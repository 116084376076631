@import '../../../../scss/settings';
@import '../../../../scss/mixins/outer-border-mixin';

$task-pill-margin-right: 0.4rem;

.TaskPill {
    @include outer-border-full(1px, var(--color-neutral-700));

    display: flex;
    position: relative;
    align-items: center;
    height: 19px;

    margin-right: $task-pill-margin-right;
    padding: 0.1rem 0.3rem 0.2rem;

    border: 1px solid var(--ws-element-background-primary);
    border-radius: 3px;

    outline: 0;
    color: var(--color-neutral-700);
    font-size: 1.1rem;

    // Ensures the click events will be handled by this DOM node
    z-index: 1;
    user-select: none;
    -webkit-touch-callout: none;

    .pill-contents {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &:before {
        border-radius: 3px;
    }

    &.active {
        @include outer-border-full(1px, var(--ws-element-primary));

        color: var(--ws-element-primary);

        &:before {
            border-radius: 3px;
        }
    }

    &.populated {
        background: var(--color-neutral-400);
        color: var(--color-neutral-800);

        &:before {
            content: none;
        }

        &.active {
            color: var(--ws-element-primary);

            &:before {
                border-color: var(--ws-element-primary);
                content: '';
            }
        }
    }

    .popup-target {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }

    .app-size-medium & {
        height: 17px;
    }

    .app-size-small & {
        height: 15px;
        padding: 1px 2px 2px;
    }
}
