@import '../../../../../../scss/settings';

.CustomColorPickerContainer {
    padding: 1.6rem 0 0 0;

    .custom-color-picker {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 3.2rem;

        padding-right: 2rem;
        padding-left: 1rem;

        border: 1px solid var(--ui-divider-on-secondary);
        border-radius: 4px;

        color: var(--ui-foreground-secondary);
        font-size: $font-size-ui-small;

        .color-wheel {
            width: 2.4rem;
            height: 2.4rem;

            background: url('color-wheel-icon.png');
            background-size: 100% auto;
        }

        .color-picker-text {
            user-select: none;
            -webkit-touch-callout: none;
        }
    }

    .app-size-medium & {
        .custom-color-picker {
            .color-wheel {
                width: 20px;
                height: 20px;
            }

            .color-picker-text {
                margin-bottom: -1px;
            }
        }
    }

    .app-size-small & {
        .custom-color-picker {
            .color-wheel {
                width: 18px;
                height: 18px;
            }
        }
    }
}
