@import '../../../scss/settings';

.FileMediaPlayerDuration {
    justify-content: flex-end;

    padding-right: 1rem;
    padding-left: 2rem;

    color: var(--color-neutral-700);
    font-size: $font-size-ui-xsmall;
    text-align: right;

    white-space: nowrap;

    > span {
        font-family: $font-monospace-name;
    }
}
