@import '../../../scss/settings';

.FilePreview {
    position: relative;
    background: var(--ws-element-background-primary);
}

.FilePreviewImage {
    // Prevent browser drag preview in Safari
    .image-node {
        pointer-events: none;
    }
}
