@import '../../scss/settings';

.CardOverflowOverlay {
    position: absolute;
    right: 1px;
    bottom: 0;
    left: 1px;

    height: 6rem;
    background: linear-gradient(
        to bottom,
        var(--ws-element-background-primary-opacity-0),
        var(--ws-element-background-primary) 80%
    );

    opacity: 0;

    z-index: 1;
    pointer-events: none;

    &.show {
        opacity: 1;
    }

    .resizing & {
        transition: opacity 0.2s linear;
    }

    .Column & {
        &:after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            border-bottom: 1px solid var(--ws-element-border-soft);
            content: '';
        }
    }
}
