@import '../../../scss/settings';
@import '../../../scss/mixins/mini-scrollbar-mixin';

.FloatingPanel.ReactionPopup {
    width: 57.2rem;

    padding: 2.4rem;
    padding-right: 1rem;

    text-align: left;

    .popup-content {
        display: flex;
    }

    .PopupModeMenu {
        width: 22rem;
        margin-right: 2rem;
    }

    .emoji-list {
        @include mini-scrollbar-mixin(var(--ui-background-primary), var(--color-neutral-600), 0.8rem);

        .emoji-list-row {
            &.title {
                display: flex;
                align-items: center;

                h2 {
                    margin-top: -3px;
                    font-size: $font-size-element-heading;
                    font-weight: $font-weight-semibold;
                }
            }
        }
    }

    .no-results {
        display: flex;
        align-items: center;
        justify-content: center;

        .no-results-text {
            display: flex;
            align-items: center;
            justify-content: center;

            color: var(--color-neutral-800);

            .EmojiImage {
                margin-top: -1px;
                margin-left: 0.8rem;
            }

            .Spinner {
                width: 6rem;
                height: 6rem;
            }
        }
    }
}
