@import '../../../scss/settings';

.BoardStatus {
    display: flex;
    position: absolute;
    right: -2px;
    bottom: -3px;

    // Force the shortcut icon to sit above the selected border
    z-index: 1;

    .TooltipSource,
    .Icon {
        width: 2em;
        height: 2em;

        &:nth-of-type(n + 2) {
            margin-left: 2px;
        }
    }

    &.multiple-icons {
        .TooltipSource,
        .Icon {
            width: 1.6em;
            height: 1.6em;
        }
    }

    // For mobile view / linear document mode
    .BoardIcon.small & {
        .TooltipSource,
        .Icon {
            width: 14px;
            height: 14px;
        }

        &.multiple-icons {
            .TooltipSource,
            .Icon {
                width: 12px;
                height: 12px;
            }
        }
    }

    .ListElement & {
        .TooltipSource,
        .Icon {
            width: 17px;
            height: 17px;

            &:nth-of-type(n + 2) {
                margin-left: 1px;
            }
        }

        &.multiple-icons {
            .TooltipSource,
            .Icon {
                width: 14px;
                height: 14px;
            }
        }
    }

    .app-size-medium .ListElement & {
        top: 41px;

        .TooltipSource,
        .Icon {
            width: 15px;
            height: 15px;
        }

        &.multiple-icons {
            top: 43px;

            .TooltipSource,
            .Icon {
                width: 13px;
                height: 13px;
            }
        }
    }

    .app-size-small .ListElement & {
        top: 36px;

        .TooltipSource,
        .Icon {
            width: 14px;
            height: 14px;
        }

        &.multiple-icons {
            top: 38px;

            .TooltipSource,
            .Icon {
                width: 12px;
                height: 12px;
            }
        }
    }
}

.board-status-tooltip {
    font-size: $font-size-ui-small;
    font-weight: normal;
    line-height: $font-size-ui-small;

    .app-size-large & {
        margin-top: 1px;
    }

    .app-size-medium & {
        margin-top: 2px;
    }

    .app-size-small & {
        margin-bottom: 2px;
    }
}
