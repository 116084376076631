@import '../../scss/settings';

.CommentLine .ActivityIndicator {
    display: inline-block;
    top: auto;
    right: auto;
    margin-top: -1px;

    .TooltipSource {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
