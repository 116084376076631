@import '../../../scss/settings';

.DocumentStatus {
    position: absolute;
    right: -2px;
    bottom: -2px;
    z-index: 3;

    .TooltipSource {
        display: flex;
    }

    .document-clone-tooltip {
        --ui-tooltip-background-color: var(--ui-tooltip-element-tip-background-color);
        --ui-tooltip-color: var(--ui-tooltip-element-tip-color);
    }
}

.document-clone-message {
    display: flex;
    align-items: center;

    padding: 5px;
    font-size: var(--font-size-element-messaging);
    font-weight: 400;

    .Icon.clone {
        margin-right: 10px;

        path {
            fill: var(--ui-tooltip-element-tip-color);
        }
    }
}
