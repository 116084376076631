@import '../scss/settings';

.WorkspaceInitContainer {
    height: 100%;

    // Need this to exist outside of the rest of the DOM hierarchy to ensure it sits above modals too
    #mention-mount-point {
        position: fixed;
        z-index: calc(var(--z-index-modal) + 1);
    }
}

.WorkspaceWrapper {
    height: 100%;

    &.show-sidebar {
        display: flex;
    }
}

.Workspace {
    // TODO - make this work with a single row header as well (e.g. publish preview mode, iFrames, etc).
    //   This isn't currently used in those circumstances however
    --app-header-height: calc(
        var(--app-header-tools-section-height) + var(--app-header-title-section-height) +
            var(--app-header-title-section-margin-top)
    );

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    transition: filter 0.25s linear;
    overflow: hidden;

    -webkit-tap-highlight-color: transparent;

    .AppHeader {
        flex-shrink: 0;
    }

    .WorkspacePositioner {
        display: flex;
        position: relative;
        flex-grow: 1;
        min-height: 0;

        .Toolbar {
            flex-shrink: 0;
        }

        .Main {
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 0;
            min-height: 0;
        }
    }

    &.is-dragging {
        pointer-events: none;
    }

    &.overlay-open,
    .modal & {
        filter: grayscale(40%);
    }

    // On the root board the app-header doesn't have the current board title
    &.workspace-root-board {
        --app-header-height: var(--app-header-tools-section-height);
    }

    .no-transition & {
        transition: none;
    }

    @media (max-width: $size-mobile) {
        .modal & {
            overflow: hidden;
        }
    }
}
