// stylelint-disable selector-class-pattern
@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';
@import '../../scss/mixins/mini-scrollbar-mixin';
@import '../elementBox';
@import '../../list/listElementDraggingMixin';

$z-index-overflow-gradient: 2;

$thumb: linear-gradient(
    to right,
    transparent 33%,
    var(--ws-comment-scroll-thumb) 33%,
    var(--ws-comment-scroll-thumb) 66%,
    transparent 66%
);

// For the colours popup

.ColorToolIcon.COMMENT_THREAD,
.ColorButton.COMMENT_THREAD {
    --ws-element-background-primary: var(--ws-comment-background);

    &.collapsed {
        --ws-no-color-button-border-color: var(--palette-purple-900);
        --ws-element-background-primary: var(--palette-purple-900);
    }
}

.CommentThread {
    @include element-box;
    @include list-element-dragging;

    --element-border-color-in-list: var(--ws-comment-border);

    padding: 0.4rem 0;

    background: var(--ws-comment-background);
    color: var(--ws-element-primary);
    font-size: var(--font-size-element-default);
    line-height: 1.8rem;

    .ColorBar {
        z-index: $z-index-overflow-gradient + 1;
    }

    &.hide-comments {
        display: none;
    }

    .ElementUserTag {
        font-size: var(--font-size-element-secondary);
    }

    .QuickLineCreationTool {
        &:hover {
            .arrow-container {
                .Icon.quick-line-arrow {
                    transform: translate3d(0, 3px, 0);
                }
            }
        }
    }

    .comment-thread-content-container {
        @include mini-scrollbar-mixin(none, $thumb, 12px, false);

        min-height: 6rem;

        &:after,
        &:before {
            position: absolute;
            right: 1.6rem;
            left: 0;

            height: 2rem;

            content: '';
            z-index: $z-index-overflow-gradient;
        }

        &:before {
            top: 0;
            background: linear-gradient(
                to bottom,
                var(--ws-comment-background),
                var(--ws-comment-overflow-color-2) 60%,
                var(--ws-comment-overflow-color-3)
            );
        }

        &:after {
            bottom: 0;
            background: linear-gradient(
                to top,
                var(--ws-comment-background),
                var(--ws-comment-overflow-color-2) 60%,
                var(--ws-comment-overflow-color-3)
            );
        }
    }

    .ElementResizeHandle {
        bottom: -4px;
    }

    .resize-handle-background {
        position: absolute;
        right: 0;
        bottom: 1px;

        transition: opacity 0.1s linear;
        opacity: 0;

        z-index: 1;

        &::after {
            position: absolute;
            right: 2px;
            bottom: 0;
            border-width: 0.8rem;
            border-style: solid;
            border-color: transparent;
            border-right-color: var(--ws-comment-background);
            border-bottom-color: var(--ws-comment-background);
            content: '';
        }

        .Column.selected &,
        .Element:hover .Column div & {
            opacity: 0;
        }

        .resizing &,
        .selected &,
        .Element:hover &,
        .Element:hover .Column .selected &,
        .Element:hover .Column .Element:hover & {
            opacity: 1;
        }
    }

    &.has-comments {
        .comment-thread-content-container {
            min-height: 7.8rem;
        }

        &.editable {
            .comment-thread-content-container {
                min-height: 10rem;
            }
        }
    }

    .ListElement & {
        .comment-thread-content-container {
            &:before {
                top: 1px;
                left: 1px;
            }

            &:after {
                bottom: 1px;
                left: 1px;
            }
        }
    }

    .app-size-small & {
        .ElementResizeHandle {
            right: 1px;
            bottom: -1px;
        }
    }

    .platform-ipad & {
        .ElementResizeHandle {
            right: -10px;
            bottom: -12px;
        }
    }

    .platform-ipad.app-size-small & {
        .ElementResizeHandle {
            right: -10px;
            bottom: -10px;
        }
    }
}
