@import '../../../scss/settings';

.ReactionPopupSkinTonePicker {
    position: relative;
    margin-bottom: 1rem;

    .select-tone-button {
        display: flex;
        position: absolute;

        top: 0;
        left: 0;

        align-items: center;
        justify-content: flex-start;

        width: 100%;
        height: 100%;

        padding-top: 0.4rem;
        padding-left: 4rem;

        transition: transform 0.3s ease-in-out, opacity 0.25s linear;

        color: var(--ui-foreground-tertiary);
        font-size: var(--font-size-ui-small);
        text-decoration: underline;

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    .tone-list {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;

        width: 100%;
        height: 32px;

        li {
            position: absolute;
            top: 0;

            transition: transform 0.25s ease-in-out, opacity 0.1s linear;

            .ReactionPopupEmojiButton {
                margin: 0;
                overflow: hidden;
            }
        }
    }

    &.closed {
        .ReactionPopupEmojiButton {
            background: transparent;
        }
    }
}
