@import '../../../../../scss/settings';

.TableCellAlignmentToolbarPopup {
    width: 15rem;
    padding: 0;

    ul.cell-alignment-options {
        display: flex;
        justify-content: space-between;
        padding: 2rem;


        .Button.cell-alignment-options-button {
            display: flex;
            margin-right: 1rem;

            svg {
                width: 3rem;
                height: 3rem;
            }

            rect {
                fill: var(--ui-toolbar-tool-background);
            }
        }

        .cell-alignment-options-button.active {
            rect {
                fill: var(--ui-tool-rich-text-active-background);
            }
            path {
                fill: var(--ui-tool-rich-text-active);
            }
        }
    }

    .divider {
        border-top: 1px solid var(--ws-section-divider-color);
    }
}
