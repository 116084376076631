@import '../../scss/settings';

// For the colours popup
.Line,
.LINE {
    --ws-element-background-primary: var(--ws-default-line-color);
    --color-element-grey: var(--ws-line-color-grey);
}
.ColorToolIcon.LINE,
.ColorButton.LINE {
    --ws-no-color-button-border-color: var(--ws-default-line-color);
}

// For an explanation of the line selection path issue see:  https://app.milanote.com/1CIvbO11mTRA4H
.Line {
    position: relative;

    // This is used by the selectionMarqueeSelectedElementFinder to determine whether measurements relate to a line
    width: 12px;
    height: 13px;
    font-size: 0;

    overflow: visible;

    .svg-line {
        position: relative;
        overflow: visible;
        z-index: 1;
    }

    .line-path {
        stroke: var(--ws-line-color);
        fill: none;
    }

    .arrow {
        stroke: none;
        fill: var(--ws-line-color);
    }

    .line-hit-area {
        z-index: 2;
        stroke: transparent;
        stroke-width: 20;
        fill: none;
    }

    .ElementUserTag {
        right: auto;
        left: -1.4rem;
        z-index: 2;
    }

    .disable-drag & {
        .svg-line {
            pointer-events: none;
        }

        // We need to add this to ensure that drag and drop is possible
        // Otherwise the drag will start but immediately get followed by a drop (I think the dragged element
        // gets the .disable-drag class added again immediately after the drag starts)
        // I wonder if this is what's causing issues selecting things underneath lines?
        .line-hit-area {
            pointer-events: stroke;
        }
    }

    &.invisible {
        visibility: hidden;
        pointer-events: none;

        .line-hit-area {
            pointer-events: none;
        }
    }

    .board-preview & {
        .svg-line {
            width: 12px;
            height: 13px;
        }
    }
}
