@import '../../scss/settings';

.DownloadButton {
    .download-button {
        color: var(--ws-element-tertiary);
    }

    .downloading {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        height: 1.6rem;

        .ProgressBar {
            width: 20rem;
            height: 0.8rem;

            border-radius: 0.4rem;

            .bar {
                border-radius: 0.4rem;
            }
        }

        .progress-message {
            margin-left: 1.5rem;
            font-size: $font-size-ui-xsmall;
            white-space: nowrap;
        }
    }
}
