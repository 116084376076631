@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';

.ElementReactionPill {
    display: flex;
    position: relative;

    flex-direction: row;
    align-items: center;

    min-width: 3.5rem;
    height: 2rem;

    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
    padding: 2px 0.3rem 3px 0.3rem;

    border-radius: 3px;

    background: var(--ws-reaction-pill-background);
    color: var(--ws-reaction-pill-color);
    font-size: 1.1rem;

    .EmojiImage {
        flex-shrink: 0;
    }

    .count {
        min-width: 1rem;
        margin-left: 0.2rem;

        font-weight: $font-weight-semibold;
    }

    &:disabled {
        background: var(--ws-reaction-pill-background);
        opacity: 1;
    }

    &.active {
        background: var(--ws-reaction-pill-active-background);
        color: var(--ws-reaction-pill-active-color);
    }

    .Main.board-preview & {
        padding-top: 1px;
        padding-bottom: 4px;
    }
}
