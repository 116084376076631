@import '../../scss/settings';

.ElementResizeHandle {
    position: absolute;
    right: 0;
    bottom: -2px;
    padding: 10px 0 0 10px;

    transform: translate(calc(-1 * var(--element-border-offset)), calc(-1 * var(--element-border-offset)));

    transition: opacity 0.1s linear, transform 0.1s linear;

    cursor: nwse-resize;

    opacity: 0;

    z-index: var(--z-index-element-resize-handle);

    pointer-events: none;

    .Icon {
        g {
            fill: var(--color-neutral-700);
        }
    }

    .Column.selected &,
    .Element:hover .Column div & {
        opacity: 0;
        pointer-events: none;
    }

    .resizing.Column > &,
    .resizing:not(.Column) &,
    .selected &,
    .Element:hover &,
    .Element:hover .Column .selected &,
    .Element:hover .Column .Element:hover & {
        opacity: 1;
        pointer-events: auto;
    }

    .in-list-column & {
        cursor: ns-resize;
    }

    .app-size-small & {
        bottom: -1px;
        padding: 8px 0 0 8px;

        line-height: 10px;

        .Icon {
            width: 12px;
            height: 12px;
        }
    }

    .Element .marquee-remove-selected &,
    .selection-marquee-active .Element:hover & {
        opacity: 0;
        pointer-events: none;
    }

    // Increase the resize hit target for iPads
    .platform-ipad & {
        right: -10px;
        bottom: -10px;

        padding: 20px 0 0 20px;

        pointer-events: auto;

        .Icon {
            position: relative;
            right: 10px;
            bottom: 8px;
        }
    }

    .platform-ipad.app-size-small & {
        .Icon {
            bottom: 9px;
        }
    }

    .minimum-width & {
        padding: 0;
    }
}
