@import '../../../scss/settings';

.FileUploadProgress {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;

    font-size: $font-size-element-default;

    .ProgressBar {
        width: 20rem;
        height: 0.8rem;

        border-radius: 0.4rem;

        .bar {
            border-radius: 0.4rem;
        }
    }

    .progress-message {
        margin-left: 1.5rem;
        font-size: $font-size-ui-xsmall;
        white-space: nowrap;
    }
}
