@import '../../../scss/settings';
@import '../../../scss/mixins/mini-scrollbar-mixin';

$list-prefix-spacing: 2.7rem;
$max-depth: 4;

.RichTextEditor {
    color: var(--ws-element-primary);
    letter-spacing: $letter-spacing-body-text;
    line-height: $element-line-height;

    .public-DraftEditorPlaceholder-root,
    .public-DraftEditor-content {
        padding: 2.4rem $element-padding-default 1.4rem $element-padding-default;

        .app-size-medium & {
            padding: 22px $element-padding-default 12px $element-padding-default;
        }

        .app-size-small & {
            padding: 19px $element-padding-default 11px $element-padding-default;
        }
    }

    .public-DraftStyleDefault-ol,
    .public-DraftStyleDefault-ul {
        margin: 0;
    }

    .public-DraftStyleDefault-listLTR {
        margin-left: 0;
        padding-left: $list-prefix-spacing;

        @for $i from 1 through $max-depth {
            &.public-DraftStyleDefault-depth#{$i} {
                margin-left: $i * $list-prefix-spacing;
            }
        }

        &:before {
            position: absolute;
            left: 0;
            width: $list-prefix-spacing;

            text-align: left;
        }

        .public-DraftStyleDefault-block {
            // FIXME Try to use variables here if possible e.g. $element-paragraph-spacing-default
            margin-bottom: 1rem;
        }

        &.public-DraftStyleDefault-unorderedListItem {
            list-style-type: none;

            @for $i from 1 through $max-depth {
                &.public-DraftStyleDefault-depth#{$i}:before {
                    content: '\2022';
                }
            }

            &:before {
                top: -1px;
                left: 1px;
            }
        }
    }

    .public-DraftStyleDefault-block {
        // FIXME Try to use variables here if possible e.g. $element-paragraph-spacing-default
        margin-bottom: 1rem;
        font-size: 1em;
    }

    .small-text {
        font-size: em(12px);
        line-height: 2rem;
    }

    h1,
    h2 {
        font-family: $font-element-heading;
        font-size: $font-size-element-heading;
        letter-spacing: $letter-spacing-heading;

        // Prevents italic headings from having bold parts, which is confusing because normal headings
        // can't be bold.  We must use important here because draft uses inline styles.
        span {
            font-weight: $font-weight-semibold !important; // stylelint-disable-line declaration-no-important
        }

        .public-DraftStyleDefault-block {
            margin-bottom: 0;
        }
    }

    h1 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        font-size: var(--font-size-element-heading-one);
        line-height: 3rem;

        // This allows headers further down the page to have more padding above, but
        // not the first heading line
        &:first-child {
            margin-top: -0.5rem;
        }

        // When the only text in a card is a header, remove some margins to keep it the same size
        // as a normal card
        &:last-child:first-child {
            margin-top: -0.6rem;
            margin-bottom: 0.6rem;
        }

        // This prevents the size of the card from jumping when a header is created on the last line
        &:last-child {
            margin-bottom: 0.5rem;
        }
    }

    h2 {
        margin-top: 1.6rem;
        margin-bottom: 0.9rem;
        line-height: var(--line-height-heading);

        &:first-child {
            margin-top: -0.4rem;
        }

        // When the only text in a card is a header, remove some margins to keep it the same size
        // as a normal card
        &:last-child:first-child {
            margin-top: -0.3rem;
            margin-bottom: 0.8rem;
        }

        .app-size-medium & {
            margin-top: 14px;
            margin-bottom: 9px;
            line-height: 22px;

            &:first-child {
                margin-top: -4px;
            }

            &:last-child:first-child {
                margin-top: -3px;
                margin-bottom: 8px;
            }

            // This prevents the size of the card from jumping when a header is created on the last line
            &:last-child {
                margin-bottom: 9px;
            }
        }

        .app-size-small & {
            margin-top: 12px;
            margin-bottom: 8px;
            line-height: 20px;

            &:first-child {
                margin-top: -4px;
            }

            &:last-child:first-child {
                margin-top: -3px;
                margin-bottom: 7px;
            }

            // This prevents the size of the card from jumping when a header is created on the last line
            &:last-child {
                margin-bottom: 8px;
            }
        }
    }

    // Ensure collapsed margins fall on exact grid points
    h1 + h1 {
        margin-top: 2rem;
    }

    h1 + h2 {
        margin-top: -0.4rem;

        .app-size-medium & {
            margin-top: -4px;
        }

        .app-size-small & {
            margin-top: -4px;
        }
    }

    h2 + h2 {
        margin-top: 1.5rem;
    }

    h2 + h1 {
        margin-top: 1.4rem;

        .app-size-medium & {
            margin-top: 13px;
        }

        .app-size-small & {
            margin-top: 12px;
        }
    }

    .checklist-ul + *:not(h2):not(ul):not(ol),
    .public-DraftStyleDefault-ol + *:not(h2):not(ul):not(ol),
    .public-DraftStyleDefault-ul + *:not(h2):not(ul):not(ol) {
        // FIXME Try to use variables here if possible e.g. $element-paragraph-spacing-default
        margin-top: 1rem;
    }

    blockquote {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 1px;
        padding: 0.5rem 0 0.5rem calc(#{$element-padding-default} - 1rem);
        border-left: 2px solid var(--ws-element-secondary);

        font-family: $font-element-quote;
        font-style: italic;
        line-height: 2rem;

        quotes: '\201C''\201D''\2018''\2019';

        > div > span:first-child > span:first-child {
            &:before {
                line-height: 1em;
                content: open-quote;
            }
        }

        > div > span:last-child > span:last-child {
            &:after {
                line-height: 1em;
                content: close-quote;
            }
        }

        .public-DraftStyleDefault-block:last-of-type {
            margin-bottom: 0;
        }

        & + blockquote {
            margin-top: 1rem;
        }

        &:last-child {
            margin-bottom: 0.5rem;
        }

        &:first-child {
            margin-top: -0.5rem;
        }
    }

    .public-DraftStyleDefault-pre {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        color: var(--ws-text-code-block-color);
        font-family: $font-monospace;
        font-size: $font-size-ui-small;
        line-height: 2rem;
        tab-size: 4;

        &:first-child {
            margin-top: -0.5rem;
        }

        &:last-child {
            margin-bottom: -0.5rem;
        }

        .public-DraftStyleDefault-block {
            @include mini-scrollbar-mixin($white-opacity-10, $white-opacity-30, 6px, false);

            padding: 0.5rem em(12px) 0.5rem;
            white-space: unset;
            word-wrap: normal;
            overflow-x: auto;
        }

        pre {
            border-radius: em(4px);
            background: var(--ws-text-code-background-color);
        }

        pre + pre {
            margin-top: 1rem;
        }
    }

    .center-text .public-DraftStyleDefault-ltr,
    .center-text {
        text-align: center;
    }

    /* -- placeholder styling -- */

    .public-DraftEditorPlaceholder-hasFocus,
    .public-DraftEditorPlaceholder-root {
        width: 100%;
        color: var(--ws-element-placeholder);
    }

    &.placeholder-align-center .public-DraftEditorPlaceholder-root {
        text-align: center;
    }

    &.placeholder-header-one .public-DraftEditorPlaceholder-root {
        padding-top: 1.8rem;

        font-family: $font-element-heading;
        font-size: var(--font-size-element-heading-one);
        letter-spacing: $letter-spacing-heading;
        line-height: 3rem;
    }

    &.placeholder-header-two .public-DraftEditorPlaceholder-root {
        padding-top: 2.1rem;

        font-family: $font-element-heading;
        font-size: $font-size-element-heading;
        letter-spacing: $letter-spacing-heading;
        line-height: $element-line-height-heading;

        .app-size-small & {
            padding-top: 2rem;
        }
    }

    &.placeholder-small-text .public-DraftEditorPlaceholder-root {
        margin-top: 1px;

        font-size: em(12px);
        line-height: 1.8rem;
    }

    &.placeholder-code-block .public-DraftEditorPlaceholder-root {
        padding-top: 2.4rem;
        padding-left: calc(#{$element-padding-default} + 1rem);

        color: var(--ws-text-code-block-color);
        font-family: $font-monospace;
        font-size: $font-size-ui-small;

        opacity: 0.3;

        z-index: 2;
    }

    &.placeholder-blockquote .public-DraftEditorPlaceholder-root {
        padding-left: calc(#{$element-padding-default} + 1.7rem);

        font-family: $font-element-quote;
        font-style: italic;
    }

    &.placeholder-unordered-list-item .public-DraftEditorPlaceholder-root,
    &.placeholder-ordered-list-item .public-DraftEditorPlaceholder-root,
    &.placeholder-checklist .public-DraftEditorPlaceholder-root {
        margin-left: $list-prefix-spacing;
    }

    &.placeholder-code-block .public-DraftEditorPlaceholder-root,
    &.placeholder-blockquote .public-DraftEditorPlaceholder-root,
    &.placeholder-unordered-list-item .public-DraftEditorPlaceholder-root,
    &.placeholder-ordered-list-item .public-DraftEditorPlaceholder-root {
        text-align: left;
    }

    /* -- special case handling for colored backgrounds -- */

    .colored-background & {
        a {
            color: inherit;
        }

        .ChecklistEditorBlock {
            .Checkbox.animate.checked {
                .Icon .fill {
                    animation: none;
                }
            }

            .Checkbox .Icon .tick {
                stroke: var(--ws-element-background-primary);
            }
        }
    }
    /* -- end colored background-specific styling -- */
}
