@import '../../scss/settings';

.DetailView {
    .metadata-container {
        display: flex;
        padding: var(--element-padding-default);

        .in-list-column & {
            padding: calc(var(--element-padding-default) + 1px) var(--element-padding-default);

            .app-size-medium & {
                padding: 22px 21px 23px;
            }

            .app-size-small & {
                padding: 20px 19px;
            }
        }

        .icon-container {
            flex-shrink: 0;
            width: 3.2rem;
            height: 4rem;

            img,
            svg {
                width: 100%;
                height: 100%;
            }
        }

        .details-container {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            // Need to do this to prevent emojis from getting cut off (due to the overflow: hidden)
            margin-top: -1px;
            margin-left: 1.2rem;
            padding-top: 3px;

            overflow: hidden;

            .title-container {
                .editable-title {
                    margin: -0.4rem 0 0.4rem 0;
                    padding: 0;
                    color: var(--ws-element-primary);
                }
            }

            .secondary-details-container {
                width: 100%;
                height: 1.8rem;
                color: var(--ws-element-secondary);
                font-size: $font-size-element-secondary;
                line-height: 1.5rem;

                .app-size-medium & {
                    height: 15px;
                }

                .app-size-small & {
                    height: 14px;
                }
            }
        }
    }
}
