@import '../../scss/settings';

.CommentTools {
    max-height: 1rem;

    color: var(--ws-mention-color);

    font-size: 1.2rem;

    .ElementReactionAddButton {
        padding-right: 0;
    }
}
