@import '../../../scss/settings';
@import 'sheet-transitions-mixin';

.SheetContainer {
    @include sheet-transitions-mixin();

    position: absolute;
    inset: 0;
    overflow: hidden;

    z-index: var(--z-index-sheet);

    pointer-events: none;

    &.show-toolbar {
        z-index: var(--z-index-sheet-with-toolbar);
    }

    .Overlay {
        position: absolute;
        inset: 0;
        pointer-events: all;
    }

    .Sheet {
        display: flex;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        flex-direction: column;

        height: 100%;

        transition: border-radius 0.3s;
        border-radius: 10px 10px 0 0;
        background: var(--ui-modal-background-primary);
        box-shadow: var(--modal-box-shadow);

        pointer-events: all;

        &.sheet-covers-screen {
            border-radius: 0;
        }

        .Content {
            overflow-y: scroll;
            padding-bottom: var(--safe-area-bottom);
        }

        &.dragging {
            [data-scrollable='true'] {
                overflow-y: hidden;
                overscroll-behavior-y: none;
            }
        }

        .Header {
            position: relative;
            padding: 12px 15px;

            .Heading {
                width: calc(100% - 130px);
                margin: 0 auto;
                font-size: var(--font-size-ui-xlarge);
                font-weight: var(--font-weight-semi-bold);
                line-height: var(--font-size-ui-xxxlarge);
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow-x: hidden;
            }

            .Handle {
                display: flex;
                justify-content: center;
                padding-bottom: 15px;

                &:after {
                    display: block;
                    width: 35px;
                    height: 5px;

                    border-radius: 4px;
                    background: var(--ui-foreground-quaternary);

                    content: '';
                }
            }

            .SheetHeaderButton {
                position: absolute;
                top: 0;
                height: 100%;
                padding: 1.5rem 2.4rem;
                line-height: var(--font-size-ui-xlarge); // ensure that text has the same baseline as the heading
            }

            .BackButton {
                left: 0;
            }

            .CloseButton {
                right: 0;

                .default-content-style {
                    color: var(--ui-accent-primary);
                    font-size: var(--font-size-ui-large);
                    font-weight: var(--font-weight-semi-bold);
                }
            }
        }
    }
}
