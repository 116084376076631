@import '../../scss/settings';
@import '../elementMetadataTitleMixin';
@import './linkStyleConstants';

.LinkMetadata {
    @include element-metadata-title-mixin;

    .editable-title {
        padding-bottom: $link-section-spacing;
        font-family: $font-link-file-heading;
        font-size: $font-size-link-file-heading;

        .in-list-column & {
            padding-bottom: calc(#{$link-section-spacing} + 1px);
        }

        .app-size-medium & {
            padding-bottom: 11px;
        }

        .app-size-small & {
            padding-bottom: 10px;
        }

        a {
            text-decoration: underline;
        }
    }

    &.no-caption {
        .editable-title {
            padding-bottom: calc(#{$element-padding-default} + 1px);

            .app-size-medium & {
                padding-bottom: 23px;
            }

            .app-size-small & {
                padding-bottom: 20px;
            }

            .in-list-column & {
                padding-bottom: calc(#{$element-padding-default} + 2px);

                .app-size-medium & {
                    padding-bottom: 25px;
                }

                .app-size-small & {
                    padding-bottom: 22px;
                }
            }
        }
    }
}
