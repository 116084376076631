@import '../../scss/settings';

.AdminDashboardAppVersions {
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px 15px 60px 15px;

    .info {
        margin: 20px 0;
        padding: 20px;
        background: var(--palette-purple-400);
        color: var(--ui-foreground-secondary);
        line-height: 1.3;
    }

    .table {
        margin-top: 20px;

        .row,
        .header {
            display: grid;

            padding: 5px 0;
            border-bottom: 1px solid var(--palette-grey-300);

            grid-template-columns: 10% 10% 10% 35% 10% 15% auto;
            grid-template-rows: auto;
        }

        .header {
            font-weight: $font-weight-semibold;
        }

        .action {
            text-align: right;
        }
    }
}
