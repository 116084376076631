.DebugUIPoint {
    position: relative;

    & > div {
        display: inline-block;
        position: absolute;
        width: 10px;
        height: 10px;
        z-index: var(--z-index-debug-tools);
    }
}
