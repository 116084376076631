.DebugUILineVector {
    position: relative;

    .DebugUILineVector--positioner {
        position: absolute;

        & > div {
            position: relative;

            svg {
                position: absolute;
                z-index: var(--z-index-debug-tools);
            }
        }
    }

}
