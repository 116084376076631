@import '../../../scss/settings';
@import '../../../scss/mixins/outer-border-mixin';

.FileUploader {
    height: 19rem;
    padding: 3rem;
    background: var(--ws-element-background-primary);
    text-align: center;
    z-index: 1;

    .uploader-content {
        max-height: 13rem;
    }

    &.hovered {
        @include outer-border-full(2px, var(--ws-element-selection));
    }

    .click-area {
        cursor: pointer;
    }

    .image-icon-container {
        min-height: 4.4rem;
        margin-bottom: 1.3rem;
    }

    .file-icon-container {
        min-height: 4.8rem;
        margin-bottom: 1.3rem;
    }

    .Icon {
        &.error-alert,
        &.image-form-upload {
            width: 5.4rem;
            height: 4.4rem;
        }

        &.file-form-upload {
            width: 3.8rem;
            height: 4.8rem;
        }

        &.error-alert {
            margin-bottom: 1.3rem;

            g {
                fill: var(--ws-element-tertiary);
            }
        }
    }

    .cta {
        display: block;
        color: var(--ws-element-anchor);
        font-size: $font-size-link-file-heading;
        font-weight: $font-weight-semibold;
        text-decoration: underline;
    }

    .hint {
        margin-top: 1rem;

        color: var(--ws-element-tertiary);
        font-size: $font-size-element-secondary;
        line-height: 1.5rem;

        a {
            color: var(--ws-element-tertiary);
        }
    }

    .in-list-document-mode & {
        height: auto;

        .file-input-description {
            display: none;
        }
    }
}
