.ElementReactions {
    display: flex;
    position: relative;
    flex-wrap: wrap;

    margin-top: -1rem;
    padding-right: var(--element-padding-default);
    padding-bottom: calc(var(--element-padding-default) - 0.8rem);
    padding-left: var(--element-padding-default);

    z-index: 1;
}
