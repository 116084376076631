@import '../../../scss/settings';

.MoreTool.ToolbarPopupTool {
    // This allows the popup to overflow the RichTextToolList container
    position: initial;

    .Tool .contents .icon {
        width: 32px;
        height: 16px;

        .Icon {
            width: 32px;
            height: 16px;
        }
    }

    .toolbar-size-small & {
        .Tool .contents .icon {
            width: 28px;
            height: 12px;

            .Icon {
                width: 28px;
                height: 12px;
            }
        }
    }
}
