@import '../../../scss/settings';

.AttachedCommentsDropTarget {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    // This is important to prevent the drop target from stopping click events on the element
    z-index: -1;

    // While dragging, move to the top of the stacking order so that it can accept drops
    .is-dragging-commit &.is-dragging {
        z-index: var(--z-index-element-hot-spot-drop-target);
    }

    // Make the drop target grow when we enter attach mode
    .attach-mode > &,
    .attach-mode > .ListContainer > & {
        &:before {
            position: absolute;
            top: -4rem;
            right: -4rem;
            bottom: -4rem;
            left: -4rem;
            content: '';
        }
    }
}
