@import '../../../scss/settings';

.Toolbar {
    --dismiss-button-height: 25px;
    --tool-icon-size: 32px;
    --tool-icon-border-radius: 0.4rem;

    display: flex;
    position: relative;

    flex-direction: column;
    flex-shrink: 0;

    align-items: center;
    justify-content: space-between;

    width: var(--toolbar-width);
    height: 100%;
    min-height: 440px;

    padding-top: 3px;

    background: var(--ws-canvas-background);

    color: var(--ui-foreground-primary);

    font-size: 10px;
    text-align: center;
    box-shadow: 1px 0 0 0 var(--ws-section-divider-color);

    z-index: var(--z-index-toolbar);

    .toolbar-window {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        overflow: hidden;
    }

    .tool-list-container {
        position: relative;
        top: 0;
        left: 0;

        height: 100%;
        transform: translate3d(0, 0, 0);
    }

    .tool-list {
        position: absolute;
        top: 0;
        left: 0;
        width: var(--toolbar-width);
        padding-top: 10px;

        .toolbar-size-small & {
            padding-top: 8px;
        }
    }

    .template-blocker {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        cursor: not-allowed;

        z-index: calc(var(--z-index-toolbar) + 1);
    }

    .presenting & {
        width: 0;
    }

    &.disabled {
        .toolbar-window,
        .Trash {
            opacity: 0.3;
        }
    }

    &.hidden {
        .Trash {
            display: none;
        }
    }

    .toolbar-size-small & {
        --tool-icon-size: 28px;
    }
}
