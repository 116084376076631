@import '../../../scss/settings';

.FormPrimaryMessage {
    display: flex;
    position: relative;
    top: 1rem;
    align-items: flex-start;
    justify-content: center;

    padding-bottom: 1rem;

    font-size: $font-size-ui-small;

    .Icon {
        width: 1.2rem;
        height: 1.2rem;
        margin-top: 0.25rem;
        margin-right: 1rem;
        overflow: visible;
    }
}
