@import '../../../../../scss/settings';

.DrawingSelectionMarquee {
    stroke: var(--color-neutral-700);
    stroke-width: 1px;

    fill: var(--ws-selection-marquee-background);

    pointer-events: none;
}
