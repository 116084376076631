@import '../../scss/settings';

.LineGhostSvg {
    position: absolute;
    overflow: visible;
    z-index: 1;
    pointer-events: none;

    .ghost-line-path {
        opacity: 0.3;

        stroke-width: 2;
        stroke-dasharray: 0, 6;
        stroke-linecap: round;
    }
}
