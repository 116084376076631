@import '../../scss/settings';

@keyframes pulse {
    70% {
        box-shadow: 0 0 0 15px transparent;
    }

    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

.ElementUserTag {
    .tag-contents {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.active {
        background: var(--remote-user-color);
    }

    &.pulse {
        // TODO Try to move this over to the variable system
        box-shadow: 0 0 0 0 transparentize($temp-color-purple-900, 0.4);
        animation: pulse 1.5s infinite;
    }

    .name-container {
        transition: opacity 0.4s linear, max-width 0.4s ease-out;
        overflow: hidden;

        .name {
            margin: 0 0.8rem 0 1rem;
        }
    }

    .force-deselect-element {
        padding-top: 5px;
        padding-right: 1rem;
        padding-bottom: 5px;
        cursor: pointer;

        .Icon.close-x-small {
            height: 0.8rem;

            path {
                fill: #97b1fb;
            }
        }
    }

    .UserAvatar {
        flex-shrink: 0;
        margin-right: -5px;
        margin-left: 3px;

        &:before {
            display: none;
        }
    }

    // -----------------
    // transitions
    // -----------------

    &.pop-appear,
    &.pop-enter {
        &.animate-name .name-container {
            max-width: 8px;
            transition-delay: 0.35s;
            opacity: 0;

            .app-size-small & {
                max-width: 6px;
            }
        }
    }

    &.pop-appear.pop-appear-active,
    &.pop-enter.pop-enter-active,
    &.pop-leave {
        &.animate-name .name-container {
            max-width: 125px;
            opacity: 1;
        }
    }

    &.pop-leave.pop-leave-active {
        &.animate-name {
            transition-delay: 0.4s;
        }

        &.animate-name .name-container {
            max-width: 8px;
            opacity: 0;

            .app-size-small & {
                max-width: 6px;
            }
        }
    }

    // -----------------
    // responsive app sizes
    // -----------------

    .app-size-small & {
        .UserAvatar {
            margin-right: -4px;
            margin-left: 2px;
        }

        .name {
            margin-bottom: 0;
        }
    }
}
