@import '../../../scss/settings';

.CommentEditor {
    position: relative;

    padding: 0.4rem 1rem 0.4rem 1rem;
    border-radius: 0.3rem;

    font-size: $font-size-comment;
    line-height: 2rem;
    user-select: text;

    // Undo the default editor link style changes
    .editing & {
        a {
            color: var(--ui-accent-primary);
            cursor: pointer;
        }
    }

    &.editing {
        background: var(--ws-comment-editor-background);

        a {
            color: var(--ws-element-primary);
            cursor: text;
        }
    }

    .public-DraftEditorPlaceholder-hasFocus,
    .public-DraftEditorPlaceholder-root {
        color: var(--ws-comment-placeholder);
    }

    .tiptap {
        & > p {
            margin-bottom: 0;
        }

        .text-selectable & {
            user-select: text;
            cursor: text;
        }

    }

    .updating & {
        background: var(--ws-comment-editor-background);
    }
}
