@import '../../scss/settings';

.Modal {
    position: relative;

    width: 45rem;
    padding: 4rem;

    border-radius: $ui-border-radius-default;
    background: var(--ui-modal-background-primary);

    font-size: $font-size-ui-default;

    // Purely to ensure consistent rounded corners in the modal. If this becomes problematic, remove
    overflow: hidden;

    .auto-width & {
        width: auto;
        min-width: 45rem;
    }

    .no-padding & {
        padding: 0;
    }

    // TRANSITION
    .fade-grow-appear &,
    .fade-grow-enter & {
        transform: scale(var(--modal-transition-box-scale));
        opacity: 0;
    }

    .fade-grow-appear.fade-grow-appear-active &,
    .fade-grow-enter.fade-grow-enter-active &,
    .fade-grow-leave & {
        transform: scale(1);
        transition: opacity var(--modal-transition-box-duration) linear,
            transform var(--modal-transition-box-duration) ease-out;
        transition-delay: var(--modal-transition-delay-duration);
        opacity: 1;
    }

    .fade-grow-leave.fade-grow-leave-active & {
        transform: scale(var(--modal-transition-box-scale));
        transition: opacity var(--modal-transition-box-duration) linear,
            transform var(--modal-transition-box-duration) ease-in;
        opacity: 0;
    }
    // END TRANSITION

    .fill-screen &,
    .app-mode-mobile-legacy & {
        display: flex;
        align-items: center;
        width: auto;
        min-height: 500px;
        padding: 50px 20px;

        border: 0;
        border-radius: 0;

        font-size: $font-size-ui-default;

        box-shadow: none;

        .auto-width & {
            min-width: 0;
        }
    }
}
