.tiptap {
    h1, h2 {
        font-family: var(--font-family-element-heading), serif;
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: var(--letter-spacing-heading);
    }

    h1 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        font-size: var(--font-size-element-heading-one);
        line-height: 3rem;

        // This allows headers further down the page to have more padding above, but
        // not the first heading line
        &:first-child {
            margin-top: -0.5rem;
        }

        // When the only text in a card is a header, remove some margins to keep it the same size
        // as a normal card
        &:last-child:first-child {
            margin-top: -0.6rem;
            margin-bottom: 0.6rem;
        }

        // This prevents the size of the card from jumping when a header is created on the last line
        &:last-child {
            margin-bottom: 0.5rem;
        }
    }

    h2 {
        margin-top: 1.6rem;
        margin-bottom: 0.9rem;
        font-size: var(--font-size-element-heading);
        line-height: var(--line-height-heading);

        &:first-child {
            margin-top: -0.4rem;
        }

        // When the only text in a card is a header, remove some margins to keep it the same size
        // as a normal card
        &:last-child:first-child {
            margin-top: -0.3rem;
            margin-bottom: 0.8rem;
        }

        .app-size-medium & {
            margin-top: 14px;
            margin-bottom: 9px;
            line-height: 22px;

            &:first-child {
                margin-top: -4px;
            }

            &:last-child:first-child {
                margin-top: -3px;
                margin-bottom: 8px;
            }

            // This prevents the size of the card from jumping when a header is created on the last line
            &:last-child {
                margin-bottom: 9px;
            }
        }

        .app-size-small & {
            margin-top: 12px;
            margin-bottom: 8px;
            line-height: 20px;

            &:first-child {
                margin-top: -4px;
            }

            &:last-child:first-child {
                margin-top: -3px;
                margin-bottom: 7px;
            }

            // This prevents the size of the card from jumping when a header is created on the last line
            &:last-child {
                margin-bottom: 8px;
            }
        }
    }

    // Ensure collapsed margins fall on exact grid points
    h1 + h1 {
        margin-top: 2rem;
    }

    h1 + h2 {
        margin-top: -0.4rem;

        .app-size-medium & {
            margin-top: -4px;
        }

        .app-size-small & {
            margin-top: -4px;
        }
    }

    h2 + h2 {
        margin-top: 1.5rem;
    }

    h2 + h1 {
        margin-top: 1.4rem;

        .app-size-medium & {
            margin-top: 13px;
        }

        .app-size-small & {
            margin-top: 12px;
        }
    }
}
