@import '../../../scss/settings';

.FileMediaPlayer {
    --z-index-element-rich-media-preview-overlay: 100;

    position: relative;

    .media-player-error {
        padding: 2rem;

        background: var(--ws-element-background-primary);

        color: var(--ui-foreground-tertiary);
        font-size: $font-size-element-default;
        text-align: center;

        .Icon {
            margin-bottom: 1.8em;

            g {
                fill: var(--ui-foreground-tertiary);
            }
        }
    }

    .OptimisticImage {
        width: 100%;
        height: 100%;

        .ProgressiveImage {
            height: 100%;
        }
    }

    .video-preview {
        width: 100%;
        height: 100%;

        canvas {
            width: 100%;
            height: 100%;
        }
    }

    .media-player-controls {
        display: grid;

        align-items: center;
        grid-template-columns: 3rem 3rem 1fr auto;

        min-height: 3rem;

        padding-right: 1.6rem;
        padding-left: 1.4rem;

        &.has-fullscreen {
            grid-template-columns: 3rem 3rem 1fr auto 3rem;
        }

        .FileMediaPlayerProgressBar {
            padding-left: 0.5rem;
        }

        .FileMediaPlayerVolumeButton {
            .Icon {
                width: 1.6rem;
                height: 1.6rem;
            }
        }

        .control-button {
            padding-top: 0.3rem;

            &.pause-button,
            &.play-button {
                .Icon {
                    width: 1.6rem;
                    height: 1.6rem;
                }
            }

            .Icon path {
                fill: var(--ws-media-player-button-color);
            }

            &.inactive {
                opacity: 0.5;
            }
        }
    }

    .RichMediaPreviewOverlay {
        // force onto openGL layer because it was getting pushed
        // below the video player in Chrome without this (sometimes)
        transform: translate3d(0, 0, 0);

        .circle-hit-area {
            padding: 0;
        }
    }

    &.audio {
        background: var(--ws-media-player-audio-controls-background);

        .media-player-controls {
            min-height: 5rem;
            padding-left: 1.2rem;

            grid-template-columns: 3.7rem 3rem 1fr auto;
        }

        .control-button.pause-button,
        .control-button.play-button {
            width: 3.2rem;
            height: 3.2rem;

            border-radius: 50%;
            background: var(--ws-media-player-button-color);

            .Icon path {
                fill: var(--ws-media-player-audio-button-path);
            }

            .Icon.media-player-play {
                margin-left: 2px;
            }
        }

        .media-player-container.error {
            display: flex;
            align-items: center;
            justify-content: center;

            min-height: 5rem;
            background: var(--ws-element-background-secondary);
            color: var(--ws-element-tertiary);

            font-size: $font-size-ui-small;
        }
    }

    &.video {
        --ws-media-player-button-color: var(--ws-media-player-video-button-color);

        background: $black;

        .media-player-container {
            position: relative;
            height: 0;
            overflow: hidden;

            > div {
                position: absolute;
                top: 0;
                left: 0;
            }

            // This ensures that the video doesn't have black borders if the aspect ratio isn't perfect
            video {
                object-fit: cover;

                &:fullscreen {
                    object-fit: contain;
                }
            }

            &.error {
                display: flex;
                align-items: center;
                justify-content: center;

                background: var(--ws-element-background-secondary);
                color: var(--ws-element-tertiary);

                > div {
                    top: 50%;
                    right: 0;
                    left: 0;

                    margin-top: -2.6rem;
                    padding: 0 4rem;

                    line-height: 1.5;
                    text-align: center;
                }
            }
        }

        .media-player-controls {
            position: absolute;

            bottom: 0;
            left: 0;

            width: 100%;

            transition: opacity 0.2s linear;

            background: var(--ws-video-player-background);

            opacity: 0;
            z-index: 100;

            .FileMediaPlayerDuration {
                color: $white;
            }
        }

        &:hover,
        &.showing-volume,
        &.dragging-scrubber,
        .selected & {
            .media-player-controls {
                opacity: 1;
            }
        }
    }

    .app-size-medium & {
        &.audio {
            .control-button.play-button {
                width: 29px;
                height: 29px;

                padding-left: 1px;

                .Icon.media-player-play {
                    margin-left: 2px;
                }
            }
        }
    }

    .app-size-small & {
        &.audio {
            .FileMediaPlayerVolumeButton {
                padding-top: 3px;
            }

            .control-button.play-button {
                width: 25px;
                height: 25px;

                padding-top: 3px;
                padding-left: 2px;

                .Icon.media-player-play {
                    margin-left: 1px;
                }
            }
        }
    }
}
