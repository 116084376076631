@import '../../../scss/settings';
@import '../../../scss/mixins/outer-border-mixin';

.CommentThreadCollapsed {
    --pin-width: 2.4rem;
    --pin-height: 2.9rem;

    --comment-thread-background-color: var(--palette-purple-900);
    --comment-thread-text-color: var(--palette-white);

    position: relative;
    top: calc(-1 * var(--pin-height) + 2px);
    left: calc(-1 * var(--pin-width) / 2);
    width: var(--pin-width);
    height: var(--pin-height);

    .pin-button {
        position: relative;
        width: var(--pin-width);
        height: var(--pin-height);

        .Icon {
            width: var(--pin-width);
            height: var(--pin-height);

            path {
                stroke: var(--comment-thread-background-color);
                fill: var(--comment-thread-background-color);
            }
        }

        .ActivityIndicator {
            top: 2px;
            right: 1px;
            width: 22px;

            .SimpleActivityIndicator {
                top: 0;
                right: 0;
            }
        }
    }

    &.hide-comments {
        display: none;
    }

    .comments-count-wrapper {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;

        height: calc(var(--pin-height) - 0.5rem);
    }

    .comments-count {
        color: var(--comment-thread-text-color);
        font-size: $font-size-ui-xsmall;
        font-weight: $font-weight-semibold;
        text-align: center;
    }

    &.marquee-selected:not(.marquee-remove-selected),
    &.selected:not(.marquee-remove-selected) {
        .pin-button {
            .Icon {
                path {
                    stroke: var(--ws-element-selection);
                }
            }
        }
    }

    .platform-ipad & {
        --pin-width: 3rem;
        --pin-height: 3.6rem;

        .comments-count {
            font-size: $font-size-ui-small;
        }
    }

    .app-size-medium & {
        --pin-width: 20px;
        --pin-height: 23px;

        left: -11px;
    }

    .app-size-small & {
        --pin-width: 19px;
        --pin-height: 23px;

        left: -10px;
    }
}
