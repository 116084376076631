@import '../../../../scss/settings';

.DrawingEditorToolbarUndoRedoTools {
    left: -1rem;
    transform: translate3d(-100%, 0, 0);

    .Icon path {
        fill: var(--ui-modal-foreground-primary);
    }
}
