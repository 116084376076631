@import '../../../scss/settings';

.FileIconView {
    .EditableTitle {
        color: var(--ui-canvas-foreground-primary);
        font-family: $font-board-heading;
        font-size: $font-size-board-heading;
    }

    .file-upload-progress {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        padding-right: 0.8rem;
        padding-bottom: 1.6rem;
        padding-left: 0.8rem;

        .status-text {
            margin-bottom: 0.6rem;

            color: var(--ws-element-tertiary);
            font-size: var(--font-size-ui-xsmall);
        }
    }
}
