@import '../../scss/settings';

.ColorBar {
    --color-bar-thickness: 0.5rem;

    position: absolute;
    z-index: calc(var(--element-auxiliary-z-index) + 1);
    pointer-events: none;

    &.top {
        top: 0;
        right: 0;
        left: 0;

        height: var(--color-bar-thickness);
    }

    &.left {
        top: 0;
        bottom: 0;
        left: 0;

        width: var(--color-bar-thickness);
    }
}
