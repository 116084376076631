@import '../../../scss/settings';

.CommentThreadCollapsedPopup.FloatingPanel {
    width: auto;
    padding: 0;
    border-radius: $ui-border-radius-default;
    text-align: left;

    .arrow {
        z-index: 1;
    }

    .CommentThread {
        border-radius: $ui-border-radius-default;
        background: var(--ws-comment-background);
        overflow: hidden;

        &:before {
            content: none;
        }

        .ColorBar {
            display: none;
            border-radius: $ui-border-radius-default $ui-border-radius-default 0 0;
        }

        .CommentLine,
        .CommentForm {
            border-radius: $ui-border-radius-default;
        }

        .comment-thread-content-container {
            // Need to bypass the sass compiler as it can't deal with the rem and vh together
            max-height: #{'min(60rem, 80vh)'};
        }
    }

    .ElementTag {
        display: none;
    }
}
