@mixin fade-transition-group-mixin {
    &.fade-appear,
    &.fade-enter,
    &.fade-leave.fade-leave-active {
        opacity: 0;
    }

    &.fade-appear.fade-appear-active,
    &.fade-enter.fade-enter-active,
    &.fade-leave {
        opacity: 1;
    }

    &.fade-appear.fade-appear-active,
    &.fade-enter.fade-enter-active,
    &.fade-leave.fade-leave-active {
        transition: opacity 0.3s ease-in;
    }
}
