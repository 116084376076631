@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';

.UserAvatar {
    --avatar-size: 2.4rem;

    position: relative;

    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: 50%;

    color: var(--ui-avatar-foreground);
    font-size: calc(var(--avatar-size) * 0.4);
    font-weight: bold;

    .notification-live {
        position: absolute;
        right: -0.3rem;
        bottom: -0.3rem;
        width: 1.4rem;
        height: 1.4rem;
        transform: scale(0.8);
    }

    &.active {
        @include overlapping-border-full(2px, var(--remote-user-color));

        color: var(--remote-user-contrast);

        .avatar-contents {
            background: var(--remote-user-color);
        }

        svg path {
            fill: var(--remote-user-contrast);
        }
    }

    &.filter {
        border-color: var(--color-neutral-800);
        color: var(--color-neutral-0);
    }

    &:before {
        border-radius: 50%;
        z-index: -1;
    }
}

.avatar-contents {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    border-radius: 50%;

    background: var(--ui-avatar-background);

    overflow: hidden;

    &.avatar-color-0 {
        background: var(--ui-avatar-background-color-0);
    }

    &.avatar-color-1 {
        background: var(--ui-avatar-background-color-1);
    }

    &.avatar-color-2 {
        background: var(--ui-avatar-background-color-2);
    }

    &.avatar-color-3 {
        background: var(--ui-avatar-background-color-3);
    }

    &.avatar-color-4 {
        background: var(--ui-avatar-background-color-4);
    }

    &.avatar-color-5 {
        background: var(--ui-avatar-background-color-5);
    }

    &.avatar-color-6 {
        background: var(--ui-avatar-background-color-6);
    }

    &.avatar-color-7 {
        background: var(--ui-avatar-background-color-7);
    }

    svg path {
        fill: var(--ui-avatar-guest-foreground);
    }

    span {
        text-transform: uppercase;
        overflow: hidden;

        &.GuestAvatar {
            position: relative;
            top: calc(var(--avatar-size) * 0.2);

            width: calc(var(--avatar-size) * 0.8);
            height: calc(var(--avatar-size) * 0.8);

            .Icon {
                width: calc(var(--avatar-size) * 0.8);
                height: calc(var(--avatar-size) * 0.8);
            }
        }
    }

    .image-node {
        border-radius: 50%;
        overflow: hidden;
    }
}
