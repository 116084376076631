@import '../../../../scss/settings';
@import '../../../../scss/mixins/mini-scrollbar-mixin';

.PopupModeContent {
    @include mini-scrollbar-mixin(var(--ui-modal-background-primary), var(--palette-gunmetal-grey-500));

    display: flex;
    flex-grow: 1;
    width: 63%;

    margin-right: -1rem;
    padding-left: $ui-section-spacing-xlarge;

    background: var(--ui-modal-background-primary);

    &.scrollable {
        overflow-y: scroll;
    }

    > div {
        width: 100%;
        height: 100%;
    }
}
