@import '../../../../../scss/settings';

.ColorTool {
    position: relative;

    .Tool.ToolbarTool .icon {
        height: 28px;

        .toolbar-size-small & {
            height: 25px;
        }
    }
}
