.BoardDropTarget {
    display: none;
}

.workspace-dragging-native,
.workspace-dragging-element {
    .BoardDropTarget {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .element-in-list {
        .BoardDropTarget {
            top: 1.8rem;
            bottom: 1.8rem;

            // While dragging, move to the top of the stacking order so that it can accept drops
            z-index: var(--z-index-element-hot-spot-drop-target);
        }
    }
}
