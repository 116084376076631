@import '../../../scss/settings';

.TiptapEditorContainer {
    width: 100%;
    height: 100%;
}

.CardTiptapEditor {
    width: 100%;
    height: 100%;

    .tiptap {
        // The right margin needs to be reduced by a few pixels to match what Draft was doing
        // (it had some internal padding/allowance that was affecting wrapping - from experimentation,
        // somewhere between 3.74 and 4.09px)
        --margin-correct: 3.9px;

        width: 100%;

        padding: 2.4rem calc(var(--element-padding-default) - var(--margin-correct)) 1.4rem var(--element-padding-default);

        color: var(--ws-element-primary);

        // NOTE: This is used to bring the editor above the CardClipper tool, otherwise
        //  the CardClipper tool will take mousedown events and fire "ELEMENT_EDIT_COMPLETE" actions.
        //  It might be instead possible to use pointer-events: none on the CardClipper tool, however
        //  I'll leave that for a future investigation
        z-index: 1;

        .app-size-medium & {
            padding-top: 22px;
            padding-bottom: 12px;
        }

        .app-size-small & {
            padding-top: 19px;
            padding-bottom: 11px;
        }
    }
}
