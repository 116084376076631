.MissingImage {
    position: relative;
    background: var(--ws-missing-image-background-color);

    .missing-image-icon-container {
        display: flex;
        position: absolute;

        top: 0;
        left: 0;

        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &.small .Icon {
            width: 20px;
            height: 20px;
        }
    }
}
