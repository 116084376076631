// Element spacing
$element-line-height: var(--line-height-element);
$element-line-height-heading: var(--line-height-heading);

$element-paragraph-spacing-xlarge: var(--element-paragraph-spacing-xlarge);
$element-paragraph-spacing-large: var(--element-paragraph-spacing-large);
$element-paragraph-spacing-default: var(--element-paragraph-spacing-default);

$ui-section-spacing-xxlarge: 3.2rem;
$ui-section-spacing-xlarge: 2.8rem;
$ui-section-spacing-large: 2.4rem;
$ui-section-spacing-default: 2rem;
$ui-section-spacing-small: 1.6rem;
$ui-section-spacing-xsmall: 1.2rem;
$ui-section-spacing-xxsmall: 0.8rem;

$element-padding-default: var(--element-padding-default);
$element-padding-small: var(--element-padding-small);

$board-border-radius: var(--board-border-radius);

$ui-border-radius-default: 0.8rem;
$ui-border-radius-xsmall: 0.4rem;

$tool-padding-regular: 9px 0 8px 0;
$tool-padding-small: 6px 0;
