@import '../../../scss/settings';

.CardCloneInstancesPopup {
    .popup-content {
        border-radius: $ui-border-radius-default;

        color: var(--ws-element-secondary);
        font-size: $font-size-element-messaging;

        overflow: hidden;
    }
}

.CardCloneInstancesPopupContentLoading {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 3.7rem;

    .Spinner {
        width: 1.6rem;
        height: 1.6rem;
    }
}
