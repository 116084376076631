@import '../../../scss/mixins/outer-border-mixin';

.ReactionPopupEmojiButton {
    display: flex;
    position: relative;
    flex-shrink: 0;

    align-items: center;
    justify-content: center;

    width: 3.6rem;
    height: 3.6rem;

    &:before {
        display: block;
        position: absolute;

        top: 0.2rem;
        right: 0.2rem;
        bottom: 0.2rem;
        left: 0.2rem;

        border-radius: 3px;

        content: '';
        z-index: -1;
    }

    &:hover {
        &:before {
            background: var(--ui-emoji-picker-item-background-hover);
        }
    }

    &.selected {
        &:before {
            background: var(--ws-reaction-pill-active-background);
        }
    }
}
