@import '../../../scss/settings';
@import '../../../scss/mixins/mini-scrollbar-mixin';

.FullScreenModal.overlay .Modal.ElementModal.document {
    background: var(--ws-element-background-primary);
    box-shadow: var(--modal-box-shadow);
}

.DocumentModalDraftJs,
.DocumentModal {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    // Prevents the scrollbar from ruining the border-radius
    overflow: hidden;

    .document-modal-body {
        display: flex;
        flex-grow: 1;

        .content {
            flex-grow: 1;
            max-width: calc(100% - 6rem);

            font-size: $font-size-ui-large;

            .RichTextEditor,
            .DocumentModalTiptapEditorContainer {
                height: 100%;
                padding-right: 3px;
                line-height: 2.4rem;
            }
        }

        #document-modal-side {
            width: 2.3rem;
        }
    }

    &:not(.preview) {
        .document-modal-body .content .RichTextEditor,
        .document-modal-body .content .DocumentModalTiptapEditorContainer {
            width: 69.6rem;

            max-width: calc(100vw - 1rem);

            min-height: calc(100vh - 4.2rem - var(--modal-header-height));
            max-height: calc(100vh - 4.2rem - var(--modal-header-height));

            .public-DraftEditorPlaceholder-root,
            .public-DraftEditor-content,
            .DocumentModalTiptapEditor{
                @include mini-scrollbar-mixin(var(--ui-background-primary), var(--color-neutral-600), 0.8rem, false);
                overflow-y: scroll;
            }

            .public-DraftEditorPlaceholder-root,
            .public-DraftEditor-content,
            .tiptap {
                padding: 3.6rem 0 8rem 0;
            }
        }
    }

    &.read-only {
        &:not(.preview) {
            .document-modal-body .content .RichTextEditor,
            .document-modal-body .content .DocumentModalTiptapEditorContainer {
                .public-DraftEditorPlaceholder-root,
                .public-DraftEditor-content,
                .tiptap {
                    padding-top: 2.7rem;
                }
            }
        }
    }

    .app-size-mobile &,
    .app-size-small & {
        .content {
            font-size: $font-size-ui-default;
        }
    }

    .app-size-mobile & {
        .content {
            max-width: 100%;
        }
    }
}
