@import '../scss/settings';
@import '../scss/mixins/outer-border-mixin';
@import '../scss/mixins/list-placeholder-shadow-mixin';

.EmptyListPlaceholder {
    display: flex;
    justify-content: center;

    .default-placeholder {
        position: relative;

        width: 100%;
        height: 7.2rem;
        margin-bottom: var(--element-in-list-vertical-padding);

        text-align: center;

        .background {
            @include list-placeholder-shadow();

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &.hovered .default-placeholder {
        @include outer-border-full(2px, var(--ws-element-selection));
    }
}
