@import '../../scss/settings';
@import '../elementColoredBackgroundMixin';
@import '../elementBox';
@import '../../list/listElementDraggingMixin';

.Table {
    @include element-box;
    @include colored-background;
    @include list-element-dragging;

    --table-title-height: 0px;
    --z-index-table-cover: 100;
    --element-border-color-in-list: var(--ws-element-column-card-border-color);

    &.editing,
    &.in-list-document-mode {
        cursor: text;
        user-select: text;
    }

    .QuickLineCreationTool {
        z-index: 101;
    }
    &.moving-row-col .QuickLineCreationTool {
        display: none;
    }

    .Caption {
        .tiptap,
        .public-DraftEditor-content {
            margin-top: -0.175rem;
        }
    }

    &.clipping {
        a {
            color: var(--ws-element-primary);
        }
    }

    .TitleDraftJsEditor {
        .public-DraftEditor-content,
        .public-DraftEditorPlaceholder-root {
            // margin necessary to ensure the bottom of the title sits exactly on the grid point
            margin-bottom: -1.5px;
            padding: calc(1.75rem - 1.5px) var(--element-padding-default) calc(1.75rem + 1.5px)
                var(--element-padding-default);
        }

        &.title-even-lines {
            .public-DraftEditor-content {
                padding: calc(2rem - 1.5px) var(--element-padding-default) calc(2rem + 1.5px)
                    var(--element-padding-default);
            }
        }
    }

    .HotTableCover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: var(--scroll-bar-height);
        left: 0;
        z-index: var(--z-index-table-cover);
    }

    .ElementResizeHandle {
        z-index: 1001;
    }

    &.selected.focus-editing {
        .ElementResizeHandle {
            opacity: 1;
            pointer-events: all;
        }
    }

    .ElementReactions {
        margin-top: 2rem;
        padding-bottom: 0;
    }
}
