.DrawingModeToolModeButton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 28px;
    padding: 6px;

    border-radius: 0.4rem;

    .Icon path {
        fill: var(--ui-modal-foreground-primary);
    }

    &.active {
        background: var(--ui-toolbar-tool-default-background);
    }

    // Make the hit area flow into the next button so the cursor doesn't flicker
    &::before {
        position: absolute;
        top: 0;
        right: -0.4rem;
        bottom: 0;
        left: -0.4rem;
        content: '';
    }

    .app-size-small & {
        width: 28px;
        height: 25px;
    }
}
