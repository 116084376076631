@import '../../scss/settings';

.Form {
    position: relative;

    .form-row {
        width: 100%;
        margin-bottom: 2.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .field-info {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        margin-bottom: 1.2rem;

        .field-message {
            margin-bottom: 0;
        }

        & + .field-message {
            margin-top: -1.2rem;
        }
    }

    .field-message {
        margin-bottom: 0.6rem;
        font-size: $font-size-ui-small;
        line-height: 1em;
        text-align: left;

        &.error {
            color: var(--error-color);
        }

        &.warn {
            color: var(--error-color);
        }

        &.success {
            color: var(--success-color);
        }
    }

    .field-row {
        display: flex;
        align-items: center;

        .field {
            flex-grow: 1;
            margin-right: 2rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .field-helper {
        margin-top: 0.6rem;
    }

    .field-label {
        font-size: $font-size-ui-default;
    }

    .button {
        width: 100%;
        transition: background 0.2s linear, opacity 0.2s linear;

        &.primary {
            background: var(--ui-accent-primary);
        }

        &.success,
        &:disabled.success {
            background: var(--success-color);
        }

        &.failure,
        &:disabled.failure {
            background: var(--error-color);
        }

        &:disabled {
            background: var(--color-neutral-700);
        }
    }

    // Used to avoid autocompleting fields (because autocomplete will fill in the password which can be shown)
    .fake-password {
        display: inline;
        position: absolute;
        top: -1rem;
        left: -1rem;
        width: 0;
        max-height: 0;
        padding: 0;
        border: 0;
        outline: none;
        background: none;
    }

    .submission-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--ui-background-primary);
        opacity: 0;
        z-index: 1;

        &.fade-appear,
        &.fade-enter,
        &.fade-leave.fade-leave-active {
            opacity: 0;
        }

        &.fade-appear.fade-appear-active,
        &.fade-enter.fade-enter-active,
        &.fade-leave {
            opacity: 0.5;
        }

        &.fade-appear.fade-appear-active,
        &.fade-enter.fade-enter-active,
        &.fade-leave.fade-leave-active {
            transition: opacity 0.1s ease-in;
        }
    }

    .field {
        position: relative;
    }
}

.google-button {
    height: 4rem;
}
