@import '../../scss/settings';
@import './PageLoaderMain';
@import '../../workspace/toolbar/components/Toolbar';
@import '../../mobile/toolbar/MobileToolbar';
@import '../../workspace/header/AppHeader';

.PageLoader {
    position: relative;
    height: 100%;
    overflow: hidden;

    @media (max-width: $size-mobile) {
        .header-tools {
            display: none;
        }

        .Main {
            left: 0;
            margin-top: 4rem;
        }

        .CurrentBoardHeader {
            display: none;
        }

        .Toolbar {
            display: none;
        }
    }

    .AppHeader {
        height: var(--app-header-tools-section-height);

        .app-size-mobile & {
            height: calc(var(--modern-mobile-header-height) + var(--safe-area-top));
        }

        .platform-ipad & {
            height: calc(var(--app-header-tools-section-height-ipad) + var(--safe-area-top));
        }
    }

    .Toolbar {
        .app-size-small & {
            width: $toolbar-width-small;
        }

        .app-size-medium & {
            width: $toolbar-width-medium;
        }

        .app-size-mobile & {
            display: none;
        }
    }

    .MobileToolbar {
        display: none;
        height: var(--workspace-safe-area-bottom);

        .app-size-mobile & {
            display: block;
        }
    }
}
