@import '../../../../../scss/settings';

.Tool.ToolbarTool.TiptapTextStyleToolButton,
.Tool.ToolbarTool.TiptapTextStyleToolButton .Button,
.TextStyleRichTextToolContainer,
.TextStyleRichTextToolContainer .Button {
    width: 100%;
}

.CardModal {
    .TiptapTextStyleTool,
    .TextStyleRichTextToolContainer {
        width: 5rem;
        margin: 0 -0.6rem;
    }
}

.Tool.ToolbarTool.TiptapTextStyleToolButton,
.Tool.ToolbarTool.TextStyleRichTextTool {
    .icon {
        // calculated from SVG path border radius, which doesn't have units
        border-radius: var(--tool-icon-border-radius);

        background: var(--ui-toolbar-tool-background);

        .text-style-icon {
            position: relative;
            top: 0.2rem;
            left: 0.2rem;

            width: 16px;
            height: 16px;

            background: url('icons/text-style-icon-light.png');
            background-size: 100% auto;

            .custom-bg-light &,
            .light-theme:not(.custom-bg-dark) & {
                background-image: url('icons/text-style-icon-light.png');
            }

            .custom-bg-dark &,
            .dark-theme:not(.custom-bg-light) & {
                background-image: url('icons/text-style-icon-dark.png');
            }
        }
    }

    &.open {
        .icon {
            background: var(--ui-toolbar-tool-popup-open-background);
        }
    }

    &.disabled {
        .text-style-icon {
            opacity: 0.2;
        }
    }
}

.MobileToolbar {
    .Tool.ToolbarTool.TiptapTextStyleToolButton,
    .Tool.ToolbarTool.TextStyleRichTextTool {
        .icon {
            width: var(--tool-icon-size);
            height: var(--tool-icon-size);
        }

        .tool-name {
            display: none;
        }
    }
}
