@import '../../scss/settings';
@import '../../scss/mixins/fade-transition-group-mixin';
@import './LogoSpinner';

.PageLoaderMain {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: calc(100vh - var(--app-header-tools-section-height));

    .Main {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .app-size-mobile & {
        height: calc(
            100vh - var(--app-header-tools-section-height) - var(--safe-area-top, 0) - var(--workspace-safe-area-bottom)
        );

        .Toolbar {
            display: none;
        }

        .Main {
            left: 0;
            margin-top: 0;
        }
    }

    .LegacyMobileWorkspace & {
        .Main {
            margin-top: 4rem;
        }
    }
}
