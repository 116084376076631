@import '../../../scss/settings';
@import '../../../scss/mixins/outer-border-mixin';

.LineLabel {
    position: absolute;

    // Centers the text in the middle of the control point
    transform: translate3d(-50%, -50%, 0);

    z-index: 2;

    .line-label-content {
        position: relative;
        width: max-content;
        max-width: 15rem;
        padding: 2px 3px;
        border-radius: $ui-border-radius-xsmall;
        background: var(--ws-line-color);

        font-size: $font-size-ui-small;
        font-weight: $font-weight-semibold;

        .LineLabelContentEditable {
            padding: 0 2px;
        }
    }

    .MiniElementUserTag {
        top: -1.5rem;
        right: -1.5rem;
    }

    // Safari behaves quite strangely during edits, this seems to fix the problem
    // The problem occurs when the label falls outside the CanvasElement's bounding box - Safari
    // can't deal with the width properly in that scenario, so we force the width of the container to
    // be as wide as the maximum width of the label, and that solves the bug
    .browser-engine-safari & {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15rem;
    }

    .editable & {
        cursor: move;
    }

    .remote-selected & {
        cursor: default;

        .line-label-content {
            background: var(--remote-user-color);
        }

        .LineLabelContentEditable {
            color: var(--remote-user-contrast);
        }
    }

    .editing & {
        .LineLabelContentEditable {
            background: var(--ui-background-primary);
            color: var(--ui-foreground-primary);
            cursor: text;
        }
    }

    // We need to add this to ensure that drag and drop is possible
    // Otherwise the drag will start but immediately get followed by a drop (I think the dragged element
    // gets the .disable-drag class added again immediately after the drag starts)
    // I wonder if this is what's causing issues selecting things underneath lines?
    .disable-drag & {
        .line-label-content {
            pointer-events: all;
        }
    }

    .app-size-medium & {
        .line-label-content .LineLabelContentEditable {
            font-size: 11px;
            line-height: 14px;
        }
    }

    .app-size-small & {
        .line-label-content .LineLabelContentEditable {
            font-size: 10px;
            line-height: 13px;
        }
    }
}
