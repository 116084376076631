@import '../../../../scss/settings';

.DrawingEditorToolbarIPadPenTools {
    right: -1rem;
    transform: translate3d(100%, 0, 0);

    .Button.DrawingEditorIPadInputButton {
        width: auto;

        font-size: $font-size-ui-small;
        font-weight: $font-weight-semibold;

        .Icon {
            margin-right: 1rem;
        }

        &.warning {
            background: var(--error-background);
        }
    }
}
