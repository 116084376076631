@import '../../../scss/settings';

.DocumentModalSidebar {
    display: flex;
    position: relative;

    flex-shrink: 0;
    justify-content: center;
    width: 10.4rem;

    margin-right: -0.1rem;
    padding-top: 3.6rem;

    &.disabled {
        .DocumentModalTools {
            pointer-events: none;
        }
    }

    .DocumentModalTools {
        width: 5rem;
        overflow: visible;
        z-index: 1;
    }

    &.hide-sidebar-content {
        width: 3.5rem;
    }

    .Tool {
        .tool-name {
            font-size: 1rem;
        }
    }

    // TODO-REMOVE-DRAFT - Remove this first line when Draft.js is removed
    //  It's simply changing to the Draft.js tools when the title is being edited
    //  because the Tiptap editor isn't currently the active editor
    .Tool.ToolbarTool.TextStyleRichTextTool,
    .Tool.ToolbarTool.TiptapTextStyleToolButton {
        padding-top: 0;

        .icon {
            padding: 0.7rem;
            border: 1px solid var(--ui-document-modal-text-tool-border-color);
            background: none;
        }

        &.open {
            .icon {
                background: var(--ui-document-modal-text-tool-border-color);
            }
        }
    }

    // TODO-REMOVE-DRAFT - As above, remove this when Draft.js is removed
    .Tool.ToolbarTool.TextStyleRichTextTool {
        margin-left: -4px;
    }

    .MoreTool.ToolbarPopupTool {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
