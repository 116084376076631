@import '../../../scss/settings';

.LineLabelContentEditable {
    position: relative;

    outline: none;
    line-height: 1.3;
    text-align: center;

    .highlight {
        background: var(--search-color);
        color: var(--search-color-contrast);
    }
}
