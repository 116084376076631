@import '../../scss/mixins/list-placeholder-shadow-mixin';
@import '../elementBox';

.ElementPlaceholder {
    @include element-box;

    &.icon-view-placeholder {
        --element-box-background: var(--ws-icon-view-background-inbox);
        --element-box-shadow: none;
    }
}
