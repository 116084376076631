.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
    content: counter(ol1, lower-latin) '. ';
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
    content: counter(ol2, lower-roman) '. ';
}

.public-DraftStyleDefault-unorderedListItem:before {
    content: '•';
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1:before {
    content: '◦︎';
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth2:before {
    content: '■';
}
