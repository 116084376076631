@import '../../../../scss/settings';

.MentionEntity {
    padding: 0 0.3rem;
    border-radius: 3px;

    background: var(--ws-mention-background);
    color: var(--ws-mention-color);
    font-weight: $font-weight-semibold;

    white-space: nowrap;

    // The padding ends up slightly too heavy on the top at a few sizes, but we
    // can't use padding to remove a line of pixels.
    // So instead, shift the container down, and shift the text inside it up!
    .app-size-large h1 &,
    .app-size-large h2 &,
    .app-size-small h1 & {
        position: relative;
        top: 1px;
        span {
            position: relative;
            top: -1px;
        }
    }

    // Some padding ends up slightly thin on the bottom too but that's much easier
    .app-size-large h1 &,
    .app-size-medium h1 &,
    .app-size-medium h2 &,
    .app-size-small h2 & {
        padding-bottom: 1px;
    }
}
