@import '../../scss/settings';
@import '../elementColoredBackgroundMixin';
@import '../elementBox';
@import '../../list/listElementDraggingMixin';

.Card {
    @include element-box;
    @include colored-background;
    @include list-element-dragging;

    --element-border-color-in-list: var(--ws-element-column-card-border-color);

    &.in-list-column {
        // Ensure the cards sit on perfect grids while in columns
        padding-top: 1px;
        padding-bottom: 1px;
    }

    &.editing,
    &.in-list-document-mode {
        cursor: text;
        user-select: text;
    }

    .Caption {
        .tiptap,
        .public-DraftEditor-content {
            margin-top: -0.4rem;
        }
    }

    &.clipping {
        a {
            color: var(--ws-element-primary);
        }
    }
}
