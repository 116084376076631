@import '../../../scss/settings';

.ProgressBar {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: var(--progress-bar-background);
    overflow: hidden;

    .bar {
        width: 0;
        height: 100%;
        transition: width ease-in 0.2s;
        border-radius: 2px;
        background: var(--progress-bar-color);
    }

    .barbershop {
        background-image: linear-gradient(
            135deg,
            transparent 25%,
            var(--progress-bar-barbershop-color) 25%,
            var(--progress-bar-barbershop-color) 50%,
            transparent 50%,
            transparent 75%,
            var(--progress-bar-barbershop-color) 75%,
            transparent
        );
        background-size: 20px 20px;

        &.animate {
            animation: animate-stripes 3s linear infinite;
        }
    }
}

@keyframes animate-stripes {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 40px 0;
    }
}
