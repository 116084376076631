@import '../../../../../scss/settings';

.TaskDueDatePopupContent {
    .footer {
        display: flex;
        justify-content: space-between;

        margin-top: 2.4rem;
        margin-bottom: 0;
    }

    .time-section {
        display: flex;
        align-items: center;
        margin-top: 1.5rem;
    }

    .reminder-section {
        margin-top: 1rem;

        .explanation {
            width: 100%;
            margin-top: 1rem;
            margin-left: 1rem;

            color: var(--color-neutral-700);
            font-size: $font-size-ui-xsmall;
            text-align: left;
        }
    }
}
