@import '../../scss/settings';

.AdminDashboardHoverReveal {
    position: relative;

    .trigger {
        border-bottom: 0.5px var(--ui-foreground-primary) dotted;
        color: var(--ui-foreground-primary);
        cursor: pointer;
    }

    .hover-popup {
        position: absolute;

        top: calc(100% + 5px);

        width: 100%;
        padding: 5px 10px;

        border: 1px solid $popup-border-color;
        border-radius: 3px;

        background: var(--ui-background-primary-contrast);
        color: var(--ui-foreground-primary-contrast);

        box-shadow: 0 2px 4px $popup-shadow-color;

        z-index: var(--z-index-floating-panels);
    }

    &.fixed {
        .trigger {
            border-bottom: 0.5px $black solid;
            color: $black;
            cursor: pointer;
        }
    }
}
