.tiptap {
    a {
        --ws-text-highlight-color: var(--ui-accent-primary);
        color: var(--ws-element-anchor);
        text-decoration: underline;
        cursor: pointer;

        .colored-background & {
            color: inherit;
        }
    }

    // in edit mode, links should just display as underlined plain text
    &[contenteditable='true']:not(.clickable-links-on-edit) a {
        --ws-text-highlight-color: var(--ws-element-primary);
        color: var(--ws-element-primary);
        cursor: text;
    }
}
