@import '../../scss/settings';

.EmptyTaskListPlaceholder {
    width: 100%;

    .fake-editor {
        border: 0;
        color: var(--ws-element-placeholder);
        font-size: $font-size-element-default;
        line-height: 2rem;
    }
}
