@import '../../scss/settings';

.MobilePageBody {
    display: flex;
    flex-direction: column;

    padding-right: var(--safe-area-right, 0px);
    padding-bottom: var(--workspace-safe-area-bottom);
    padding-left: var(--safe-area-left, 0px);

    overflow: auto;
}
