@import '../../../../../scss/settings';

.DueDateReminderSelect {
    position: relative;
    border: 1px solid var(--ui-calendar-tertiary);
    border-radius: 3px;

    .bell-icon-container {
        display: flex;
        position: absolute;
        top: 0;
        left: 0.8rem;
        align-items: center;
        justify-content: center;
        height: 100%;

        pointer-events: none;

        .Icon {
            width: 2.4rem;
            height: 2.4rem;
        }
    }

    select {
        display: block;
        width: 100%;
        height: 3.5rem;
        margin: 0;
        padding: 0 0 0 4rem;

        border: 0;
        outline: 0;
        background: none;

        color: var(--ui-foreground-primary);
        font-family: $font-body;
        font-size: $font-size-ui-small;
        font-weight: $font-weight-semibold;

        cursor: pointer;

        -moz-appearance: none;
        -webkit-appearance: none;

        &:hover {
            background: var(--color-neutral-400);
        }
    }

    .arrow-icon-container {
        position: absolute;
        top: 0.5rem;
        right: 0.8rem;

        pointer-events: none;
    }

    .app-size-small & {
        .bell-icon-container {
            top: 1px;
        }

        .arrow-icon-container {
            top: 2px;
        }
    }
}
