@import '../../scss/settings';

.PopupPanel.TabbedPopupPanel {
    width: 41.6rem;

    .PopupList {
        .popup-list {
            max-height: var(--header-popup-max-height);
        }
    }

    .popup-content {
        padding: 0;
        border-radius: 0.8rem;
    }

    .PopupPanelMobileContainer & {
        .popup-content {
            padding: 0;
        }
    }
}
