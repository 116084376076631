// Delays the reveal of a popup - this is used in instances when a popup might show on page load, but
// we don't want it to when the OneTrust cookie banner is showing
@keyframes ot-delay-reveal-popup {
    0%,
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
