@import '../../../scss/settings';

.FilePreviewUnavailable {
    display: flex;
    position: relative;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
    min-height: 16rem;

    background: var(--ws-element-background-secondary);
    color: var(--ui-foreground-tertiary);
    font-size: $font-size-element-default;

    .unavailable-message {
        display: flex;
        position: absolute;

        top: 0;
        left: 0;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        .FileIcon {
            width: 14rem;
            height: 14rem;
            filter: brightness(0) invert(100%) brightness(0.65);
        }
    }
}
