@mixin sheet-transitions-mixin($distance: 30px, $time: 0.5s, $easing: cubic-bezier(0.32, 0.72, 0, 1)) {
    .Overlay {
        transition: opacity $time $easing;
        opacity: 0;
    }

    .Sheet:not(.dragging) {
        transition: all $time $easing;
        transition-property: transform;
    }
}
