@import '../../../../../scss/settings';

.CustomColorTool {
    position: relative;

    .Tool.ToolbarTool {
        padding-top: 18px;
    }

    .app-size-small & {
        .Tool.ToolbarTool {
            padding-top: 16px;
        }
    }

    .custom-color-picker-input {
        position: absolute;
        width: 100%;
        height: 100%;

        opacity: 0;
    }
}
