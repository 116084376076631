@import '../../../../../scss/settings';

.ColorToolIcon {
    // This ensures that the white colour button stands out from the background
    --ws-no-color-button-border-color: #{$grey-600};
    --ui-color-tool-icon-color-bar-height: 4px;
    --ui-color-button-background: var(--ws-element-background-primary);

    position: relative;

    width: 32px;
    height: 28px;
    padding: 6px;

    // calculated from SVG path border radius, which doesn't have units
    border-radius: var(--tool-icon-border-radius);

    background: var(--ui-toolbar-tool-background);

    .color-icon-container {
        width: 100%;
        height: 100%;

        border-radius: 0.2rem;

        background: var(--ui-color-button-background);
    }

    .color-bar-icon {
        width: 100%;
        height: var(--ui-color-tool-icon-color-bar-height);

        border-radius: 0;
        border-top-left-radius: 0.15rem;
        border-top-right-radius: 0.15rem;

        background: var(--ws-element-background-primary);
    }

    // Give a bit of space between the color bar and the letter icon
    &.show-dual-color {
        --ui-color-tool-icon-color-bar-height: 3px;

        .letter-icon {
            font-size: 1.2rem;
        }
    }

    &.no-color.support-color-bar:not(.support-background-color) {
        .color-icon-container {
            height: 100%;
            border-radius: 0.15rem;

            background-image: linear-gradient(
                141deg,
                #{$white-opacity-0} 45%,
                var(--ui-color-picker-no-color-stroke-color) 46%,
                var(--ui-color-picker-no-color-stroke-color) 54%,
                #{$white-opacity-0} 55%
            );
        }
    }

    &.transparent {
        .color-icon-container.background-color-target {
            background-image: url('transparency-pattern-light.gif');
            image-rendering: pixelated;
        }

        .color-icon-container {
            background-image: url('transparency-pattern-light.gif');
            background-position: -4px 0;
            image-rendering: pixelated;
        }

        .dark-theme & {
            .color-icon-container.background-color-target {
                background-image: url('transparency-pattern-dark.gif');
            }

            .color-icon-container {
                background-image: url('transparency-pattern-dark.gif');
            }
        }
    }

    .toolbar-size-small & {
        --ui-color-tool-icon-color-bar-height: 3px;

        width: 28px;
        height: 25px;

        padding: 5px;
    }

    .MobileToolbar & {
        width: 40px;
        height: 40px;
        padding: 8px;

        .letter-icon {
            font-size: 20px;
        }
    }
}
