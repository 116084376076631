@import '../../../../scss/settings';

.DrawingEditorCanvasEraseMode {
    --ws-drawing-erase-highlight-border-color: rgba(255, 0, 0);

    // stylelint-disable declaration-block-no-duplicate-properties
    cursor: url('./cursors/cursor-drawing-eraser.png') 8 21, auto;
    cursor: image-set(url('./cursors/cursor-drawing-eraser.png') 1x, url('./cursors/cursor-drawing-eraser@2x.png') 2x) 8
            21,
        auto;
    cursor: -webkit-image-set(
                url('./cursors/cursor-drawing-eraser.png') 1x,
                url('./cursors/cursor-drawing-eraser@2x.png') 2x
            )
            8 21,
        auto;
    // stylelint-enable declaration-block-no-duplicate-properties

    .DrawingPath {
        &.stroke-only {
            pointer-events: stroke;
        }

        &:not(.stroke-only) {
            stroke: transparent;
            stroke-width: 1.4rem;
        }

        &.deletable-hovered {
            pointer-events: none;

            &.outline {
                stroke: var(--ws-drawing-erase-highlight-border-color);

                &:not(.stroke-only) {
                    stroke-width: calc(3px / var(--drawing-canvas-scale));
                }
            }
        }
    }
}
