$max-depth: 8;

$list-style-types: decimal, lower-latin, lower-roman;
$sel: 'ol';

.tiptap {
    --list-start: 0;

    ul,
    ol {
        margin-left: 2.7rem;

        li {
            p {
                margin-left: 1.2rem;

                // Ensure that text-align properties aren't accidentally rendered
                text-align: unset !important;
            }
        }

        // Placeholder
        &.is-editor-empty:first-child::before {
            position: relative;
            left: 1.2rem;
            width: auto;
        }
    }

    ul {
        list-style-type: disc;
    }

    // Ensure list numbers are left-aligned & don't cause the text to be pushed right when the
    // width of the numbers changes.
    ol {
        list-style-type: none;
        // WEB-11755 - We need to use a custom counter, rather than the OOTB "list-item" counter
        //  due to a bug in Chrome where it won't re-render the list's numbers after deleting
        //  an item. Using a custom counter fixes this issue.
        counter-reset: list-counter var(--list-start);

        > li {
            position: relative;
            counter-increment: list-counter;

            &::before {
                position: absolute;
                left: -1.5rem;
                width: 2.7rem;
                text-align: left;
                content: counter(list-counter, decimal) ". ";
            }
        }
    }

    // If the list starts at the root level, it needs a different padding
    > ul,
    > ol {
        margin-left: 1.5rem;
    }

    // Chooses the correct list style type based on the depth of the list
    @for $i from 1 through $max-depth - 1 {
        $sel: if($i == 1, ".list-el", selector-nest($sel, ".list-el"));
        $index: $i % length($list-style-types);

        $list-style-type: nth($list-style-types, $index + 1);

        #{$sel} ol > li::before {
            content: counter(list-counter, $list-style-type) ". ";
        }
    }
}
