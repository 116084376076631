@import '../../../scss/settings';
@import '../../../scss/mixins/mini-scrollbar-mixin';

.CardCloneInstancesPopupContentMultiBoards {
    @include mini-scrollbar-mixin;

    max-height: 35rem;

    .CloneInstanceBoardEntry {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3.7rem;

        padding: 1rem 0.8rem 1rem 1.2rem;

        border-bottom: 1px solid var(--ui-modal-divider);

        cursor: pointer;
        overflow: hidden;

        .title {
            flex-grow: 1;
            flex-shrink: 1;

            color: var(--ui-foreground-primary);
            font-size: $font-size-ui-small;
            font-weight: $font-weight-semibold;

            text-align: left;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;

            overflow: hidden;
        }

        .chevron-right {
            flex-shrink: 0;
        }

        .BreadcrumbIcon {
            margin-bottom: 0;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}
