.ColorPopupContent {
    .colors {
        display: grid;
        grid-template-columns: repeat(5, 2.4rem);
        row-gap: 0.8rem;
        column-gap: 0.8rem;

        li {
            display: inline-flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: min-content;
        }
    }

    .divider {
        margin-top: 2rem;
        margin-right: -2.3rem;
        margin-bottom: 2rem;
        margin-left: -2.3rem;

        border-bottom: 1px solid var(--ui-modal-divider);
    }
}
