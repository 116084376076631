// Weights
$font-weight-semibold: 600;

// Sizes
$font-size-ui-xxxlarge: var(--font-size-ui-xxxlarge);
$font-size-ui-xxlarge: var(--font-size-ui-xxlarge);
$font-size-ui-xlarge: var(--font-size-ui-xlarge);
$font-size-ui-large: var(--font-size-ui-large);
$font-size-ui-default: var(--font-size-ui-default);
$font-size-ui-small: var(--font-size-ui-small);
$font-size-ui-xsmall: var(--font-size-ui-xsmall);
$font-size-ui-xxsmall: var(--font-size-ui-xxsmall);
// UI Chrome
$font-size-page-heading: var(--font-size-page-heading);
$font-size-tool-large: var(--font-size-tool-large);
$font-size-tool: var(--font-size-tool);
$font-size-static-header: var(--font-size-static-header);
$font-size-static-header-secondary: var(--font-size-static-header-secondary);
$font-size-tool-secondary: var(--font-size-tool-secondary);
$font-size-toolbar: var(--font-size-toolbar);
$font-size-toolbar-small: var(--font-size-toolbar-small);
// UI
$font-size-ui-page-heading: $font-size-ui-xxxlarge;
$font-size-ui-modal-heading: $font-size-ui-xxlarge;
$font-size-ui-modal-subheading: 1.5rem;

// Elements
$font-size-column-heading: var(--font-size-column-heading);
$font-size-element-heading: var(--font-size-element-heading);
$font-size-board-heading: var(--font-size-board-heading);
$font-size-link-file-heading: var(--font-size-link-file-heading);
$font-size-element-default: var(--font-size-element-default);
$font-size-element-indicator: var(--font-size-element-indicator);
$font-size-element-secondary: var(--font-size-element-secondary);
$font-size-comment: var(--font-size-comment);
$font-size-element-messaging: var(--font-size-element-messaging);

// Letter spacing
$letter-spacing-page-title: var(--letter-spacing-page-title);
$letter-spacing-column-heading: var(--letter-spacing-column-heading);
$letter-spacing-heading: var(--letter-spacing-heading);
$letter-spacing-default: var(--letter-spacing-default);
$letter-spacing-body-text: var(--letter-spacing-body-text);

// Font-faces
$font-ui-heading-name: var(--font-ui-heading);
$font-body-name: var(--font-default);
$font-element-heading-name: var(--font-element-heading);
$font-column-heading-name: var(--font-column-heading);
$font-board-heading-name: var(--font-board-heading);
$font-link-file-heading-name: var(--font-link-file-heading);
$font-element-quote-name: var(--font-element-quote);
$font-title-name: var(--font-page-title);
$font-monospace-name: var(--font-element-code);
$font-icon-name: var(--font-toolbar);
$font-modal-heading: var(--font-ui-modal-heading);

$font-basic: Helvetica, Arial, sans-serif;
$font-serif-basic: 'Georgia', 'Times New Roman', Times, serif;
$font-brand: 'Tiempos', 'Georgia', 'Times New Roman', Times, serif;
$font-body: $font-body-name, $font-basic;
$font-ui-heading: $font-ui-heading-name, $font-serif-basic;
$font-element-heading: $font-element-heading-name, $font-serif-basic;
$font-column-heading: $font-column-heading-name, $font-serif-basic;
$font-board-heading: $font-board-heading-name, $font-basic;
$font-link-file-heading: $font-link-file-heading-name, $font-basic;
$font-title: $font-title-name, $font-serif-basic;
$font-element-quote: $font-element-quote-name, $font-basic;
$font-monospace: $font-monospace-name, 'Menlo', 'Courier New', Courier, monospace;
$font-icon: $font-icon-name, $font-basic;

$font-family-mobile-system: -apple-system, "system-ui", $font-basic;

// Font ratios (used for text-cropping - utils.scss)
$cap-ratio-inter: calc(8 / 11);
