@import '../../../../../../scss/settings';

.ColorPopupModeSwitcher {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.8rem;

    margin-top: -1.6rem;
    margin-right: -1.6rem;
    margin-bottom: 2.4rem;
    margin-left: -1.6rem;

    .color-mode-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding-top: 1rem;
        padding-bottom: 0.9rem;

        border-radius: 0.4rem;

        color: var(--ui-color-picker-mode-tab-inactive-color);

        font-size: var(--font-size-ui-xsmall);
        font-weight: 600;
        letter-spacing: 0.01px;

        .Icon {
            margin-bottom: 0.7rem;

            .fill {
                fill: var(--ui-color-picker-mode-tab-inactive-color);
            }
        }

        &.active {
            background: var(--ui-modal-background-tab-active);
            color: var(--ui-modal-foreground-primary);

            .Icon .fill {
                fill: var(--ui-modal-foreground-primary);
            }
        }
    }
}
