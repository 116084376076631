@import '../../scss/settings';
@import '../elementBox';

.ElementContainerErrorRenderer {
    &.dragging {
        visibility: hidden;
        pointer-events: none;
    }
}

.ElementErrorRenderer {
    @include element-box;

    width: 34rem;
    padding: 1.6rem;
    background: var(--error-background);

    .Column & {
        width: auto;
    }
}
