@import '../../../../scss/settings';

.ImageModalViewToolbar {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
