@import '../../../scss/settings';
@import '../../../scss/mixins/outer-border-mixin';

.Tool.ToolbarTool {
    .icon {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        width: var(--tool-icon-size);
        height: var(--tool-icon-size);

        .Icon {
            width: var(--tool-icon-size);
            height: var(--tool-icon-size);
            border-radius: var(--tool-icon-border-radius);
        }

        .background,
        rect:not(.path) {
            fill: var(--ui-toolbar-tool-background);
            rx: var(--tool-icon-border-radius);
        }

        .path,
        path:not(.decoration) {
            fill: var(--ui-tool-path);
        }
    }

    .tool-name {
        margin-top: 5px;
    }

    &.open {
        .contents .icon rect {
            fill: var(--ui-toolbar-tool-popup-open-background);
        }
    }

    &.active {
        .icon {
            @include overlapping-border-full(2px, var(--ui-tool-path), 4px);
        }
    }

    &.disabled {
        opacity: 1;

        .Icon {
            path {
                opacity: 0.2;
            }
        }
    }

    &.disabled.active {
        .icon {
            opacity: 0.4;
        }
    }
}
