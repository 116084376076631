@import '../../../scss/settings';

.RichMediaErrorOverlay {
    .error-alert {
        padding-bottom: 1.6rem;

        g {
            fill: var(--ui-background-primary);
        }
    }
}
